import { FC, useCallback } from "react";
import { Menu, MenuItem } from '@material-ui/core';
import { AccountCircleRounded } from "@material-ui/icons";

import strings from "../../../strings";
import { ResponsiveTypography } from '../../shared';
import styles from './style';
import { useHistory } from "react-router";

type TableActionsProps = {
    anchorEl: HTMLElement | null;
    open: boolean;
    selectedId: number | null;
    language: LanguageType;
    onClose: () => void;
}

const TableActions: FC<TableActionsProps> = (props) => {
    const { anchorEl, open, onClose, selectedId, language } = props;
    const history = useHistory();
    const classes = styles();

    const handleOpenItem = useCallback(() => {
        if (selectedId) history.push(strings.pages.EXECUTION(selectedId).url);
        onClose();
    }, [selectedId, onClose])

    return (
        <Menu
            elevation={2}
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <MenuItem
                onClick={handleOpenItem}
            >
                <AccountCircleRounded className={classes.icon} />
                <ResponsiveTypography fontWeight={800}>
                    {strings.actions.GO_TO_PAGE(strings.pages.EXECUTION(selectedId as number).label[language])[language]}
                </ResponsiveTypography>
            </MenuItem>
        </Menu>
    );
}

export default TableActions;