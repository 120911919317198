import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    title: {
        margin: theme.spacing(0, 0, 2, 0),
    },
    root_paper: {
        overflow: 'hidden',
        marginTop: theme.spacing(4),
        padding: theme.spacing(2, 2, 0, 2),
        backgroundColor:
            theme.palette.type === 'dark'
                ? theme.palette.background.default
                : theme.palette.background.paper,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 0, 0, 0),
        },
    },
    templateActions: {
        display: 'flex',
        padding: theme.spacing(0, 4, 2, 4),
    },
    actionButton: {
        marginRight: theme.spacing(2),
    },
    buttonIcon: {
        marginRight: theme.spacing(2),
    },
    checkbox: {
        userSelect: 'none',
    },
}))
