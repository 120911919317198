import { Box, ClickAwayListener, Divider, Grow, MenuList, Paper, Popper, SvgIconProps, useTheme, Link as MuiLink } from '@material-ui/core';
import { ClearRounded, StarRounded } from '@material-ui/icons';
import moment from 'moment';
import clsx from 'clsx';
import { FC } from 'react';
import { ResponsiveTypography } from '../../../shared';
import CustomBox from '../../../shared/CustomBox';
import CustomIconButton from '../../../shared/CustomIconButton';
import CustomMenuItem from '../../../shared/CustomMenuItem';
import styles from './style';
import strings from '../../../../strings';
import { Link } from 'react-router-dom';

moment.updateLocale('ro', {
    relativeTime: {
        future: "în %s",
        past: "%s",
        s: 'acum câteva secunde',
        ss: '%d secunde',
        m: "acum un minut",
        mm: "%d minute",
        h: "acum o oră",
        hh: "%d ore",
        // d: "1d",
        // dd: "%dd",
        // w: "1w",
        // ww: "%dw",
        // M: "1m",
        // MM: "%dm",
        // y: "1y",
        // yy: "%dy"
    }
});

type MainMenuProps = {
    anchorEl: Element | null;
    open: boolean;
    onClose: (event: any) => void;
    language: LanguageType;
    notifications: NotificationProps[];
    auth?: AuthProps;
    onNotificationRead: (id: number, e: any) => void;
    onNotificationClick: (type: NotificationType, relatedId: number, id: number) => void;
    onSoftReadNotifications: () => void;
}

const MainMenu: FC<MainMenuProps> = ({
    anchorEl,
    open,
    onClose,
    notifications,
    onNotificationRead,
    onNotificationClick,
    onSoftReadNotifications,
    language,
}) => {
    const classes = styles();
    const theme = useTheme();

    return (
        <Popper
            open={open}
            anchorEl={anchorEl}
            role={undefined}
            transition
            disablePortal
            style={{zIndex: 2101}}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === "bottom" ? "center top" : "center bottom",
                    }}
                >
                    <Paper className={classes.menuPaper}>
                        <ClickAwayListener onClickAway={onClose}>
                            <Box style={{ position: 'relative' }}>
                                <Box className={classes.header}>
                                    <ResponsiveTypography fontWeight={600} lgDown={18}>
                                        {strings.general.NOTIFICATIONS[language]}
                                    </ResponsiveTypography>
                                    <MuiLink underline='none' className={classes.linkButton} onClick={onSoftReadNotifications}>
                                        <ResponsiveTypography color={theme.palette.secondary.main} fontWeight={600} lgDown={12}>
                                            {strings.general.MARK_ALL_READ[language]}
                                        </ResponsiveTypography>
                                    </MuiLink>
                                </Box>
                                <Divider />
                                {notifications.length === 0 && <ResponsiveTypography className={classes.title} lgDown={15}>{strings.general.NO_NOTIFICATIONS[language]}</ResponsiveTypography>}
                                <MenuList className={classes.list} autoFocusItem={open} id="menu-list-grow">
                                    {notifications?.map((item: NotificationProps, index: number) => (
                                        <MainMenuItem
                                            key={index}
                                            title={`New user`}
                                            subtitle={`User ${item.relatedId} has requested access`}
                                            time={moment(item.createdAt).fromNow()}
                                            icon={<StarRounded className={clsx(!item.softRead && classes.readIcon)} />}
                                            onClick={() => onNotificationClick(item.type, item.relatedId, item.id)}
                                            onRead={(e) => onNotificationRead(item.id, e)}
                                            read={item.softRead}
                                        />
                                    ))}
                                </MenuList>
                                <Divider />
                                <Link to='/'>
                                    <ResponsiveTypography color={theme.palette.secondary.main} className={classes.title} fontWeight={600} lgDown={15}>
                                        {strings.general.SEE_ALL_NOTIFICATIONS[language]}
                                    </ResponsiveTypography>
                                </Link>
                            </Box>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
};

export default MainMenu;

type MainMenuItemProps = {
    title: string;
    subtitle?: string;
    time?: string;
    onClick?: () => void;
    onRead?: (e?: any) => void;
    icon?: SvgIconProps;
    read: boolean;
}

const MainMenuItem: FC<MainMenuItemProps> = ({
    title,
    subtitle,
    time,
    icon,
    onClick,
    onRead,
    read
}) => {
    const classes = styles();
    const theme = useTheme();

    return (
        <CustomMenuItem className={classes.menuItem} flex justifyContent='space-between' alignItems='center' padding={8} borderRadius={8} onClick={onClick}>
            <CustomBox flex justifyContent='space-around' alignItems='center'>
                <Box className={classes.icon}>{icon}</Box>
                <Box className={classes.text}>
                    <ResponsiveTypography lgDown={15}>{title}</ResponsiveTypography>
                    {subtitle && <ResponsiveTypography lgDown={13} opacity={80}>{subtitle}</ResponsiveTypography>}
                    <ResponsiveTypography color={theme.palette.secondary.main} lgDown={10}>{time}</ResponsiveTypography>
                </Box>
            </CustomBox>
            <CustomIconButton className={classes.deleteIcon} onClick={onRead}><ClearRounded fontSize='small' /></CustomIconButton>
        </CustomMenuItem>
    );
};