export const SIGN_IN = {
    ro: 'Autentifică-te',
    en: 'Log In',
}
export const SIGN_UP = {
    ro: 'Creează cont',
    en: 'Create New Account',
}
export const LOG_OUT = {
    ro: 'Deloghează-te',
    en: 'Log out',
}
export const LOG_OUT_CONFIRM = {
    ro: 'Ești sigur că vrei să te deconectezi?',
    en: 'Are you sure you want to log out?',
}
export const CONFIRM_ACTION = {
    ro: 'Ești sigur că vrei să faci această operațiune?',
    en: 'Are you sure you want to proceed?',
}
export const GO_TO_PAGE = (page: string) => ({
    ro: `Vezi ${page.toLowerCase()}`,
    en: `Go to ${page.toLowerCase()}`,
})
export const SEARCH_PAGE = (page: string) => ({
    ro: `Caută ${page.toLowerCase()}`,
    en: `Search ${page.toLowerCase()}`,
})
export const EDIT = (field: string) => ({
    ro: `Editează ${field.toLowerCase()}`,
    en: `EDIT ${field.toLowerCase()}`,
})
export const ACCEPT = (field: string) => ({
    ro: `Acceptă ${field.toLowerCase()}`,
    en: `Accept ${field.toLowerCase()}`,
})
export const QUOTE_ACCEPTED = {
    ro: 'Ofertă acceptată',
    en: 'Quote accepted',
}
export const ASSIGN_TO_ME = {
    ro: 'Preia',
    en: 'Assign to me',
}
export const DECLINE = (field: string) => ({
    ro: `Refuză ${field.toLowerCase()}`,
    en: `Decline ${field.toLowerCase()}`,
})
export const ACTIVATE_DEACTIVATE = (field: boolean) =>
    field
        ? {
              ro: 'Dezactivează',
              en: 'Deactivate',
          }
        : {
              ro: 'Activează',
              en: 'Activate',
          }
export const BACK = {
    ro: 'Înapoi',
    en: 'Back',
}
export const NEXT = {
    ro: 'Continuă',
    en: 'Next',
}
export const DONE = {
    ro: 'Finalizează',
    en: 'Done',
}
export const CHANGE = {
    ro: 'Modifică',
    en: 'Change',
}
export const CLOSE = {
    ro: 'Închide!',
    en: 'Close!',
}
export const SAVE = {
    ro: 'Salvează',
    en: 'Save',
}
export const CANCEL = {
    ro: 'Renunță',
    en: 'Cancel',
}
export const ADD = {
    ro: 'Adaugă',
    en: 'Add',
}
export const DELETE = {
    ro: 'Șterge',
    en: 'Delete',
}
export const SCHEDULE = {
    ro: 'Programează',
    en: 'Schedule',
}
export const DOWNLOAD_CONTRACT = {
    ro: 'Descarcă contract',
    en: 'Download contract',
}
export const DOWNLOAD_QUOTE = {
    ro: 'Descarcă oferta',
    en: 'Download quote',
}
export const CREATE_PROJECT = {
    ro: 'Creează proiect',
    en: 'Create project',
}
export const ADD_NEW_ROLE = {
    title: {
        ro: 'Adaugă rol',
        en: 'Add new role',
    },
    subtitle: {
        ro: 'Definește un nou rol de acces',
        en: 'Define a new access role',
    },
}
export const DELETE_ROLE = {
    title: {
        ro: 'Șterge rol',
        en: 'Delete role',
    },
    subtitle: {
        ro: 'Ești sigur că vrei să ștergi acest rol? Această acțiune este ireversibilă!',
        en: 'Are you sure you want to delete this role?',
    },
}
export const ADD_NEW_PRODUCT = {
    title: {
        ro: 'Adaugă produs',
        en: 'Add new product',
    },
    subtitle: {
        ro: 'Definește un nou produs',
        en: 'Define a new product',
    },
}
export const ADD_NEW_SERVICE_TYPE = {
    title: {
        ro: 'Adaugă tip serviciu',
        en: 'Add new service type',
    },
    subtitle: {
        ro: 'Definește un nou tip de serviciu',
        en: 'Define a new service type',
    },
}
export const ADD_NEW_SERVICE = {
    title: {
        ro: 'Adaugă serviciu',
        en: 'Add new service',
    },
}
export const ADD_NEW_CATEGORY = {
    title: {
        ro: 'Adaugă categorie',
        en: 'Add new category',
    },
    subtitle: {
        ro: 'Definește o nouă categorie',
        en: 'Define a new category',
    },
}
export const ADD_NEW_CUSTOMER = {
    title: {
        ro: 'Adaugă client',
        en: 'Add new customer',
    },
    subtitle: {
        ro: 'Creează un nou client',
        en: 'Create a new customer',
    },
}
export const ADD_NEW_VEHICLE = {
    title: {
        ro: 'Adaugă vehicul',
        en: 'Add new vehicle',
    },
    subtitle: {
        ro: 'Definește un nou vehicul în flotă',
        en: 'Define a new vehicle in the fleet',
    },
}
export const ADD_COMMENT = {
    ro: 'Adaugă comentariu',
    en: 'Add comment',
}
export const ADD_ADDRESS = {
    ro: 'Adaugă adresă',
    en: 'Add address',
}
export const ADD_BUSINESS = {
    ro: 'Adaugă companie',
    en: 'Add business',
}
export const ADD_PROXY = {
    ro: 'Adaugă persoană împuternicită',
    en: 'Add proxy',
}
export const ADD_PHONE = {
    ro: 'Adaugă telefon',
    en: 'Add phone',
}
export const GENERATE_TICKETS = {
    ro: 'Generează bilete',
    en: 'Generate tickets',
}
export const FILTER = {
    ro: 'Filtrează',
    en: 'Filter',
}
export const CANCEL_EXECUTION = {
    ro: 'Anulează',
    en: 'Cancel',
}
export const SAVE_AS_TEMPLATE = {
    ro: 'Salvează ca șablon',
    en: 'Save as template',
}
export const LOAD_TEMPLATE = {
    ro: 'Încarcă șablon',
    en: 'Load template',
}
export const GENERATE_GDPR = {
    ro: 'Generează formular GDPR',
    en: 'Generate GDPR form',
}
export const BLOCK = {
    ro: 'Blocare',
    en: 'Block',
}
export const BLOCK_CONFIRM = {
    ro: 'Ești sigur că vrei să blochezi acest utilizator?',
    en: 'Are you sure you want to block this user?',
}
export const UNBLOCK = {
    ro: 'Deblocare',
    en: 'Unlock',
}
export const UNBLOCK_CONFIRM = {
    ro: 'Ești sigur că vrei să deblochezi acest utilizator?',
    en: 'Are you sure you want to unblock this user?',
}
export const RESET = {
    ro: 'Resetare',
    en: 'Reset',
}
export const RESET_CONFIRM = {
    ro: 'Ești sigur că vrei să resetezi parola acestui utilizator?',
    en: "Are you sure you want to reset this user' password?",
}
export const VALIDATE = {
    ro: 'Validează',
    en: 'Validate',
}
export const VALIDATE_CONFIRM = {
    ro: 'Ești sigur că vrei să validezi acest utilizator?',
    en: 'Are you sure you want to validate this user?',
}
export const GDPR_CONFIRM = {
    title: {
        ro: 'Accept politica GDPR',
        en: 'I accept GDPR policy',
    },
    subtitle: {
        ro: '* Acest camp este obligatoriu',
        en: '* This field is required',
    },
}

export const SAVE_PRODUCT = {
    title: {
        ro: 'Salvează',
        en: 'Save',
    },
    content: {
        ro: 'Ești sigur că vrei să salvezi?',
        en: 'Are you sure you want to save this?'
    }
}