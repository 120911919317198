import { FC } from "react";

import styles from './style';
import { CustomWrapper, FieldWithLabel } from "../../shared";

type AddressSectionProps = {
    language: LanguageType;
    data: CustomerAddressProps | undefined;
    title: string;
}
const AddressSection: FC<AddressSectionProps> = (props) => {
    const classes = styles();
    const { data, title } = props;


    return (
        <CustomWrapper title={title} className={classes.root}>
            <FieldWithLabel
                value={data?.addressString || '-'}
            />
        </CustomWrapper>
    )
}
export default AddressSection;