import { ChangeEvent, useEffect, useState, useCallback, FC } from "react";
import {
  Paper,
  Box,
  Grid,
  Button,
  TextField,
  Divider,
  CircularProgress,
  MenuItem,
  InputAdornment,
  IconButton,
  useTheme,
  Slide,
} from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import requests from "../../requests";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import styles from "./style";
import strings from "../../strings";
import { useHistory } from "react-router-dom";
import { ReactComponent as RaisisCRMLogo } from "../../resources/svg/logo.svg";
import { ReactComponent as RaisisCRMLogoDark } from "../../resources/svg/logo-white.svg";

const SignUp: FC<PageBasePropsNew> = ({ language, onClose }) => {
  const history = useHistory();
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [credentials, setCredentials] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    roleId: null,
    validated: true,
  });

  // roles testing start
  const { data: rolesData, refetch: refetchRoles } = useQuery(requests.user.query.SEARCH_ROLES, {
    fetchPolicy: "no-cache",
});

type RoleProps = {
  id: number;
  name: string;
  value: number;
  // permissions: boolean[];
  // noOfPermissions: number;
}

const [roleList, setRoleList] = useState<RoleProps[]>([]);

useEffect(() => {
  if (rolesData?.roles)
      setRoleList(rolesData.roles)
}, [rolesData])
  // roles testing end
  

  const togglePasswordVisibility = useCallback(() => {
    setPasswordVisibility(!passwordVisibility);
  }, [passwordVisibility]);

  const updateField = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    setCredentials({ ...credentials, [name]: value });
    if (name === "password" && value === "") setPasswordVisibility(false);
  };

  const [signIn, { loading: signUpLoading }] = useMutation(
    requests.user.mutation.SIGN_UP_QUERY
  );

  useEffect(() => {
    document.title = `Corsem - ${strings.pages.SIGN_UP.label[language]}`;
  }, [language]);

  // useEffect(() => {
  //     if (auth.isAuthenticated) auth.login();
  // }, [auth]);

  return (
    <Grid
      container
      component="main"
      direction="column"
      justify="space-evenly"
      alignItems="center"
      alignContent="center"
      className={classes.root}
    >
      <Grid item lg={3}>
        <Slide direction="down" in={true} mountOnEnter unmountOnExit>
          {theme.palette.type === "light" ? (
            <RaisisCRMLogo className={classes.logo} />
          ) : (
            <RaisisCRMLogoDark className={classes.logo} />
          )}
        </Slide>
      </Grid>
      <Slide direction="up" in={true} mountOnEnter unmountOnExit>
        <Paper className={classes.paper}>
          <form
            className={classes.form}
            onSubmit={(event) => {
              event.preventDefault();
              signIn({
                variables: { ...credentials },
              })
                .then((response) => {
                  // history.push(strings.pages.SIGN_IN.url);
                  onClose();
                  enqueueSnackbar(
                    strings.notifications.SIGN_UP_SUCCESS[language],
                    {
                      variant: "success",
                    }
                  );
                })
                .catch((error) => {
                  enqueueSnackbar(
                    error.message in strings.notifications
                      ? strings.notifications[
                          error.message as GlobalNotification
                        ][language]
                      : error.message,
                    {
                      variant: "error",
                    }
                  );
                });
            }}
          >
            <Grid container spacing={0}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label={strings.general.FIRST_NAME[language]}
                    name="firstName"
                    autoComplete="firstName"
                    onChange={updateField}
                    classes={{
                      root: classes.textField,
                    }}
                    // autoFocus
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label={strings.general.LAST_NAME[language]}
                    name="lastName"
                    autoComplete="lastName"
                    onChange={updateField}
                    classes={{
                      root: classes.textField,
                    }}
                    // autoFocus
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={strings.general.EMAIL[language]}
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={updateField}
                  classes={{
                    root: classes.textField,
                  }}
                  // autoFocus
                />
              </Grid>

              {/* role */}
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="roleId"
                  label={strings.general.ROLE[language]}
                  name="roleId"
                  select
                  onChange={updateField}
                  classes={{
                    root: classes.textField,
                  }}
                >
                  {roleList.map((role, index) => (
                    <MenuItem key={role.id} value={role.id}>
                      {`${index + 1} - `}{role.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {/* role end */}
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={strings.general.PASSWORD[language]}
                  type={passwordVisibility ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ opacity: credentials.password === "" ? 0 : 1 }}
                      >
                        <IconButton
                          size="small"
                          onClick={togglePasswordVisibility}
                        >
                          {passwordVisibility ? (
                            <VisibilityOff fontSize="small" />
                          ) : (
                            <Visibility fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={updateField}
                />
              </Grid>
            </Grid>
            <Box className={classes.buttonWrapper}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                size="large"
                disabled={signUpLoading}
                className={classes.submit}
              >
                {strings.actions.SIGN_UP[language]}
              </Button>
              {signUpLoading && (
                <CircularProgress className={classes.progress} />
              )}
            </Box>
            <Divider className={classes.divider} />
            {/* <Grid container justify="center">
                            <Button
                                variant="contained"
                                color="primary"
                                size='large'
                                onClick={() => history.push(strings.pages.SIGN_IN.url)}
                                disabled={signUpLoading}
                                className={classes.submit}
                            >
                                {strings.actions.SIGN_IN[language]}
                            </Button>
                        </Grid> */}
          </form>
        </Paper>
      </Slide>
    </Grid>
  );
};

export default SignUp;
