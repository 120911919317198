import React, { FC, useCallback } from "react"
import { makeStyles, Box } from "@material-ui/core"
import clsx from "clsx";

type ComponentType = {
    children?: React.ReactNode;
    className?: any;
    flex?: boolean;
    flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse' | 'unset' | 'revert' | 'initial' | '-moz-initial' | 'inherit';
    justifyContent?: 'space-around' | 'space-between' | 'space-evenly' | 'flex-end' | 'flex-start' | 'center';
    alignItems?: 'space-around' | 'space-between' | 'space-evenly' | 'flex-end' | 'flex-start' | 'center';
    width?: any;
    margin?: any;
    padding?: any;
    borderRadius?: number;
}

const customClass = (flex = false, flexDirection = 'row', justifyContent = 'flex-start', alignItems = 'flex-start', width = 'auto', margin = 0, padding = 0, borderRadius = 0) => {
    const customStyle = makeStyles(() => ({
        root: {
            display: flex ? 'flex' : 'initial',
            flexDirection: flexDirection === 'row' ? 'row' : flexDirection === 'row-reverse' ? 'row-reverse' : flexDirection === 'column' ? 'column' : flexDirection === 'column-reverse' ? 'column-reverse' : 'initial',
            justifyContent,
            alignItems,
            width,
            margin,
            padding,
            borderRadius,
        },
    }))();
    return customStyle.root;
};

const CustomBox: FC<ComponentType> = (props) => {

    const { children, className, flex, flexDirection, justifyContent, alignItems, width, margin, padding, borderRadius } = props;

    const getCustomClass = useCallback(() => customClass(flex, flexDirection, justifyContent, alignItems, width, margin, padding, borderRadius), [flex, flexDirection, justifyContent, alignItems, width, margin, padding, borderRadius]);

    return (
        <Box
            className={
                clsx({
                    [getCustomClass()]: true,
                    [className]: !!className
                })
            }>
            {children}
        </Box>)
}

export default CustomBox;