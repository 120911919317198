import { Box, Divider } from "@material-ui/core";
import React, { FC } from "react";
import clsx from 'clsx';
import ResponsiveTypography from "../ResponsiveTypography";
import styles from './style';

type CustomWrapperProps = {
    children?: React.ReactNode;
    className?: string;
    title?: string;
    link?: React.ReactNode;
}

const CustomWrapper: FC<CustomWrapperProps> = ({ children, title, className, link }) => {
    const classes = styles();
    return (
        typeof title === 'string' ?
            <Box className={clsx(classes.root, className)}>
                <Box
                    className={classes.title}
                >
                    <ResponsiveTypography
                        fontWeight={600}
                        growthRate={50}
                        lgDown={15}
                        mdDown={20}
                        smDown={15}
                    >
                        {title.toUpperCase()}
                    </ResponsiveTypography>
                    {link}
                </Box>
                {typeof children !== 'undefined' &&
                    <Divider className={classes.divider} />
                }
                {typeof children !== 'undefined' &&
                    <Box className={classes.content}>
                        {children}
                    </Box>
                }
            </Box>
            :
            <Box className={classes.root}>
                {children}
            </Box>
    )
}
export default CustomWrapper;