import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    drawer: {
        backgroundColor: theme.palette.background.default,
        border: 'none',
        width: 240,
        height: '100%',
        maxHeight: '100vh',
        // overflowY: 'hidden',
        [theme.breakpoints.down('md')]: {
            position: 'relative'
        },
        '&::-webkit-scrollbar': {
            // backgroundColor: 'rgba(0,0,0,0)',
            width: '0'
          },
        '&::-webkit-scrollbar-track': {
            // backgroundColor: 'rgba(0,0,0,0)',
            width: '0'
          },
        '&::-webkit-scrollbar-thumb': {
            // backgroundColor: 'rgba(0,0,0,0)',
            width: '0'
          }
    },
    root: {
        backgroundColor: 'transparent',
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        padding: theme.spacing(2),
    },
    divider: {
        position: 'absolute',
        width: `calc(100% + ${theme.spacing(8)}px)`,
        left: -theme.spacing(4),
        top: '50%',
        transform: 'translateY(-50%)',
    },
    section: {
        position: 'relative',
        margin: theme.spacing(1, 0, 0, 0),
    },
    sectionLabel: {
        position: 'relative',
        backgroundColor: theme.palette.background.default,
        width: 'max-content',
        padding: theme.spacing(0, 1),
    },
    nav: {
        marginBottom: theme.spacing(2),
    },
    item: {
        display: "flex",
        paddingTop: theme.spacing(1),
        paddingBottom: 0,
    },
    button: {
        color: theme.palette.grey[500],
        padding: "10px 8px",
        justifyContent: "flex-start",
        textTransform: "none",
        letterSpacing: 0,
        width: "100%",
        fontWeight: theme.typography.fontWeightMedium,
    },
    icon: {
        color: theme.palette.grey[500],
        width: 24,
        height: 24,
        display: "flex",
        alignItems: "center",
        marginRight: theme.spacing(1),
    },
    flag: {
        width: 24,
        height: 24
    },
    active: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.primary.dark,
        fontWeight: theme.typography.fontWeightMedium,
        "& $icon": {
            color: theme.palette.primary.dark,
        },
    },
    logo: {
        height: '95px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        zIndex: 1000
    },
    techSuppButton: {
        marginTop: theme.spacing(2),
        backgroundColor: '#869DB6',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '25px',
        color: theme.palette.common.white,
        padding: theme.spacing(2, 0),
    },
    buttonIcon: {
        marginRight: theme.spacing(1),
    },
    versionButton: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.grey[500],
        margin: theme.spacing(1.5, 0, 0 , 0),
        fontWeight: 800,
        cursor: 'pointer',
        "&:hover": {
            color: theme.palette.grey[600],
        }
    },
}));