import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	form: {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		alignContent: "space-between",
	},
	button: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
	},
	customBackground: {
		backgroundColor: theme.palette.background.default,
	},
}));