import { gql } from "@apollo/client";


const SEARCH_PRICEHISTORY= gql`
    query {
        searchPriceHistory {
            id
            oldPrice
            newPrice
            updatedAt
            productId 
        }
    }
`;

const SEARCH_PRICEHISTORY_BYID = gql `
    query{
        searchPriceHistoryByID{
            id
            oldPrice
            newPrice
            updatedAt
            productId
        }
    }
`;

export const pricehistory = {
    query: {
        SEARCH_PRICEHISTORY,
        SEARCH_PRICEHISTORY_BYID,
        
    },
   
}