import { Document, StyleSheet, Font, Page, Text, Image, View } from '@react-pdf/renderer';
import moment from 'moment';
import { FC } from 'react';
import logo from "../../../resources/logo.png";
import RobotoCondensedRegular from "../../../resources/fonts/RobotoCondensed-Regular.ttf";
import RobotoCondensedRegularItalic from "../../../resources/fonts/RobotoCondensed-Italic.ttf";
import RobotoCondensedBold from "../../../resources/fonts/RobotoCondensed-Bold.ttf";
import RobotoCondensedBoldItalic from "../../../resources/fonts/RobotoCondensed-BoldItalic.ttf";

const QuotePDF: FC<{ data: QuoteProps }> = ({ data }) => {
    let total: number[] = [];
    data.services.forEach((item, sIndex) => {
        total.push(0);
        if (typeof item.price === 'number') total[sIndex] += item.price;
        if (typeof item.discount === 'number') total[sIndex] -= item.discount;
        item.auxs.forEach((aux) => {
            if (typeof aux.price === 'number') total[sIndex] += aux.price;
            if (typeof aux.discount === 'number') total[sIndex] -= aux.discount;
        })
        item.products.forEach((prod) => {
            if (typeof prod.product.price === 'number') total[sIndex] += (prod.product.price * prod.amount);
        })
    })
    let quoteTotal = 0;
    total.forEach(sum => quoteTotal += sum);
    if (typeof data.discount === 'number') quoteTotal -= data.discount;

    return (
        <Document>
            <Page size='A4' style={styles.body}>
                <Image
                    style={styles.logo}
                    src={logo}
                />
                <View style={styles.header}>
                    <View style={styles.titleGroup}>
                        <Text style={styles.title}>
                            Oferta Nr. {data.id}
                        </Text>
                        <Text style={styles.subtitle}>
                            Data: {moment(data.createdAt).format('DD/MM/YYYY')}
                        </Text>
                        <Text style={styles.subtitle}>
                            Birou ofertare: 0752066023
                        </Text>
                    </View>
                    <View style={styles.info}>
                        <Text style={styles.subtitleInfo} fixed>
                            str. Humorului, nr. 961, Șcheia
                    </Text>
                        <Text style={styles.subtitleInfo} fixed>
                            corsemimpex@yahoo.com
                    </Text>
                        <Text style={styles.subtitleInfo} fixed>
                            www.corsem.ro
                    </Text>
                        <Text style={styles.subtitleInfo} fixed>
                            0744472358
                    </Text>
                        <Text style={styles.subtitleInfo} fixed>
                            Director General Ungurașu Costel
                    </Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColSmall}>
                            <Text style={styles.tableCellTitle}>Nume</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data.customer.lastName}</Text>
                        </View>
                        <View style={styles.tableColSmall}>
                            <Text style={styles.tableCellTitle}>Prenume</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data.customer.firstName}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColSmall}>
                            <Text style={styles.tableCellTitle}>Județ</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data.workAddress.region.region}</Text>
                        </View>
                        <View style={styles.tableColSmall}>
                            <Text style={styles.tableCellTitle}>Localitate</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data.workAddress.city.city}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColSmall}>
                            <Text style={styles.tableCellTitle}>Strada</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data.workAddress.streetName}</Text>
                        </View>
                        <View style={styles.tableColSmall}>
                            <Text style={styles.tableCellTitle}>Nr.</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data.workAddress.streetNo}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColSmall}>
                            <Text style={styles.tableCellTitle}>Bloc</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data.workAddress.building}</Text>
                        </View>
                        <View style={styles.tableColSmall}>
                            <Text style={styles.tableCellTitle}>Scara</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data.workAddress.entrance}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColSmall}>
                            <Text style={styles.tableCellTitle}>Apartament</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data.workAddress.apartment}</Text>
                        </View>
                        <View style={styles.tableColSmall}>
                            <Text style={styles.tableCellTitle}>Telefon</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{data.phone}</Text>
                        </View>
                    </View>
                </View>

                {data.services.map((service, index) =>
                    <View key={index} style={styles.service}>
                        <Text style={styles.title}>
                            Oferta {service.type.description.description}
                        </Text>
                        <View style={styles.serviceTable}>
                            {service.auxs.map((aux, index) =>
                                <View key={index} style={styles.tableRow}>
                                    <View style={styles.tableColService}>
                                        <Text style={styles.tableCellLeft}>{aux.type.description.description}</Text>
                                    </View>
                                </View>
                            )}
                            {service.products.map((product, index) =>
                                <View key={index} style={styles.tableRow}>
                                    <View style={styles.tableColProductAmount}>
                                        <Text style={styles.tableCellLeft}>{product.amount} buc.</Text>
                                    </View>
                                    <View style={styles.tableColProduct}>
                                        <Text style={styles.tableCellLeft}>{product.product.manufacturer} {product.product.model}</Text>
                                    </View>
                                </View>
                            )}
                            <View style={styles.tableRow}>
                                <View style={styles.tableColSmall}>
                                    <Text style={styles.tableCellTotalTitle}>TOTAL</Text>
                                </View>
                                <View style={styles.tableColTotal}>
                                    <Text style={styles.tableCellTotal}>{total[index].toFixed(2)} RON</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                )}
                <View style={styles.tableTotal}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol50}>
                            <Text style={styles.tableCellTotalTitle}>TOTAL</Text>
                        </View>
                        <View style={styles.tableCol50}>
                            <Text style={styles.tableCellTotal}>{quoteTotal.toFixed(2)} RON</Text>
                        </View>
                    </View>
                </View>
                <View>
                    <Text style={styles.footerTitle}>* Acte necesare pentru proiectarea instalației de gaz:</Text>
                    <Text style={styles.footerSubtitle}>Acord acces la sistem</Text>
                    <Text style={styles.footerSubtitle}>Copie tabel semnături</Text>
                    <Text style={styles.footerSubtitle}>Copie contract de proprietate asupra imobilului</Text>
                    <Text style={styles.footerSubtitle}>Copie declarație pe propria răspundere</Text>
                    <Text style={styles.footerSubtitle}>Copie C.I.</Text>
                </View>
            </Page>
        </Document>
    )
}

export default QuotePDF;

Font.register({
    family: 'RobotoCondensed',
    fonts: [
        { src: RobotoCondensedRegular, fontWeight: 400, },
        { src: RobotoCondensedRegularItalic, fontWeight: 400, fontStyle: 'italic' },
        { src: RobotoCondensedBold, fontWeight: 700 },
        { src: RobotoCondensedBoldItalic, fontWeight: 700, fontStyle: 'italic' },
    ]
});

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontWeight: 400,
        fontFamily: 'RobotoCondensed',
    },
    titleGroup: {
        display: 'flex',
        justifyContent: 'center',
    },
    title: {
        fontSize: 18,
        textAlign: 'center',
        fontWeight: 700,
        fontFamily: 'RobotoCondensed',
    },
    subtitle: {
        fontSize: 14,
        textAlign: 'center',
        fontWeight: 400,
        fontFamily: 'RobotoCondensed',
    },
    logo: {
        position: "absolute",
        width: '150',
        top: 35,
        left: 35,
    },
    info: {
        width: 150,
    },
    subtitleInfo: {
        fontSize: 10,
        textAlign: 'right',
        fontWeight: 400,
        fontStyle: 'italic',
        fontFamiliy: 'RobotoCondensed',
    },
    header: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    table: {
        marginTop: 10,
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableColSmall: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol: {
        width: "35%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColTotal: {
        width: "85%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColService: {
        width: "100%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColProduct: {
        width: "80%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColProductAmount: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCellTitle: {
        textAlign: 'left',
        margin: 3,
        fontSize: 10,
        fontWeight: 700,
    },
    tableCellTotalTitle: {
        textAlign: 'left',
        margin: 3,
        fontSize: 18,
        fontWeight: 700,
    },
    tableCellTotal: {
        textAlign: 'right',
        margin: 3,
        fontSize: 18,
        fontWeight: 700,
    },
    tableCell: {
        textAlign: 'right',
        margin: 3,
        fontSize: 10
    },
    tableCellLeft: {
        textAlign: 'left',
        margin: 3,
        fontSize: 10,
    },
    service: {
        marginTop: 10,
    },
    serviceTable: {
        marginTop: 10,
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableTotal: {
        marginTop: 10,
        display: "table",
        width: "50%",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableCol50: {
        width: "50%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    footerTitle: {
        marginTop: 10,
        fontStyle: 'italic',
        fontSize: '12',
    },
    footerSubtitle: {
        marginLeft: 10,
        fontStyle: 'italic',
        fontSize: '8',
    },
});