import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Box, Divider, Grid } from "@material-ui/core";

import { SummaryProps } from "../types";
import styles from './style';
import { AuxFormValues, ProductFormValues, ServiceFormValues } from "../../../Views/NewQuote/types";
import { ResponsiveTypography } from "../../shared";
import { useQuery } from "@apollo/client";
import requests from "../../../requests";

const Summary: FC<SummaryProps> = (props) => {
    const classes = styles();
    const { formData } = props;
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        setTotal(0)
    }, [setTotal])

    return (
        <Box className={classes.root}>
            {formData?.services && formData?.services.map((service, index) =>
                <ServiceSummary
                    key={index}
                    index={index}
                    service={service}
                    setTotal={setTotal}
                />
            )}
            <Box className={classes.section_root}>
                <Grid item xs={12}>
                    <Grid container justify='space-between'>
                        <ResponsiveTypography
                            fontWeight={600}
                            growthRate={50}
                            lgDown={18}
                            mdDown={20}
                            smDown={15}
                        >
                            TOTAL
                        </ResponsiveTypography>
                        <ResponsiveTypography
                            fontWeight={600}
                            growthRate={50}
                            lgDown={18}
                            mdDown={20}
                            smDown={15}
                        >
                            {`${total} RON`}
                        </ResponsiveTypography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
export default Summary;


type ServiceSummaryProps = {
    index: number;
    service: ServiceFormValues;
    setTotal: Dispatch<SetStateAction<number>>;
}
const ServiceSummary: FC<ServiceSummaryProps> = (props) => {
    const classes = styles();
    const [subtotal, setSubtotal] = useState<number>(0);
    const { index, service, setTotal } = props;

    const { data } = useQuery(requests.service.query.SEARCH_SERVICE, {
        fetchPolicy: "cache-first",
        variables: {
            id: service.typeId
        }
    });

    useEffect(() => {
        setSubtotal(prevData => prevData + (service?.price || 0) - (service?.discount || 0))
    }, [setSubtotal, service])

    useEffect(() => {
        setTotal(prevData => prevData + subtotal)
    }, [setTotal, subtotal])

    return (
        <Box className={classes.section_root}>
            <ResponsiveTypography
                className={classes.title}
                fontWeight={600}
                growthRate={50}
                lgDown={15}
                mdDown={20}
                smDown={15}
            >
                {`#${index + 1} ${data?.searchServiceType?.category.toUpperCase()}`}
            </ResponsiveTypography>
            <Divider />
            <Grid container spacing={2} style={{ marginTop: 0 }}>
                <Grid item xs={12}>
                    {service?.auxs && service.auxs.map((aux, index) =>
                        <AuxPriceRow
                            key={index}
                            aux={aux}
                            setSubtotal={setSubtotal}
                        />
                    )}
                    {service?.products && service.products.map((prod, index) =>
                        <ProductPriceRow
                            key={index}
                            product={prod}
                            setSubtotal={setSubtotal}
                        />
                    )}
                    {data?.searchServiceType?.price && service.price !== undefined && service.price !== 0 &&
                        <PriceRow
                            label='Pret serviciu'
                            price={service.price || 0}
                            divider
                        />
                    }
                    {data?.searchServiceType?.discount && service.discount !== undefined && service.discount !== 0 &&
                        <PriceRow
                            label='Discount serviciu'
                            price={service.discount || 0}
                            divider
                        />
                    }
                    <PriceRow
                        label='SUBTOTAL'
                        price={subtotal}
                        bold
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

type PriceRowProps = {
    label: string;
    price: number;
    divider?: boolean;
    bold?: boolean;
}

const PriceRow: FC<PriceRowProps> = (props) => {
    const { label, price, divider, bold } = props;
    return (
        <Grid item xs={12}>
            <Grid container justify='space-between'>
                <ResponsiveTypography
                    fontWeight={bold ? 600 : 300}
                    growthRate={50}
                    lgDown={15}
                    mdDown={20}
                    smDown={15}
                >
                    {label}
                </ResponsiveTypography>
                <ResponsiveTypography
                    fontWeight={bold ? 600 : 300}
                    growthRate={50}
                    lgDown={15}
                    mdDown={20}
                    smDown={15}
                >
                    {`${price} RON`}
                </ResponsiveTypography>
            </Grid>
            {divider && <Divider />}
        </Grid>
    )
}

type AuxPriceRowProps = {
    aux: AuxFormValues;
    setSubtotal: Dispatch<SetStateAction<number>>;
}

const AuxPriceRow: FC<AuxPriceRowProps> = (props) => {
    const { aux, setSubtotal } = props;

    const { data } = useQuery(requests.service.query.SEARCH_AUX_TYPE, {
        fetchPolicy: "cache-first",
        variables: {
            id: aux.typeId,
        }
    });

    useEffect(() => {
        setSubtotal(prevData => prevData + (aux?.price || 0) - (aux?.discount || 0))
    }, [aux, setSubtotal])

    return (
        <>
            <Grid item xs={12}>
                <Grid container justify='space-between'>
                    <ResponsiveTypography
                        fontWeight={300}
                        growthRate={50}
                        lgDown={15}
                        mdDown={20}
                        smDown={15}
                    >
                        {data?.searchAuxType.category}
                    </ResponsiveTypography>
                    <ResponsiveTypography
                        fontWeight={300}
                        growthRate={50}
                        lgDown={15}
                        mdDown={20}
                        smDown={15}
                    >
                        {`${aux?.price || 0} RON`}
                    </ResponsiveTypography>
                </Grid>
                <Divider />
            </Grid>
            {aux?.discount &&
                <Grid item xs={12}>
                    <Grid container justify='space-between'>
                        <ResponsiveTypography
                            fontWeight={300}
                            growthRate={50}
                            lgDown={15}
                            mdDown={20}
                            smDown={15}
                        >
                            {`Discount - ${data?.searchAuxType.category}`}
                        </ResponsiveTypography>
                        <ResponsiveTypography
                            fontWeight={300}
                            growthRate={50}
                            lgDown={15}
                            mdDown={20}
                            smDown={15}
                        >
                            {`${aux.discount} RON`}
                        </ResponsiveTypography>
                    </Grid>
                    <Divider />
                </Grid>
            }
        </>
    )
}

type ProductPriceRowProps = {
    product: ProductFormValues;
    setSubtotal: Dispatch<SetStateAction<number>>;
}

const ProductPriceRow: FC<ProductPriceRowProps> = (props) => {
    const { product, setSubtotal } = props;

    const { data } = useQuery(requests.product.query.SEARCH_PRODUCT, {
        fetchPolicy: "cache-first",
        variables: {
            id: product.productId,
        }
    });

    useEffect(() => {
        setSubtotal(prevData => prevData + (data?.searchProduct?.price * product.amount || 0))
    }, [product, setSubtotal, data?.searchProduct?.price])

    return (
        <Grid item xs={12}>
            <Grid container justify='space-between'>
                <ResponsiveTypography
                    fontWeight={300}
                    growthRate={50}
                    lgDown={15}
                    mdDown={20}
                    smDown={15}
                >
                    {`${product.amount}x ${data?.searchProduct?.category}-${data?.searchProduct?.manufacturer} ${data?.searchProduct?.model}`}
                </ResponsiveTypography>
                <ResponsiveTypography
                    fontWeight={300}
                    growthRate={50}
                    lgDown={15}
                    mdDown={20}
                    smDown={15}
                >
                    {`${data?.searchProduct?.price * product.amount} RON`}
                </ResponsiveTypography>
            </Grid>
            <Divider />
        </Grid>
    )
}