import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styles from './style';

const HeaderWrapper: FC<{ children: React.ReactNode }> = ({ children }) => {
    const classes = styles();
    return (
        <Box className={classes.root}>
            {children}
        </Box>
    )
}
export default HeaderWrapper;