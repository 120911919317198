import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 10,
        margin: theme.spacing(0, 4),
        padding: theme.spacing(2),
        boxShadow: `inset 5px 5px 10px ${theme.palette.common.black}${theme.palette.type === 'light' ? '20' : '2'}`,
    },
    buttonIcon: {
        marginRight: theme.spacing(1),
    },
    section_root: {
        padding: theme.spacing(2, 2, 1 ,2),
        border: `1px solid ${theme.palette.divider}`,
    },
    title: {
        marginBottom: theme.spacing(1),
    },
}));