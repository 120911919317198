import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        boxShadow: "none",
        zIndex: 2000,
    },
    topbar_regular: {
        outline: '0px',
        display: 'flex',
        alignItems: 'center',
        minHeight: '95px',
        [theme.breakpoints.down('xs')]: {
            minHeight: '64px',
        }
    },
    flexGrow: {
        flexGrow: 1,
    },
    signOutButton: {
        marginLeft: theme.spacing(1),
    },
    logo: {
        height: '95px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        padding: theme.spacing(3, 0),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 0),
            height: '64px',
        }
    },
    logoSvg: {
        height: '100%',
    },
    avatar: {
        height: '34px',
        width: '34px',
    },
    profile_chip: {
        border: 'none',
    },
    profile_text: {
        display: 'flex',
        alignItems: 'center',
    },
    menuWrapper: {
        position: 'relative',
        height: '95px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '200px',
    },
    menuRoot: {
        backgroundColor: theme.palette.type === 'light' ? theme.palette.background.paper : theme.palette.background.default,
        borderRadius: 25,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        position: 'absolute',
        width: '200px',
        left: 0,
        top: 0,
        marginTop: 95,
        overflow: 'hidden',
    },
    chipWrapper: {
        backgroundColor: theme.palette.type === 'light' ? theme.palette.background.paper : theme.palette.background.default,
        position: 'relative',
        height: '95px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '200px',
        zIndex: 2100,
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    logout: {
        color: theme.palette.error.light,
    },
    arrow: {
        transition: '0.25s',
    },
    arrowActive: {
        transform: 'rotate(-180deg)',
    }
}));