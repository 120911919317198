import { gql } from "@apollo/client";

const SEARCH_SERVICE = gql`
    query onSearchServiceType($id: Int!) {
        searchServiceType(id: $id) {
            id
            category
            status
            description {
                id
                description
            }
            auxTypes {
                id
                status
                description {
                    id
                    description
                }
                price
                discount
                project
            }
            products
            price
            discount
            schedulable
            createdAt
        }
    }
`;

const SEARCH_SERVICES = gql`
    query onSearchServiceTypes($searchBy: String, $filters: ServiceTypeWhereInput, $orderBy: ServiceTypeOrderBy, $paginate: Pagination) {
        searchServiceTypes(searchBy: $searchBy, filters: $filters, orderBy: $orderBy, paginate: $paginate) {
            serviceTypes {
                id
                category
                status
                noOfAuxTypes
                auxTypes {
                    id
                    category
                    status
                    price
                    discount
                    project
                }
                products
                price
                discount
                schedulable
                createdAt
            }
            count
        }
    }
`;

const SEARCH_AUX_TYPE = gql`
    query onSearchAuxType($id: Int!) {
        searchAuxType(id: $id) {
            id
            status
            category
            price
            discount
            project
        }
    }
`;

const CREATE_SERVICE_TYPE = gql`
    mutation onServiceTypeCreate($description: String, $products: Boolean, $price: Boolean, $discount: Boolean, $schedulable: Boolean, $auxTypes: [AuxTypeInput!]) {
        createServiceType(description: $description, products: $products, price: $price, discount: $discount, schedulable: $schedulable, auxTypes: $auxTypes) {
            id
        }
    }
`;

const UPDATE_SERVICE_TYPE = gql`
    mutation onServiceTypeUpdate($id: Int!, $status: StatusType, $description: String, $products: Boolean, $price: Boolean, $discount: Boolean, $schedulable: Boolean, $auxTypes: [AuxTypeInput!]) {
        updateServiceType(id: $id, status: $status, description: $description, products: $products, price: $price, discount: $discount, schedulable: $schedulable, auxTypes: $auxTypes) {
            id
        }
    }
`;

export const service = {
    query: {
        SEARCH_SERVICE,
        SEARCH_SERVICES,
        SEARCH_AUX_TYPE,
    },
    mutation: {
        CREATE_SERVICE_TYPE,
        UPDATE_SERVICE_TYPE,
    },
    subscription: {
    }
}