import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    divider: {
        margin: theme.spacing(1, 0),
    },
    textField: {
        margin: theme.spacing(1, 0),
    },
    title: {
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dividerTitle: {
        boxShadow: `10px 10px 20px ${theme.palette.common.black}${theme.palette.type === 'light' ? '20' : '2'}`,
    },
    content: {
        paddingTop: theme.spacing(2),
        width: '100%',
        height: '100%',
    },
    deleteButton: {
        marginLeft: theme.spacing(1),
        color: theme.palette.error.light,
    },
    auxWrapper: {
        marginTop: theme.spacing(2),
    }
}));