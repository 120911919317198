import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    title: {
        margin: theme.spacing(0, 0, 2, 0),
    },
    submit: {
        margin: theme.spacing(2, 0, 0, 0),
        borderRadius: theme.spacing(1),
    },
    buttonX:{
        display:"flex",
        justifyContent:"flex-end",
        alignItems:"flex-end",
        cursor:"pointer"
    },
    root: {
        padding: theme.spacing(0, 20),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0, 10),
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0),
        },
       
    }
}));