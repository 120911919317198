import { createRef, FC, useCallback, useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { ThemeProvider } from '@material-ui/styles';
import { IconButton, Theme, CssBaseline } from '@material-ui/core';
import { Clear } from "@material-ui/icons";

import './App.css';
import { getInitialLanguage, getInitialTheme, isLoggedIn, logout } from './config/localState';
import apolloClient from './config/apolloClient';
import Routes from './config/routes';
import strings from './strings';
import generateTheme from './config/theme';

type AppProps = {
	theme: Theme;
	toggleTheme: () => void;
}

const App: FC<AppProps> = (props) => {
	let history = useHistory();
	let location = useLocation();
	const [language, setLanguage] = useState<'ro' | 'en'>(getInitialLanguage());
	const [isAuthenticated, setAuthentication] = useState(isLoggedIn());
	const { enqueueSnackbar } = useSnackbar();

	const handleLogin = useCallback(() => {
		setAuthentication(true);
		history.push(strings.pages.DASHBOARD.url);
	}, [history]);

	const handleLogout = useCallback(() => {
		logout();
		setAuthentication(false);
		history.push(strings.pages.SIGN_IN.url);
	}, [history]);

	useEffect(() => {
		localStorage.setItem("language", language);
	}, [language]);

	useEffect(() => {
		const exceptions = [strings.pages.SIGN_IN.url, strings.pages.SIGN_UP.url];
		if (!isAuthenticated && !exceptions.includes(location.pathname))
			handleLogout();
	}, [isAuthenticated, handleLogout, location]);

	return (
		<Routes
			{...props}
			language={language}
			toggleLanguage={() => {
				setLanguage(language === "ro" ? "en" : "ro");
				enqueueSnackbar(
					strings.notifications.LANGUAGE_CHANGE_SUCCESS[language],
					{
						variant: "success",
					}
				);
			}}
			auth={{
				isAuthenticated,
				login: handleLogin,
				logout: handleLogout,
			}}
		/>
	);
}

const AppWithNotifications: FC = () => {
	const [theme, setTheme] = useState(getInitialTheme());
	const notistackRef: React.RefObject<SnackbarProvider> = createRef();

	const toggleTheme = useCallback(
		() => {
			setTheme(
				theme.palette.type === "light"
					? generateTheme("dark")
					: generateTheme("light")
			)
		}, [theme]
	)

	useEffect(() => {
		localStorage.setItem("theme", theme.palette.type);
	}, [theme]);

	return (
		<ThemeProvider theme={theme}>
			<SnackbarProvider
				maxSnack={3}
				preventDuplicate
				ref={notistackRef}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				action={(key) => (
					<IconButton onClick={() => notistackRef.current?.closeSnackbar(key)}>
						<Clear />
					</IconButton>
				)}
			>
				<CssBaseline />
				<ApolloProvider client={apolloClient}>
					<Router>
						<App
							theme={theme}
							toggleTheme={toggleTheme}
						/>
					</Router>
				</ApolloProvider>
			</SnackbarProvider>
		</ThemeProvider>
	)
}

export default AppWithNotifications;