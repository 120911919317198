import { FC } from "react";
import {
    Card,
    CardContent,
    Grid,
    Hidden,
    Fade,
} from "@material-ui/core";
import styles from './style';
import strings from "../../../strings";
import { ResponsiveTypography } from "../../shared";
import { useQuery } from "@apollo/client";
import requests from "../../../requests";

type WelcomMessageProps = {
    auth: AuthProps;
    language: LanguageType;
}

const WelcomeMessage: FC<WelcomMessageProps> = ({ language, auth }) => {
    const classes = styles();

    const { loading, data } = useQuery(requests.notification.query.COUNT_NOTIFICAITONS, {
        fetchPolicy: "no-cache",
        variables: {
            read: false,
        }
    });

    return (
        <Fade in={true}>
            <Card className={classes.root}>
                <Hidden xsDown>
                    <img
                        alt='Avatar'
                        src={
                            auth?.me?.data?.avatar
                                ? `/svg/avatars/avatar-default-0${auth?.me?.data?.avatar}.svg`
                                : ""
                        }
                        className={classes.avatar}
                    />
                </Hidden>
                <CardContent className={classes.content}>
                    <Grid container justify="space-between" spacing={0}>
                        <Grid item>
                            <ResponsiveTypography
                                growthRate={50}
                                fontWeight={800}
                                smDown={33}
                                mdDown={45}
                                lgDown={33}
                            >
                                {strings.general.WELCOME_MESSAGE(auth?.me?.data?.profile?.firstName)[language]}
                            </ResponsiveTypography>
                            {!loading && <ResponsiveTypography
                                className={classes.subtitle}
                                growthRate={50}
                                fontWeight={300}
                                smDown={15}
                                mdDown={20}
                                lgDown={15}
                            >
                                {strings.general.NEW_NOTIFICAIONS(data?.countNotifications)[language]}
                            </ResponsiveTypography>}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Fade>
    );
};

export default WelcomeMessage;
