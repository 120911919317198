import { darken, lighten, makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    commentsBox: {
        overflow: 'hidden',
        overflowY: 'auto',
        borderRadius: `10px 10px 0px 0px`,
        padding: theme.spacing(2),
        width: '100%',
        border: `1px solid ${theme.palette.divider}`,
        maxHeight: 300,
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
        boxShadow: `inset 5px 5px 10px ${theme.palette.common.black}${
            theme.palette.type === 'light' ? '20' : '2'
        }`,
    },
    comment_root: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: theme.spacing(1),
        borderRadius: 10,
        maxWidth: '90%',
        width: 'max-content',
        backgroundColor:
            theme.palette.type === 'light'
                ? lighten(theme.palette.secondary.light, 0.85)
                : `${theme.palette.secondary.dark}30`,
        marginTop: theme.spacing(1),
    },
    comment_root_file: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: theme.spacing(1),
        borderRadius: '10px 0 10px 10px',
        maxWidth: '90%',
        width: 'max-content',
        backgroundColor:
            theme.palette.type === 'light'
                ? lighten(theme.palette.secondary.light, 0.85)
                : `${theme.palette.secondary.dark}30`,
        marginTop: theme.spacing(1),
    },
    comment_root_file_text: {
        fontStyle: 'italic',
        fontSize: '0.8rem !important',
        textDecoration: 'underline',
        textUnderlineOffset: '2px',
        opacity: 0.8,
        padding: '0.25rem 0.5rem',
        cursor: 'pointer',
        userSelect: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',

        '&:hover': {
            opacity: 1,
        },
    },
    comment_root_file_icon: {
        marginLeft: '1rem',
    },
    comment_root_self: {
        alignSelf: 'flex-end',
        flexDirection: 'row-reverse',
        backgroundColor:
            theme.palette.type === 'light'
                ? lighten(theme.palette.secondary.light, 0.95)
                : `${theme.palette.secondary.dark}80`,
    },
    comment_root_sys: {
        alignSelf: 'center',
        borderRadius: 30,
        justifyContent: 'center',
        backgroundColor:
            theme.palette.type === 'light'
                ? theme.palette.primary.main
                : theme.palette.primary.dark,
    },
    comment_text: {
        padding: theme.spacing(1),
    },
    comment_title: {
        display: 'flex',
        alignItems: 'baseline',
    },
    comment_user: {
        marginRight: theme.spacing(1),
        '&:hover > p': {
            color: darken(theme.palette.text.primary, 0.5),
            cursor: 'pointer',
        },
    },
    message_box: {
        position: 'relative',
        '& fieldset': {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            border: `1px solid ${theme.palette.divider}`,
        },
    },
    progressbar: {
        position: 'absolute',
        width: '100%',
        zIndex: 20,
    },
    messagebox_button: {
        display: 'flex',
        '& > *': {
            marginLeft: theme.spacing(2),
        },
    },
    message_text: {
        textAlign: 'justify',
    },
    timestamp_self: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    arrowIcon: {
        alignSelf: 'center',
        marginTop: theme.spacing(1),
        color: theme.palette.primary.main,
    },
    divWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
    },
    fileName: {
        fontSize: '0.8rem !important',
        fontStyle: 'italic',
    },
}))
