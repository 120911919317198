import React, { FC } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme, makeStyles, Theme } from '@material-ui/core/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { InputAdornment, Typography } from '@material-ui/core';
import { Search } from '@material-ui/icons';

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: (style.top as number) + LISTBOX_PADDING,
        },
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactNode) => {
        if (React.isValidElement(child) && child.type === ListSubheader) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    style={{ zIndex: 5000 }}
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const useStyles = makeStyles((theme: Theme) => ({
    listbox: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
    popper: {
        zIndex: 5000,
    },
    paper: {
        backgroundColor: theme.palette.background.default
    }
}));

const defaultRenderGroup = (params: AutocompleteRenderGroupParams) => [params.children];
const defaultRenderOption = (option: any) => <Typography noWrap>{option}</Typography>;

type VirtualizedSelectProps = {
    language: LanguageType;
    options: any[];
    onChange: (event: React.ChangeEvent<{}>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any> | undefined) => void;
    value?: any;
    search?: boolean;
    freeSolo?: boolean;
    disabled?: boolean;
    autoComplete?: boolean;
    autoHighlight?: boolean;
    multiple?: boolean;
    disableCloseOnSelect?: boolean;
    disableListWrap?: boolean;
    disableClearable?: boolean;
    textFieldProps?: TextFieldProps;
    groupBy?: (option: any) => string;
    getOptionLabel?: (option: any) => string;
    renderOption?: (option: any) => any;
    renderGroup?: (option: any) => any;
    getOptionSelected?: (option: any, value: any) => boolean;
    className?: string;
}

const VirtualizedSelect: FC<VirtualizedSelectProps> = (props) => {
    const classes = useStyles()
    const { language, textFieldProps, className, search, ...rest } = props;

    return (
        <Autocomplete
            {...rest}
            className={className}
            classes={classes}
            ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
            renderInput={(params) =>
                <TextField
                    {...params}
                    {...textFieldProps}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: search && (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                />
            }

        />
    );
}

export default VirtualizedSelect;

VirtualizedSelect.defaultProps = {
    autoComplete: true,
    autoHighlight: true,
    search: false,
    freeSolo: false,
    disabled: false,
    multiple: false,
    disableCloseOnSelect: false,
    disableListWrap: false,
    renderGroup: defaultRenderGroup,
    renderOption: defaultRenderOption,
}