import { FC } from "react";
import clsx from "clsx";
import { Avatar, Box, Divider, Button, Drawer, List, ListItem, useTheme, Hidden, Typography } from "@material-ui/core";
import {
    DashboardRounded as DashboardIcon,
    BuildRounded as WorkOrderIcon,
    PeopleAltRounded as CustomerIcon,
    LocalOfferRounded as QuoteIcon,
    NewReleasesRounded as NewQuoteIcon,
    SquareFootRounded as ProjectIcon,
    PeopleRounded as UsersIcon,
    MeetingRoomRounded as LogOutIcon,
    MessageRounded as TechSupportIcon,
    ClassRounded as ServiceIcon,
    CategoryRounded as ProductIcon,
    GroupAddRounded as LeadIcon,
    LocalShippingRounded as FleetIcon,
} from "@material-ui/icons";
import styles from './style';
import strings from "../../../../strings";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as RaisisCRMLogo } from '../../../../resources/svg/logo.svg';
import { ReactComponent as RaisisCRMLogoDark } from '../../../../resources/svg/logo-white.svg';
import { ResponsiveTypography } from "../../../shared";
import { permissionAccess } from "../../../../functions/permissionAccess";
import { useHistory } from "react-router-dom";
import changelog from "../../../../Views/Versions/changelog.json";


type SidebarProps = {
    open: boolean;
    variant: 'persistent' | 'temporary' | 'permanent';
    onClose: () => void;
    className?: string;
    me: AuthUser;
    language: LanguageType;
    toggleLanguage: () => void;
    openLogoutModal: () => void;
}
type SectionProps = {
    title: string;
    pages: PageBaseProps[];
    render?: boolean;
}
type PageBaseProps = {
    title: string;
    href: string;
    icon: MuiIconProps;
    render?: boolean;
}
type SidebarNavProps = {
    sections: SectionProps[];
    className?: string;
    onClose: () => void;
    language: LanguageType;
    toggleLanguage: () => void;
    openLogoutModal: () => void;
}

const Sidebar: FC<SidebarProps> = (props) => {
    const { open, variant, onClose, className, language, toggleLanguage, openLogoutModal, me } = props;
    const classes = styles();
    const theme = useTheme();

    const sections = [
        {
            title: strings.pages.MAIN_SECTION[language],
            pages: [
                {
                    title: strings.pages.DASHBOARD.label[language],
                    href: strings.pages.DASHBOARD.url,
                    icon: <DashboardIcon />,
                    render: true,
                },
                {
                    title: strings.pages.NEW_QUOTE.label[language],
                    href: strings.pages.NEW_QUOTE.url,
                    icon: <NewQuoteIcon />,
                    render: permissionAccess(me.data.permissions, 6),
                },
                {
                    title: strings.pages.QUOTES.label[language],
                    href: strings.pages.QUOTES.url,
                    icon: <QuoteIcon />,
                    render: permissionAccess(me.data.permissions, 7),
                },
                {
                    title: strings.pages.EXECUTIONS.label[language],
                    href: strings.pages.EXECUTIONS.url,
                    icon: <WorkOrderIcon />,
                    render: permissionAccess(me.data.permissions, 11),
                },
                {
                    title: strings.pages.PROJECTS.label[language],
                    href: strings.pages.PROJECTS.url,
                    icon: <ProjectIcon />,
                    render: permissionAccess(me.data.permissions, 15),
                },
                {
                    title: strings.pages.CUSTOMERS.label[language],
                    href: strings.pages.CUSTOMERS.url,
                    icon: <CustomerIcon />,
                    render: permissionAccess(me.data.permissions, 19),
                },
                {
                    title: strings.pages.LEADS.label[language],
                    href: strings.pages.LEADS.url,
                    icon: <LeadIcon />,
                    render: permissionAccess(me.data.permissions, 19),
                },
            ]
        },
        {
            title: strings.pages.ADMIN_SECTION[language],
            pages: [
                {
                    title: strings.pages.USERS.label[language],
                    href: strings.pages.USERS.url,
                    icon: <UsersIcon />,
                    render: permissionAccess(me.data.permissions, 3),
                },
                {
                    title: strings.pages.SERVICES.label[language],
                    href: strings.pages.SERVICES.url,
                    icon: <ServiceIcon />,
                    render: permissionAccess(me.data.permissions, 23),
                },
                {
                    title: strings.pages.PRODUCTS.label[language],
                    href: strings.pages.PRODUCTS.url,
                    icon: <ProductIcon />,
                    render: permissionAccess(me.data.permissions, 27),
                },
                {
                    title: strings.pages.FLEET.label[language],
                    href: strings.pages.FLEET.url,
                    icon: <FleetIcon />,
                    render: permissionAccess(me.data.permissions, 31),
                },
            ]
        },
    ];

    return (
        <Drawer
            anchor="left"
            classes={{ paper: classes.drawer }}
            onClose={onClose}
            open={open}
            variant={variant}
        >
            <div className={clsx(classes.root, className)}>
               <Hidden mdDown>
                    <Box className={classes.logo}>
                        {theme.palette.type === 'light' ? <RaisisCRMLogo /> : <RaisisCRMLogoDark />}
                    </Box>
                </Hidden>
                <SidebarNav
                    className={classes.nav}
                    sections={sections}
                    onClose={onClose}
                    language={language}
                    toggleLanguage={toggleLanguage}
                    openLogoutModal={openLogoutModal}
                />
            </div>
        </Drawer>
    );
};


const SidebarNav: FC<SidebarNavProps> = (props) => {
    const { sections, className, language, toggleLanguage, openLogoutModal, ...rest } = props;
    const classes = styles();
    const { pathname } = useLocation();
    const history = useHistory();

    return (
        <List {...rest} className={clsx(classes.root, className)}>
            <Box>
                {sections.filter(section => section.render !== false).map((section, index) => (
                    <Box key={index}>
                        <SectionTitle title={section.title} />
                        <Box>
                            {section.pages.filter(page => page.render !== false).map((page, index) =>
                                <ListItem
                                    className={classes.item}
                                    disableGutters
                                    key={index}
                                >
                                    <Button
                                        className={clsx({ [classes.button]: true, [classes.active]: pathname === page.href })}
                                        component={NavLink}
                                        to={page.href}
                                        onClick={props.onClose}
                                    >
                                        <Box className={classes.icon}>{page.icon}</Box>
                                        {page.title}
                                    </Button>
                                </ListItem>
                            )}
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box>
                <SectionTitle title={strings.pages.ACCOUNT_SECTION[language]} />
                <ListItem
                    className={classes.item}
                    disableGutters
                >
                    <Button
                        className={classes.button}
                        onClick={() => {
                            props.onClose();
                            openLogoutModal();
                        }}
                    >
                        <Box className={classes.icon}><LogOutIcon /></Box>
                        {strings.actions.LOG_OUT[language]}
                    </Button>
                </ListItem>
                <ListItem
                    className={classes.item}
                    disableGutters
                >
                    <Button
                        className={classes.button}
                        // disabled
                        onClick={() => {
                            props.onClose();
                            toggleLanguage();
                        }}
                    >
                        <Box className={classes.icon}><Avatar src={`/svg/${language}.svg`} className={classes.flag} /></Box>
                        {language === 'ro' ? strings.general.ROMANIAN.ro : strings.general.ENGLISH.en}
                    </Button>
                </ListItem>
                <a className={classes.techSuppButton} href='mailto:support@raisissoftware.com'>
                    <TechSupportIcon className={classes.buttonIcon} />
                    {strings.general.TECH_SUPPORT[language]}
                </a>
                <div className={classes.versionButton} onClick={()=> history.push("/versions")}>{" v " + changelog[0].version}</div>
            </Box>
        </List >
    );
};

export default Sidebar;

const SectionTitle: FC<{ title: string }> = ({ title }) => {
    const classes = styles();
    const theme = useTheme();

    return (
        <Box className={classes.section}>
            <Divider className={classes.divider} />
            <ResponsiveTypography growthRate={50} color={theme.palette.divider} className={classes.sectionLabel} fontWeight='bold' lgDown={13}>{title}</ResponsiveTypography>
        </Box>
    );
}