import { useLazyQuery } from "@apollo/client";
import { FC, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CustomersEnhancedTable } from "../../components/Customers";

import { ResponsiveTypography } from "../../components/shared";
import MainWrapper from "../../components/shared/MainWrapper";
import { permissionAccess } from "../../functions/permissionAccess";
import requests from "../../requests";
import strings from "../../strings";
import styles from './style';

const Leads: FC<PageBaseProps> = (props) => {
    const { language, auth } = props;
    const classes = styles();
    const history = useHistory();

    const [onSearch, { data, loading: loadingSearch }] = useLazyQuery(requests.customer.query.SEARCH_CUSTOMERS, {
        fetchPolicy: "network-only",
    });

    const handleSearch = useCallback((rows: number, page: number, orderBy: CustomerOrderBy) => {
        onSearch({
            variables: {
                filters: {
                    lead: true,
                },
                orderBy,
                paginate: {
                    take: rows,
                    skip: Math.max(page, 0) * rows,
                }
            }
        })
    }, [onSearch]);

    useEffect(() => {
        document.title = `Corsem - ${strings.pages.LEADS.label[language]}`;
    }, [language]);

    useEffect(() => {
        if (auth.me)
            if (!permissionAccess(auth.me.data.permissions, 19)) history.replace(strings.pages.FORBIDDEN.url)
    })

    return (
        <MainWrapper>
            <ResponsiveTypography
                className={classes.title}
                growthRate={50}
                fontWeight={800}
                smDown={33}
                mdDown={45}
                lgDown={33}
            >
                {strings.pages.LEADS.label[language]}
            </ResponsiveTypography>

            <ResponsiveTypography
                growthRate={50}
                fontWeight={300}
                smDown={12}
                mdDown={20}
                lgDown={12}
            >
                {strings.pages.LEADS.sublabel[language]}
            </ResponsiveTypography>

            <CustomersEnhancedTable
                rows={data?.searchCustomers?.customers || []}
                count={data?.searchCustomers?.count || 0}
                loading={loadingSearch}
                language={language}
                handleSearch={handleSearch}
                title={strings.pages.LEADS.label[language]}
            />
        </MainWrapper>
    )
}
export default Leads;