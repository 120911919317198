import { colors } from "@material-ui/core";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";

const white = "#fafafa";
const black = "#000";
const blackContrast = "rgba(0, 0, 0, 0.87)";

const customColors = {
    light: '#84a2ce',
    main: '#668bc2',
    dark: '#476187',
    "50": '#e5ebf4',
    "100": '#becce5',
    "200": '#93abd3',
    "300": '#668bc2',
    "400": '#3e72b6',
    "500": '#005aae',
    "600": '#0052a4',
    "700": '#004899',
    "800": '#003e8e',
    "900": '#002e7a',
}

export const paletteDark: PaletteOptions = {
    common: {
        black,
        white,
    },
    type: "dark",
    primary: {
        light: colors.grey[500],
        main: colors.grey[700],
        dark: colors.grey[900],
        contrastText: white,
    },
    secondary: {
        ...customColors,
        contrastText: white,
    },
    error: {
        light: colors.red[300],
        main: colors.red[500],
        dark: colors.red[700],
        contrastText: white,
    },
    warning: {
        light: colors.orange[300],
        main: colors.orange[500],
        dark: colors.orange[700],
        contrastText: blackContrast,
    },
    info: {
        light: colors.blue[300],
        main: colors.blue[500],
        dark: colors.blue[700],
        contrastText: white,
    },
    success: {
        light: colors.green[300],
        main: colors.green[500],
        dark: colors.green[700],
        contrastText: blackContrast,
    },
    text: {
        primary: white,
        secondary: "rgba(255,255,255,0.7)",
        disabled: "rgba(255,255,255,0.5)",
        hint: "rgba(255,255,255,0.5)",
    },
    divider: "rgba(255,255,255,0.12)",
    background: {
        paper: colors.grey[800],
        default: colors.grey["A400"],
    },
    action: {
        active: white,
        hover: "rgba(255,255,255,0.2)",
        hoverOpacity: 0.08,
        selected: "rgba(255,255,255,0.16)",
        selectedOpacity: 0.16,
        disabled: "rgba(255,255,255,0.3)",
        disabledBackground: "rgba(255,255,255,0.12)",
        disabledOpacity: 0.38,
        focus: "rgba(255,255,255,0.12)",
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
    },
};
