import { FC, useEffect } from "react";
import WelcomeMessage from "../../components/Dashboard/WelcomeMessage";

import MainWrapper from "../../components/shared/MainWrapper";
import strings from "../../strings";

const Dashboard: FC<PageBaseProps> = (props) => {
    const { language, auth } = props;

    useEffect(() => {
        document.title = `Corsem - ${strings.pages.DASHBOARD.label[language]}`;
    }, [language]);

    return (
        <MainWrapper>
            <WelcomeMessage language={language} auth={auth} />
        </MainWrapper>
    )
}
export default Dashboard;