import { green, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    title: {
        margin: theme.spacing(0, 0, 2, 0),
    },
    root_paper: {
        overflow: 'hidden',
        marginTop: theme.spacing(4),
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.background.paper
    },
    root_content: {
        margin: theme.spacing(2),
    },
    buttonIcon: {
        marginRight: theme.spacing(1),
    },
    redAction: {
        color: theme.palette.error.main,
    },
    avatarContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    avatar: {
        width: 100,
        height: 100,
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonStd: {
        margin: theme.spacing(1),
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
    buttonError: {
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700],
        },
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}));