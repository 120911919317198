import { ChangeEvent, FC } from "react";
import {
    Box,
    Button,
    FormControlLabel,
    Grid,
    IconButton,
    Switch,
    TextField,
} from "@material-ui/core";
import { Add, Delete, Restore } from "@material-ui/icons";
import { UseFormReturn } from "react-hook-form";
import clsx from 'clsx';

import styles from './style';
import strings from "../../../strings";
import { getError } from "../../../functions/formFunctions";
import { AuxTypeProps, ServiceTypeFormValues } from "../types";

type SerivceTypeFormProps = {
    language: LanguageType;
    loading: boolean;
    useForm: UseFormReturn<ServiceTypeFormValues>;
    handleChangeField: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, checked?: boolean) => void;
    onChangeAuxTypesNumber: (index?: number) => void;
    type: 'CREATE' | 'EDIT';
}

const SerivceTypeForm: FC<SerivceTypeFormProps> = (props) => {
    const classes = styles();
    const { language, loading, handleChangeField, useForm, onChangeAuxTypesNumber, type } = props;
    const { register, watch, setValue, formState: { errors } } = useForm;

    return (
        <Box>
            <TextField
                variant='outlined'
                disabled={loading}
                value={watch('description')}
                label={strings.columns.NAME()[language]}
                {...register('description', {
                    required: true,
                })}
                onChange={handleChangeField}
                error={Boolean(errors?.description)}
                helperText={getError(errors.description, language)}
            />
            <Grid className={classes.checks} container spacing={2}>
                <Grid item lg={2} md={3} sm={4} xs={6}>
                    <FormControlLabel
                        disabled={loading}
                        label={strings.columns.ACCEPTS_PRICE[language]}
                        control={
                            <Switch
                                checked={Boolean(watch('price'))}
                                {...register('price')}
                                onChange={handleChangeField}
                            />
                        }
                    />
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={6}>
                    <FormControlLabel
                        disabled={loading}
                        label={strings.columns.ACCEPTS_DISCOUNT[language]}
                        control={
                            <Switch
                                checked={Boolean(watch('discount'))}
                                {...register('discount')}
                                onChange={handleChangeField}
                            />
                        }
                    />
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={6}>
                    <FormControlLabel
                        disabled={loading}
                        label={strings.columns.ACCEPTS_PRODUCTS[language]}
                        control={
                            <Switch
                                checked={Boolean(watch('products'))}
                                {...register('products')}
                                onChange={handleChangeField}
                            />
                        }
                    />
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={6}>
                    <FormControlLabel
                        disabled={loading}
                        label={strings.columns.SCHEDULABLE[language]}
                        control={
                            <Switch
                                checked={Boolean(watch('schedulable'))}
                                {...register('schedulable')}
                                onChange={handleChangeField}
                            />
                        }
                    />
                </Grid>
            </Grid>
            {watch('auxTypes')?.map((_: AuxTypeProps, index: number) =>
                <Box
                    className={clsx(classes.auxTypeBox, watch(`auxTypes.${index}.status` as any) === "INACTIVE" && classes.auxTypeBoxDeleted)}
                    key={index}
                >
                    <Box className={classes.auxTypeControls}>
                        <TextField
                            variant='outlined'
                            disabled={loading || watch(`auxTypes.${index}.status` as any) === "INACTIVE"}
                            value={watch(`auxTypes.${index}.description` as any)}
                            label={`${index + 1}# ${strings.columns.NAME(strings.general.AUX_TYPE[language])[language]}`}
                            {...register(`auxTypes.${index}.description` as const, {
                                required: true,
                            })}
                            onChange={handleChangeField}
                            error={Boolean(errors?.auxTypes && errors?.auxTypes[index]?.description)}
                            helperText={getError(errors?.auxTypes && errors?.auxTypes[index]?.description, language)}
                        />
                        <FormControlLabel
                            disabled={loading || watch(`auxTypes.${index}.status` as any) === "INACTIVE"}
                            className={classes.auxTypeSwitch}
                            label={strings.columns.ACCEPTS_PRICE[language]}
                            control={
                                <Switch
                                    size='small'
                                    checked={Boolean(watch(`auxTypes.${index}.price` as any))}
                                    {...register(`auxTypes.${index}.price` as const)}
                                    onChange={handleChangeField}
                                />
                            }
                        />
                        <FormControlLabel
                            disabled={loading || watch(`auxTypes.${index}.status` as any) === "INACTIVE"}
                            className={classes.auxTypeSwitch}
                            label={strings.columns.ACCEPTS_DISCOUNT[language]}
                            control={
                                <Switch
                                    size='small'
                                    checked={Boolean(watch(`auxTypes.${index}.discount` as any))}
                                    {...register(`auxTypes.${index}.discount` as const)}
                                    onChange={handleChangeField}
                                />
                            }
                        />
                        <FormControlLabel
                            disabled={loading || watch(`auxTypes.${index}.status` as any) === "INACTIVE"}
                            className={classes.auxTypeSwitch}
                            label={strings.columns.IS_PLAN[language]}
                            control={
                                <Switch
                                    size='small'
                                    checked={Boolean(watch(`auxTypes.${index}.project` as any))}
                                    {...register(`auxTypes.${index}.project` as const)}
                                    onChange={handleChangeField}
                                />
                            }
                        />
                    </Box>
                    {type === 'CREATE' &&
                        <IconButton
                            onClick={() => onChangeAuxTypesNumber(index)}
                        >
                            <Delete />
                        </IconButton>
                    }
                    {type === 'EDIT' && (watch(`auxTypes.${index}.status` as any) === "ACTIVE" ?
                        <IconButton
                            disabled={loading}
                            className={classes.deleteButton}
                            onClick={() => setValue(`auxTypes.${index}.status` as any, 'INACTIVE')}
                        >
                            <Delete />
                        </IconButton>
                        :
                        <IconButton
                            disabled={loading}
                            className={classes.restoreButton}
                            onClick={() => setValue(`auxTypes.${index}.status` as any, 'ACTIVE')}
                        >
                            <Restore />
                        </IconButton>
                    )}
                </Box>
            )}
            <Button
                disabled={loading}
                variant='contained'
                color='secondary'
                onClick={() => onChangeAuxTypesNumber()}
            >
                <Add className={classes.buttonIcon} />
                {strings.general.ADD_AUX_TYPE[language]}
            </Button>
        </Box>
    );
}

export default SerivceTypeForm;