import React, { useCallback, useState, FC } from "react";
import { Theme, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";

import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import styles from './style';
import LoadingScreen from "./components/LoadingScreen";
import CustomModal from "../shared/CustomModal";
import strings from "../../strings";

type LayoutProps = {
    children: React.ReactNode;
    toggleTheme: () => void;
    theme: Theme;
    auth: AuthProps;
    me: AuthUser;
    language: LanguageType;
    toggleLanguage: () => void;
}

const Layout: FC<LayoutProps> = (props) => {
    const { children, language, auth } = props;
    const { me: { loading } } = props;
    const classes = styles();
    const theme = useTheme();
    const [logoutModal, setLogoutModal] = useState<boolean>(false);
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
        defaultMatches: true,
    });

    const [openSidebar, setOpenSidebar] = useState(false);

    const toggleSidebar = useCallback(() => {
        setOpenSidebar(!openSidebar);
    }, [openSidebar]);

    const shouldOpenSidebar = isDesktop ? true : openSidebar;

    return (
        <div
            className={clsx({
                [classes.root]: true,
                [classes.shiftContent]: isDesktop,
            })}
        >
            <LoadingScreen loading={loading} />
            <CustomModal
                open={logoutModal}
                title={strings.actions.LOG_OUT[language]}
                content={strings.actions.LOG_OUT_CONFIRM[language]}
                language={language}
                doneButtonLabel={strings.actions.LOG_OUT[language]}
                onClose={() => setLogoutModal(false)}
                onExecute={() => {
                    setLogoutModal(false);
                    auth.logout();
                }}
            />
            <Topbar
                {...props}
                sideBarOpen={shouldOpenSidebar}
                onSidebarOpen={toggleSidebar}
                openLogoutModal={() => setLogoutModal(true)}
            />
            <Sidebar
                {...props}
                onClose={toggleSidebar}
                open={shouldOpenSidebar}
                openLogoutModal={() => setLogoutModal(true)}
                variant={isDesktop ? "persistent" : "temporary"}
            />
            <main className={classes.content}>
                {children}
            </main>
        </div>
    );
};

export default Layout;
