import { useLazyQuery } from "@apollo/client";
import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ServiceAddTypeSection, ServiceEnhancedTable } from "../../components/Services";

import { ResponsiveTypography } from "../../components/shared";
import MainWrapper from "../../components/shared/MainWrapper";
import { permissionAccess } from "../../functions/permissionAccess";
import requests from "../../requests";
import strings from "../../strings";
import styles from './style';

const Services: FC<PageBaseProps> = (props) => {
    const { language, auth } = props;
    const history = useHistory();
    const classes = styles();
    const [addTypeExpanded, setAddTypeExpanded] = useState<boolean>(false)

    const toggleAddTypeExpand = useCallback(() => {
        setAddTypeExpanded(!addTypeExpanded);
    }, [addTypeExpanded]);

    const [onSearchServiceTypes, { data, loading: loadingSearchServiceTypes, refetch: refetchServiceTypes }] = useLazyQuery(requests.service.query.SEARCH_SERVICES, {
        fetchPolicy: "network-only",
    });

    const handleSearchServiceTypes = useCallback((rows: number, page: number, orderBy: ServiceTypeOrderBy) => {
        onSearchServiceTypes({
            variables: {
                orderBy,
                paginate: {
                    take: rows,
                    skip: Math.max(page, 0) * rows,
                }
            }
        })
    }, [onSearchServiceTypes]);

    useEffect(() => {
        document.title = `Corsem - ${strings.pages.SERVICES.label[language]}`;
    }, [language]);

    useEffect(() => {
        if (auth.me)
            if (!permissionAccess(auth.me.data.permissions, 23)) history.replace(strings.pages.FORBIDDEN.url)
    })

    return (
        <MainWrapper>
            <ResponsiveTypography
                className={classes.title}
                growthRate={50}
                fontWeight={800}
                smDown={33}
                mdDown={45}
                lgDown={33}
            >
                {strings.pages.SERVICES.label[language]}
            </ResponsiveTypography>

            <ResponsiveTypography
                growthRate={50}
                fontWeight={300}
                smDown={12}
                mdDown={20}
                lgDown={12}
            >
                {strings.pages.SERVICES.sublabel[language]}
            </ResponsiveTypography>

            <ServiceAddTypeSection
                language={language}
                expanded={addTypeExpanded}
                toggleAction={toggleAddTypeExpand}
                refetch={refetchServiceTypes}
            />

            <ServiceEnhancedTable
                rows={data?.searchServiceTypes?.serviceTypes || []}
                count={data?.searchServiceTypes?.count || 0}
                loading={loadingSearchServiceTypes}
                language={language}
                handleSearch={handleSearchServiceTypes}
                title={strings.pages.SERVICES.label[language]}
            />

        </MainWrapper>
    )
}
export default Services;