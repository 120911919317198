import { FC, useEffect } from "react";
import { Box, Button, Divider, MenuItem, TextField, Theme, useMediaQuery } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import clsx from 'clsx';
import { getError } from "../../../functions/formFunctions";
import strings from "../../../strings";
import { CustomerAddressProps, AddressPreviewProps, CustomerBusinessProps } from "../types";

import styles from './style';

const AddressesPreview: FC<AddressPreviewProps> = (props) => {
    const classes = styles();
    const { language, data, formData, formErrors, handleChangeField, toggleAddAddressModal, toggleAddBusinessModal, toggleAddProxyModal } = props;

    useEffect(() => {
        if(data?.addresses && data?.addresses.length > 0) {
            handleChangeField({ target: { name: "homeAddressId", value: data?.addresses[0].id } } as any)
            handleChangeField({ target: { name: "workAddressId", value: data?.addresses[0].id } } as any)
            handleChangeField({ target: { name: "billingAddressId", value: data?.addresses[0].id } } as any)
        }
    }, [data]);
    
    const isDownSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    return (
        <Box className={classes.root}>
            <TextField
                select
                fullWidth
                required
                className={classes.textField}
                variant="outlined"
                disabled={data?.addresses?.length === 0}
                label={strings.customer.HOME_ADDRESS[language]}
                value={data?.addresses ? (formData?.homeAddressId || '') : ''}
                onChange={handleChangeField}
                name='homeAddressId'
                error={Boolean(formErrors?.homeAddressId)}
                helperText={getError(formErrors?.homeAddressId, language)}
            >
                {data?.addresses ? data.addresses.map((item: CustomerAddressProps, index: number) =>
                    <MenuItem
                        key={index}
                        value={item.id}
                    >
                        {item.addressString}
                    </MenuItem>
                ) :
                    <MenuItem value=''>
                        <em>NONE</em>
                    </MenuItem>
                }
            </TextField>
            <TextField
                select
                fullWidth
                required
                className={classes.textField}
                variant="outlined"
                disabled={data?.addresses?.length === 0}
                label={strings.customer.WORK_ADDRESS[language]}
                value={data?.addresses ? (formData?.workAddressId || '') : ''}
                onChange={handleChangeField}
                name='workAddressId'
                error={Boolean(formErrors?.workAddressId)}
                helperText={getError(formErrors?.workAddressId, language)}
            >
                {data?.addresses ? data.addresses.map((item: CustomerAddressProps, index: number) =>
                    <MenuItem
                        key={index}
                        value={item.id}
                    >
                        {item.addressString}
                    </MenuItem>
                ) :
                    <MenuItem value=''>
                        <em>NONE</em>
                    </MenuItem>
                }
            </TextField>
            <TextField
                select
                fullWidth
                required
                className={classes.textField}
                variant="outlined"
                disabled={data?.addresses?.length === 0}
                label={strings.customer.BILLING_ADDRESS[language]}
                value={data?.addresses ? (formData?.billingAddressId || '') : ''}
                onChange={handleChangeField}
                name='billingAddressId'
                error={Boolean(formErrors?.billingAddressId)}
                helperText={getError(formErrors?.billingAddressId, language)}
            >
                {data?.addresses ? data.addresses.map((item: CustomerAddressProps, index: number) =>
                    <MenuItem
                        key={index}
                        value={item.id}
                    >
                        {item.addressString}
                    </MenuItem>
                ) :
                    <MenuItem value=''>
                        <em>NONE</em>
                    </MenuItem>
                }
            </TextField>
            <Button
                onClick={toggleAddAddressModal}
                className={classes.actionButton}
                color='secondary'
                size={isDownSm ? 'small' : 'medium'}
                variant={isDownSm ? 'contained' : 'outlined'}
            >
                <Add className={clsx(!isDownSm && classes.buttonIcon)} />
                {!isDownSm && strings.actions.ADD_ADDRESS[language]}
            </Button>
            <Divider className={classes.divider} />
            <TextField
                select
                fullWidth
                className={classes.textField}
                variant="outlined"
                disabled={data?.businesses?.length === 0}
                label={strings.customer.BILLING_INFO[language]}
                value={data?.businesses ? (formData?.businessId || '') : ''}
                onChange={handleChangeField}
                name='businessId'
                error={Boolean(formErrors?.businessId)}
                helperText={getError(formErrors?.businessId, language)}
            >
                <MenuItem value=''>
                    <em>NONE</em>
                </MenuItem>
                {data?.businesses?.map((item: CustomerBusinessProps, index: number) =>
                    <MenuItem
                        key={index}
                        value={item.id}
                    >
                        {item.name}
                    </MenuItem>
                )}
            </TextField>
            <Button
                onClick={toggleAddBusinessModal}
                className={classes.actionButton}
                color='secondary'
                size={isDownSm ? 'small' : 'medium'}
                variant={isDownSm ? 'contained' : 'outlined'}
            >
                <Add className={clsx(!isDownSm && classes.buttonIcon)} />
                {!isDownSm && strings.actions.ADD_BUSINESS[language]}
            </Button>
            <Divider className={classes.divider} />
            <TextField
                select
                fullWidth
                className={classes.textField}
                variant="outlined"
                disabled={data?.proxies?.length === 0}
                label={strings.customer.PROXY[language]}
                value={data?.proxies ? (formData?.proxyId || '') : ''}
                onChange={handleChangeField}
                name='proxyId'
                error={Boolean(formErrors?.proxyId)}
                helperText={getError(formErrors?.proxyId, language)}
            >
                <MenuItem value=''>
                    <em>NONE</em>
                </MenuItem>
                {data?.proxies?.map((item: CustomerProxyProps, index: number) =>
                    <MenuItem
                        key={index}
                        value={item.id}
                    >
                        {`${item.firstName} ${item.lastName} - ${item.phone}`}
                    </MenuItem>
                )}
            </TextField>
            <Button
                onClick={toggleAddProxyModal}
                className={classes.actionButton}
                color='secondary'
                size={isDownSm ? 'small' : 'medium'}
                variant={isDownSm ? 'contained' : 'outlined'}
            >
                <Add className={clsx(!isDownSm && classes.buttonIcon)} />
                {!isDownSm && strings.actions.ADD_PROXY[language]}
            </Button>
        </Box >
    )
}
export default AddressesPreview;