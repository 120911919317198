import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            backgroundColor: `${theme.palette.secondary.main}40`,
            boxShadow: "0 0 0",
            padding: theme.spacing(2, 2, 0, 2),
            position: "relative",
            overflow: "visible",
        },
        content: {
            opacity: 1,
        },
        subtitle: {
            marginTop: theme.spacing(2),
        },
        avatar: {
            position: "absolute",
            width: 150,
            height: 150,
            right: 0,
            bottom: 0,
        },
    }),
);