import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    menuPaper: {
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
        width: "350px",
        border: "1px solid rgba(255,255,255,0.12)",
        padding: "0px 8px",
        overflow: 'hidden',
    },
    list: {
        width: '100%',
        maxHeight: '400px',
        overflowY: 'auto'
    },
    menuItem: {
        position: 'relative',
    },
    title: {
        textAlign: 'center',
        margin: theme.spacing(2, 0)
    },
    header: {
        margin: theme.spacing(2, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    icon: {
        flexGrow: 1,
        marginRight: theme.spacing(1),
        backgroundColor: "rgba(255,255,255,.1)",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "2.5rem",
        minWidth: "2.5rem",
    },
    linkButton: {
        cursor: 'pointer',
    },
    text: {
        width: '100%',
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    deleteIcon: {
        height: 30,
        width: 30,
        position: 'absolute',
        right: theme.spacing(2),
        top: '50%',
        transform: 'translateY(-50%)',
        boxShadow: theme.shadows[5],
    },
    readIcon: {
        color: theme.palette.secondary.main,
    },
}));