import { ApolloClient, HttpLink, InMemoryCache, ApolloLink, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { getAccessToken, isLoggedIn } from './localState';

const authLink = new ApolloLink((operation, forward) => {
    if (isLoggedIn()) {
        operation.setContext({
            headers: {
                authorization: "Bearer " + getAccessToken(),
            },
        });
    }
    return forward(operation);
});

const httpLink = ApolloLink.from([authLink, new HttpLink({
    uri: process.env.REACT_APP_API_URL || "http://localhost:5000/graphql"
})]);

const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_WS_URL || "ws://localhost:5000/subscriptions",
    options: {
        reconnect: true,
        connectionParams: {
            authorization: isLoggedIn() && "Bearer " + getAccessToken(),
        },
    }
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink,
);


export default new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
        addTypename: false
    }),
});