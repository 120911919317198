import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        paddingTop: 56,
        position: 'relative',
        height: "100%",
        [theme.breakpoints.up("sm")]: {
            paddingTop: 95,
        },
    },
    shiftContent: {
        paddingLeft: 240,
    },
    content: {
        height: "100%",
    },
}));