import { FC } from "react";

import styles from './style';
import strings from "../../../strings";
import { CustomWrapper, FieldWithLabel } from "../../shared";
import { Divider } from "@material-ui/core";

type ProxySectionProps = {
    language: LanguageType;
    data: CustomerProxyProps | undefined;
}
const ProxySection: FC<ProxySectionProps> = (props) => {
    const classes = styles();
    const { language, data } = props;

    return (
        <CustomWrapper
            title={strings.customer.PROXY[language]}
        >
            <FieldWithLabel
                label={strings.general.ID[language]}
                value={data?.id.toString() || '-'}
            />
            <Divider className={classes.divider} />
            <FieldWithLabel
                label={strings.general.FIRST_NAME[language]}
                value={data?.firstName || '-'}
            />
            <Divider className={classes.divider} />
            <FieldWithLabel
                label={strings.general.LAST_NAME[language]}
                value={data?.lastName || '-'}
            />
            <Divider className={classes.divider} />
            <FieldWithLabel
                label={strings.general.PHONE[language]}
                value={data?.phone || '-'}
            />
        </CustomWrapper>
    )
}
export default ProxySection;