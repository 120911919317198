import { FC } from "react";
import { Grid, Avatar } from "@material-ui/core";
import clsx from "clsx";
import styles from './style';

const avatars = [
    { id: 1, name: "avatar-01.svg" },
    { id: 2, name: "avatar-02.svg" },
    { id: 3, name: "avatar-03.svg" },
    { id: 4, name: "avatar-04.svg" },
    { id: 5, name: "avatar-05.svg" },
    { id: 6, name: "avatar-06.svg" },
    { id: 7, name: "avatar-07.svg" },
    { id: 8, name: "avatar-08.svg" },
];

type AvatarSelectProps = {
    selected: number;
    handleSelect: (id: number) => void;
}

const AvatarSelect: FC<AvatarSelectProps> = (props) => {
    const classes = styles();
    const { selected, handleSelect } = props;

    return (
        <Grid container spacing={3}>
            {avatars.map((item) => (
                <Grid
                    item
                    xs={6}
                    md={3}
                    key={item.id}
                    className={classes.wrapper}
                >
                    <Avatar
                        className={clsx(classes.avatar, {
                            [classes.selected]: selected === item.id,
                        })}
                        src={`/svg/avatars/${item.name}`}
                        onClick={() => handleSelect(item.id)}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default AvatarSelect;
