import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Card, Divider, Fab, Grid, LinearProgress, TextField, Theme, Tooltip, useMediaQuery } from "@material-ui/core";
import { CheckRounded, DeleteRounded, EditRounded, PictureAsPdfRounded } from "@material-ui/icons";
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from 'clsx';

import { CommentBox, ResponsiveTypography } from "../../components/shared";
import MainWrapper from "../../components/shared/MainWrapper";
import { useRouteQuery } from "../../functions";
import requests from "../../requests";
import strings from "../../strings";
import styles from './style';
import { AddressSection, CustomerSection, ProxySection, ServiceSection } from "../../components/Quote";
import CustomModal from "../../components/shared/CustomModal";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { useSnackbar } from "notistack";
import moment from "moment";
import { permissionAccess } from "../../functions/permissionAccess";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { QuotePDF } from "../../components/PDFs";

type CustomerAllType = {
    customer: CustomerProps;
    phone: CustomerPhoneProps;
    business?: CustomerBusinessProps;
}

type AddressGroup = {
    homeAddress: CustomerAddressProps;
    billingAddress: CustomerAddressProps;
    workAddress: CustomerAddressProps;
}

const Quote: FC<PageBaseProps> = (props) => {
    const { language, auth } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [pageId, setPageId] = useState<number | undefined>();
    const [quoteAccepted, setQuoteAccepted] = useState<boolean>(false);
    const [services, setServices] = useState<ServiceProps[]>([]);
    const [customer, setCustomer] = useState<CustomerAllType>();
    const [addresses, setAddresses] = useState<AddressGroup>();
    const [proxy, setProxy] = useState<CustomerProxyProps>();
    const [scheduleModal, setScheduleModal] = useState<boolean>(false);
    const [projectModal, setProjectModal] = useState<boolean>(false);
    const [acceptQuoteModal, setAcceptQuoteModal] = useState<boolean>(false);
    const [selectedService, setSelectedService] = useState<number | undefined>();
    const [scheduledDate, setScheduleDate] = useState<string>(moment().format().split('T')[0]);
    const [projectNumber, setProjectNumber] = useState<string | undefined>();
    const query = useRouteQuery();
    const history = useHistory();
    const classes = styles();

    const toggleScheduleModal = (serviceId?: number) => {
        if (typeof serviceId === 'number') {
            setSelectedService(serviceId);
            setScheduleModal(true);
        }
        else {
            setSelectedService(undefined);
            setScheduleModal(false);
        }
    }
    const toggleProjectModal = (serviceId?: number) => {
        if (typeof serviceId === 'number') {
            setSelectedService(serviceId);
            setProjectModal(true);
        }
        else {
            setSelectedService(undefined);
            setProjectModal(false);
        }
    }
    const toggleAcceptQuoteModal = () => setAcceptQuoteModal(prev => !prev);

    const [onQuoteSearch, { data, refetch, loading: loadingQuoteFetch }] = useLazyQuery(requests.quote.query.SEARCH_QUOTE_DETAIL, {
        fetchPolicy: "network-only",
    });

    const onServiceSchedule = () => {
        createExecution({
            variables: {
                data: {
                    scheduledDate,
                    quoteId: pageId,
                    serviceId: selectedService,
                }
            }
        }).then(() => {
            enqueueSnackbar(
                strings.notifications.EXECUTION_SCHEDULED_SUCCESS[language],
                {
                    variant: "success",
                }
            );
            refetch && refetch();
            toggleScheduleModal();
            setScheduleDate(moment().format().split('T')[0]);
        }).catch((error) => {
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }

    const onServiceCreateProject = () => {
        createProject({
            variables: {
                data: {
                    number: projectNumber,
                    quoteId: pageId,
                    serviceId: selectedService,
                }
            }
        }).then(() => {
            enqueueSnackbar(
                strings.notifications.PROJECT_CREATE_SUCCESS[language],
                {
                    variant: "success",
                }
            );
            refetch && refetch();
            toggleProjectModal();
            setProjectNumber(undefined);
        }).catch((error) => {
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }

    const onQuoteAccept = () => {
        acceptQuote({
            variables: {
                id: pageId
            }
        }).then(() => {
            enqueueSnackbar(
                strings.notifications.QUOTE_UPDATE_SUCCESS[language],
                {
                    variant: "success",
                }
            );
            refetch && refetch();
            toggleAcceptQuoteModal();
        }).catch((error) => {
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }

    const [createExecution, { loading: loadingCreateExecution }] = useMutation(requests.execution.mutation.CREATE_EXECUTION);
    const [createProject, { loading: loadingCreateProject }] = useMutation(requests.project.mutation.CREATE_PROJECT);
    const [acceptQuote, { loading: loadingAcceptQuote }] = useMutation(requests.quote.mutation.ACCEPT_QUOTE);

    const handleEditQuote = () => history.push(`${strings.pages.NEW_QUOTE.url}?edit=${pageId}`)

    useEffect(() => {
        document.title = `Corsem - ${strings.pages.QUOTES.label[language]}`;
    }, [language]);

    useEffect(() => setPageId(Number(query.get("id"))), [query, setPageId]);

    useEffect(() => {
        if (data) {
            setServices(data.searchQuote.services)
            setCustomer({
                customer: data.searchQuote.customer,
                phone: data.searchQuote.customerPhone,
                business: data.searchQuote.business,
            })
            setAddresses({
                homeAddress: data.searchQuote.homeAddress,
                billingAddress: data.searchQuote.billingAddress,
                workAddress: data.searchQuote.workAddress,
            })
            setProxy(data.searchQuote.proxy)
            setQuoteAccepted(data.searchQuote.accepted)
        }
    }, [data, setServices]);

    useEffect(() => {
        if (pageId)
            onQuoteSearch({
                variables: { id: pageId },
            })
    }, [pageId, onQuoteSearch])

    useEffect(() => {
        if (auth.me)
            if (!permissionAccess(auth.me.data.permissions, 7)) history.replace(strings.pages.FORBIDDEN.url)
    })

    const isDownSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    return (
        <MainWrapper>
            <ResponsiveTypography
                className={classes.title}
                growthRate={50}
                fontWeight={800}
                smDown={33}
                mdDown={45}
                lgDown={33}
            >
                {`${strings.pages.QUOTE(1).label[language]} #${pageId}`}
            </ResponsiveTypography>

            <Card elevation={5} className={classes.root_paper}>
                <Box className={classes.actions}>
                    <Fab
                        className={classes.acceptButton}
                        onClick={toggleAcceptQuoteModal}
                        color='secondary'
                        disabled={loadingQuoteFetch || quoteAccepted}
                        size={isDownSm ? 'small' : 'medium'}
                        variant={isDownSm ? 'round' : 'extended'}
                    >
                        {<CheckRounded className={clsx(!isDownSm && classes.buttonIcon)} />}
                        {!isDownSm && (quoteAccepted ? strings.actions.QUOTE_ACCEPTED[language] : strings.actions.ACCEPT('')[language])}
                    </Fab>
                    <Fab
                        className={classes.actionButton}
                        onClick={handleEditQuote}
                        color='secondary'
                        disabled={loadingQuoteFetch}
                        size={isDownSm ? 'small' : 'medium'}
                        variant={isDownSm ? 'round' : 'extended'}
                    >
                        <EditRounded className={clsx(!isDownSm && classes.buttonIcon)} />
                        {!isDownSm && strings.actions.EDIT('')[language]}
                    </Fab>
                    {data?.searchQuote &&
                        <PDFDownloadLink document={<QuotePDF data={data.searchQuote as QuoteProps} />} fileName="quote.pdf">
                            {({ loading, error }) => error ? error :
                                <Tooltip title={strings.actions.DOWNLOAD_QUOTE[language]}>
                                    <Fab
                                        className={clsx(classes.actionButton)}
                                        disabled={loadingQuoteFetch || loading}
                                        color='secondary'
                                        size='small'
                                        variant='round'
                                    >
                                        <PictureAsPdfRounded />
                                    </Fab>
                                </Tooltip>
                            }
                        </PDFDownloadLink>
                    }
                    <Fab
                        className={clsx(classes.actionButton, classes.deleteButton)}
                        color='secondary'
                        size='small'
                        disabled={loadingQuoteFetch}
                    >
                        <DeleteRounded />
                    </Fab>
                </Box>
                {loadingQuoteFetch ?
                    <LinearProgress />
                    :
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={3}>
                            <CustomerSection
                                language={language}
                                data={customer}
                            />
                        </Grid>
                        {proxy !== null &&
                            <Grid item xs={12} md={6} lg={3}>
                                <ProxySection
                                    language={language}
                                    data={proxy}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={6} lg={6}>
                            <Grid container direction='column' spacing={2}>
                                <Grid item xs={12}>
                                    <AddressSection
                                        language={language}
                                        title={strings.customer.HOME_ADDRESS[language]}
                                        data={addresses?.homeAddress}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AddressSection
                                        language={language}
                                        title={strings.customer.BILLING_ADDRESS[language]}
                                        data={addresses?.billingAddress}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AddressSection
                                        language={language}
                                        title={strings.customer.WORK_ADDRESS[language]}
                                        data={addresses?.workAddress}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        {services.map((service: ServiceProps, index: number) =>
                            <Grid
                                key={index}
                                item
                                xs={12}
                                md={service.products?.length > 0 ? 12 : 6}
                                lg={service.products?.length > 0 ? 6 : 3}
                            >
                                <ServiceSection
                                    language={language}
                                    data={service}
                                    toggleScheduleModal={() => toggleScheduleModal(service.id)}
                                    toggleProjectModal={() => toggleProjectModal(service.id)}
                                    quoteAccepted={quoteAccepted}
                                />
                            </Grid>
                        )}
                    </Grid>
                }
            </Card>
            <Card elevation={5} className={classes.root_paper}>
                <CommentBox
                    id={pageId as number}
                    category='QUOTE'
                    language={language}
                    me={auth.me as AuthUser}
                />
            </Card>
            <CustomModal
                language={language}
                title={strings.actions.SCHEDULE[language]}
                open={scheduleModal}
                disableExecute={loadingCreateExecution}
                content={
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            autoOk
                            orientation="portrait"
                            variant="static"
                            openTo="date"
                            minDate={new Date()}
                            value={scheduledDate}
                            onChange={(date: any) => setScheduleDate(moment(date).format().split('T')[0])}
                        />
                    </MuiPickersUtilsProvider>
                }
                onClose={toggleScheduleModal}
                onExecute={onServiceSchedule}
            />

            <CustomModal
                language={language}
                title={strings.actions.CREATE_PROJECT[language]}
                open={projectModal}
                disableExecute={loadingCreateProject}
                content={
                    <TextField
                        fullWidth
                        label={strings.columns.PROJECT_NO[language]}
                        value={projectNumber || ''}
                        variant='outlined'
                        onChange={(e) => setProjectNumber(e.target.value)}
                        helperText={strings.general.IF_EXISTS[language]}
                    />
                }
                onClose={toggleProjectModal}
                onExecute={onServiceCreateProject}
            />

            <CustomModal
                fullWidth
                language={language}
                title={strings.actions.ACCEPT(strings.pages.QUOTE(1).label[language])[language]}
                open={acceptQuoteModal}
                disableExecute={loadingAcceptQuote}
                onClose={toggleAcceptQuoteModal}
                onExecute={onQuoteAccept}
            />
        </MainWrapper>
    )
}
export default Quote;