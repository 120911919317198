import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(0, 1),
    }
}));