import { FC, useState } from "react";

import styles from './style';
import strings from "../../../strings";
import { CustomWrapper, FieldWithLabel } from "../../shared";
import { Divider, IconButton } from "@material-ui/core";
import { OpenInNewRounded } from "@material-ui/icons";
import { useEffect } from "react";
import { useHistory } from "react-router";

type CustomerAllType = {
    customer: CustomerProps;
    phone: CustomerPhoneProps;
    business?: CustomerBusinessProps;
}

type CustomerSectionProps = {
    language: LanguageType;
    data: CustomerAllType | undefined;
}
const CustomerSection: FC<CustomerSectionProps> = (props) => {
    const classes = styles();
    const { language, data } = props;
    const history = useHistory();
    const [isBusiness, setIsBusiness] = useState<boolean>(false);

    useEffect(() => data?.business && setIsBusiness(true), [data])

    console.log(data)

    return (
        <CustomWrapper
            title={strings.general.CUSTOMER_DETAILS[language]}
            link={
                <IconButton
                    size='small'
                    color='secondary'
                    onClick={() => data && history.push(strings.pages.CUSTOMER(data?.customer.id).url)}
                >
                    <OpenInNewRounded fontSize='small' />
                </IconButton>
            }
        >
            <FieldWithLabel
                label={strings.general.ID[language]}
                value={data?.customer.id.toString() || '-'}
            />
            <Divider className={classes.divider} />
            {isBusiness ?
                <>
                    <FieldWithLabel
                        label={strings.customer.NAME[language]}
                        value={data?.business?.name || '-'}
                    />
                    <Divider className={classes.divider} />
                    <FieldWithLabel
                        label={strings.customer.CIF[language]}
                        value={data?.business?.cif || '-'}
                    />
                    <Divider className={classes.divider} />
                    <FieldWithLabel
                        label={strings.customer.NRC[language]}
                        value={data?.business?.nrc || '-'}
                    />
                    <Divider className={classes.divider} />
                    <FieldWithLabel
                        label={strings.customer.ACCOUNT[language]}
                        value={data?.business?.account || '-'}
                    />
                    <Divider className={classes.divider} />
                    <FieldWithLabel
                        label={strings.customer.BANK[language]}
                        value={data?.business?.bank || '-'}
                    />
                    <Divider className={classes.divider} />
                </>
                :
                <>
                    <FieldWithLabel
                        label={strings.general.FIRST_NAME[language]}
                        value={data?.customer.firstName || '-'}
                    />
                    <Divider className={classes.divider} />
                    <FieldWithLabel
                        label={strings.general.LAST_NAME[language]}
                        value={data?.customer.lastName || '-'}
                    />
                    <Divider className={classes.divider} />
                    <FieldWithLabel
                        label={strings.general.EMAIL[language]}
                        value={data?.customer.email || '-'}
                    />
                    <Divider className={classes.divider} />
                    <FieldWithLabel
                        label={strings.general.CNP[language]}
                        value={data?.customer.cnp || '-'}
                    />
                    <Divider className={classes.divider} />
                </>
            }
            <FieldWithLabel
                label={strings.general.PHONE[language]}
                value={data?.phone.phone || '-'}
            />
        </CustomWrapper>
    )
}
export default CustomerSection;