import { ChangeEvent, FC, useCallback, useState } from "react";

import { AuxTypeProps, ServicePreviewProps, ServiceTypeProps } from "../types";
import styles from './style';
import strings from "../../../strings";
import { useLazyQuery } from "@apollo/client";
import requests from "../../../requests";
import { useEffect } from "react";
import { Box, Button, Divider, Grid, IconButton, InputAdornment, Menu, MenuItem, TextField } from "@material-ui/core";
import { CustomWrapper, ResponsiveTypography } from "../../shared";
import { Add, Delete } from "@material-ui/icons";
import { getError } from "../../../functions/formFunctions";
import { AuxFormValues, ProductFormValues } from "../../../Views/NewQuote/types";
import { AuxPreview, ProductPreview, SearchProduct } from "..";
import { AutocompleteChangeReason } from "@material-ui/lab";

const ServicePreview: FC<ServicePreviewProps> = (props) => {
    const classes = styles();
    const { language, index, handleChangeField, handleDeleteService, serviceData, formErrors, handleSelectAux, handleDeleteAux, handleAuxChangeField, handleDeleteProd, handleProdChangeField, handleSelectProd } = props;
    const [serviceTypeData, setServiceTypeData] = useState<ServiceTypeProps>();
    const [auxTypeList, setAuxTypeList] = useState<AuxTypeProps[]>([]);

    const [onSearchServiceTypes, { data }] = useLazyQuery(requests.service.query.SEARCH_SERVICES, {
        fetchPolicy: "network-only",
    });

    const handleSearchServices = useCallback((rows: number, page: number, orderBy: ServiceTypeOrderBy) => {
        onSearchServiceTypes({
            variables: {
                filters: {
                    id: serviceData.typeId,
                },
                orderBy,
                paginate: {
                    take: rows,
                    skip: Math.max(page, 0) * rows,
                }
            }
        })
    }, [onSearchServiceTypes, serviceData.typeId]);

    useEffect(() => {
        handleSearchServices(10, 0, 'category_asc' as ServiceTypeOrderBy);
    }, [handleSearchServices]);

    useEffect(() => {
        if (data?.searchServiceTypes?.serviceTypes && data.searchServiceTypes.serviceTypes[0]) {
            setServiceTypeData(data.searchServiceTypes.serviceTypes[0]);
            setAuxTypeList(data.searchServiceTypes.serviceTypes[0].auxTypes);
        }
    }, [data])

    return (
        <Box className={classes.root}>
            <ResponsiveTypography
                className={classes.title}
                fontWeight={600}
                growthRate={50}
                lgDown={15}
                mdDown={20}
                smDown={15}
            >
                {serviceTypeData && `#${index + 1} ${serviceTypeData.category.toUpperCase()}`}
                <IconButton className={classes.deleteButton} onClick={() => handleDeleteService(index)} size='small'>
                    <Delete fontSize='small' />
                </IconButton>
            </ResponsiveTypography>
            <Divider className={classes.dividerTitle} />
            <Grid container className={classes.content} direction='row' spacing={2}>
                <Grid item xs={12} lg={3}>
                    <Grid container direction='column' spacing={2}>
                        {serviceTypeData?.price &&
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    type='number'
                                    variant='outlined'
                                    label={strings.columns.PRICE[language]}
                                    name='price'
                                    value={serviceData.price === 0 ? "" :serviceData.price }
                                    onChange={(e) => handleChangeField(e, index)}
                                    error={Boolean(formErrors?.services && formErrors.services[index]?.price && formErrors.services[index]?.price.type === '')}
                                    helperText={getError(formErrors?.services && formErrors?.services[index]?.price, language)}
                                    InputProps={{
                                        inputProps: {
                                            min: 0
                                        }
                                    }}
                                />
                            </Grid>
                        }
                        {serviceTypeData?.discount &&
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type='number'
                                    variant='outlined'
                                    label={strings.columns.DISCOUNT[language]}
                                    name='discount'
                                    value={serviceData.discount || ''}
                                    onChange={(e) => handleChangeField(e, index)}
                                    error={Boolean(formErrors?.services && formErrors.services[index]?.discount && formErrors.services[index]?.discount.type !== '')}
                                    helperText={getError(formErrors?.services && formErrors?.services[index]?.discount, language)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">RON</InputAdornment>,
                                    }}
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                variant='outlined'
                                label={strings.columns.OBS[language]}
                                name='obs'
                                value={serviceData.obs || ''}
                                onChange={(e) => handleChangeField(e, index)}
                                error={Boolean(formErrors?.services && formErrors.services[index]?.obs)}
                                helperText={getError(formErrors?.services && formErrors?.services[index]?.obs, language)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {serviceTypeData?.noOfAuxTypes && serviceTypeData.noOfAuxTypes > 0 &&
                    <Grid item xs={12} lg={9}>
                        <CustomWrapper className={classes.subsection} title='Subservicii'>
                            <Grid container spacing={2} alignContent='stretch' style={{ marginTop: '8px' }}>
                                {serviceData.auxs && serviceData.auxs.map((aux: AuxFormValues, auxIndex: number) =>
                                    <Grid key={auxIndex} item xs={12}>
                                        <CustomWrapper>
                                            <AuxPreview
                                                {...props}
                                                index={auxIndex}
                                                auxData={aux}
                                                handleChangeField={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, auxIndex: number) => handleAuxChangeField(event, auxIndex, index)}
                                                handleDeleteAux={() => handleDeleteAux(index, auxIndex)}
                                            />
                                        </CustomWrapper>
                                    </Grid>
                                )}
                            </Grid>
                            <AuxList
                                {...props}
                                list={auxTypeList}
                                handleSelectAux={(value: number) => handleSelectAux(index, value)}
                            />
                        </CustomWrapper>
                    </Grid>
                }
                {serviceTypeData?.noOfAuxTypes && serviceTypeData.noOfAuxTypes > 0 &&
                    <Grid item xs={12}>
                        <CustomWrapper className={classes.subsection} title='Produse'>
                            <Box style={{ display: 'flex' }}>
                                <SearchProduct
                                    language={language}
                                    onHandleSelectProduct={(event: ChangeEvent<{}>, value: any, reason: AutocompleteChangeReason) => handleSelectProd(event, value, reason, index)}
                                />
                            </Box>
                            <Grid container spacing={2} alignContent='stretch' style={{ marginTop: '8px' }}>
                                {serviceData.products && serviceData.products.map((prod: ProductFormValues, prodIndex: number) =>
                                    <Grid key={prodIndex} item xs={12} md={6}>
                                        <CustomWrapper>
                                            <ProductPreview
                                                {...props}
                                                index={prodIndex}
                                                productData={prod}
                                                handleChangeField={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleProdChangeField(event, prodIndex, index)}
                                                handleDeleteProd={() => handleDeleteProd(index, prodIndex)}
                                            />
                                        </CustomWrapper>
                                    </Grid>
                                )}
                            </Grid>
                        </CustomWrapper>
                    </Grid>
                }
            </Grid>
        </Box >
    )
}
export default ServicePreview;

type AuxListProps = {
    language: LanguageType;
    list: AuxTypeProps[];
    handleSelectAux: (value: number) => void;
}

const AuxList: FC<AuxListProps> = (props) => {
    const { language, list, handleSelectAux } = props;
    const classes = styles();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleItemSelect = (id: number) => {
        handleSelectAux(id);
        setAnchorEl(null);
    }

    return (
        <>
            <Menu
                elevation={2}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {list.map((item: AuxTypeProps, index: number) =>
                    <MenuItem
                        key={index}
                        onClick={() => handleItemSelect(item.id)}
                    >
                        <ResponsiveTypography fontWeight={800}>
                            {item.category}
                        </ResponsiveTypography>
                    </MenuItem>
                )}
            </Menu>
            <Button
                className={classes.addAuxButton}
                variant='outlined'
                color='secondary'
                size='small'
                onClick={handlePopoverOpen}
            >
                <Add fontSize='small' />
                {strings.actions.ADD[language]}
            </Button>
        </>
    )
}