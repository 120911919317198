import { FC, useCallback, useState } from "react";

import { SearchServiceProps, ServiceTypeProps } from "../types";
import styles from './style';
import strings from "../../../strings";
import { VirtualizedSelect } from "../../shared";
import { useLazyQuery } from "@apollo/client";
import requests from "../../../requests";
import { useEffect } from "react";
import { Typography } from "@material-ui/core";

const SearchService: FC<SearchServiceProps> = (props) => {
    const classes = styles();
    const { language, onHandleSelectService } = props;
    const [shouldRefetch, setShouldRefetch] = useState(false)


    const [onSearchServiceTypes, { data, loading: loadingSearchServiceTypes }] = useLazyQuery(requests.service.query.SEARCH_SERVICES, {
        fetchPolicy: "network-only",
    });

    const handleSearchServices = useCallback((rows: number, page: number, orderBy: ServiceTypeOrderBy) => {
        onSearchServiceTypes({
            variables: {
                filters: {
                    status: 'ACTIVE'
                },
                orderBy,
                paginate: {
                    take: rows,
                    skip: Math.max(page, 0) * rows,
                }
            }
        })
    }, [onSearchServiceTypes]);

    useEffect(() => {
        handleSearchServices(10, 0, 'category_asc' as ServiceTypeOrderBy);
    }, [handleSearchServices]);

    return (
        <VirtualizedSelect
            search
            className={classes.searchField}
            language={language}
            options={data?.searchServiceTypes?.serviceTypes || []}
            onChange={onHandleSelectService}
            disabled={loadingSearchServiceTypes}
            getOptionSelected={(option: ServiceTypeProps, value: ServiceTypeProps) => option.id === value.id}
            getOptionLabel={(option: ServiceTypeProps) => option.category}
            renderOption={(option: ServiceTypeProps) => <Typography noWrap>{`${option.id}# ${option.category}`}</Typography>}
            textFieldProps={{
                required: true,
                fullWidth: true,
                placeholder: `${strings.actions.SEARCH_PAGE(strings.pages.SERVICE(1).label[language])[language]}...`,
            }}
        />
    )
}
export default SearchService;