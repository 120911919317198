import { ChangeEvent, FC, useState } from "react";
import { ApolloQueryResult, OperationVariables, useMutation, useQuery } from "@apollo/client";
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    Hidden,
    InputAdornment,
    Paper,
    TextField,
    Theme,
    Typography,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import { AddBoxRounded, ClearRounded, ExpandMoreRounded, ImageRounded } from "@material-ui/icons";
import { Fab } from "@material-ui/core";
import { DeepMap, FieldError, SubmitHandler, useForm, UseFormRegister, UseFormSetValue } from "react-hook-form";
import clsx from 'clsx';

import { ResponsiveTypography, VirtualizedSelect } from "../../shared";
import styles from './style';
import strings from "../../../strings";
import requests from "../../../requests";
import { convertFileSize } from "../../../functions/numberFunctions";
import { useCallback } from "react";
import { getError } from "../../../functions/formFunctions";
import { AutocompleteChangeReason } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { useEffect } from "react";

const defaultImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAHlBMVEX09PTh4eH19fXg4ODk5OTw8PDs7Ozq6uru7u7n5+dZKxXMAAAELUlEQVR4nO2dWXKtMAwFwQMX9r/hB9RNMOARhCTyTv/kIxVQl4kHYZmuAwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADgAsZ03o9eA6OfwyH360bXWz30kyd2HHvbq8LaibQdJ2V+C7Yf6RSdQsEZOxApGqWCsyJNK5qPVsEZCsHOS1tksBNFI2rsZTY8gaG0QxaCRjRj0ITS4/wvW0judguaKbjaID1f+xLEZG8/psZtV6OeKF3GbH2DHW9fbDN0WgTn/j0wvBtVYEjSM9MAwxZgKAMMW4ChDLyGxoQ/eGA0NMYPk3O9c9PH80nyGZo1A/e9l3WEiZM8DxmeZm3GH/Ib1jFNXZna0AzntTFZcigPj2E8f2M/HIoshqkEFYsih+Fu7b9XZOhvWNow4bdw85YVMBjmkqg0Kb4sHG2YaUKaFF+W5w3zefDnO5vnR/wwPxXh8YwOw1Oaz4Pbmzct8ryhLxhe+EdsivRxw/RgePW2821a/uZ5w8ILt/auZp7Ct8Qqb9g6/17XKA3BKnhK227yswirjvZtPc22yqwN912jxW6bQGW87xrx93mCuoBfNWs7JkKqIuaYeWcf04brn3ey1ITMsXrK7F9oWD1Ft+pUxPyeFXB8L1I5aJYsRnKbTcM9E5utyleQzUTVz2eSu8mKYb8km5jbLleI+xUZ4cJ+wHzgr8jqFzY85iNnfPe0vJn5uVff8mamuKMzGzrn27Xu9+3a4Bu2JddsWc3Erv4NaeWe3HTw6t9yV246Tkev3bB6V3UyfOWGDdvGU/HrNmzbFx8X0GzYvPE/asA14l+hubIhqqC4DS+UbsQc9Bpeq005S2g1vFx8c7LQani5uuikIWhohnR5wI3yqaOHnOG8ZLQpxVv1YYd1p5jhuiZOKN4sgNsrShl+F/0xxfsVfjtFoRE/yGqcfkdQwhgqyrRhmLY5tiJJjWbwvkfEcJeXOjyoNEWoVrYND4m3UJGqyla2DU+ZxUCRqoxY1DCWOv3pbsjqpCUNo4LfVqQrBBc0jAquioaylF/OMCG4KlKW8j9vmBjxk4LUSLUhm6CUIZ+gkCGjoIwhp6CIIaughCGvoIAhsyC/Ibcg+4jPLsjdhvyCzIYCgryGEoK8hoV93u83lDn+i9VQ4iGFIQwrDN2fN/z7bQhDGMIQhjCEYdnwvxrxPyKnljIadn6QgNOwMxKwGgoDwxZgKAMMW4ChDE/N2kiCoyA4eYTgPO/t/PPluyc6COrICc5kDybZ1jodhPP+u4LFg1qkofjXyR9jIgzFaaKl84Rkoen9FDci1Tmb0h5JqM6gNFo7m2R5zgVFbd8kW5nHZzJOx0MowNqhHHiL4+h2H7ARZvl4HvUHAtfD1yfpycyX6TPSfwCxE8o+JXhADwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAs/ANyGFT0fw3sTAAAAABJRU5ErkJggg==';

type AddSectionProps = {
    language: LanguageType;
    expanded: boolean;
    toggleAction: () => void;
    refetchProducts: ((variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>) | undefined;
}

type DescriptionValues = {
    id?: number;
    description?: string;
}

type ProductTypeValues = {
    id?: number;
    description?: DescriptionValues;
}

type ProductFormValues = {
    manufacturer: string;
    model: string;
    price: number;
    image?: string;
    type: ProductTypeValues;
};

const AddSection: FC<AddSectionProps> = (props) => {
    const classes = styles();
    const { enqueueSnackbar } = useSnackbar();
    const { language, expanded, toggleAction, refetchProducts } = props;
    const [base64, setBase64] = useState<string>(defaultImage);
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm<ProductFormValues>({
        defaultValues: {
            image: defaultImage,
            type: {
                id: undefined,
                description: {
                    id: undefined,
                    description: undefined,
                }
            }
        },
        reValidateMode: 'onChange',
        shouldFocusError: true,
        criteriaMode: 'firstError'
    });

    const handleToggle = () => {
        toggleAction();
        setBase64(defaultImage);
        setValue('image', defaultImage);
        reset();
    }

    const onSubmit: SubmitHandler<ProductFormValues> = (data, event) => {
        event?.preventDefault();
        createProduct({
            variables: {
                ...data,
                price: parseFloat(data.price.toString())
            }
        }).then(() => {
            enqueueSnackbar(
                strings.notifications.PRODUCT_CREATE_SUCCESS[language],
                {
                    variant: "success",
                }
            );
            refetchProducts && refetchProducts();
            toggleAction();
            reset();
            setValue('manufacturer', "");
            setValue('model', "");
            setValue('price', 0);
            setValue('image', defaultImage);
            setValue('type', {
                id: undefined,
                description: {
                    id: undefined,
                    description: undefined,
                }
            });
        }).catch((error) => {
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }

    const onChangeType = (event: React.ChangeEvent<{}>, value: any, reason: AutocompleteChangeReason) => {
        if (reason === 'clear')
            setValue('type', {
                id: undefined,
                description: {
                    id: undefined,
                    description: undefined,
                }
            });
        else if (reason === 'select-option')
            setValue('type', {
                id: value.id,
                description: {
                    id: value.description.id,
                    description: value.description.description,
                }
            });
        else return;
    }

    const [
        createProduct,
        { loading: loadingCreateProduct }
    ] = useMutation(requests.product.mutation.CREATE_PRODUCT);

    const { data: productTypes, refetch: refetchProductTypes } = useQuery(requests.product.query.SEARCH_PRODUCT_TYPES, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        refetchProductTypes();
    }, [expanded, refetchProductTypes])

    return (
        <Paper elevation={5} className={classes.root_paper}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Accordion className={classes.role_item} expanded={expanded} onChange={handleToggle}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreRounded />}
                        aria-controls={'content'}
                        id={'header'}
                    >
                        <Box className={classes.addRoleTitle}>
                            <ResponsiveTypography
                                className={clsx(classes.addRoleTitle, classes.column)}
                                growthRate={50}
                                mdDown={20}
                            >
                                <AddBoxRounded className={classes.addRoleIcon} />
                                {strings.actions.ADD_NEW_PRODUCT.title[language]}
                            </ResponsiveTypography>
                            <Hidden mdDown>
                                <ResponsiveTypography
                                    className={classes.column}
                                    growthRate={50}
                                    lgDown={12}
                                >
                                    {strings.actions.ADD_NEW_PRODUCT.subtitle[language]}
                                </ResponsiveTypography>
                            </Hidden>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                        {expanded && <ProductForm
                            language={language}
                            register={register}
                            setValue={setValue}
                            loading={loadingCreateProduct}
                            errors={errors}
                            base64={base64}
                            setBase64={(value: string) => {
                                setBase64(value);
                                setValue('image', value);
                            }}
                            productTypes={productTypes?.searchProductTypes || []}
                            onChangeType={onChangeType}
                        />
                        }
                    </AccordionDetails>
                    <Divider />
                    <AccordionActions>
                        <Button
                            size="small"
                            onClick={handleToggle}
                            disabled={loadingCreateProduct}
                        >
                            {strings.actions.CANCEL[language]}
                        </Button>
                        <Box className={classes.buttonWrapper}>
                            <Button
                                size="small"
                                disabled={loadingCreateProduct}
                                color="secondary"
                                type="submit"
                            >
                                {strings.actions.SAVE[language]}
                            </Button>
                            {loadingCreateProduct && <CircularProgress size={24} className={classes.progress} />}
                        </Box>
                    </AccordionActions>
                </Accordion>
            </form>
        </Paper>
    );
}

export default AddSection;

type ProductFormProps = {
    language: LanguageType;
    register: UseFormRegister<ProductFormValues>;
    setValue: UseFormSetValue<ProductFormValues>;
    loading: boolean;
    errors: DeepMap<ProductFormValues, FieldError>;
    base64: string;
    setBase64: (value: string) => void;
    productTypes: ProductTypeProps[];
    onChangeType: (event: React.ChangeEvent<{}>, value: any, reason: AutocompleteChangeReason) => void;
}

const ProductForm: FC<ProductFormProps> = (props) => {
    const classes = styles();
    const isDownXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const { language, register, setValue, errors, loading, base64, setBase64, productTypes, onChangeType } = props;

    const handleChangeField = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { name, value } = event.target;
        setValue(name as any, value);
    }, [setValue])

    return (
        <Box style={{ display: 'flex' }}>
            <Grid container>
                <Grid container item lg={3} md={6} xs={12} direction='column' justify='center'>
                    <ImageHandler
                        language={language}
                        base64={base64}
                        setBase64={setBase64}
                    />
                </Grid>
                <Grid item lg={9} md={6} xs={12}>
                    <Grid container item lg={12} justify='space-evenly' alignContent={isDownXs ? 'stretch' : 'center'} direction='column'>
                        <VirtualizedSelect
                            language={language}
                            options={productTypes || []}
                            onChange={onChangeType}
                            getOptionSelected={(option: ProductTypeProps, value: string | ProductTypeProps) => typeof value === 'string' ? option.description.description === value : value === option}
                            getOptionLabel={(option: ProductTypeProps) => option.description.description}
                            renderOption={(option: ProductTypeProps) => <Typography noWrap>{option.description.description}</Typography>}
                            textFieldProps={{
                                fullWidth: true,
                                label: strings.columns.CATEGORY[language],
                                ...register('type.description.description', {
                                    required: true,
                                }),
                                error: Boolean(errors?.type?.description?.description),
                                helperText: getError(errors?.type?.description?.description, language, 10),
                            }}
                        />
                        <TextField
                            variant='outlined'
                            disabled={loading}
                            label={strings.columns.MANUFACTURER[language]}
                            {...register('manufacturer')}
                            onChange={handleChangeField}
                            className={classes.textField}
                            error={Boolean(errors?.manufacturer)}
                            helperText={getError(errors.manufacturer, language, 10)}
                        />
                        <TextField
                            variant='outlined'
                            disabled={loading}
                            label={strings.columns.MODEL[language]}
                            {...register('model', {
                                required: true,
                            })}
                            onChange={handleChangeField}
                            className={classes.textField}
                            error={Boolean(errors?.model)}
                            helperText={getError(errors.model, language, 10)}
                        />
                        <TextField
                            variant='outlined'
                            disabled={loading}
                            label={strings.columns.PRICE[language]}
                            {...register('price', {
                                required: true,
                                pattern: /^[1-9][0-9]*(.[0-9]{2})?/i,
                            })}
                            onChange={handleChangeField}
                            className={classes.textField}
                            error={Boolean(errors?.price)}
                            helperText={getError(errors.price, language, 10)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">RON</InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

type ImageHandlerProps = {
    language: LanguageType;
    base64: string;
    setBase64: (value: string) => void;
}

const ImageHandler: FC<ImageHandlerProps> = (props) => {
    const classes = styles();
    const theme = useTheme();
    const { language, base64, setBase64 } = props;
    const [file, setFile] = useState<string>();
    const [imagePreview, setImagePreview] = useState<any>("");
    const [size, setSize] = useState<string>();
    const mbSize = 1048576;

    const onChange = (e: any) => {
        let file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = _handleReaderLoaded
            reader.readAsBinaryString(file)
        }
    }

    const _handleReaderLoaded = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        setBase64('data:image/png;base64, ' + btoa(binaryString))
    }

    const photoUpload = (e: any) => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        if (reader !== undefined && file !== undefined) {
            reader.onloadend = () => {
                setFile(file)
                setSize(file.size);
                setImagePreview(reader.result)
            }
            reader.readAsDataURL(file);
        }
    }

    const remove = () => {
        setFile("")
        setImagePreview("")
        setBase64(defaultImage)
        setSize("")
    }

    return (
        <Box className={classes.imageRoot}>
            <Box className={classes.imageBox}>
                {base64 && <img className={classes.imageBox} src={base64} alt="Product" />}
            </Box>
            <ResponsiveTypography
                className={classes.imageField}
                fontWeight={300}
                growthRate={50}
                lgDown={15}
                mdDown={20}
            >
                {size && convertFileSize(parseInt(size))}
            </ResponsiveTypography>
            {size && parseInt(size) > 5 * mbSize &&
                <ResponsiveTypography
                    className={classes.imageField}
                    color={theme.palette.error.main}
                    growthRate={50}
                    lgDown={10}
                    mdDown={15}
                >
                    {strings.general.FILE_SIZE_ERROR[language]}
                </ResponsiveTypography>
            }
            <form onChange={onChange}>
                <input
                    id="productImage"
                    name="productImage"
                    type="file"
                    accept=".jpef, .png, .jpg"
                    onChange={photoUpload}
                    src={imagePreview}
                    style={{ display: 'none' }}
                />
                <Box className={classes.imageActions}>
                    <label htmlFor="productImage">
                        <Fab
                            component="span"
                            color="secondary"
                            size="small"
                        >
                            <ImageRounded fontSize="small" />
                        </Fab>
                    </label>
                    {file &&
                        <Fab
                            component="span"
                            color="primary"
                            size="small"
                            onClick={remove}
                        >
                            <ClearRounded fontSize="small" />
                        </Fab>
                    }
                </Box>
            </form>
        </Box>
    )
}