import React, { FC, useCallback } from "react"
import { makeStyles, MenuItem } from "@material-ui/core"
import clsx from "clsx";

type ComponentType = {
    children?: React.ReactNode;
    onClick: ((x?: any) => void) | undefined;
    className?: any;
    flex?: boolean;
    justifyContent?: 'space-around' | 'space-between' | 'space-evenly' | 'flex-end' | 'flex-start' | 'center';
    alignItems?: 'space-around' | 'space-between' | 'space-evenly' | 'flex-end' | 'flex-start' | 'center';
    width?: any;
    margin?: any;
    padding?: any;
    borderRadius?: number;
    selected?: boolean;
}

const customClass = (flex = false, justifyContent = 'flex-start', alignItems = 'flex-start', width = 'auto', margin = 0, padding = '6px 16px', borderRadius = 0) => {
    const customStyle = makeStyles(() => ({
        root: {
            display: flex ? 'flex' : 'initial',
            justifyContent,
            alignItems,
            width,
            margin,
            padding,
            borderRadius,
        },
    }))();
    return customStyle.root;
};

const CustomMenuItem: FC<ComponentType> = (props) => {

    const { children, onClick, className, flex, justifyContent, alignItems, width, margin, padding, borderRadius, selected } = props;

    const getCustomClass = useCallback(() => customClass(flex, justifyContent, alignItems, width, margin, padding, borderRadius), [flex, justifyContent, alignItems, width, margin, padding, borderRadius]);

    return (
        <MenuItem
            onClick={onClick}
            className={
                clsx({
                    [getCustomClass()]: true,
                    [className]: !!className
                })
            }
            selected={selected}
        >
            {children}
        </MenuItem>)
}

export default CustomMenuItem;