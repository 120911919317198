import { useMutation, useQuery } from "@apollo/client";
import { Button, Card, CardActions, CircularProgress, Divider } from "@material-ui/core";
import { Done, Save, Error, Lock } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ServiceForm } from "../../components/Services";
import { ServiceTypeFormValues } from "../../components/Services/types";
import clsx from 'clsx';

import { ResponsiveTypography } from "../../components/shared";
import MainWrapper from "../../components/shared/MainWrapper";
import { useRouteQuery } from "../../functions";
import requests from "../../requests";
import strings from "../../strings";
import styles from './style';
import { VehicleForm } from "../../components/Fleet";
import { VehicleFormValues } from "../../components/Fleet/types";
import moment from "moment";
import { permissionAccess } from "../../functions/permissionAccess";
import { useHistory } from "react-router-dom";

const Service: FC<PageBaseProps> = (props) => {
    const classes = styles();
    const { language, auth } = props;
    const history = useHistory();
    const [pageId, setPageId] = useState(0);
    const [buttonStatus, setButtonStatus] = useState<undefined | ButtonStatusType>();
    const { enqueueSnackbar } = useSnackbar();
    const query = useRouteQuery();

    const { data: vehicle, refetch: refetchVehicle } = useQuery(requests.vehicle.query.SEARCH_VEHICLE, {
        variables: {
            id: pageId
        },
        fetchPolicy:'network-only',
    });

    const ServiceTypeForm = useForm<VehicleFormValues>({
        defaultValues: {
            manufacturer: '',
            plate1: '',
            plate2: '',
            plate3: '',
        }
    });
    const { handleSubmit, setValue, watch } = ServiceTypeForm;


    const handleChangeField = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, checked?: boolean) => {
        const { name, value } = event.target;
        setValue(name as any, typeof checked === 'boolean' ? checked : value);
    }, [setValue])


    const onSubmit: SubmitHandler<VehicleFormValues> = (data, event) => {
        event?.preventDefault();

        update({
            variables: {
                id: pageId,
                data: {
                    ...data,
                    RCA: data.RCA ? moment(data.RCA).format().split('T')[0] : undefined,
                    ITP: data.ITP ? moment(data.ITP).format().split('T')[0] : undefined,
                    ROV: data.ROV ? moment(data.ROV).format().split('T')[0] : undefined,
                },
            }
        }).then(() => {
            enqueueSnackbar(
                strings.notifications.VEHICLE_UPDATE_SUCCESS[language],
                {
                    variant: "success",
                }
            );
            refetchVehicle();
            setButtonStatus('SUCCESS');
            setTimeout(() => {
                setButtonStatus(undefined);
            }, 2000);
        }).catch((error: any) => {
            setButtonStatus('ERROR');
            setTimeout(() => {
                setButtonStatus(undefined);
            }, 2000);
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }

    const [
        update,
        { loading: loadingUpdate }
    ] = useMutation(requests.vehicle.mutation.UPDATE_VEHICLE);

    useEffect(() => {
        document.title = `Corsem - ${strings.pages.FLEET.label[language]}`;
    }, [language]);

    useEffect(() => {
        if (vehicle?.searchVehicle) {
            setValue('manufacturer', vehicle.searchVehicle.manufacturer);
            setValue('model', vehicle.searchVehicle.model);
            setValue('plate1', vehicle.searchVehicle.plate1);
            setValue('plate2', vehicle.searchVehicle.plate2);
            setValue('plate3', vehicle.searchVehicle.plate3);
            setValue('RCA', vehicle.searchVehicle.RCA);
            setValue('ITP', vehicle.searchVehicle.ITP);
            setValue('ROV', vehicle.searchVehicle.ROV);
        }
    }, [vehicle, setValue]);

    useEffect(() => setPageId(Number(query.get("id"))), [query]);

    useEffect(() => {
        if (auth.me)
            if (!permissionAccess(auth.me.data.permissions, 31)) history.replace(strings.pages.FORBIDDEN.url)
    })

    const buttonClassname = clsx({
        [classes.buttonStd]: true,
        [classes.buttonSuccess]: buttonStatus === 'SUCCESS',
        [classes.buttonError]: buttonStatus === 'ERROR',
    });

    return (
        <MainWrapper>
            <ResponsiveTypography
                className={classes.title}
                growthRate={50}
                fontWeight={800}
                smDown={33}
                mdDown={45}
                lgDown={33}
            >
                {strings.pages.FLEET.label[language]}
            </ResponsiveTypography>

            <ResponsiveTypography
                growthRate={50}
                fontWeight={300}
                smDown={12}
                mdDown={20}
                lgDown={12}
            >
                {strings.pages.FLEET.sublabel[language]}
            </ResponsiveTypography>

            <Card elevation={5} className={classes.root_paper}>
                <VehicleForm
                    language={language}
                    loading={loadingUpdate}
                    handleChangeField={handleChangeField}
                    useForm={ServiceTypeForm}
                />
                <Divider className={classes.divider} />
                <CardActions className={classes.actions}>
                    <div className={classes.wrapper}>
                        <Button
                            color="secondary"
                            variant={buttonStatus === 'SUCCESS' ? "contained" : "outlined"}
                            disabled={loadingUpdate}
                            className={buttonClassname}
                            onClick={handleSubmit(onSubmit)}
                        >
                            {buttonStatus === undefined && <Save fontSize="small" className={classes.leftIcon} />}
                            {buttonStatus === 'ERROR' && <Error fontSize="small" className={classes.leftIcon} />}
                            {buttonStatus === 'SUCCESS' && <Done fontSize="small" className={classes.leftIcon} />}
                            {strings.actions.SAVE[language]}
                        </Button>
                        {loadingUpdate && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </CardActions>
            </Card>

        </MainWrapper>
    )
}
export default Service;