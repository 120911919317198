export const DISPLAY_SETTINGS = {
    ro: "Setări de afișare",
    en: "Display settings",
}
export const PERMISSION_SETTINGS = {
    ro: "Setarea permisiunilor",
    en: "Permission settings",
}
export const ACCOUNT_DATA_SETTINGS = {
    ro: "Setările datelor de logare",
    en: "Account settings",
}
export const ADMIN_ONLY = {
    ro: "Acces permis doar pentru Admin",
    en: "Admin only settings",
}
export const CHANGE_LANGUAGE = {
    title: {
        ro: "Schimbă limba",
        en: "Change language",
    },
    subtitle: {
        ro: "Actualizează limba în care este afișată aplicația",
        en: "Update the display language",
    },
}
export const CHANGE_AVATAR = {
    ro: "Modificare avatar",
    en: "Change avatar",
};
export const DARK_THEME = {
    title: {
        ro: "Temă întunecată",
        en: "Dark theme",
    },
    subtitle: {
        ro: "Actualizează tema aplicației",
        en: "Update the display theme",
    },
}
export const CHANGE_NAME = {
    ro: "Schimbă numele/prenumele",
    en: "Change name",
}
export const CHANGE_EMAIL = {
    title: {
        ro: "Schimbă adresa de email",
        en: "Change email address",
    },
    subtitle: {
        ro: "Actualizează-ți adresa e email asociată contului.",
        en: "Update the email address associated to this account.",
    },
}
export const CHANGE_PASSWORD = {
    title: {
        ro: "Schimbă parola",
        en: "Change password",
    },
    subtitle: {
        ro: "Actualizează-ți parola cât mai des pentru o mai bună securizare a contului.",
        en: "Update your password regularly to keep your account secure.",
    },
}
export const CLOSE_ACCOUNT = {
    title: {
        ro: "Închide contul",
        en: "Close account",
    },
    subtitle: {
        ro: "Această acțiune este ireversibilă!",
        en: "This action is irreversible!",
    },
}
export const REQUIRED_FIELD = {
    ro: "Acest câmp este obligatoriu",
    en: "This field is required",
};
export const CHARS_NOT_ALLOWED = {
    ro: "Caractere nepermise",
    en: "Characters not allowed",
};
export const PATTERN = {
    ro: "Format nerespectat",
    en: "Format required",
};
export const CONFIRM_FIELD = {
    ro: "Confirmați câmpul",
    en: "Confirm field",
};
export const MIN_CHARS = (number: number) => ({
    ro: `Minim ${number} caractere`,
    en: `Minimum ${number} characters`,
});
export const MAX_CHARS = (number: number) => ({
    ro: `Maxim ${number} caractere`,
    en: `Maximum ${number} characters`,
});
export const WRONG_PASSWORD = {
    ro: `Parolă greșită`,
    en: `Wrong password`,
};
export const CONFIRM_PASSWORD = {
    ro: `Confirmă parola`,
    en: `Confirm password`,
};
export const CURRENT_PASSWORD = {
    ro: "Parola curentă",
    en: "Current password",
};
export const NEW_PASSWORD = {
    ro: "Parola nouă",
    en: "New password",
};
export const NEW_EMAIL = {
    ro: "Email nou",
    en: "New email",
};
export const CONFIRM_EMAIL = {
    ro: "Confirmare email",
    en: "Confirm email",
};