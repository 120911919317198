import { gql } from "@apollo/client";

const CREATE_PROJECT = gql`
mutation onProjectCreate($data: ProjectCreateInput!) {
    createProject(data: $data) {
        id
    }
}
`;

const SEARCH_PROJECTS = gql`
    query onProjectsSearch($searchBy: String, $filters: ProjectWhereInput, $orderBy: ProjectOrderBy, $paginate: Pagination) {
        searchProjects(searchBy: $searchBy, filters: $filters, orderBy: $orderBy, paginate: $paginate) {
            count
            projects {
                id
                number
                ownerName
                serviceName
                status
                quote {
                    id
                    fullName
                    phone
                    address
                    business {
                        name
                    }
                }
                createdAt
            }
        }
    }
`;

const SEARCH_PROJECT_DETAIL = gql`
    query onSearchExecution($id: Int!) {
        searchProject(id: $id) {
            id
            serviceName
            number
            status
            ownerId
            ownerName
            actions {
                id
                author {
                    id
                    fullName
                }
                from
                to
                createdAt
            }
            quote {
                id
                customer {
                    id
                    firstName
                    lastName
                    fullName
                    cnp
                    email
                }
                customerPhone {
                    id
                    phone
                }
                business {
                    id
                    name
                    cif
                    nrc
                    account
                    bank
                }
            }
        }
    }
`;

const PROJECT_ACTION = gql`
    mutation onProjectAction($data: ProjectActionCreateInput!) {
        projectAction(data: $data) {
            id
        }
    }
`;

const PROJECT_ASSIGN = gql`
    mutation onProjectAssign($id: Int!, $ownerId: Int!) {
        assignProject(id: $id, ownerId: $ownerId) {
            id
        }
    }
`;

export const project = {
    query: {
        SEARCH_PROJECTS,
        SEARCH_PROJECT_DETAIL,
    },
    mutation: {
        CREATE_PROJECT,
        PROJECT_ACTION,
        PROJECT_ASSIGN,
    },
    subscription: {
    }
}