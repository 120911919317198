import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    title: {
        margin: theme.spacing(0, 0, 2, 0),
    },
    root: {
        padding: theme.spacing(0, 20),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0, 10),
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0),
        },
    },
    profileRoot: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    imageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: theme.spacing(4),
        },
    },
    profileImage: {
        height: 150,
        width: 150,
        margin: theme.spacing(0, 6, 2, 6),
        [theme.breakpoints.down('md')]: {
            height: 100,
            width: 100,
        },
    },
    formPaper: {
        borderRadius: 30,
        backgroundColor: theme.palette.type === 'light' ? theme.palette.background.paper : theme.palette.background.default,
        padding: theme.spacing(4),
        width: '50%',
        height: 250,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            width: '70%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));