import { ChangeEvent, FC, useCallback, useState } from 'react'
import {
    Box,
    Button,
    Divider,
    Fab,
    Grid,
    IconButton,
    Theme,
    Typography,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    useMediaQuery,
} from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'

import { CustomerDetailsProps } from '../types'
import styles from './style'
import CustomModal from '../../shared/CustomModal'
import strings from '../../../strings'
import {
    CustomerForm,
    AddressForm,
    BusinessForm,
    ProxyForm,
    SearchCustomers,
    CustomerPreview,
    AddressesPreview,
    PhoneForm,
} from '../../Customer'
import {
    CustomerFormProps,
    CustomerFormValues,
    CustomerProps,
    RegionProps,
} from '../../Customer/types'
import { useLazyQuery, useMutation } from '@apollo/client'
import requests from '../../../requests'
import { AutocompleteChangeReason } from '@material-ui/lab'
import { CustomWrapper } from '../../shared'
import { useEffect } from 'react'
import { Add, OpenInNewRounded } from '@material-ui/icons'
import { QuoteFormValues } from '../../../Views/NewQuote/types'
import { useHistory } from 'react-router'

const CustomerDetails: FC<CustomerDetailsProps> = (props) => {
    const classes = styles()
    const { enqueueSnackbar } = useSnackbar()
    const [hasProxy, setHasProxy] = useState<boolean>(false)
    const [hasBusiness, setHasBusiness] = useState<boolean>(false)
    const [hasAddress, setHasAddress] = useState<boolean>(false)
    const [addCustomerModal, setAddCustomerModal] = useState<boolean>(false)
    const [addAddressModal, setAddAddressModal] = useState<boolean>(false)
    const [addBusinessModal, setAddBusinessModal] = useState<boolean>(false)
    const [addProxyModal, setAddProxyModal] = useState<boolean>(false)
    const [addPhoneModal, setAddPhoneModal] = useState<boolean>(false)
    const [shouldRefetch, setShouldRefetch] = useState(false)
    const { language, formData, setFormData, formErrors } = props
    const [gdprAccepted, setGdprAccepted] = useState<boolean>(false)
    const history = useHistory()

    const AddCustomerForm = useForm<CustomerFormValues>()
    const {
        setValue,
        handleSubmit,
        reset,
        clearErrors,
        formState: { errors },
    } = AddCustomerForm

    const onSubmit: SubmitHandler<CustomerFormValues> = (data, event) => {
        event?.preventDefault()
        createCustomer({
            variables: {
                data,
            },
        })
            .then((response) => {
                setFormData({ customerId: response.data.createCustomer.id })
                enqueueSnackbar(
                    strings.notifications.CUSTOMER_CREATE_SUCCESS[language],
                    {
                        variant: 'success',
                    }
                )
                setAddCustomerModal(false)
                reset()
                clearErrors()
            })
            .catch((error) => {
                enqueueSnackbar(
                    error.message in strings.notifications
                        ? strings.notifications[
                              error.message as GlobalNotification
                          ][language]
                        : error.message,
                    {
                        variant: 'error',
                    }
                )
            })
    }

    const onSubmitUpdate: SubmitHandler<CustomerFormValues> = (data, event) => {
        event?.preventDefault()
        updateCustomer({
            variables: {
                data: {
                    ...data,
                    id: formData?.customerId,
                },
            },
        })
            .then((response) => {
                enqueueSnackbar(
                    strings.notifications.CUSTOMER_UPDATE_SUCCESS[language],
                    {
                        variant: 'success',
                    }
                )
                setAddAddressModal(false)
                setAddBusinessModal(false)
                setAddProxyModal(false)
                setAddPhoneModal(false)
                refetch && refetch()
                reset()
                clearErrors()
            })
            .catch((error) => {
                enqueueSnackbar(
                    error.message in strings.notifications
                        ? strings.notifications[
                              error.message as GlobalNotification
                          ][language]
                        : error.message,
                    {
                        variant: 'error',
                    }
                )
            })
    }

    const [createCustomer, { loading: loadingCreateCustomer }] = useMutation(
        requests.customer.mutation.CREATE_CUSTOMER
    )

    const [updateCustomer, { loading: loadingUpdateCustomer }] = useMutation(
        requests.customer.mutation.UPDATE_CUSTOMER
    )

    const onHandleChangeRegion = (
        event: React.ChangeEvent<{}>,
        value: RegionProps,
        reason: AutocompleteChangeReason
    ) => {
        if (reason === 'clear') setValue('address.regionId', 0)
        else if (reason === 'select-option')
            setValue('address.regionId', value.id)
        else return
    }
    const onHandleChangeCity = (
        event: React.ChangeEvent<{}>,
        value: RegionProps,
        reason: AutocompleteChangeReason
    ) => {
        if (reason === 'clear') setValue('address.cityId', 0)
        else if (reason === 'select-option')
            setValue('address.cityId', value.id)
        else return
    }
    const onHandleSelectCustomer = (
        event: React.ChangeEvent<{}>,
        value: CustomerProps,
        reason: AutocompleteChangeReason
    ) => {
        if (reason === 'select-option') {
            onSearchCustomer({
                variables: { id: value.id },
            })
            setFormData({ customerId: value.id })
        } else return
    }

    const handleChangeCustomerDataField = useCallback(
        (
            event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            checked?: boolean
        ) => {
            const { name, value } = event.target
            setFormData(
                (prevData) =>
                    ({ ...prevData, [name]: value } as QuoteFormValues)
            )
        },
        [setFormData]
    )

    const toggleAddCustomerModal = () => {
        setAddCustomerModal((prev) => !prev)
        clearErrors()
        reset()
    }
    const toggleAddAddressModal = () => {
        setAddAddressModal((prev) => !prev)
        clearErrors()
        reset()
    }
    const toggleAddBusinessModal = () => {
        setAddBusinessModal((prev) => !prev)
        clearErrors()
        reset()
    }
    const toggleAddProxyModal = () => {
        setAddProxyModal((prev) => !prev)
        clearErrors()
        reset()
    }
    const toggleAddPhoneModal = () => {
        setAddPhoneModal((prev) => !prev)
        clearErrors()
        reset()
    }
    const toggleProxy = () => {
        setHasProxy((prev) => !prev)
        clearErrors()
        reset()
    }
    const toggleBusiness = () => {
        setHasBusiness((prev) => !prev)
        clearErrors()
        reset()
    }
    const toggleAddress = () => {
        setHasAddress((prev) => !prev)
        clearErrors()
        reset()
    }

    const handleChangeAddCustomerField = useCallback(
        (
            event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            checked?: boolean
        ) => {
            const { name, value } = event.target
            setValue(
                name as any,
                typeof checked === 'boolean' ? checked : value
            )
        },
        [setValue]
    )

    const [onSearchCustomer, { data, refetch }] = useLazyQuery(
        requests.customer.query.SEARCH_CUSTOMER,
        {
            fetchPolicy: 'network-only',
        }
    )

    useEffect(() => {
        if (data === undefined && formData?.customerId)
            onSearchCustomer({
                variables: { id: formData.customerId },
            })
    }, [data, onSearchCustomer, formData])

    const isDownSm = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    )

    return (
        <Box className={classes.root}>
            <Box style={{ display: 'flex' }}>
                {isDownSm ? (
                    <Fab
                        color='secondary'
                        className={classes.addButton}
                        onClick={toggleAddCustomerModal}
                    >
                        <Add />
                    </Fab>
                ) : (
                    <Button
                        variant='contained'
                        color='secondary'
                        className={classes.addButton}
                        onClick={toggleAddCustomerModal}
                    >
                        <Add className={classes.buttonIcon} />
                        {strings.actions.ADD_NEW_CUSTOMER.title[language]}
                    </Button>
                )}
                <SearchCustomers
                    language={language}
                    onHandleSelectCustomer={onHandleSelectCustomer}
                />
            </Box>
            {formData?.customerId && (
                <Grid
                    container
                    spacing={2}
                    alignContent='stretch'
                    style={{ marginTop: '8px' }}
                >
                    <Grid item xs={12} md={4} lg={4}>
                        <CustomWrapper
                            title={strings.general.CUSTOMER_DETAILS[language]}
                            link={
                                <IconButton
                                    size='small'
                                    color='secondary'
                                    onClick={() =>
                                        formData &&
                                        history.push(
                                            strings.pages.CUSTOMER(
                                                formData.customerId
                                            ).url
                                        )
                                    }
                                >
                                    <OpenInNewRounded fontSize='small' />
                                </IconButton>
                            }
                        >
                            <CustomerPreview
                                data={data?.searchCustomer}
                                formData={formData}
                                formErrors={formErrors}
                                handleChangeField={
                                    handleChangeCustomerDataField
                                }
                                language={language}
                                toggleAddPhoneModal={toggleAddPhoneModal}
                            />
                        </CustomWrapper>
                    </Grid>

                    <Grid item xs={12} md={8} lg={8}>
                        <CustomWrapper
                            title={strings.general.BILLING_DETAILS[language]}
                        >
                            <AddressesPreview
                                data={data?.searchCustomer}
                                formData={formData}
                                formErrors={formErrors}
                                handleChangeField={
                                    handleChangeCustomerDataField
                                }
                                language={language}
                                toggleAddAddressModal={toggleAddAddressModal}
                                toggleAddBusinessModal={toggleAddBusinessModal}
                                toggleAddProxyModal={toggleAddProxyModal}
                            />
                        </CustomWrapper>
                    </Grid>
                </Grid>
            )}
            <CustomModal
                fullWidth
                language={language}
                open={addCustomerModal}
                title={strings.actions.ADD_NEW_CUSTOMER.title[language]}
                disableExecute={loadingCreateCustomer}
                content={
                    addCustomerModal && (
                        <CustomerCompleteForm
                            language={language}
                            useForm={AddCustomerForm}
                            onHandleChangeRegion={onHandleChangeRegion}
                            onHandleChangeCity={onHandleChangeCity}
                            onHandleSelectCustomer={onHandleSelectCustomer}
                            handleChangeField={handleChangeAddCustomerField}
                            toggleProxy={toggleProxy}
                            toggleBusiness={toggleBusiness}
                            toggleAddress={toggleAddress}
                            hasProxy={hasProxy}
                            hasBusiness={hasBusiness}
                            hasAddress={hasAddress}
                        />
                    )
                }
                onClose={toggleAddCustomerModal}
                onExecute={handleSubmit(onSubmit)}
            />
            <CustomModal
                fullWidth
                language={language}
                open={addAddressModal}
                title={strings.actions.ADD_ADDRESS[language]}
                disableExecute={loadingUpdateCustomer}
                content={
                    addAddressModal && (
                        <AddressForm
                            language={language}
                            useForm={AddCustomerForm}
                            onHandleChangeRegion={onHandleChangeRegion}
                            onHandleChangeCity={onHandleChangeCity}
                            onHandleSelectCustomer={onHandleSelectCustomer}
                            handleChangeField={handleChangeAddCustomerField}
                            toggleProxy={toggleProxy}
                            toggleBusiness={toggleBusiness}
                            toggleAddress={toggleAddress}
                            hasProxy={hasProxy}
                            hasBusiness={hasBusiness}
                            hasAddress={hasAddress}
                        />
                    )
                }
                onClose={toggleAddAddressModal}
                onExecute={handleSubmit(onSubmitUpdate)}
            />
            <CustomModal
                fullWidth
                language={language}
                open={addBusinessModal}
                title={strings.actions.ADD_BUSINESS[language]}
                disableExecute={loadingUpdateCustomer}
                content={
                    addBusinessModal && (
                        <BusinessForm
                            language={language}
                            useForm={AddCustomerForm}
                            onHandleChangeRegion={onHandleChangeRegion}
                            onHandleChangeCity={onHandleChangeCity}
                            onHandleSelectCustomer={onHandleSelectCustomer}
                            handleChangeField={handleChangeAddCustomerField}
                            toggleProxy={toggleProxy}
                            toggleBusiness={toggleBusiness}
                            toggleAddress={toggleAddress}
                            hasProxy={hasProxy}
                            hasBusiness={hasBusiness}
                            hasAddress={hasAddress}
                        />
                    )
                }
                onClose={toggleAddBusinessModal}
                onExecute={handleSubmit(onSubmitUpdate)}
            />
            <CustomModal
                fullWidth
                language={language}
                open={addProxyModal}
                title={strings.actions.ADD_PROXY[language]}
                disableExecute={loadingUpdateCustomer}
                content={
                    addProxyModal && (
                        <ProxyForm
                            language={language}
                            useForm={AddCustomerForm}
                            onHandleChangeRegion={onHandleChangeRegion}
                            onHandleChangeCity={onHandleChangeCity}
                            onHandleSelectCustomer={onHandleSelectCustomer}
                            handleChangeField={handleChangeAddCustomerField}
                            toggleProxy={toggleProxy}
                            toggleBusiness={toggleBusiness}
                            toggleAddress={toggleAddress}
                            hasProxy={hasProxy}
                            hasBusiness={hasBusiness}
                            hasAddress={hasAddress}
                        />
                    )
                }
                onClose={toggleAddProxyModal}
                onExecute={handleSubmit(onSubmitUpdate)}
            />
            <CustomModal
                fullWidth
                language={language}
                open={addPhoneModal}
                title={strings.actions.ADD_PHONE[language]}
                disableExecute={loadingUpdateCustomer}
                content={
                    addPhoneModal && (
                        <PhoneForm
                            language={language}
                            useForm={AddCustomerForm}
                            onHandleChangeRegion={onHandleChangeRegion}
                            onHandleChangeCity={onHandleChangeCity}
                            onHandleSelectCustomer={onHandleSelectCustomer}
                            handleChangeField={handleChangeAddCustomerField}
                            toggleProxy={toggleProxy}
                            toggleBusiness={toggleBusiness}
                            toggleAddress={toggleAddress}
                            hasProxy={hasProxy}
                            hasBusiness={hasBusiness}
                            hasAddress={hasAddress}
                        />
                    )
                }
                onClose={toggleAddPhoneModal}
                onExecute={handleSubmit(onSubmitUpdate)}
            />
        </Box>
    )
}
export default CustomerDetails

const CustomerCompleteForm: FC<CustomerFormProps> = (props) => {
    const classes = styles()
    const {
        toggleProxy,
        toggleBusiness,
        toggleAddress,
        hasProxy,
        hasBusiness,
        hasAddress,
    } = props
    return (
        <Grid container spacing={2}>
            <CustomerForm {...props} />
            <Grid item xs={12}>
                <Typography
                    variant='caption'
                    color='secondary'
                    className={classes.textLink}
                    onClick={toggleAddress}
                    align='center'
                    gutterBottom
                >
                    {hasAddress ? 'Elimină adresă' : 'Adaugă adresă'}
                </Typography>
            </Grid>
            {hasAddress ? (
                <AddressForm {...props} />
            ) : (
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            )}
            <Grid item xs={12}>
                <Typography
                    variant='caption'
                    color='secondary'
                    className={classes.textLink}
                    onClick={toggleBusiness}
                    align='center'
                    gutterBottom
                >
                    {hasBusiness ? 'Elimină companie' : 'Adaugă companie'}
                </Typography>
            </Grid>
            {hasBusiness ? (
                <BusinessForm {...props} />
            ) : (
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            )}
            <Grid item xs={12}>
                <Typography
                    variant='caption'
                    color='secondary'
                    className={classes.textLink}
                    onClick={toggleProxy}
                    align='center'
                    gutterBottom
                >
                    {hasProxy
                        ? 'Elimină personă împuternicită'
                        : 'Adaugă personă împuternicită'}
                </Typography>
            </Grid>
            {hasProxy && <ProxyForm {...props} />}
        </Grid>
    )
}