import { useLazyQuery } from "@apollo/client";
import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { VehicleAddSection, VehicleEnhancedTable } from "../../components/Fleet";

import { ResponsiveTypography } from "../../components/shared";
import MainWrapper from "../../components/shared/MainWrapper";
import { permissionAccess } from "../../functions/permissionAccess";
import requests from "../../requests";
import strings from "../../strings";
import styles from './style';

const Fleet: FC<PageBaseProps> = (props) => {
    const { language, auth } = props;
    const classes = styles();
    const history = useHistory();
    const [addExpanded, setAddExpanded] = useState<boolean>(false)

    const toggleAddExpand = () => setAddExpanded(prev => !prev);

    const [onSearch, { data, loading: loadingSearch, refetch }] = useLazyQuery(requests.vehicle.query.SEARCH_VEHICLES, {
        fetchPolicy: "network-only",
    });

    const handleSearch = useCallback((rows: number, page: number, orderBy: VehicleOrderBy) => {
        onSearch({
            variables: {
                orderBy,
                paginate: {
                    take: rows,
                    skip: Math.max(page, 0) * rows,
                }
            }
        })
    }, [onSearch]);

    useEffect(() => {
        document.title = `Corsem - ${strings.pages.FLEET.label[language]}`;
    }, [language]);

    useEffect(() => {
        if (auth.me)
            if (!permissionAccess(auth.me.data.permissions, 31)) history.replace(strings.pages.FORBIDDEN.url)
    })

    return (
        <MainWrapper>
            <ResponsiveTypography
                className={classes.title}
                growthRate={50}
                fontWeight={800}
                smDown={33}
                mdDown={45}
                lgDown={33}
            >
                {strings.pages.FLEET.label[language]}
            </ResponsiveTypography>
            <ResponsiveTypography
                growthRate={50}
                fontWeight={300}
                smDown={12}
                mdDown={20}
                lgDown={12}
            >
                {strings.pages.FLEET.sublabel[language]}
            </ResponsiveTypography>

            <VehicleAddSection
                language={language}
                expanded={addExpanded}
                toggleAction={toggleAddExpand}
                refetch={refetch}
            />

            <VehicleEnhancedTable
                rows={data?.searchVehicles?.vehicles || []}
                count={data?.searchVehicles?.count || 0}
                loading={loadingSearch}
                language={language}
                handleSearch={handleSearch}
                title={strings.pages.FLEET.label[language]}
            />
        </MainWrapper>
    )
}
export default Fleet;