import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    root: {
        width: 'max-content',
        padding: theme.spacing(1, 2, 1, 1),
        borderTopRightRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
        borderLeft: `4px solid ${theme.palette.error.main}`,
        backgroundColor: theme.palette.type === 'light' ?
            theme.palette.grey[100] : theme.palette.grey[700]
    },
    openStatus: {
        borderLeft: `4px solid ${theme.palette.success.main}`
    },
    startedStatus: {
        borderLeft: `4px solid ${theme.palette.secondary.main}`
    },
    closedStatus: {
        borderLeft: `4px solid ${theme.palette.primary.light}`
    },
    cancelledStatus: {
        borderLeft: `4px solid ${theme.palette.common.black}`
    },
}));