import { ChangeEvent, useEffect, useState, useCallback, FC } from "react";
import { Paper, Box, Grid, Button, TextField, Divider, CircularProgress, InputAdornment, IconButton, useTheme, useMediaQuery, Slide } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import requests from "../../requests";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import styles from './style';
import strings from "../../strings";
import { useHistory } from "react-router-dom";
import { ReactComponent as RaisisCRMLogo } from '../../resources/svg/logo.svg';
import { ReactComponent as RaisisCRMLogoDark } from '../../resources/svg/logo-white.svg';


const SignIn: FC<PageBaseProps> = ({ language, auth, toggleTheme }) => {
    const history = useHistory();
    const classes = styles();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [credentials, setCredentials] = useState({
        email: "",
        password: "",
    });

    const togglePasswordVisibility = useCallback(
        () => {
            setPasswordVisibility(!passwordVisibility)
        }, [passwordVisibility]
    )

    const updateField = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { name, value } = event.target as HTMLInputElement;
        setCredentials({ ...credentials, [name]: value });
        if (name === 'password' && value === '') setPasswordVisibility(false);
    }

    const [
        signIn,
        { loading: signInLoading },
    ] = useMutation(requests.user.mutation.SIGN_IN_QUERY);

    useEffect(() => {
        document.title = `Corsem - ${strings.pages.SIGN_IN.label[language]}`;
    }, [language]);

    useEffect(() => {
        if (auth.isAuthenticated) auth.login();
    }, [auth]);

    return (
        <Grid container component="main" direction={isMdDown ? 'column' : 'row'} justify='space-evenly' alignItems='center' alignContent='center' className={classes.root}>
            <Grid item lg={3}>
                <Slide direction="right" in={true} mountOnEnter unmountOnExit>
                    {theme.palette.type === 'light'
                        ? <RaisisCRMLogo className={classes.logo} />
                        : <RaisisCRMLogoDark className={classes.logo} />
                    }
                </Slide>
            </Grid>
            <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                <Paper className={classes.paper}>
                    <form
                        className={classes.form}
                        onSubmit={(event) => {
                            event.preventDefault();
                            signIn({
                                variables: { ...credentials, rememberMe: true },
                            })
                                .then((response) => {
                                    localStorage.setItem(
                                        "accessTokenKey",
                                        response.data.signInUser.token
                                    );
                                    auth.login();
                                    enqueueSnackbar(
                                        strings.notifications.SIGN_IN_SUCCESS[language],
                                        {
                                            variant: "success",
                                        }
                                    );
                                })
                                .catch((error) => {
                                    enqueueSnackbar(
                                        error.message in strings.notifications
                                            ? strings.notifications[error.message as GlobalNotification][language]
                                            : error.message,
                                        {
                                            variant: "error",
                                        }
                                    );
                                });
                        }}
                    >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={strings.general.EMAIL[language]}
                            name="email"
                            autoComplete="email"
                            onChange={updateField}
                            classes={{
                                root: classes.textField
                            }}
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={strings.general.PASSWORD[language]}
                            type={passwordVisibility ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{ opacity: credentials.password === "" ? 0 : 1 }}>
                                        <IconButton size='small' onClick={togglePasswordVisibility}>
                                            {passwordVisibility ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={updateField}
                        />
                        <Box className={classes.buttonWrapper}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                size='large'
                                disabled={signInLoading}
                                className={classes.submit}
                            >
                                {strings.actions.SIGN_IN[language]}
                            </Button>
                            {signInLoading && <CircularProgress className={classes.progress} />}
                        </Box>
                        <Divider className={classes.divider} />
                        {/* <Grid container justify="center">
                            <Button
                                variant="contained"
                                color="primary"
                                size='large'
                                onClick={() => history.push(strings.pages.SIGN_UP.url)}
                                disabled={signInLoading}
                                className={classes.submit}
                            >
                                {strings.actions.SIGN_UP[language]}
                            </Button>
                        </Grid> */}
                    </form>
                </Paper>
            </Slide>
        </Grid>
    );
};

export default SignIn;
