import React, { FC, useCallback, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import { FilterListRounded, AssignmentReturnedRounded } from '@material-ui/icons';

import { TableActions } from '../';
import styles from './style';
import { CustomStatusChip, ResponsiveTypography } from '../../shared'
import { ArrowDropDownRounded, MoreHorizRounded } from '@material-ui/icons';
import strings from '../../../strings';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import { ExecutionFilters } from '../types';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import requests from '../../../requests';
// import { numberWithCommas } from '../../../functions/numberFunctions';

interface Data {
    id: number;
    serviceName: string;
    fullName: string;
    phone: string;
    address: string;
    status: string;
    scheduledDate: Date;
    createdAt: Date;
}
interface TableProps extends CustomTableProps {
    rows: ExecutionProps[];
    filters: ExecutionFilters;
    handleSearch: (rows: number, page: number, orderBy: ExecutionOrderBy, filters: ExecutionFilters) => void;
    openFilters?: () => void;
    indexes: number[];
}

type Order = 'asc' | 'desc';

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    sort?: boolean;
}

const headCells = (language: LanguageType) => ([
    { id: 'id', sort: true, numeric: false, disablePadding: true, label: strings.columns.ID[language] },
    { id: 'serviceName', sort: true, numeric: false, disablePadding: false, label: strings.columns.SERVICE[language] },
    { id: 'status', sort: true, numeric: false, disablePadding: false, label: strings.columns.STATUS[language] },
    { id: 'fullName', sort: true, numeric: false, disablePadding: false, label: strings.columns.FULL_NAME[language] },
    { id: 'phone', sort: true, numeric: false, disablePadding: false, label: strings.columns.PHONE[language] },
    { id: 'address', sort: true, numeric: false, disablePadding: false, label: strings.columns.ADDRESS[language] },
    { id: 'scheduledDate', sort: true, numeric: false, disablePadding: false, label: strings.columns.SCHEDULED_AT[language] },
    { id: 'createdAt', sort: true, numeric: true, disablePadding: true, label: strings.columns.CREATED_AT[language] },
] as HeadCell[]);

interface EnhancedTableProps {
    classes: ReturnType<typeof styles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    language: LanguageType
    count: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, language, onSelectAllClick, order, orderBy, numSelected, onRequestSort, count } = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < count}
                        checked={numSelected > 0 && numSelected === count}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                        size='small'
                    />
                </TableCell>
                {headCells(language).map((headCell) => (
                    headCell.sort ?
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                IconComponent={ArrowDropDownRounded}
                            >
                                <ResponsiveTypography fontWeight={700}
                                    smDown={15}
                                    mdDown={20}
                                    lgDown={15}>
                                    {headCell.label}
                                </ResponsiveTypography>
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                        :
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <ResponsiveTypography fontWeight={700}
                                smDown={15}
                                mdDown={20}
                                lgDown={15}>
                                {headCell.label}
                            </ResponsiveTypography>
                        </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
    language: LanguageType;
    title: String;
    openFilters?: () => void;
    generateTickets: () => void;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = styles();
    const {
        numSelected,
        title,
        openFilters,
        generateTickets,
        language,
    } = props;

    return (
        <Toolbar
            className={classes.toolbar}
        >
            <ResponsiveTypography
                className={classes.title}
                growthRate={50}
                fontWeight={800}
                smDown={24}
                mdDown={33}
                lgDown={24}
            >
                {title}
            </ResponsiveTypography>
            {numSelected > 0 ? (
                <Tooltip title={strings.actions.GENERATE_TICKETS[language]}>
                    <IconButton aria-label="delete" onClick={generateTickets}>
                        <AssignmentReturnedRounded />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title={strings.actions.FILTER[language]}>
                    <IconButton aria-label="filter list" onClick={openFilters}>
                        <FilterListRounded />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};

const EnhancedTable: FC<TableProps> = (props) => {
    const classes = styles();
    const { enqueueSnackbar } = useSnackbar();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>('id');
    const [selected, setSelected] = useState<number[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [isRendered,setIsRendered] = useState(false);
    const { rows, count, language, handleSearch, filters, loading, title, openFilters, indexes } = props;

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, id: number) => {
        setAnchorEl(event.currentTarget);
        setSelectedId(id);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            console.log(indexes)
            setSelected(indexes);
            return;
        }
        setSelected([]);
    };

    const [onGenerateTickets] = useMutation(requests.execution.mutation.GENERATE_TICKETS);

    const handleGenerateTickets = useCallback((selected: number[], fromDate: string, toDate: string) => {
        onGenerateTickets({
            variables: {
                executionIds: selected,
                scheduledDate_gte: fromDate,
                scheduledDate_lte: toDate,
            }
        }).then((response) => {
            console.log(response.data.generateExecutionSpreadSheet)
            const link = document.createElement('a');
            link.href = `http://localhost:5000/static/docs/${response.data.generateExecutionSpreadSheet}`;
            document.body.appendChild(link);
            link.click();
            setSelected([]);
            enqueueSnackbar(
                strings.notifications.TICKETS_GENERATED_SUCCESS[language],
                {
                    variant: "success",
                }
            );
        }).catch((error) => {
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }, [onGenerateTickets])

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, page: number) => {
        setPage(page);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setRowsPerPage(parseInt(value));
    };


    const isSelected = (id: number) => selected.indexOf(id) !== -1;
    
    useEffect(() => {
        setIsRendered(!isRendered);
    },[])

    useEffect(() => {
        if(isRendered){
            handleSearch(rowsPerPage, page, `${orderBy}_${order}` as ExecutionOrderBy, filters);
            setIsRendered(true);
        }
        
    }, [handleSearch, rowsPerPage, page, order, orderBy, filters,isRendered]);

    return (
        <div className={classes.root}>
            <TableActions
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                open={anchorEl !== null}
                selectedId={selectedId}
                language={language}
            />
            <Paper elevation={10} className={classes.paper}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    language={language}
                    title={title}
                    openFilters={openFilters}
                    generateTickets={() => handleGenerateTickets(selected, filters.scheduledDate_gte, filters.scheduledDate_lte)}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            language={language}
                            count={count}
                        />
                        <TableBody>
                            {rows
                                .map((row, index: number) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                            hover
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    size='small'
                                                    onClick={(event) => handleClick(event, row.id)}
                                                />
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                <ResponsiveTypography
                                                    growthRate={50}
                                                    fontWeight={500}
                                                    smDown={10}
                                                    mdDown={12}
                                                    lgDown={10}
                                                >
                                                    {row.id}
                                                </ResponsiveTypography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <ResponsiveTypography
                                                    growthRate={50}
                                                    fontWeight={500}
                                                    smDown={10}
                                                    mdDown={12}
                                                    lgDown={10}
                                                >
                                                    {row.serviceName}
                                                </ResponsiveTypography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <CustomStatusChip value={row.status}>
                                                    {row.status}
                                                </CustomStatusChip>
                                            </TableCell>
                                            <TableCell align="left">
                                                <ResponsiveTypography
                                                    growthRate={50}
                                                    fontWeight={500}
                                                    smDown={10}
                                                    mdDown={12}
                                                    lgDown={10}
                                                >
                                                    {row.quote.fullName}
                                                </ResponsiveTypography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <ResponsiveTypography
                                                    growthRate={50}
                                                    fontWeight={500}
                                                    smDown={10}
                                                    mdDown={12}
                                                    lgDown={10}
                                                >
                                                    {row.quote.phone}
                                                </ResponsiveTypography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <ResponsiveTypography
                                                    growthRate={50}
                                                    fontWeight={500}
                                                    smDown={10}
                                                    mdDown={12}
                                                    lgDown={10}
                                                >
                                                    {row.quote.address}
                                                </ResponsiveTypography>
                                            </TableCell>
                                            <Tooltip enterDelay={1000} placement='top-start' title={moment(row.scheduledDate).format("dddd, MMMM Do YYYY")}>
                                                <TableCell align="left">
                                                    <ResponsiveTypography
                                                        growthRate={50}
                                                        fontWeight={500}
                                                        smDown={10}
                                                        mdDown={12}
                                                        lgDown={10}
                                                    >
                                                        {moment(row.scheduledDate).format("D MMM. YYYY")}
                                                    </ResponsiveTypography>
                                                </TableCell>
                                            </Tooltip>
                                            <Tooltip enterDelay={1000} placement='top-start' title={moment(row.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss A")}>
                                                <TableCell align="right">
                                                    <ResponsiveTypography
                                                        growthRate={50}
                                                        fontWeight={500}
                                                        smDown={10}
                                                        mdDown={12}
                                                        lgDown={10}
                                                    >
                                                        {moment(row.createdAt).format("D MMM. YYYY")}
                                                    </ResponsiveTypography>
                                                </TableCell>
                                            </Tooltip>
                                            <TableCell align="right">
                                                <IconButton onClick={e => handlePopoverOpen(e, row.id)} size='small'>
                                                    <MoreHorizRounded fontSize='small' />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {rowsPerPage - rows.length > 0 && (
                                <TableRow
                                    style={{
                                        height:
                                            66 *
                                            (rowsPerPage -
                                                rows.length),
                                    }}
                                >
                                    <TableCell colSpan={8} />
                                </TableRow>
                            )}
                            {loading && getTableSkeleton(8, 5)}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage={strings.general.ROWS_PER_PAGE[language]}
                    labelDisplayedRows={({ from, to, count }) => strings.general.DISPLAYED_ROWS(from, to, count)[language]}
                    nextIconButtonText={strings.general.NEXT_PAGE[language]}
                    backIconButtonText={strings.general.PREV_PAGE[language]}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

export default EnhancedTable;

const getTableSkeleton = (columns: any, rows: any) => {
    let rowsContent = [];
    for (let i = 0; i < rows; i++) {
        rowsContent.push(
            <TableRowSkeleton key={i} columns={columns} />
        );
    }
    return rowsContent;
};

const TableRowSkeleton: FC<{ columns: number }> = ({ columns }) => {
    return (
        <TableRow style={{ height: 66 }}>
            <TableCell colSpan={columns} style={{ width: '100%' }}>
                <Skeleton style={{ width: '100%' }} />
            </TableCell>
        </TableRow>
    )
};