import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    title: {
        margin: theme.spacing(0, 0, 2, 0),
    },
    root_paper: {
        overflow: 'hidden',
        marginTop: theme.spacing(4),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.background.paper
    },
    actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            justifyContent: "flex-end",
        }
    },
    actionButton: {
        marginLeft: theme.spacing(2),
    },
    deleteButton: {
        backgroundColor: theme.palette.error.main,
        marginLeft: 'auto',
        "&:hover": {
            backgroundColor: theme.palette.error.dark,
        }
    },
    buttonIcon: {
        marginRight: theme.spacing(1),
    },
    buttonRightIcon: {
        marginLeft: theme.spacing(1),
    },
    acceptButton: {
        backgroundColor: theme.palette.success.main,
        "&:hover": {
            backgroundColor: theme.palette.success.dark,
        },
    },
    refuseButton: {
        backgroundColor: theme.palette.error.main,
        "&:hover": {
            backgroundColor: theme.palette.error.dark,
        }
    },
}));