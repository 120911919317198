import { FC, useCallback, useState } from "react";

import { CustomerProps, SearchCustomersProps } from "../types";
import styles from './style';
import strings from "../../../strings";
import { VirtualizedSelect } from "../../shared";
import { useLazyQuery } from "@apollo/client";
import requests from "../../../requests";
import { useEffect } from "react";
import { Typography } from "@material-ui/core";


const SearchCustomers: FC<SearchCustomersProps> = (props) => {
    const classes = styles();
    const { language, onHandleSelectCustomer } = props;
    const [isRendered, setIsRendered]=useState(false);


    const [onSearchCustomers, { data, loading: loadingSearchCustomers }] = useLazyQuery(requests.customer.query.SEARCH_CUSTOMERS, {
        fetchPolicy: "network-only",
    });


    const handleSearcCustomers = useCallback((rows: number, page: number, orderBy: CustomerOrderBy) => {
        onSearchCustomers({
            variables: {
                orderBy,
            }
        })
    }, [onSearchCustomers]);

    useEffect(() => {
        if(isRendered){
            handleSearcCustomers(10, 0, 'fullName_asc' as CustomerOrderBy);
            setIsRendered(true);
        }
        handleSearcCustomers(10, 0, 'fullName_asc' as CustomerOrderBy);
    }, [handleSearcCustomers,isRendered]);

    useEffect(() => {
        setIsRendered(!isRendered)

    },[onHandleSelectCustomer])

    return (
        <VirtualizedSelect
            search
            className={classes.searchField}
            language={language}
            options={data?.searchCustomers?.customers || []}
            onChange={onHandleSelectCustomer}
            disabled={loadingSearchCustomers}
            getOptionSelected={(option: CustomerProps, value: CustomerProps) => option.id === value.id}
            getOptionLabel={(option: CustomerProps) => `${option.id}# ${option.fullName} - ${option.phoneNumbers ? option.phoneNumbers[0].phone : '-'}`}
            renderOption={(option: CustomerProps) => <Typography noWrap>{`${option.id}# ${option.fullName} - ${option.phoneNumbers ? option.phoneNumbers[0].phone : '-'}`}</Typography>}
            textFieldProps={{
                required: true,
                fullWidth: true,
                placeholder: `${strings.actions.SEARCH_PAGE(strings.pages.CUSTOMER(1).label[language])[language]}...`,
            }}
        />
    )
}
export default SearchCustomers;