import { Box } from "@material-ui/core";
import { FC } from "react";
import ResponsiveTypography from "../ResponsiveTypography";
import styles from './style';

type FieldWithLabelProps = {
    label?: string;
    value: string;
}

const FieldWithLabel: FC<FieldWithLabelProps> = (props) => {
    const classes = styles();
    const { label, value } = props;

    return (
        <Box className={classes.root}>
            <Box className={classes.content}>
                {label && <ResponsiveTypography
                    fontWeight={300}
                    growthRate={50}
                    smDown={12}
                    mdDown={15}
                    lgDown={12}
                >
                    {label}
                </ResponsiveTypography>}
                <ResponsiveTypography
                    fontWeight={600}
                    growthRate={50}
                    smDown={16}
                    mdDown={20}
                    lgDown={16}
                >
                    {value}
                </ResponsiveTypography>
            </Box>
        </Box>
    )
}

export default FieldWithLabel;