import { ChangeEvent, FC, useState } from "react";
import { Avatar, Box, Button, Divider, Paper } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useCallback } from "react";
import { useForm } from "react-hook-form";

import SettingsSection from "../SettingsSection";
import SettingsTitle from "../SettingsTitle";
import { ResponsiveTypography } from "../../shared";
import { PasswordModal } from "../";
import CustomModal from "../../shared/CustomModal";
import AvatarSelect from "../AvatarSelect";
import requests from "../../../requests";
import strings from "../../../strings";
import styles from './style';
import EmailModal from "../EmailModal";
import NameModal from "../NameModal";

type UserUpdateInput = {
    firstName: string;
    lastName: string;
    avatar: number;
    email: string;
}

const ProfileSettings: FC<PageBaseProps> = (props) => {
    const { language, auth } = props;
    const classes = styles();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedAvatar] = useState(auth?.me?.data?.avatar || 1);
    const [userData, setUserData] = useState<null | UserUpdateInput>(null);
    const [closeAccountModal, setCloseAccountModal] = useState(false);
    const [avatarModal, setAvatarModal] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);
    const [emailModal, setEmailModal] = useState(false);
    const [nameModal, setNameModal] = useState(false);

    const [onUpdateUser] = useMutation(requests.user.mutation.UPDATE_SELF_USER);
    const [onUserAccountClose] = useMutation(requests.user.mutation.USER_CLOSE_ACCOUNT);
    const [onUpdateUserName, { loading: loadingUpdateUserName }] = useMutation(requests.user.mutation.USER_NAME_UPDATE);
    const [onUpdateUserPassword, { loading: loadingUpdateUserPassword }] = useMutation(requests.user.mutation.USER_PASSWORD_UPDATE);
    const [onUpdateUserEmail, { loading: loadingUpdateUserEmail }] = useMutation(requests.user.mutation.USER_EMAIL_UPDATE);

    const PasswordUpdateForm = useForm<PasswordModalFieldProps>();
    const EmailUpdateForm = useForm<EmailModalFieldProps>();
    const NameUpdateForm = useForm<NameModalFieldProps>();

    const onUserAvatarUpdate = (id: number) => {
        onUpdateUser({
            variables: {
                ...userData,
                avatar: id,
            }
        }).then(() => {
            setAvatarModal(false);
            auth?.me?.refetch && auth?.me?.refetch();
        }).catch((err) => console.log(err));
    };

    const onUserCloseAccount = () => {
        onUserAccountClose().then(() => {
            setCloseAccountModal(false);
            auth?.logout && auth?.logout();
        }).catch((err) => console.log(err));
    };

    const handleChangePasswordField = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { name, value } = event.target;
        PasswordUpdateForm.setValue(name as any, value);
    }, [PasswordUpdateForm])

    const handleChangeEmailField = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { name, value } = event.target;
        EmailUpdateForm.setValue(name as any, value);
    }, [EmailUpdateForm])

    const handleChangeNameField = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { name, value } = event.target;
        NameUpdateForm.setValue(name as any, value);
    }, [NameUpdateForm])

    const handleUpdateUserName = useCallback((data: NameModalFieldProps) => {
        onUpdateUserName({
            variables: {
                ...data
            }
        }).then(() => {
            enqueueSnackbar(strings.notifications.USER_UPDATE_SUCCSESS[language], {
                variant: "success",
            });
            setNameModal(false);
            auth?.me?.refetch && auth?.me?.refetch();
        }).catch((error) => {
            enqueueSnackbar(strings.notifications.SOMETHING_WRONG[language], {
                variant: "error",
            });
        });
    }, [auth?.me, enqueueSnackbar, language, onUpdateUserName]);

    const handleUpdateUserPassword = useCallback((data: PasswordModalFieldProps) => {
        onUpdateUserPassword({
            variables: {
                ...data
            }
        }).then(() => {
            enqueueSnackbar(strings.notifications.USER_UPDATE_SUCCSESS[language], {
                variant: "success",
            });
            setPasswordModal(false);
            auth?.me?.refetch && auth?.me?.refetch();
        }).catch((error) => {
            enqueueSnackbar(strings.notifications.SOMETHING_WRONG[language], {
                variant: "error",
            });
        });
    }, [auth?.me, enqueueSnackbar, language, onUpdateUserPassword]);

    const handleUpdateUserEmail = useCallback((data: EmailModalFieldProps) => {
        onUpdateUserEmail({
            variables: {
                email: data.email
            }
        }).then(() => {
            enqueueSnackbar(strings.notifications.USER_UPDATE_SUCCSESS[language], {
                variant: "success",
            });
            setEmailModal(false);
            auth?.me?.refetch && auth?.me?.refetch();
        }).catch((error) => {
            enqueueSnackbar(strings.notifications.SOMETHING_WRONG[language], {
                variant: "error",
            });
        });
    }, [auth?.me, enqueueSnackbar, language, onUpdateUserEmail]);

    useEffect(() => {
        if (auth?.me?.data?.profile)
            setUserData({
                firstName: auth.me.data.profile.firstName,
                lastName: auth.me.data.profile.lastName,
                avatar: auth.me.data.avatar,
                email: auth.me.data.email,
            })
    }, [auth]);

    return (
        <Box className={classes.root}>
            <CustomModal
                fullWidth
                language={language}
                open={avatarModal}
                onClose={() => setAvatarModal(false)}
                title={strings.settings.CHANGE_AVATAR[language]}
                content={
                    <AvatarSelect
                        selected={selectedAvatar}
                        handleSelect={onUserAvatarUpdate}
                    />
                }
            />
            <CustomModal
                fullWidth
                language={language}
                open={closeAccountModal}
                onClose={() => setCloseAccountModal(false)}
                onExecute={onUserCloseAccount}
                title={strings.settings.CLOSE_ACCOUNT.title[language]}
                content={strings.settings.CLOSE_ACCOUNT.subtitle[language]}
            />
            <CustomModal
                fullWidth
                language={language}
                open={nameModal}
                onClose={() => setNameModal(false)}
                title={strings.settings.CHANGE_NAME[language]}
                handleSubmit={NameUpdateForm.handleSubmit}
                onExecute={handleUpdateUserName}
                form
                disableExecute={loadingUpdateUserName}
                content={
                    nameModal && (
                        <NameModal
                            language={language}
                            useForm={NameUpdateForm}
                            onChange={handleChangeNameField}
                        />
                    )
                }
            />
            <CustomModal
                fullWidth
                language={language}
                open={emailModal}
                onClose={() => setEmailModal(false)}
                title={strings.settings.CHANGE_EMAIL.title[language]}
                handleSubmit={EmailUpdateForm.handleSubmit}
                onExecute={handleUpdateUserEmail}
                form
                disableExecute={loadingUpdateUserEmail}
                content={
                    emailModal && (
                        <EmailModal
                            language={language}
                            useForm={EmailUpdateForm}
                            onChange={handleChangeEmailField}
                        />
                    )
                }
            />
            <CustomModal
                fullWidth
                language={language}
                open={passwordModal}
                onClose={() => { setPasswordModal(false); PasswordUpdateForm.reset(); }}
                title={strings.settings.CHANGE_PASSWORD.title[language]}
                handleSubmit={PasswordUpdateForm.handleSubmit}
                onExecute={handleUpdateUserPassword}
                form
                disableExecute={loadingUpdateUserPassword}
                content={
                    passwordModal && (
                        <PasswordModal
                            language={language}
                            useForm={PasswordUpdateForm}
                            onChange={handleChangePasswordField}
                        />
                    )
                }
            />
            <ProfileSummary
                language={language}
                auth={auth}
                openAvatarModal={() => setAvatarModal(true)}
                openEmailModal={() => setEmailModal(true)}
                openNameModal={() => setNameModal(true)}
            />
            <SettingsTitle
                title={strings.settings.ACCOUNT_DATA_SETTINGS[language]}
            />
            <SettingsSection
                language={language}
                title={strings.settings.CHANGE_PASSWORD.title[language]}
                subtitle={strings.settings.CHANGE_PASSWORD.subtitle[language]}
                buttonText={strings.actions.CHANGE[language]}
                action={() => setPasswordModal(true)}
                spaceBottom
            />
            <SettingsSection
                language={language}
                title={strings.settings.CHANGE_EMAIL.title[language]}
                subtitle={strings.settings.CHANGE_EMAIL.subtitle[language]}
                buttonText={strings.actions.CHANGE[language]}
                action={() => setEmailModal(true)}
                spaceBottom
            />
            <SettingsSection
                language={language}
                title={strings.settings.CLOSE_ACCOUNT.title[language]}
                subtitle={strings.settings.CLOSE_ACCOUNT.subtitle[language]}
                buttonText={strings.actions.CLOSE[language]}
                action={() => setCloseAccountModal(true)}
                red
            />
        </Box>
    )
}
export default ProfileSettings;

type ProfileSummaryProps = {
    language: LanguageType;
    auth: AuthProps;
    openAvatarModal: () => void;
    openEmailModal: () => void;
    openNameModal: () => void;
}

const ProfileSummary: FC<ProfileSummaryProps> = (props) => {
    const { language, auth, openAvatarModal, openEmailModal, openNameModal } = props;
    const classes = styles();
    return (
        <Box className={classes.profileRoot}>
            <Box className={classes.imageWrapper}>
                <Avatar
                    src={
                        auth?.me?.data?.avatar
                            ? `/svg/avatars/avatar-0${auth?.me?.data?.avatar}.svg`
                            : ""
                    }
                    className={classes.profileImage}
                />
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={openAvatarModal}
                >
                    {strings.actions.CHANGE[language]}
                </Button>
            </Box>
            <Paper elevation={5} className={classes.formPaper}>
                <ProfileSummaryField
                    label={strings.general.FULL_NAME[language]}
                    value={auth?.me?.data?.fullName}
                    language={language}
                    onClick={openNameModal}
                />
                <Divider />
                <ProfileSummaryField
                    label={strings.general.ROLE[language]}
                    value={auth?.me?.data?.role?.name}
                    language={language}
                />
                <Divider />
                <ProfileSummaryField
                    label={strings.general.EMAIL[language]}
                    value={auth?.me?.data?.email}
                    language={language}
                    onClick={openEmailModal}
                />
            </Paper>
        </Box>
    );
}

type ProfileSummaryFieldProps = {
    label: string;
    value: string;
    onClick?: () => void;
    language: LanguageType;
}

const ProfileSummaryField: FC<ProfileSummaryFieldProps> = (props) => {
    const { label, value, onClick, language } = props;
    return (
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <ResponsiveTypography
                    fontWeight={500}
                    growthRate={50}
                    smDown={12}
                    mdDown={15}
                    lgDown={12}
                >
                    {label}
                </ResponsiveTypography>
                <ResponsiveTypography
                    fontWeight={700}
                    growthRate={50}
                    smDown={16}
                    mdDown={20}
                    lgDown={16}
                >
                    {value}
                </ResponsiveTypography>
            </Box>
            {onClick &&
                <Button
                    size='small'
                    variant='text'
                    onClick={onClick}
                >
                    {strings.actions.CHANGE[language]}
                </Button>
            }
        </Box>
    )
}