import { gql } from "@apollo/client";

const SEARCH_PRODUCTS = gql`
    query onSearchProducts($searchBy: String, $filters: ProductWhereInput, $orderBy: ProductOrderBy, $paginate: Pagination) {
        searchProducts(searchBy: $searchBy, filters: $filters, orderBy: $orderBy, paginate: $paginate) {
            products {
                id
                category
                status
                type {
                    id
                    description {
                        id
                        cat
                    }
                }
                image
                manufacturer
                model
                price
                createdAt
            }
            count
        }
    }
`;

const SEARCH_PRODUCTS_NEW = gql `
    query onSearchProductsNew{
        searchProductsNew{
            products {
                id
                category
                status
                type {
                    id
                    description {
                        id
                        cat
                    }
                }
                image
                manufacturer
                model
                price
                createdAt
            }
            count
        }
    }

`;

const CREATE_PRODUCT = gql`
    mutation onProductCreate($manufacturer: String, $model: String, $price: Float, $image: String, $type: ProductTypeInput) {
        createProduct(manufacturer: $manufacturer, model: $model, price: $price, image: $image, type: $type) {
            id
        }
    }
`;

const CREATE_PRODUCT_TYPE = gql`
    mutation onProductTypeCreate($description: String) {
        createProductType(description: $description) {
            id
        }
    }
`;

const SEARCH_PRODUCT_TYPES = gql`
    query onSearchProductTypes {
        searchProductTypes {
            id
            description {
                id
                description
            }
        }
    }
`;

const SEARCH_PRODUCT = gql`
    query onSearchProduct($id: Int!) {
        searchProduct(id: $id) {
            id
            status
            category
            price
            manufacturer
            model
            image
        }
    }
`;

//category: $category,manufacturer: $manufacturer, model: $model, price: $price,
// const UPDATE_PRODUCT = gql`
//     mutation onUpdateProduct($id: Int!,$data: ProductWhereInput!, $image: String){
//         updateProduct(id: $id, data: $data image: $image) {
//             id
//         }
      
//     }
// `;

const UPDATE_PRODUCT = gql`
    mutation onUpdateProduct($id: Int!, $manufacturer: String!, $model: String!, $price: Float!){
        updateProduct(id: $id, manufacturer: $manufacturer, model: $model, price: $price) {
            id
        }
      
    }
`;


export const product = {
    query: {
        SEARCH_PRODUCT,
        SEARCH_PRODUCTS,
        SEARCH_PRODUCT_TYPES,
        SEARCH_PRODUCTS_NEW,
    },
    mutation: {
        CREATE_PRODUCT,
        CREATE_PRODUCT_TYPE,
        UPDATE_PRODUCT
    },
    subscription: {
    }
}