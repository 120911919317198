import { createStyles, lighten, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(4),
            "& > *": {
                borderBottom: "unset",
              },
        },
        toolbar: {
            padding: theme.spacing(2, 2),
            display: 'flex',
            alignItems: 'center',
        },
        tableCell: {
            border: 'none',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
            padding: theme.spacing(0, 3),
            backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.background.paper
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        cell: {
            fontWeight: 700,
            fontSize: 15,
        },
        entries: {
            fontWeight: 500,
            fontSize: 10,

        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
        },
    }),
);