import { useLazyQuery } from "@apollo/client";
import { FC, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { QuoteEnhancedTable } from "../../components/Quotes";

import { ResponsiveTypography } from "../../components/shared";
import MainWrapper from "../../components/shared/MainWrapper";
import { permissionAccess } from "../../functions/permissionAccess";
import requests from "../../requests";
import strings from "../../strings";
import styles from './style';

const Quotes: FC<PageBaseProps> = (props) => {
    const { language, auth } = props;
    const history = useHistory();
    const classes = styles();

    const [onSearchQuotes, { data, loading: loadingSearchQuotes }] = useLazyQuery(requests.quote.query.SEARCH_QUOTES, {
        fetchPolicy: "network-only",
    });

    const handleSearchQuotes = useCallback((rows: number, page: number, orderBy: QuoteOrderBy) => {
        onSearchQuotes({
            variables: {
                orderBy,
                paginate: {
                    take: rows,
                    skip: Math.max(page, 0) * rows,
                }
            }
        })
    }, [onSearchQuotes]);

    useEffect(() => {
        document.title = `Corsem - ${strings.pages.QUOTES.label[language]}`;
    }, [language]);

    useEffect(() => {
        if (auth.me)
            if (!permissionAccess(auth.me.data.permissions, 7)) history.replace(strings.pages.FORBIDDEN.url)
    })

    return (
        <MainWrapper>
            <ResponsiveTypography
                className={classes.title}
                growthRate={50}
                fontWeight={800}
                smDown={33}
                mdDown={45}
                lgDown={33}
            >
                {strings.pages.QUOTES.label[language]}
            </ResponsiveTypography>

            <ResponsiveTypography
                growthRate={50}
                fontWeight={300}
                smDown={12}
                mdDown={20}
                lgDown={12}
            >
                {strings.pages.SERVICES.sublabel[language]}
            </ResponsiveTypography>

            <QuoteEnhancedTable
                rows={data?.searchQuotes?.quotes || []}
                count={data?.searchQuotes?.count || 0}
                loading={loadingSearchQuotes}
                language={language}
                handleSearch={handleSearchQuotes}
                title={strings.pages.QUOTES.label[language]}
            />

        </MainWrapper>
    )
}
export default Quotes;