import strings from "../strings";

export const getError = (error: FormError | undefined, language: LanguageType, aux1?: number | undefined, aux2?: number | undefined) => {
    if (error === undefined) return;
    const { type } = error;
    switch (type) {
        case "required":
            return strings.settings.REQUIRED_FIELD[language];
        case "minLength":
            return strings.settings.MIN_CHARS(aux1 ? aux1 : 8)[language];
        case "maxLength":
            return strings.settings.MAX_CHARS(aux2 ? aux2 : 16)[language];
        case "confirmError":
            return strings.settings.CONFIRM_PASSWORD[language];
        case "wrongPassword":
            return strings.settings.WRONG_PASSWORD[language];
        case "pattern":
            return strings.settings.PATTERN[language];
        case "validate":
            return strings.settings.CONFIRM_FIELD[language];
        default:
            return false;
    }
};
