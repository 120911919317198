import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        borderRadius: '30px',
        padding: theme.spacing(4),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.background.paper : theme.palette.background.default,
    },
    rootMargin: {
        marginBottom: theme.spacing(3),
    },
    rootRed: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.common.white,
    },
    title: {
        marginBottom: theme.spacing(1),
    },
    subtitle: {
    },
    textWrapper: {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: theme.spacing(2),
    },
    button: {
        textTransform: 'none',
    },
}));