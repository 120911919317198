import { Box } from "@material-ui/core";
import React, { FC } from "react";
import clsx from 'clsx';
import styles from './style';

enum ExecutionStatus {
    OPEN = "OPEN",
    STARTED = "STARTED",
    CLOSED = "CLOSED",
    CANCELLED = "CANCELLED",
}

const CustomStatusChip: FC<{ children: React.ReactNode, value: string }> = ({ children, value }) => {
    const classes = styles();
    return (
        <Box className={clsx({
            [classes.root]: true,
            [classes.openStatus]: value === ExecutionStatus.OPEN,
            [classes.startedStatus]: value === ExecutionStatus.STARTED,
            [classes.closedStatus]: value === ExecutionStatus.CLOSED,
            [classes.cancelledStatus]: value === ExecutionStatus.CANCELLED,
        })}>
            {children}
        </Box>
    )
}

export default CustomStatusChip;