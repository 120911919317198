import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 10,
        margin: theme.spacing(0, 4),
        padding: theme.spacing(2),
        boxShadow: `inset 5px 5px 10px ${theme.palette.common.black}${theme.palette.type === 'light' ? '20' : '2'}`,
    },
    textLink: {
        cursor: "pointer",
    },
    addButton: {
        borderRadius: 30,
        marginRight: theme.spacing(2),
    },
    buttonIcon: {
        marginRight: theme.spacing(1),
    },
}));