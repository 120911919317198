import { FC } from "react";
import { Grid, TextField } from "@material-ui/core";

import { CustomerFormProps } from "../types";
import styles from './style';
import strings from "../../../strings";
import { getError } from "../../../functions/formFunctions";

const Form: FC<CustomerFormProps> = (props) => {
    const classes = styles();
    const { language, useForm, handleChangeField } = props;
    const { register, formState: { errors } } = useForm;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    required
                    variant="outlined"
                    className={classes.textField}
                    label={strings.general.FIRST_NAME[language]}
                    {...register('firstName', {
                        required: true,
                    })}
                    onChange={handleChangeField}
                    error={Boolean(errors.firstName)}
                    helperText={getError(errors.firstName, language)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    required
                    variant="outlined"
                    className={classes.textField}
                    label={strings.general.LAST_NAME[language]}
                    {...register('lastName', {
                        required: true,
                    })}
                    onChange={handleChangeField}
                    error={Boolean(errors.lastName)}
                    helperText={getError(errors.lastName, language)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    required
                    variant="outlined"
                    className={classes.textField}
                    label={strings.general.CNP[language]}
                    {...register('cnp', {
                        required: true,
                        minLength: 13,
                        maxLength: 16,
                        pattern: /^[1-9]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d|5[0-2]|99)(00[1-9]|0[1-9]\d|[1-9]\d\d)\d$/i,
                    })}
                    onChange={handleChangeField}
                    error={Boolean(errors.cnp)}
                    helperText={getError(errors.cnp, language, 13)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    required
                    variant="outlined"
                    className={classes.textField}
                    label={strings.general.PHONE[language]}
                    {...register('phone', {
                        required: true,
                        pattern: /^(\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|-)?([0-9]{3}(\s|\.|-|)){2}$/i,
                    })}
                    onChange={handleChangeField}
                    error={Boolean(errors.phone)}
                    helperText={getError(errors.phone, language)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.textField}
                    label={strings.general.EMAIL[language]}
                    {...register('email', {
                        pattern: /^(([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}))|$/i,
                    })}
                    onChange={handleChangeField}
                    error={Boolean(errors.email)}
                    helperText={getError(errors.email, language)}
                />
            </Grid>
        </Grid>
    )
}
export default Form;