import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { ChangeEvent, FC, useCallback, useEffect, useState, useRef } from "react";
import clsx from 'clsx';

import { ResponsiveTypography } from "../../components/shared";
import MainWrapper from "../../components/shared/MainWrapper";
import { useRouteQuery } from "../../functions";
import {AddTypeSection,AddSection, EditProduct} from '../../components/Product'
import { Button, Card, CardActions, CircularProgress, Divider, Box, Theme ,TextField, Grid,InputAdornment, Typography,useMediaQuery, } from "@material-ui/core";
import requests from "../../requests";
import strings from "../../strings";
import styles from './style';
import { permissionAccess } from "../../functions/permissionAccess";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomModal from "../../components/shared/CustomModal"


type ProductFormValues = {
    manufacturer: string;
    model: string;
    price: number;
    image?: string;
    
};

const Product : FC<PageBaseProps> = (props) => {
    const classes = styles();
    const { language, auth } = props;
    const [pageId, setPageId] = useState(0);
    const history = useHistory();
    const [buttonStatus, setButtonStatus] = useState<undefined | ButtonStatusType>();
    const query = useRouteQuery();
    const [manufacturer, setManufacturer]= useState('');
    const [model, setModel]= useState('');
    const [price, setPrice]= useState('');
    const isDownXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const [id, setId] = useState<number | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const [sureModal, setSureModal] = useState<boolean>(false);
    const subButton = useRef();

    useEffect(() => {
        const string = window.location.href;
        const match = string.match(/id=([^&]*)/);
        setId(match && match[1] ? Number(match[1]) : null);
    }, []);
    
    // useEffect(() => {
    //     document.title = `Corsem - ${strings.pages.PRODUCT.label[language]}`;
        
    // }, [language]);

    // const [onProductSearch, { data, refetch, loading: loadingProductFetch }] = useLazyQuery(requests.product.query.SEARCH_PRODUCT,{
    //     fetchPolicy:"cache-first"
    // });

    // useEffect(() => setPageId(Number(query.get("id"))), [query, setPageId]);

    useEffect(() => {
        if (auth.me) 
            if (!permissionAccess(auth.me.data.permissions, 15)) history.replace(strings.pages.FORBIDDEN.url)
    })

    const [getProduct, { data }] =  useLazyQuery(requests.product.query.SEARCH_PRODUCT, {
        fetchPolicy: "network-only"
    })

    useEffect(() => {
        if(id) getProduct({variables: {id}});
    }, [id])

    useEffect(() => {
        if(data) {
            const { 
                category,
                id,
                image,
                manufacturer,
                model,
                price,
                status
            } = data.searchProduct;

            // setCategory(category);
            setManufacturer(manufacturer);
            setModel(model);
            setPrice(price);
        }
    }, [data]);

    const [
        updateProduct,
        { loading: loadingUpdateProduct }
    ] = useMutation(requests.product.mutation.UPDATE_PRODUCT);

    const onSubmit = () => {
        updateProduct({
            variables: {
                id: id,
                manufacturer: manufacturer,
                model: model,
                price: parseFloat(price)
            }
        }).then(() => {
            enqueueSnackbar(
                strings.notifications.PRODUCT_UPDATE_SUCCESS[language],
                {
                    variant: "success",
                }
            );
        }).catch((error) => {
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }


    return(
        <MainWrapper>
             <ResponsiveTypography
                className={classes.title}
                growthRate={50}
                fontWeight={800}
                smDown={33}
                mdDown={45}
                lgDown={33}
            >
                {/* {strings.pages.PRODUCT.label[language]} */}
                PRODUS - {manufacturer} - {model}
            </ResponsiveTypography>

                <Card elevation={5} className={classes.root_paper}>
                <Divider style={{marginTop:"1rem", marginBottom:"1rem"}} />
                <Box style={{display: 'flex', justifyContent:"center", alignItems:"center"}}>
                    <Grid container>
                        <Grid item lg={9} md={6} xs={12} >
                            <Grid container item lg={12} justify='space-evenly' alignContent={isDownXs ? 'stretch' : 'center'} direction='column'>
                                <TextField variant='outlined'
                                    label={strings.columns.MANUFACTURER[language]}
                                    onChange={(e) => setManufacturer(e.target.value)}
                                    className={classes.textField}
                                    required
                                    value={manufacturer}
                                >
                                    
                                </TextField>
                                <TextField variant='outlined'
                                    label={strings.columns.MODEL[language]}
                                    onChange={(e) => setModel(e.target.value)}
                                    className={classes.textField}
                                    required
                                    value={model}
                                >
                                    
                                </TextField>
                                <TextField variant='outlined'
                                    label={strings.columns.PRICE[language]}
                                    onChange={(e) => setPrice(e.target.value)}
                                    className={classes.textField}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">RON</InputAdornment>,
                                        
                                    }}
                                    required
                                    value={price}
                                >   
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Divider style={{marginTop:"1rem", marginBottom:"1rem"}}/>
                <CustomModal
                    open={sureModal}
                    title={strings.actions.SAVE_PRODUCT.title[language]}
                    content={strings.actions.SAVE_PRODUCT.content[language]}
                    language={language}
                    doneButtonLabel= {strings.actions.SAVE[language]}
                    onClose={() => setSureModal(false)}
                    onExecute={() => {
                        setSureModal(false);
                        onSubmit();
                    }}
                />
                <div style={{display:"flex", alignItems:"flex-end", justifyContent:"flex-end",}}>
                    <Button size="small" onClick={() => history.replace(strings.pages.PRODUCTS.url) }>
                        {strings.actions.CANCEL[language]}  
                    </Button>
                    <Box className={classes.buttonWrapper}>
                    <Button
                        size="small"
                        color="secondary"
                        onClick={() => setSureModal(true)}
                    > 
                        {strings.actions.SAVE[language]}
                    </Button>   
                     
                    </Box>
                </div>
                </Card>
        </MainWrapper>
    )
}
export default Product;