import { makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
    root_paper: {
        overflow: 'hidden',
        marginTop: theme.spacing(4),
    },
    role_item: {
        backgroundColor: theme.palette.type === 'light' ? theme.palette.background.paper : theme.palette.background.default,
    },
    addRoleTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    addRoleIcon: {
        marginRight: theme.spacing(1),
    },
    column: {
        [theme.breakpoints.up('lg')]: {
            flexBasis: '33.33%'
        }
    },
    root: {
        color: theme.palette.text.secondary,
        '&:hover > $content': {
            color: 'var(--tree-view-color)',
            backgroundColor: theme.palette.action.hover,
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: 'transparent',
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent',
        },
    },
    content: {
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 1),
        height: 38,
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
    },
    roleNameField: {
        marginBottom: theme.spacing(1),
    },
    buttonWrapper: {
        position: 'relative',
    },
    progress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 24,
        height: 24,
        marginTop: -12,
        marginLeft: -12,
    },
    checks: {
        margin: theme.spacing(2, 1, 2, 1),
    },
    buttonIcon: {
        marginRight: theme.spacing(1),
    },
    auxTypeBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 10,
        marginBottom: theme.spacing(2),
    },
    auxTypeControls: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            // alignItems: 'center',
        }
    },
    auxTypeSwitch: {
        margin: theme.spacing(0, 0, 0, 3),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0, 0, 0),
        }
    },
}))