import { gql } from "@apollo/client";

const CREATE_VEHICLE = gql`
mutation onVehicleCreate($data: VehicleCreateInput!) {
    createVehicle(data: $data) {
        id
    }
}
`;

const UPDATE_VEHICLE = gql`
mutation onVehicleUpdate($id: Int!, $data: VehicleUpdateInput!) {
    updateVehicle(id: $id, data: $data) {
        id
    }
}
`;

const SEARCH_VEHICLES = gql`
    query onVehiclesSearch($searchBy: String, $filters: VehicleWhereInput, $orderBy: VehicleOrderBy, $paginate: Pagination) {
        searchVehicles(searchBy: $searchBy, filters: $filters, orderBy: $orderBy, paginate: $paginate) {
            count
            vehicles {
                id
                manufacturer
                model
                plate
                plate1
                plate2
                plate3
                RCA
                ITP
                ROV
                createdAt
            }
        }
    }
`;

const SEARCH_VEHICLE = gql`
    query onVehicleSearch($id: Int!) {
        searchVehicle(id: $id) {
            id
            manufacturer
            model
            plate1
            plate2
            plate3
            RCA
            ITP
            ROV
        }
    }
`;

export const vehicle = {
    query: {
        SEARCH_VEHICLES,
        SEARCH_VEHICLE,
    },
    mutation: {
        CREATE_VEHICLE,
        UPDATE_VEHICLE,
    },
    subscription: {
    }
}