import { FC, useCallback } from "react";

import { SearchProductProps, ProductTypeProps } from "../types";
import styles from './style';
import strings from "../../../strings";
import { VirtualizedSelect } from "../../shared";
import { useLazyQuery } from "@apollo/client";
import requests from "../../../requests";
import { useEffect } from "react";
import { ListSubheader, Typography } from "@material-ui/core";

const SearchProduct: FC<SearchProductProps> = (props) => {
    const classes = styles();
    const { language, onHandleSelectProduct } = props;


    const [onSearchProducts, { data, loading: loadingSearchServiceTypes }] = useLazyQuery(requests.product.query.SEARCH_PRODUCTS, {
        fetchPolicy: "cache-first",
    });

    const handleSearchProducts = useCallback((rows: number, page: number, orderBy: ProductOrderBy) => {
        onSearchProducts({
            variables: {
                filters: {
                    status: 'ACTIVE'
                },
                orderBy,
                paginate: {
                    take: rows,
                    skip: Math.max(page, 0) * rows,
                }
            }
        })
    }, [onSearchProducts]);

    useEffect(() => {
        handleSearchProducts(10, 0, 'category_asc' as ProductOrderBy);
    }, [handleSearchProducts]);

    return (
        <VirtualizedSelect
            search
            className={classes.searchField}
            language={language}
            options={data?.searchProducts?.products || []}
            onChange={onHandleSelectProduct}
            disabled={loadingSearchServiceTypes}
            getOptionSelected={(option: ProductTypeProps, value: ProductTypeProps) => option.id === value.id}
            getOptionLabel={(option: ProductTypeProps) => `${option.manufacturer} ${option.model}`}
            groupBy={(option: ProductTypeProps) => option.category}
            renderOption={(option: ProductTypeProps) => <Typography noWrap>{`${option.id}# ${option.manufacturer} ${option.model}`}</Typography>}
            textFieldProps={{
                required: true,
                fullWidth: true,
                placeholder: `${strings.actions.SEARCH_PAGE(strings.pages.PRODUCT(1).label[language])[language]}...`,
            }}
            renderGroup={(params) => [
                <ListSubheader key={params.key} component="div">
                    {params.group}
                </ListSubheader>,
                params.children,
            ]}
        />
    )
}
export default SearchProduct;