import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Avatar, Button, ButtonGroup, Card, CardActions, CardContent, CircularProgress, Grid, MenuItem, TextField } from "@material-ui/core";
import { CheckCircleOutlineRounded, LockOpenRounded, LockRounded, SecurityRounded, SaveRounded as Save, ErrorRounded as Error, DoneRounded as Done } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import clsx from 'clsx';

import { ResponsiveTypography } from "../../components/shared";
import CustomModal from "../../components/shared/CustomModal";
import MainWrapper from "../../components/shared/MainWrapper";
import { useRouteQuery } from "../../functions";
import { getError } from "../../functions/formFunctions";
import { permissionAccess } from "../../functions/permissionAccess";
import requests from "../../requests";
import strings from "../../strings";
import styles from './style';

type RoleProps = {
    id: number;
    name: string;
    value: number;
    permissions: boolean[];
    noOfPermissions: number;
}
type ProfileFormValues = {
    firstName: string;
    lastName: string;
};
type UserFormValues = {
    profile: ProfileFormValues;
    email: string;
    roleId: number;
};

const User: FC<PageBaseProps> = (props) => {
    const { language, auth } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [pageId, setPageId] = useState<number | undefined>();
    const [resetModal, setResetModal] = useState<boolean>(false);
    const [blockModal, setBlockModal] = useState<boolean>(false);
    const [unblockModal, setUnlockModal] = useState<boolean>(false);
    const [validateModal, setValidateModal] = useState<boolean>(false);
    const [buttonStatus, setButtonStatus] = useState<undefined | ButtonStatusType>();
    const history = useHistory();
    const query = useRouteQuery();
    const classes = styles();

    const toggleResetModal = () => setResetModal(prev => !prev);
    const toggleBlockModal = () => setBlockModal(prev => !prev);
    const toggleUnblockModal = () => setUnlockModal(prev => !prev);
    const toggleValidateModal = () => setValidateModal(prev => !prev);

    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm<UserFormValues>({
        reValidateMode: 'onChange',
        shouldFocusError: true,
        criteriaMode: 'firstError'
    });

    const [onUserSearch, { data, refetch, loading }] = useLazyQuery(requests.user.query.SEARCH_USER, {
        fetchPolicy: "network-only",
    });

    const [onUserValidate, { loading: loadingValidate }] = useMutation(requests.user.mutation.VALIDATE_USER);
    const [onUserBlock, { loading: loadingBlock }] = useMutation(requests.user.mutation.BLOCK_USER);
    const [onUserUnblock, { loading: loadingUnblock }] = useMutation(requests.user.mutation.UNBLOCK_USER);
    const [onUserReset, { loading: loadingReset }] = useMutation(requests.user.mutation.RESET_USER);

    const [onUserUpdate, { loading: loadingUserUpdate }] = useMutation(requests.user.mutation.UPDATE_USER);

    const { data: rolesData } = useQuery(requests.user.query.SEARCH_ROLES, {
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (auth.me)
            if (!permissionAccess(auth.me.data.permissions, 3)) history.replace(strings.pages.FORBIDDEN.url)
    })

    useEffect(() => setPageId(Number(query.get("id"))), [query, setPageId]);

    useEffect(() => {
        if (pageId)
            onUserSearch({
                variables: { id: pageId },
            })
    }, [pageId, onUserSearch])

    useEffect(() => {
        if (data?.searchUser) {
            setValue('email', data.searchUser.email);
            setValue('profile.firstName', data.searchUser.profile.firstName);
            setValue('profile.lastName', data.searchUser.profile.lastName);
            setValue('roleId', data.searchUser.roleId);
        }
    }, [data])

    const onSubmit: SubmitHandler<UserFormValues> = (data, event) => {
        event?.preventDefault();

        onUserUpdate({
            variables: {
                id: pageId,
                data,
            }
        }).then(() => {
            enqueueSnackbar(
                strings.notifications.USER_UPDATE_SUCCSESS[language],
                {
                    variant: "success",
                }
            );
            refetch && refetch();
            setButtonStatus('SUCCESS');
            setTimeout(() => {
                setButtonStatus(undefined);
            }, 2000);
        }).catch((error: any) => {
            setButtonStatus('ERROR');
            setTimeout(() => {
                setButtonStatus(undefined);
            }, 2000);
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }

    const buttonClassname = clsx({
        [classes.buttonStd]: true,
        [classes.buttonSuccess]: buttonStatus === 'SUCCESS',
        [classes.buttonError]: buttonStatus === 'ERROR',
    });

    return (
        <MainWrapper>
            <ResponsiveTypography
                className={classes.title}
                growthRate={50}
                fontWeight={800}
                smDown={33}
                mdDown={45}
                lgDown={33}
            >
                {`${strings.pages.USER(1).label[language]} - #${pageId}`}
            </ResponsiveTypography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Card elevation={5} className={classes.root_paper}>
                        <CardContent className={classes.root_content}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <ResponsiveTypography
                                        lgDown={20}
                                        mdDown={25}
                                        smDown={20}
                                        fontWeight={700}
                                        growthRate={50}
                                    >
                                        {data?.searchUser?.profile?.firstName}
                                    </ResponsiveTypography>
                                    <ResponsiveTypography
                                        lgDown={20}
                                        mdDown={25}
                                        smDown={20}
                                        fontWeight={700}
                                        growthRate={50}
                                    >
                                        {data?.searchUser?.profile?.lastName}
                                    </ResponsiveTypography>
                                    <ResponsiveTypography
                                        mdDown={20}
                                        growthRate={50}
                                    >
                                        {data?.searchUser?.email}
                                    </ResponsiveTypography>
                                </Grid>
                                <Grid item xs={6} className={classes.avatarContainer}>
                                    <Avatar
                                        className={classes.avatar}
                                        src={
                                            data?.searchUser?.avatar
                                                ? `/svg/avatars/avatar-0${data?.searchUser?.avatar}.svg`
                                                : ""
                                        } />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <ButtonGroup
                                fullWidth
                                variant='text'
                            >
                                {data?.searchUser?.validated === false ?
                                    <Button
                                        variant='text'
                                        color='secondary'
                                        onClick={toggleValidateModal}
                                        disabled={loading || loadingValidate}
                                    >
                                        <CheckCircleOutlineRounded className={classes.buttonIcon} />
                                        {strings.actions.VALIDATE[language]}
                                    </Button>
                                    :
                                    data?.searchUser?.active === false ?
                                        <Button
                                            variant='text'
                                            color='secondary'
                                            onClick={toggleUnblockModal}
                                            disabled={loading || loadingUnblock}
                                        >
                                            <LockOpenRounded className={classes.buttonIcon} />
                                            {strings.actions.UNBLOCK[language]}
                                        </Button>
                                        :
                                        <Button
                                            variant='text'
                                            color='secondary'
                                            className={classes.redAction}
                                            onClick={toggleBlockModal}
                                            disabled={loading || loadingBlock}
                                        >
                                            <LockRounded className={classes.buttonIcon} />
                                            {strings.actions.BLOCK[language]}
                                        </Button>
                                }
                                <Button
                                    variant='text'
                                    color='inherit'
                                    onClick={toggleResetModal}
                                    disabled={loading || loadingReset}
                                >
                                    <SecurityRounded className={classes.buttonIcon} />
                                    {strings.actions.RESET[language]}
                                </Button>
                            </ButtonGroup>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card elevation={5} className={classes.root_paper}>
                        <CardContent className={classes.root_content}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        value={watch('profile.firstName') || ''}
                                        variant="outlined"
                                        label={strings.columns.FIRST_NAME[language]}
                                        {...register('profile.firstName', {
                                            required: true,
                                        })}
                                        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setValue('profile.firstName', event.target.value)}
                                        error={Boolean(errors?.profile?.firstName)}
                                        helperText={getError(errors?.profile?.firstName, language)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        value={watch('profile.lastName') || ''}
                                        variant="outlined"
                                        label={strings.columns.LAST_NAME[language]}
                                        {...register('profile.lastName', {
                                            required: true,
                                        })}
                                        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setValue('profile.lastName', event.target.value)}
                                        error={Boolean(errors?.profile?.lastName)}
                                        helperText={getError(errors?.profile?.lastName, language)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        value={watch('email') || ''}
                                        variant="outlined"
                                        label={strings.columns.EMAIL[language]}
                                        {...register('email', {
                                            required: true,
                                            pattern: /^(([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}))$/i,
                                        })}
                                        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setValue('email', event.target.value)}
                                        error={Boolean(errors.email)}
                                        helperText={getError(errors.email, language)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        select
                                        fullWidth
                                        required
                                        variant="outlined"
                                        label={strings.general.ROLE[language]}
                                        value={rolesData?.roles ? watch('roleId') ? watch('roleId') : 2 : 2}
                                        name='roleId'
                                        onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setValue('roleId', Number(event.target.value))}
                                        error={Boolean(errors?.roleId)}
                                        helperText={getError(errors?.roleId, language)}
                                    >
                                        {rolesData?.roles ? rolesData.roles.filter((item: RoleProps) => item.id !== 2).map((item: RoleProps, index: number) =>
                                            <MenuItem
                                                key={index}
                                                value={item.id}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        ) :
                                            <MenuItem value={2}>
                                                <em>NONE</em>
                                            </MenuItem>
                                        }
                                    </TextField>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <div className={classes.wrapper}>
                                <Button
                                    color="secondary"
                                    variant={buttonStatus === 'SUCCESS' ? "contained" : "outlined"}
                                    disabled={loadingUserUpdate}
                                    className={buttonClassname}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    {buttonStatus === undefined && <Save fontSize="small" className={classes.leftIcon} />}
                                    {buttonStatus === 'ERROR' && <Error fontSize="small" className={classes.leftIcon} />}
                                    {buttonStatus === 'SUCCESS' && <Done fontSize="small" className={classes.leftIcon} />}
                                    {strings.actions.SAVE[language]}
                                </Button>
                                {loadingUserUpdate && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>

            <CustomModal
                fullWidth
                disableExecute={loading || loadingValidate}
                language={language}
                open={validateModal}
                title={strings.actions.VALIDATE[language]}
                content={strings.actions.VALIDATE_CONFIRM[language]}
                onClose={toggleValidateModal}
                onExecute={() => {
                    onUserValidate({
                        variables: { id: pageId }
                    }).then(() => {
                        enqueueSnackbar(
                            strings.notifications.USER_UPDATE_SUCCSESS[language],
                            {
                                variant: "success",
                            }
                        );
                        refetch && refetch();
                        toggleValidateModal();
                    }).catch((error) => {
                        enqueueSnackbar(
                            error.message in strings.notifications
                                ? strings.notifications[error.message as GlobalNotification][language]
                                : error.message,
                            {
                                variant: "error",
                            }
                        );
                    });
                }}
            />

            <CustomModal
                fullWidth
                disableExecute={loading || loadingReset}
                language={language}
                open={resetModal}
                title={strings.actions.RESET[language]}
                content={strings.actions.RESET_CONFIRM[language]}
                onClose={toggleResetModal}
                onExecute={() => {
                    onUserReset({
                        variables: { id: pageId }
                    }).then(() => {
                        enqueueSnackbar(
                            strings.notifications.USER_UPDATE_SUCCSESS[language],
                            {
                                variant: "success",
                            }
                        );
                        refetch && refetch();
                        toggleResetModal();
                    }).catch((error) => {
                        enqueueSnackbar(
                            error.message in strings.notifications
                                ? strings.notifications[error.message as GlobalNotification][language]
                                : error.message,
                            {
                                variant: "error",
                            }
                        );
                    });
                }}
            />

            <CustomModal
                fullWidth
                disableExecute={loading || loadingBlock}
                language={language}
                open={blockModal}
                title={strings.actions.BLOCK[language]}
                content={strings.actions.BLOCK_CONFIRM[language]}
                onClose={toggleBlockModal}
                onExecute={() => {
                    onUserBlock({
                        variables: { id: pageId }
                    }).then(() => {
                        enqueueSnackbar(
                            strings.notifications.USER_UPDATE_SUCCSESS[language],
                            {
                                variant: "success",
                            }
                        );
                        refetch && refetch();
                        toggleBlockModal();
                    }).catch((error) => {
                        enqueueSnackbar(
                            error.message in strings.notifications
                                ? strings.notifications[error.message as GlobalNotification][language]
                                : error.message,
                            {
                                variant: "error",
                            }
                        );
                    });
                }}
            />

            <CustomModal
                fullWidth
                disableExecute={loading || loadingUnblock}
                language={language}
                open={unblockModal}
                title={strings.actions.UNBLOCK[language]}
                content={strings.actions.UNBLOCK_CONFIRM[language]}
                onClose={toggleUnblockModal}
                onExecute={() => {
                    onUserUnblock({
                        variables: { id: pageId }
                    }).then(() => {
                        enqueueSnackbar(
                            strings.notifications.USER_UPDATE_SUCCSESS[language],
                            {
                                variant: "success",
                            }
                        );
                        refetch && refetch();
                        toggleUnblockModal();
                    }).catch((error) => {
                        enqueueSnackbar(
                            error.message in strings.notifications
                                ? strings.notifications[error.message as GlobalNotification][language]
                                : error.message,
                            {
                                variant: "error",
                            }
                        );
                    });
                }}
            />
        </MainWrapper>
    )
}
export default User;