import { ChangeEvent, FC } from "react";
import { Grid, TextField } from "@material-ui/core";
import strings from "../../../strings";
import { getError } from "../../../functions/formFunctions";
import { UseFormReturn } from "react-hook-form";
import styles from './style';

type PasswordModalProps = {
    language: LanguageType;
    useForm: UseFormReturn<PasswordModalFieldProps>;
    onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const PasswordModal: FC<PasswordModalProps> = (props) => {
    const classes = styles();
    const { language, useForm, onChange } = props;
    const { register, watch, formState: { errors } } = useForm;

    return (
        <Grid container>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={strings.settings.CURRENT_PASSWORD[language]}
                    className={classes.textField}
                    type="password"
                    variant="outlined"
                    autoComplete="old-password"
                    {...register("oldPassword", {
                        required: true,
                    })}
                    onChange={onChange}
                    error={!!errors?.oldPassword}
                    helperText={getError(errors.oldPassword, language)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={strings.settings.NEW_PASSWORD[language]}
                    className={classes.textField}
                    type="password"
                    variant="outlined"
                    autoComplete="new-password"
                    {...register("password", {
                        required: true,
                        minLength: 8,
                        maxLength: 16,
                    })}
                    onChange={onChange}
                    error={!!errors?.password}
                    helperText={getError(errors.password, language, 8, 16)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={strings.settings.CONFIRM_PASSWORD[language]}
                    className={classes.textField}
                    type="password"
                    variant="outlined"
                    autoComplete="new-password"
                    {...register("confirm", {
                        required: true,
                        minLength: 8,
                        maxLength: 16,
                        validate: value => value === watch('password'),
                    })}
                    onChange={onChange}
                    error={!!errors?.confirm}
                    helperText={getError(errors.confirm, language, 8, 16)}
                />
            </Grid>
        </Grid>
    );
};

export default PasswordModal;
