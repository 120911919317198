import { PaletteType } from '@material-ui/core';
import generateTheme from './theme';

export function getAccessToken() {
    return localStorage.getItem("accessTokenKey");
}

export function isLoggedIn() {
    return !!localStorage.getItem("accessTokenKey");
}

export function logout() {
    localStorage.removeItem("accessTokenKey");
    localStorage.removeItem("me");
}

export function getInitialLanguage() {
    const language = localStorage.getItem("language");
    return (language ? language : "ro") as "ro" | "en";
}

export function getInitialTheme() {
    let savedTheme;
    if (typeof window !== "undefined")
        savedTheme = localStorage.getItem("theme");
    return savedTheme ? generateTheme(savedTheme as PaletteType) : generateTheme("dark" as PaletteType);
}

export function getCurrentNotifications() {
    let notifications = [];
    let storage;
    if (typeof window !== "undefined")
        storage = localStorage.getItem("notiData");
    if (typeof storage === 'string') {
        try {
            notifications = JSON.parse(storage);
        }
        catch (err) { return []; }
    }
    return notifications;
}