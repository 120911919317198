import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ExecutionsEnhancedTable, ExecutionsTableFilters } from "../../components/Executions";
import { ExecutionFilters } from "../../components/Executions/types";
import { ProjectsEnhancedTable, ProjectsTableFilters } from "../../components/Projects";
import { ProjectFilters } from "../../components/Projects/types";
import { QuoteEnhancedTable } from "../../components/Quotes";

import { ResponsiveTypography } from "../../components/shared";
import MainWrapper from "../../components/shared/MainWrapper";
import { useRouteQuery } from "../../functions";
import { permissionAccess } from "../../functions/permissionAccess";
import requests from "../../requests";
import strings from "../../strings";
import styles from './style';

const Customer: FC<PageBaseProps> = (props) => {
    const { language, auth } = props;
    const history = useHistory();
    const [pageId, setPageId] = useState<number | undefined>();
    const query = useRouteQuery();

    const [executionsFilters, setExecutionsFilters] = useState<ExecutionFilters>({
        status: [],
        quote: { customer: { id: pageId } },
        scheduledDate_gte: moment().format().split('T')[0],
        scheduledDate_lte: moment().add(14, 'days').format().split('T')[0]
    })
    const [openExecutionsFilters, setOpenExecutionsFilters] = useState<boolean>(false);
    const classes = styles();

    const [onSearch, { data, loading: loadingSearch }] = useLazyQuery(requests.execution.query.SEARCH_EXECUTIONS, {
        fetchPolicy: "network-only",
    });

    const handleSearchExecutions = useCallback((rows: number, page: number, orderBy: ExecutionOrderBy, filters?: ExecutionFilters) => {
        onSearch({
            variables: {
                filters,
                orderBy,
                paginate: {
                    take: rows,
                    skip: Math.max(page, 0) * rows,
                }
            }
        })
    }, [onSearch]);
    const [projectsFilters, setProjectsFilters] = useState<ProjectFilters>({
        status: [],
        ownerId: [],
        quote: { customer: { id: pageId } },
    })
    const [openProjectsFilters, setOpenProjectsFilters] = useState<boolean>(false);

    const [onSearchProjects, { data: projects, loading: loadingSearchProjects }] = useLazyQuery(requests.project.query.SEARCH_PROJECTS, {
        fetchPolicy: "cache-first",
    });

    const handleSearchProjects = useCallback((rows: number, page: number, orderBy: ProjectOrderBy, filters?: ProjectFilters) => {
        onSearchProjects({
            variables: {
                filters,
                orderBy,
                paginate: {
                    take: rows,
                    skip: Math.max(page, 0) * rows,
                }
            }
        })
    }, [onSearchProjects]);

    const [onSearchQuotes, { data: quotes, loading: loadingSearchQuotes }] = useLazyQuery(requests.quote.query.SEARCH_QUOTES, {
        fetchPolicy: "cache-first",
    });

    const handleSearchQuotes = useCallback((rows: number, page: number, orderBy: QuoteOrderBy) => {
        onSearchQuotes({
            variables: {
                filters: {
                    customer: { id: pageId },
                },
                orderBy,
                paginate: {
                    take: rows,
                    skip: Math.max(page, 0) * rows,
                }
            }
        })
    }, [onSearchQuotes, pageId]);

    useEffect(() => setPageId(Number(query.get("id"))), [query, setPageId]);

    useEffect(() => {
        setProjectsFilters(prev => ({
            ...prev,
            quote: { customer: { id: pageId } }
        }))
        setExecutionsFilters(prev => ({
            ...prev,
            quote: { customer: { id: pageId } }
        }))
    }, [pageId]);

    useEffect(() => {
        document.title = `Corsem - ${strings.pages.CUSTOMER(1).label[language]}`;
    }, [language]);

    useEffect(() => {
        if (auth.me)
            if (!permissionAccess(auth.me.data.permissions, 19)) history.replace(strings.pages.FORBIDDEN.url)
    })

    return (
        <MainWrapper>
            <ResponsiveTypography
                className={classes.title}
                growthRate={50}
                fontWeight={800}
                smDown={33}
                mdDown={45}
                lgDown={33}
            >
                {`${strings.pages.CUSTOMER(1).label[language]} #${pageId}`}
            </ResponsiveTypography>

            <QuoteEnhancedTable
                rows={quotes?.searchQuotes?.quotes || []}
                count={quotes?.searchQuotes?.count || 0}
                loading={loadingSearchQuotes}
                language={language}
                handleSearch={handleSearchQuotes}
                title={strings.pages.QUOTES.label[language]}
            />
            <ProjectsEnhancedTable
                rows={projects?.searchProjects?.projects || []}
                count={projects?.searchProjects?.count || 0}
                loading={loadingSearchProjects}
                language={language}
                handleSearch={handleSearchProjects}
                title={strings.pages.PROJECTS.label[language]}
                openFilters={() => setOpenProjectsFilters(true)}
                filters={projectsFilters}
                indexes={projects?.searchExecutions?.index || []}
            />

            <ProjectsTableFilters
                language={language}
                open={openProjectsFilters}
                onClose={() => setOpenProjectsFilters(false)}
                filters={projectsFilters}
                submitFilters={(filters: ProjectFilters) => setProjectsFilters(filters)}
            />

            <ExecutionsEnhancedTable
                rows={data?.searchExecutions?.executions || []}
                count={data?.searchExecutions?.count || 0}
                loading={loadingSearch}
                language={language}
                handleSearch={handleSearchExecutions}
                title={strings.pages.EXECUTIONS.label[language]}
                openFilters={() => setOpenExecutionsFilters(true)}
                filters={executionsFilters}
                indexes={data?.searchExecutions?.index || []}
            />

            <ExecutionsTableFilters
                language={language}
                open={openExecutionsFilters}
                onClose={() => setOpenExecutionsFilters(false)}
                filters={executionsFilters}
                submitFilters={(filters: ExecutionFilters) => setExecutionsFilters(filters)}
            />
        </MainWrapper>
    )
}
export default Customer;