import { useMutation, useQuery } from "@apollo/client";
import { Button, Card, CardActions, CircularProgress, Divider } from "@material-ui/core";
import { Done, Save, Error, Lock } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ServiceForm } from "../../components/Services";
import { ServiceTypeFormValues } from "../../components/Services/types";
import clsx from 'clsx';

import { ResponsiveTypography } from "../../components/shared";
import MainWrapper from "../../components/shared/MainWrapper";
import { useRouteQuery } from "../../functions";
import requests from "../../requests";
import strings from "../../strings";
import styles from './style';
import { permissionAccess } from "../../functions/permissionAccess";
import { useHistory } from "react-router-dom";

const Service: FC<PageBaseProps> = (props) => {
    const classes = styles();
    const { language, auth } = props;
    const [pageId, setPageId] = useState(0);
    const history = useHistory();
    const [buttonStatus, setButtonStatus] = useState<undefined | ButtonStatusType>();
    const { enqueueSnackbar } = useSnackbar();
    const query = useRouteQuery();

    const { data: serviceType, refetch: refetchServiceType } = useQuery(requests.service.query.SEARCH_SERVICE, {
        variables: {
            id: pageId
        },
        fetchPolicy: "no-cache",
    });

    const ServiceTypeForm = useForm<ServiceTypeFormValues>({
        defaultValues: {
            description: "",
            products: false,
            price: false,
            discount: false,
            schedulable: false,
            auxTypes: [],
        }
    });
    const { handleSubmit, unregister, setValue, watch, getValues } = ServiceTypeForm;

    const onChangeAuxTypesNumber = useCallback((index?: number) => {
        let currentAuxTypes = getValues('auxTypes');
        if (typeof index === 'number') {
            const lastItemIndex = currentAuxTypes.length - 1;
            for (let i = index; i < currentAuxTypes.length - 1; i++) {
                const nextValue = getValues(`auxTypes.${i + 1}` as any);
                setValue(`auxTypes.${i}` as any, nextValue);
            }
            unregister(`auxTypes.${lastItemIndex}` as any);
        }
        else {
            const newItemIndex = currentAuxTypes?.length || 0;
            setValue(`auxTypes.${newItemIndex}.description` as any, "")
            setValue(`auxTypes.${newItemIndex}.discount` as any, false)
            setValue(`auxTypes.${newItemIndex}.project` as any, false)
            setValue(`auxTypes.${newItemIndex}.price` as any, false)
        }
    }, [setValue, getValues, unregister]);

    const handleChangeField = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, checked?: boolean) => {
        const { name, value } = event.target;
        setValue(name as any, typeof checked === 'boolean' ? checked : value);
    }, [setValue])


    const onSubmit: SubmitHandler<ServiceTypeFormValues> = (data, event) => {
        event?.preventDefault();
        // alert(JSON.stringify(data))
        updateServiceType({
            variables: {
                id: pageId,
                ...data,
            }
        }).then(() => {
            enqueueSnackbar(
                strings.notifications.SERVICE_UPDATE_SUCCESS[language],
                {
                    variant: "success",
                }
            );
            refetchServiceType();
            setButtonStatus('SUCCESS');
            setTimeout(() => {
                setButtonStatus(undefined);
            }, 2000);
        }).catch((error: any) => {
            setButtonStatus('ERROR');
            setTimeout(() => {
                setButtonStatus(undefined);
            }, 2000);
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }

    const onSubmitDeactivate: SubmitHandler<ServiceTypeFormValues> = (data, event) => {
        event?.preventDefault();
        // alert(JSON.stringify(data))
        // alert(data.status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE')
        data.status === 'INACTIVE' ? data.status = 'ACTIVE' : data.status = 'INACTIVE';
        updateServiceType({
            variables: {
                id: pageId,
                ...data,
            }
        }).then(() => {
            enqueueSnackbar(
                strings.notifications.SERVICE_UPDATE_SUCCESS[language],
                {
                    variant: "success",
                }
            );
            refetchServiceType();
        }).catch((error: any) => {
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }

    const [
        updateServiceType,
        { loading: loadingUpdateServiceType }
    ] = useMutation(requests.service.mutation.UPDATE_SERVICE_TYPE);

    useEffect(() => {
        document.title = `Corsem - ${strings.pages.SERVICES.label[language]}`;
    }, [language]);

    useEffect(() => {
        if (auth.me)
            if (!permissionAccess(auth.me.data.permissions, 23)) history.replace(strings.pages.FORBIDDEN.url)
    })

    useEffect(() => {
        if (serviceType?.searchServiceType) {
            serviceType.searchServiceType.auxTypes.forEach((item: any, index: number) => {
                setValue(`auxTypes.${index}.id` as any, item.id);
                setValue(`auxTypes.${index}.description` as any, item.description.description);
                setValue(`auxTypes.${index}.price` as any, item.price);
                setValue(`auxTypes.${index}.discount` as any, item.discount);
                setValue(`auxTypes.${index}.project` as any, item.project);
                setValue(`auxTypes.${index}.status` as any, item.status);
            });
            setValue('description', serviceType.searchServiceType.description.description);
            setValue('status', serviceType.searchServiceType.status);
            setValue('products', serviceType.searchServiceType.products);
            setValue('price', serviceType.searchServiceType.price);
            setValue('discount', serviceType.searchServiceType.discount);
            setValue('schedulable', serviceType.searchServiceType.schedulable);
        }
    }, [serviceType, setValue]);

    useEffect(() => setPageId(Number(query.get("id"))), [query]);


    const buttonClassname = clsx({
        [classes.buttonStd]: true,
        [classes.buttonSuccess]: buttonStatus === 'SUCCESS',
        [classes.buttonError]: buttonStatus === 'ERROR',
    });

    return (
        <MainWrapper>
            <ResponsiveTypography
                className={classes.title}
                growthRate={50}
                fontWeight={800}
                smDown={33}
                mdDown={45}
                lgDown={33}
            >
                {`${strings.pages.SERVICES.label[language]} - ${serviceType?.searchServiceType?.category}`}
            </ResponsiveTypography>

            <ResponsiveTypography
                growthRate={50}
                fontWeight={300}
                smDown={12}
                mdDown={20}
                lgDown={12}
            >
                {strings.pages.SERVICES.sublabel[language]}
            </ResponsiveTypography>

            <Card elevation={5} className={classes.root_paper}>
                <ServiceForm
                    language={language}
                    loading={loadingUpdateServiceType || watch('status') === 'INACTIVE'}
                    handleChangeField={handleChangeField}
                    useForm={ServiceTypeForm}
                    onChangeAuxTypesNumber={onChangeAuxTypesNumber}
                    type='EDIT'
                />
                <Divider className={classes.divider} />
                <CardActions className={classes.actions}>
                    <div className={classes.wrapper}>
                        <Button
                            color="secondary"
                            variant={buttonStatus === 'SUCCESS' ? "contained" : "outlined"}
                            disabled={loadingUpdateServiceType || serviceType?.searchServiceType.status === 'INACTIVE'}
                            className={buttonClassname}
                            onClick={handleSubmit(onSubmit)}
                        >
                            {buttonStatus === undefined && <Save fontSize="small" className={classes.leftIcon} />}
                            {buttonStatus === 'ERROR' && <Error fontSize="small" className={classes.leftIcon} />}
                            {buttonStatus === 'SUCCESS' && <Done fontSize="small" className={classes.leftIcon} />}
                            {strings.actions.SAVE[language]}
                        </Button>
                        {loadingUpdateServiceType && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                    <div className={classes.wrapper}>
                        <Button
                            variant='outlined'
                            disabled={loadingUpdateServiceType}
                            className={clsx(watch('status') === 'ACTIVE' && classes.deleteButton,
                                watch('status') === 'INACTIVE' && classes.restoreButton)}
                            onClick={handleSubmit(onSubmitDeactivate)}
                        >
                            <Lock fontSize="small" className={classes.leftIcon} />
                            {strings.actions.ACTIVATE_DEACTIVATE(watch('status') === 'ACTIVE')[language]}
                        </Button>
                    </div>
                </CardActions>
            </Card>

        </MainWrapper>
    )
}
export default Service;