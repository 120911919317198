import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        borderTopLeftRadius: '20px',
        padding: theme.spacing(7),
        boxShadow: `inset 5px 5px 10px ${theme.palette.common.black}20`,
        minHeight: 'calc(100vh - 95px)',
        backgroundColor: theme.palette.type === 'light' ? '#fafbfd' : theme.palette.background.paper,
        [theme.breakpoints.down('md')]: {
            borderRadius: 0,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '8px',
            minHeight: 'calc(100vh - 64px)',
            padding: theme.spacing(3),
        },
    }
}));