export const numberWithCommas = (x: number | undefined, y?: number | undefined) => {
    return x ?
        y
            ? x.toFixed(y).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : '-';
};

export const convertFileSize = (x: number | undefined) => {
    if (x)
        if (x > Math.pow(1024, 2)) return `${(x / Math.pow(1024, 2)).toFixed(2)} mb`;
        else if (x > 1024) return `${(x / 1024).toFixed(2)} kb`
        else if (x > 1) return `${x} bytes`
        else if (x === 1) return `1 byte`
        else return `0 bytes`
}