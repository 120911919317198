import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    rootMargin: {
        marginTop: theme.spacing(8),
    }
}));