import gql from "graphql-tag";

const SEARCH_REGIONS = gql`
    query onSearchRegions {
        searchRegions {
            id
            region
        }
    }
`;

const SEARCH_CITIES = gql`
    query onSearchCities($regionId: Int!) {
        searchCities(regionId: $regionId) {
            id
            city
            regionId
        }
    }
`;

export const general = {
    query: {
        SEARCH_REGIONS,
        SEARCH_CITIES,
    },
    mutation: {
    },
    subscription: {
    }
}