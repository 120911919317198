import {
    AddCircleRounded as CreateIcon,
    VisibilityRounded as ReadIcon,
    EditRounded as UpdateIcon,
    DeleteRounded as DeleteIcon,
    VerifiedUserRounded as AdminIcon,
    PeopleRounded as UsersIcon,
    LocalOfferRounded as QuotesIcon,
    SquareFootRounded as PlannerIcon,
    LocalShippingRounded as FleetIcon,
    BuildRounded as ExecutionIcon,
    ClassRounded as ServiceIcon,
    CategoryRounded as ProductIcon,
    PeopleAltRounded as CustomerIcon,
} from "@material-ui/icons";

export const CREATE = {
    ro: "Creare",
    en: "Create",
};
export const READ = {
    ro: "Vizualizare",
    en: "Read",
};
export const UPDATE = {
    ro: "Editare",
    en: "Update",
};
export const DELETE = {
    ro: "Ștergere",
    en: "Delete",
};
export const ADMIN = {
    ro: "Administrator",
    en: "Admin",
};
export const PLANNER = {
    ro: "Proiectant",
    en: "Planner",
};
export const USERS = {
    ro: "Utilizatori",
    en: "Users",
};
export const QUOTES = {
    ro: "Oferte",
    en: "Quotes",
};
export const EXECUTIONS = {
    ro: "Execuții",
    en: "Executions",
};
export const PROJECTS = {
    ro: "Proiecte",
    en: "Projects",
};
export const CUSTOMERS = {
    ro: "Clienți",
    en: "Customers",
};
export const SERVICES = {
    ro: "Servicii",
    en: "Services",
};
export const PRODUCTS = {
    ro: "Produse",
    en: "Products",
};
export const FLEET = {
    ro: "Flotă",
    en: "Fleet",
};


export const permissions = (language: LanguageType) => [
    {
        index: 0,
        labelText: ADMIN[language],
        labelIcon: AdminIcon,
    },
    {
        index: 15,
        labelText: PLANNER[language],
        labelIcon: PlannerIcon,
    },
    {
        labelText: USERS[language],
        labelIcon: UsersIcon,
        permissions: [
            {
                index: 2,
                labelText: CREATE[language],
                labelIcon: CreateIcon,
                color: "#1a73e8",
                bgColor: "#e8f0fe",
            },
            {
                index: 3,
                labelText: READ[language],
                labelIcon: ReadIcon,
                color: "#3c8039",
                bgColor: "#e6f4ea",
            },
            {
                index: 4,
                labelText: UPDATE[language],
                labelIcon: UpdateIcon,
                color: "#e3742f",
                bgColor: "#fcefe3",
            },
            {
                index: 5,
                labelText: DELETE[language],
                labelIcon: DeleteIcon,
                color: "#cc1d1d",
                bgColor: "#f4e6e6",
            },
        ]
    },
    {
        labelText: QUOTES[language],
        labelIcon: QuotesIcon,
        permissions: [
            {
                index: 6,
                labelText: CREATE[language],
                labelIcon: CreateIcon,
                color: "#1a73e8",
                bgColor: "#e8f0fe",
            },
            {
                index: 7,
                labelText: READ[language],
                labelIcon: ReadIcon,
                color: "#3c8039",
                bgColor: "#e6f4ea",
            },
            {
                index: 8,
                labelText: UPDATE[language],
                labelIcon: UpdateIcon,
                color: "#e3742f",
                bgColor: "#fcefe3",
            },
            {
                index: 9,
                labelText: DELETE[language],
                labelIcon: DeleteIcon,
                color: "#cc1d1d",
                bgColor: "#f4e6e6",
            },
        ]
    },
    {
        labelText: EXECUTIONS[language],
        labelIcon: ExecutionIcon,
        permissions: [
            {
                index: 10,
                labelText: CREATE[language],
                labelIcon: CreateIcon,
                color: "#1a73e8",
                bgColor: "#e8f0fe",
            },
            {
                index: 11,
                labelText: READ[language],
                labelIcon: ReadIcon,
                color: "#3c8039",
                bgColor: "#e6f4ea",
            },
            {
                index: 12,
                labelText: UPDATE[language],
                labelIcon: UpdateIcon,
                color: "#e3742f",
                bgColor: "#fcefe3",
            },
            {
                index: 13,
                labelText: DELETE[language],
                labelIcon: DeleteIcon,
                color: "#cc1d1d",
                bgColor: "#f4e6e6",
            },
        ]
    },
    {
        labelText: PROJECTS[language],
        labelIcon: PlannerIcon,
        permissions: [
            {
                index: 14,
                labelText: CREATE[language],
                labelIcon: CreateIcon,
                color: "#1a73e8",
                bgColor: "#e8f0fe",
            },
            {
                index: 15,
                labelText: READ[language],
                labelIcon: ReadIcon,
                color: "#3c8039",
                bgColor: "#e6f4ea",
            },
            {
                index: 16,
                labelText: UPDATE[language],
                labelIcon: UpdateIcon,
                color: "#e3742f",
                bgColor: "#fcefe3",
            },
            {
                index: 17,
                labelText: DELETE[language],
                labelIcon: DeleteIcon,
                color: "#cc1d1d",
                bgColor: "#f4e6e6",
            },
        ]
    },
    {
        labelText: CUSTOMERS[language],
        labelIcon: CustomerIcon,
        permissions: [
            {
                index: 18,
                labelText: CREATE[language],
                labelIcon: CreateIcon,
                color: "#1a73e8",
                bgColor: "#e8f0fe",
            },
            {
                index: 19,
                labelText: READ[language],
                labelIcon: ReadIcon,
                color: "#3c8039",
                bgColor: "#e6f4ea",
            },
            {
                index: 20,
                labelText: UPDATE[language],
                labelIcon: UpdateIcon,
                color: "#e3742f",
                bgColor: "#fcefe3",
            },
            {
                index: 21,
                labelText: DELETE[language],
                labelIcon: DeleteIcon,
                color: "#cc1d1d",
                bgColor: "#f4e6e6",
            },
        ]
    },
    {
        labelText: SERVICES[language],
        labelIcon: ServiceIcon,
        permissions: [
            {
                index: 22,
                labelText: CREATE[language],
                labelIcon: CreateIcon,
                color: "#1a73e8",
                bgColor: "#e8f0fe",
            },
            {
                index: 23,
                labelText: READ[language],
                labelIcon: ReadIcon,
                color: "#3c8039",
                bgColor: "#e6f4ea",
            },
            {
                index: 24,
                labelText: UPDATE[language],
                labelIcon: UpdateIcon,
                color: "#e3742f",
                bgColor: "#fcefe3",
            },
            {
                index: 25,
                labelText: DELETE[language],
                labelIcon: DeleteIcon,
                color: "#cc1d1d",
                bgColor: "#f4e6e6",
            },
        ]
    },
    {
        labelText: PRODUCTS[language],
        labelIcon: ProductIcon,
        permissions: [
            {
                index: 26,
                labelText: CREATE[language],
                labelIcon: CreateIcon,
                color: "#1a73e8",
                bgColor: "#e8f0fe",
            },
            {
                index: 27,
                labelText: READ[language],
                labelIcon: ReadIcon,
                color: "#3c8039",
                bgColor: "#e6f4ea",
            },
            {
                index: 28,
                labelText: UPDATE[language],
                labelIcon: UpdateIcon,
                color: "#e3742f",
                bgColor: "#fcefe3",
            },
            {
                index: 29,
                labelText: DELETE[language],
                labelIcon: DeleteIcon,
                color: "#cc1d1d",
                bgColor: "#f4e6e6",
            },
        ]
    },
    {
        labelText: FLEET[language],
        labelIcon: FleetIcon,
        permissions: [
            {
                index: 30,
                labelText: CREATE[language],
                labelIcon: CreateIcon,
                color: "#1a73e8",
                bgColor: "#e8f0fe",
            },
            {
                index: 31,
                labelText: READ[language],
                labelIcon: ReadIcon,
                color: "#3c8039",
                bgColor: "#e6f4ea",
            },
            {
                index: 32,
                labelText: UPDATE[language],
                labelIcon: UpdateIcon,
                color: "#e3742f",
                bgColor: "#fcefe3",
            },
            {
                index: 33,
                labelText: DELETE[language],
                labelIcon: DeleteIcon,
                color: "#cc1d1d",
                bgColor: "#f4e6e6",
            },
        ]
    },
]