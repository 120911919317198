import { ChangeEvent, FC, useCallback } from "react";
import { Box, Grid } from "@material-ui/core";

import { ServiceDetailsProps } from "../types";
import styles from './style';
import { SearchService, ServicePreview } from "../../Service";
import { AutocompleteChangeReason } from "@material-ui/lab";
import { ServiceTypeProps } from "../../Service/types";
import { CustomWrapper } from "../../shared";
import { QuoteFormValues, ServiceFormValues } from "../../../Views/NewQuote/types";

const ServiceDetails: FC<ServiceDetailsProps> = (props) => {
    const classes = styles();
    const { language, formErrors, formData, setFormData } = props;

    const onHandleSelectService = (_: React.ChangeEvent<{}>, value: ServiceTypeProps, reason: AutocompleteChangeReason) => {
        if (reason === 'select-option') {
            setFormData(prevData => ({
                ...prevData,
                services: prevData?.services ?
                    [...prevData?.services, { typeId: value.id }]
                    : [{ typeId: value.id }]
            } as QuoteFormValues))
        }
        else return;
    }
    const handleDeleteService = useCallback((index: number) => {
        setFormData(prevData => ({
            ...prevData,
            services: prevData?.services && prevData.services.filter((_, i) => i !== index)
        } as QuoteFormValues))
    }, [setFormData])

    const handleSelectAux = (index: number, value: number) => {
        let newServices = [...formData?.services as ServiceFormValues[]].map((item, i) => {
            if (index !== i) return item;
            else return ({
                ...item,
                auxs: item.auxs ? [...item.auxs, { typeId: value }] : [{ typeId: value }]
            })
        })
        setFormData(prevData => ({
            ...prevData, services: newServices
        } as QuoteFormValues));
    }
    const handleDeleteAux = useCallback((index: number, auxIndex: number) => {
        setFormData(prevData => ({
            ...prevData,
            services: prevData?.services && prevData.services.map((service, sIndex) => {
                if (sIndex === index) return ({ ...service, auxs: service.auxs.filter((_, aIndex) => aIndex !== auxIndex) })
                else return service;
            })
        } as QuoteFormValues))
    }, [setFormData])

    const handleChangeServiceDataField = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number) => {
        const { name, value } = event.target;
        let newServices = [...formData?.services as ServiceFormValues[]].map((item, i) => {
            if (index !== i) return item;
            else return ({
                ...item,
                [name]: name === 'price' || name === 'discount' ? parseFloat(parseFloat(value).toFixed(2)) || 0 : value,
            })
        })
        setFormData(prevData => ({
            ...prevData, services: newServices
        } as QuoteFormValues));
    }, [setFormData, formData?.services])

    const handleChangeAuxDataField = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number, sIndex: number) => {
        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData, services: [...prevData?.services as ServiceFormValues[]].map((service, i) => {
                if (i !== sIndex) return service;
                else return ({
                    ...service,
                    auxs: [...service.auxs].map((aux, j) => {
                        if (j !== index) return aux;
                        else return ({
                            ...aux,
                            [name]: name === 'price' || name === 'discount' ? parseFloat(parseFloat(value).toFixed(2)) || 0 : value,
                        })
                    })
                })
            })
        } as QuoteFormValues));
    }, [setFormData])

    const handleSelectProd = (_: React.ChangeEvent<{}>, value: ServiceTypeProps, reason: AutocompleteChangeReason, index: number) => {
        if (reason === 'select-option') {
            setFormData(prevData => ({
                ...prevData,
                services: [...prevData?.services as ServiceFormValues[]].map((service, sIndex) => {
                    if (sIndex === index) return ({ ...service, products: service.products ? [...service.products, { productId: value.id, amount: 1 }] : [{ productId: value.id, amount: 1 }] })
                    else return service;
                })
            } as QuoteFormValues))
        }
        else return;
    }
    const handleDeleteProd = useCallback((index: number, prodIndex: number) => {
        setFormData(prevData => ({
            ...prevData,
            services: prevData?.services && prevData.services.map((service, sIndex) => {
                if (sIndex === index) return ({ ...service, products: service.products.filter((_, pIndex) => pIndex !== prodIndex) })
                else return service;
            })
        } as QuoteFormValues))
    }, [setFormData])


    const handleChangeProdDataField = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, index: number, sIndex: number) => {
        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData, services: [...prevData?.services as ServiceFormValues[]].map((service, i) => {
                if (i !== sIndex) return service;
                else return ({
                    ...service,
                    products: [...service.products].map((prod, j) => {
                        if (j !== index) return prod;
                        else return ({
                            ...prod,
                            [name]: name === 'amount' ? parseInt(value) || 1 : value,
                        })
                    })
                })
            })
        } as QuoteFormValues));
    }, [setFormData])


    return (
        <Box className={classes.root}>
            <Box style={{ display: 'flex' }}>
                <SearchService
                    language={language}
                    onHandleSelectService={onHandleSelectService}
                />
            </Box>
            <Grid container spacing={2} alignContent='stretch' style={{ marginTop: '8px' }}>
                {formData?.services && formData.services.map((service: ServiceFormValues, index: number) =>
                    <Grid key={index} item xs={12} md={12} lg={12}>
                        <CustomWrapper>
                            <ServicePreview
                                index={index}
                                serviceData={service}
                                formErrors={formErrors}
                                handleChangeField={handleChangeServiceDataField}
                                handleAuxChangeField={handleChangeAuxDataField}
                                handleDeleteService={handleDeleteService}
                                handleSelectAux={handleSelectAux}
                                handleDeleteAux={handleDeleteAux}
                                handleSelectProd={handleSelectProd}
                                handleDeleteProd={handleDeleteProd}
                                handleProdChangeField={handleChangeProdDataField}
                                language={language}
                            />
                        </CustomWrapper>
                    </Grid>
                )}
            </Grid>
        </Box >
    )
}
export default ServiceDetails;