import {
    ChangeEvent,
    FC,
    KeyboardEvent,
    useState,
    createRef,
    useEffect,
    useRef,
} from 'react'
import {
    Avatar,
    Box,
    Fab,
    Grow,
    InputAdornment,
    LinearProgress,
    TextField,
    Tooltip,
} from '@material-ui/core'
import axios from 'axios'
import clsx from 'clsx'

import styles from './style'
import ResponsiveTypography from '../ResponsiveTypography'
import strings from '../../../strings'
import { useMutation, useQuery } from '@apollo/client'
import requests from '../../../requests'
import { CommentProps } from './types'
import moment from 'moment'
import {
    ExpandLessRounded,
    SendRounded,
    AttachFile,
    ClearRounded,
} from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import CustomModal from '../../shared/CustomModal'
import { convertFileSize } from '../../../functions/numberFunctions'

// icons
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

type CommentBoxProps = {
    language: LanguageType
    category: CommentType
    id: number
    me: AuthUser
    refetchTrigger?: any
}

const CommentBox: FC<CommentBoxProps> = (props) => {
    const classes = styles()
    const { enqueueSnackbar } = useSnackbar()
    const [message, setMessage] = useState('')
    // const [fileAddress, setFileAddress] = useState('')
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const { language, id, category, me, refetchTrigger } = props

    const { data, refetch } = useQuery(requests.comment.query.SEARCH_COMMENTS, {
        fetchPolicy: 'network-only',
        variables: {
            id,
            category,
        },
    })

    const [createComment, { loading: loadingCreateComment }] = useMutation(
        requests.comment.mutation.CREATE_COMMENT
    )

    const onSubmitComment = (fileAddress: string) => {
        setLoading(true)
        createComment({
            variables: {
                id,
                category,
                message,
                fileAddress,
            },
        })
            .then(() => {
                setTimeout(() => {
                    // added
                    setFile(null)
                    setLoading(false)
                    setMessage('')
                }, 500)
            })
            .catch((error) => {
                enqueueSnackbar(
                    error.message in strings.notifications
                        ? strings.notifications[
                              error.message as GlobalNotification
                          ][language]
                        : error.message,
                    {
                        variant: 'error',
                    }
                )
            })
    }

    const handleChangeMessage = (
        event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        const { value } = event.target
        setMessage(value)
    }

    const handleKeyDown = (
        event: KeyboardEvent<HTMLDivElement> | undefined
    ) => {
        if (event && event.ctrlKey && event.keyCode === 13) {
            // onSubmitComment()
        }
    }

    useEffect(() => {
        if (refetch) refetch()
    }, [refetchTrigger, refetch])

    useEffect(() => {
        if (data?.searchComments) setCount(data.searchComments.length)
    }, [data])

    // file test
    const [file, setFile] = useState<FileList | null>(null)

    const removeFile = () => {
        if (file) {
            setFile(null)
        }
    }

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target
        if (!file && files) {
            setFile(files)
        }
    }

    const onSubmit = async () => {
        try {
            if (file) {
                const formData = new FormData()

                // console.log('This is the file', file)
                // @ts-ignore
                formData.append('file', file ? file[0] : null)
                const response = await axios.post(
                    process.env.REACT_APP_REST_API + 'comment_file',
                    formData
                )

                if (response.data.fileName) {
                    onSubmitComment(response.data.fileName)
                } else {
                    throw new Error('File upload failed')
                }
            } else {
                onSubmitComment('')
            }
        } catch (error) {
            console.error(error)
        }
    }

    // end file test

    console.log(data)

    return (
        <Box className={classes.root}>
            <ResponsiveTypography
                className={classes.title}
                fontWeight={600}
                growthRate={50}
                lgDown={20}
                mdDown={20}
                smDown={20}
            >
                {`${strings.general.COMMENTS[language]}${
                    count ? ` - ${count}` : ''
                }`}
            </ResponsiveTypography>
            <Box className={classes.commentsBox}>
                {data?.searchComments && data?.searchComments.length > 0 ? (
                    data?.searchComments.map(
                        (comment: CommentProps, index: number) => (
                            <div key={index} className={classes.divWrapper}>
                                {index > 0 && (
                                    <ExpandLessRounded
                                        className={classes.arrowIcon}
                                    />
                                )}
                                <CommentPreview
                                    key={index}
                                    comment={comment}
                                    language={language}
                                    me={me.data.id === comment.author.id}
                                />
                            </div>
                        )
                    )
                ) : (
                    <ResponsiveTypography
                        fontWeight={300}
                        growthRate={50}
                        lgDown={15}
                        mdDown={15}
                        smDown={15}
                    >
                        {`${strings.general.NO_COMMENTS[language]}`}
                    </ResponsiveTypography>
                )}
            </Box>
            <Box className={classes.message_box}>
                <Grow in={loadingCreateComment || loading}>
                    <LinearProgress
                        color='secondary'
                        className={classes.progressbar}
                    />
                </Grow>
                <TextField
                    fullWidth
                    multiline
                    placeholder={strings.actions.ADD_COMMENT[language]}
                    variant='outlined'
                    value={message}
                    disabled={loadingCreateComment || loading}
                    onChange={handleChangeMessage}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position='end'
                                className={classes.messagebox_button}
                            >
                                {/* file test */}
                                <input
                                    type='file'
                                    name='zip'
                                    id='zip'
                                    accept='.zip, .rar, .7zip, .png, .jpeg, .jpg, .pdf'
                                    onChange={handleFileChange}
                                    // value={file}
                                    style={{
                                        display: 'none',
                                    }}
                                />
                                {file ? (
                                    <>
                                        <ResponsiveTypography
                                            className={classes.fileName}
                                        >
                                            {file?.[0].name}
                                        </ResponsiveTypography>
                                        <Fab
                                            component='span'
                                            size='small'
                                            color='primary'
                                            onClick={removeFile}
                                        >
                                            <ClearRounded fontSize='small' />
                                        </Fab>
                                    </>
                                ) : (
                                    <label htmlFor='zip'>
                                        <Fab
                                            component='span'
                                            size='small'
                                            color='primary'
                                        >
                                            <AttachFile fontSize='small' />
                                        </Fab>
                                    </label>
                                )}
                                {/* test end */}

                                <Fab
                                    size='small'
                                    color='secondary'
                                    onClick={onSubmit}
                                    disabled={
                                        loadingCreateComment ||
                                        loading ||
                                        !message
                                    }
                                >
                                    <SendRounded fontSize='small' />
                                </Fab>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Box>
    )
}
export default CommentBox

type CommentPreviewProps = {
    language: LanguageType
    comment: CommentProps
    me: boolean
}

const CommentPreview: FC<CommentPreviewProps> = (props) => {
    const classes = styles()
    const history = useHistory()
    const { comment, me } = props

    const handleFileDownload = (fileName: string) => {
        window.open(
            process.env.REACT_APP_REST_API + 'get_comment_file/' + fileName,
            '_blank'
        )
    }

    const handleFileType = (fileName: string) => {
        const fileType = fileName.split('.').pop()
        if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
            return 'image'
        } else if (fileType === 'pdf') {
            return 'pdf'
        } else if (
            fileType === 'zip' ||
            fileType === 'rar' ||
            fileType === '7zip'
        ) {
            return 'zip'
        } else {
            return 'file'
        }
    }

    return (
        <>
            <Box
                className={clsx(
                    classes.comment_root,
                    me && classes.comment_root_self,
                    comment.sys && classes.comment_root_sys
                )}
            >
                {!me && (
                    <Avatar
                        src={`/svg/avatars/avatar-0${comment.author.avatar}.svg`}
                    />
                )}
                <Box className={classes.comment_text}>
                    <Box className={classes.comment_title}>
                        {(!me || comment.sys) && (
                            <Box
                                className={classes.comment_user}
                                onClick={() =>
                                    history.push(
                                        strings.pages.USER(comment.author.id)
                                            .url
                                    )
                                }
                            >
                                <ResponsiveTypography
                                    fontWeight={600}
                                    growthRate={50}
                                    lgDown={15}
                                    mdDown={15}
                                    smDown={15}
                                >
                                    {`${comment.author.profile.firstName} ${comment.author.profile.lastName[0]}.`}
                                </ResponsiveTypography>
                            </Box>
                        )}
                    </Box>
                    <ResponsiveTypography
                        className={classes.message_text}
                        fontWeight={400}
                        growthRate={50}
                        lgDown={13}
                        mdDown={13}
                        smDown={13}
                    >
                        {comment.sys
                            ? comment.message
                                  .split(' ')
                                  .map((word, index) =>
                                      index % 2 === 0 ? (
                                          <b key={index}>{` ${word} `}</b>
                                      ) : (
                                          word
                                      )
                                  )
                            : comment.message}
                    </ResponsiveTypography>
                    <Box className={clsx(me && classes.timestamp_self)}>
                        <Tooltip
                            enterDelay={500}
                            placement='right'
                            title={moment(comment.createdAt).format(
                                'dddd, MMMM Do YYYY, h:mm:ss A'
                            )}
                        >
                            <Box>
                                <ResponsiveTypography
                                    fontWeight={300}
                                    growthRate={50}
                                    lgDown={10}
                                    mdDown={10}
                                    smDown={10}
                                >
                                    {moment(comment.createdAt).fromNow()}
                                </ResponsiveTypography>
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
            {comment.fileAddress && (
                <Box
                    className={clsx(
                        classes.comment_root_file,
                        me && classes.comment_root_self,
                        comment.sys && classes.comment_root_sys
                    )}
                >
                    <span
                        onClick={() => handleFileDownload(comment.fileAddress)}
                        className={classes.comment_root_file_text}
                    >
                        {handleFileType(comment.fileAddress) === 'image' ? (
                            // image
                            <img
                                src={
                                    process.env.REACT_APP_REST_API +
                                    'get_comment_file/' +
                                    comment.fileAddress
                                }
                                alt=''
                            />
                        ) : handleFileType(comment.fileAddress) === 'pdf' ? (
                            // pdf
                            comment.fileAddress.slice(37)
                        ) : handleFileType(comment.fileAddress) === 'zip' ? (
                            // zip
                            comment.fileAddress.slice(37)
                        ) : handleFileType(comment.fileAddress) === 'file' ? (
                            // other
                            comment.fileAddress.slice(37)
                        ) : null}
                    </span>
                </Box>
            )}
        </>
    )
}
