import * as notifications from './notifications';
import * as pages from './pages';
import * as general from './general';
import * as actions from './actions';
import * as columns from './columns';
import * as settings from './settings';
import * as customer from './customer';
import * as permissions from './permissions';
import * as priceHistory from './priceHistory'

const strings = {
    notifications,
    pages,
    general,
    actions,
    columns,
    settings,
    customer,
    permissions,
    priceHistory
}

export default strings;