import { FC, useCallback, useEffect, useState } from "react";
import { ProductPreviewProps, ProductTypeProps } from "../types";
import styles from './style';
import strings from "../../../strings";
import { Box, Divider, Grid, Hidden, IconButton, TextField, Theme, useMediaQuery } from "@material-ui/core";
import { ResponsiveTypography } from "../../shared";
import { Delete } from "@material-ui/icons";
import { useLazyQuery } from "@apollo/client";
import requests from "../../../requests";
import { InputAdornment } from "@material-ui/core";

const ProductPreview: FC<ProductPreviewProps> = (props) => {
    const classes = styles();
    const { language, index, productData, handleDeleteProd, handleChangeField } = props;
    const [productTypeData, setProductTypeData] = useState<ProductTypeProps>();

    const [onSearchProduct, { data }] = useLazyQuery(requests.product.query.SEARCH_PRODUCT, {
        fetchPolicy: "cache-first",
    });

    const handleSearcProduct = useCallback(() => {
        onSearchProduct({
            variables: {
                id: productData.productId,
            }
        })
    }, [onSearchProduct, productData.productId]);

    useEffect(() => {
        handleSearcProduct();
    }, [handleSearcProduct]);

    useEffect(() => {
        if (data?.searchProduct) {
            setProductTypeData(data?.searchProduct);
        }
    }, [data])

    const isDownXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

    return (
        <Box className={classes.root}>
            <ResponsiveTypography
                className={classes.title}
                fontWeight={600}
                growthRate={50}
                lgDown={15}
                mdDown={20}
                smDown={15}
            >
                {productTypeData && `#${index + 1} ${productTypeData.category.toUpperCase()} ${productTypeData.manufacturer} ${productTypeData.model}`}
                <IconButton className={classes.deleteButton} onClick={handleDeleteProd} size='small'>
                    <Delete fontSize='small' />
                </IconButton>
            </ResponsiveTypography>
            <Divider className={classes.dividerTitle} />
            <Grid container className={classes.content} direction={isDownXs ? 'column' : 'row'} alignItems={isDownXs ? 'stretch' : 'center'} justify='center' alignContent='center' spacing={2}>
                <Grid item xs={12} sm={6} lg={2} style={{ display: 'flex', justifyContent: 'center' }}>
                    <img alt='Product' src={productTypeData?.image} style={{ width: '50%', minWidth: 100, maxWidth: 150 }} />
                </Grid>
                <Hidden mdDown>
                    <Grid item lg={2} />
                </Hidden>
                <Grid item xs={12} sm={6} lg={4}>
                    <TextField
                        required
                        fullWidth
                        variant='outlined'
                        label={strings.columns.AMOUNT[language]}
                        name='amount'
                        value={productData.amount || 1}
                        onChange={(e) => handleChangeField(e, index)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{strings.general.PIECES[language]}</InputAdornment>,
                        }}
                    />
                </Grid>
            </Grid>
        </Box >
    )
}
export default ProductPreview;