import { useLazyQuery } from "@apollo/client";
import { Button } from "@material-ui/core";
import { useState } from "react";
import { FC, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { EnhancedTable, AddSection, AddTypeSection } from "../../components/Products/";

import { ResponsiveTypography } from "../../components/shared";
import MainWrapper from "../../components/shared/MainWrapper";
import { permissionAccess } from "../../functions/permissionAccess";
import requests from "../../requests";
import strings from "../../strings";
import styles from './style';

const Services: FC<PageBaseProps> = (props) => {
    const { language, auth } = props;
    const classes = styles();
    const history = useHistory();
    const [addExpanded, setAddExpanded] = useState<boolean>(false)
    const [addTypeExpanded, setAddTypeExpanded] = useState<boolean>(false)

    const toggleAddExpand = useCallback(() => {
        setAddExpanded(!addExpanded);
        setAddTypeExpanded(false);
    }, [addExpanded]);

    const toggleAddTypeExpand = useCallback(() => {
        setAddTypeExpanded(!addTypeExpanded);
        setAddExpanded(false);
    }, [addTypeExpanded]);

    const [onSearchProducts, { data, loading: loadingSearchProducts, refetch: refetchProducts }] = useLazyQuery(requests.product.query.SEARCH_PRODUCTS, {
        fetchPolicy: "network-only",
    });

    const handleSearchProducts = useCallback((rows: number, page: number, orderBy: ProductOrderBy) => {
        onSearchProducts({
            variables: {
                orderBy,
                paginate: {
                    take: rows,
                    skip: Math.max(page, 0) * rows,
                }
            }
        })
    }, [onSearchProducts]);

    useEffect(() => {
        document.title = `Corsem - ${strings.pages.PRODUCTS.label[language]}`;
    }, [language]);

    useEffect(() => {
        if (auth.me)
            if (!permissionAccess(auth.me.data.permissions, 27)) history.replace(strings.pages.FORBIDDEN.url)
    })

    return (
        <MainWrapper>
            <ResponsiveTypography
                className={classes.title}
                growthRate={50}
                fontWeight={800}
                smDown={33}
                mdDown={45}
                lgDown={33}
            >
                {strings.pages.PRODUCTS.label[language]}
            </ResponsiveTypography>

            <ResponsiveTypography
                growthRate={50}
                fontWeight={300}
                smDown={12}
                mdDown={20}
                lgDown={12}
            >
                {strings.pages.PRODUCTS.sublabel[language]}
            </ResponsiveTypography>

            <AddTypeSection
                language={language}
                expanded={addTypeExpanded}
                toggleAction={toggleAddTypeExpand}
            />

            <AddSection
                language={language}
                expanded={addExpanded}
                toggleAction={toggleAddExpand}
                refetchProducts={refetchProducts}
            />

            <EnhancedTable
                rows={data?.searchProducts?.products || []}
                count={data?.searchProducts?.count || 0}
                loading={loadingSearchProducts}
                language={language}
                handleSearch={handleSearchProducts}
                title={strings.pages.PRODUCTS.label[language]}
            />
            <Button 
                variant='contained'
                color='secondary'
                className={classes.addButton}
                onClick={()=> history.push("/productshistory")}
            >
                {strings.pages.HISTORY.label[language]}
               
            </Button>
           
        </MainWrapper>
    )
}
export default Services;