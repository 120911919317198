import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    title: {
        margin: theme.spacing(0, 0, 2, 0),
    },
    addButton: {
        borderRadius: 30,
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
}));