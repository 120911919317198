import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Card, Fab, Grid, LinearProgress, Theme, Tooltip, useMediaQuery } from "@material-ui/core";
import { ArrowForwardIosRounded, CancelRounded, AssignmentIndRounded } from "@material-ui/icons";
import { FC, useCallback, useEffect, useState } from "react";
import clsx from 'clsx';

import { CommentBox, ResponsiveTypography } from "../../components/shared";
import MainWrapper from "../../components/shared/MainWrapper";
import { useRouteQuery } from "../../functions";
import requests from "../../requests";
import strings from "../../strings";
import styles from './style';
import { CustomerSection, ProjectSection, QuoteSection } from "../../components/Quote";
import { useSnackbar } from "notistack";
import CustomModal from "../../components/shared/CustomModal";
import { permissionAccess } from "../../functions/permissionAccess";
import { useHistory } from "react-router-dom";

type CustomerAllType = {
    customer: CustomerProps;
    phone: CustomerPhoneProps;
    business?: CustomerBusinessProps;
}

const getNextStatus = (status: ProjectStatus) => {
    if (status === 'OPEN') return 'STARTED';
    if (status === 'STARTED') return 'CLOSED';
    return;
}

const Project: FC<PageBaseProps> = (props) => {
    const { language, auth } = props;
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [pageId, setPageId] = useState<number | undefined>();
    const [actionModal, setActionModal] = useState<boolean>(false);
    const [assignModal, setAssignModal] = useState<boolean>(false);
    const [meId, setMeId] = useState<number>();
    const query = useRouteQuery();
    const classes = styles();
    const [currentStatus, setCurrentStatus] = useState<ProjectStatus>();
    const [nextStatus, setNextStatus] = useState<ProjectStatus>();
    const [customer, setCustomer] = useState<CustomerAllType>();
    const [shouldRender, setShouldRender] = useState<boolean>(false);

    const toggleActionModal = (status?: ProjectStatus) => {
        if (status) setNextStatus(status);
        else setNextStatus(undefined)
        setActionModal(prev => !prev);
    }
    const toggleAssignModal = () => setAssignModal(prev => !prev);

    const [onSearch, { data, refetch, loading }] = useLazyQuery(requests.project.query.SEARCH_PROJECT_DETAIL, {
        fetchPolicy: "network-only",
    });

    useEffect(() => setPageId(Number(query.get("id"))), [query, setPageId]);

    useEffect(() => {
        document.title = `Corsem - ${strings.pages.PROJECT(1).label[language]}`;
    }, [language]);

    useEffect(() => {
        if (auth.me)
            if (!permissionAccess(auth.me.data.permissions, 15)) history.replace(strings.pages.FORBIDDEN.url)
    })

    useEffect(() => {
        setShouldRender(!shouldRender);
    },[])

    useEffect(() => {
        if (data) {
                setCustomer({
                    customer: data.searchProject.quote.customer,
                    phone: data.searchProject.quote.customerPhone,
                    business: data.searchProject.quote.business,
                })
                if(shouldRender){
                    setCurrentStatus(data.searchProject.status);
                    setShouldRender(true);
                }  
        }
    }, [data, setCustomer, shouldRender]);

    useEffect(() => {
        if (pageId)
            onSearch({
                variables: { id: pageId },
            })
    }, [pageId, onSearch])

    useEffect(() => {
        if (auth) setMeId(auth.me?.data.id)
    }, [auth])

    const isDownSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    const [onProjectAction, { loading: loadingAction }] = useMutation(requests.project.mutation.PROJECT_ACTION);
    const [onProjectAssign, { loading: loadingAssign }] = useMutation(requests.project.mutation.PROJECT_ASSIGN);

    const onSubmitAction = useCallback(() => {
        onProjectAction({
            variables: {
                data: {
                    from: currentStatus,
                    to: nextStatus,
                    projectId: pageId,
                }
            }
        }).then(() => {
            enqueueSnackbar(
                strings.notifications.PROJECT_UPDATE_SUCCESS[language],
                {
                    variant: "success",
                }
            );
            setActionModal(false);
            refetch && refetch();
        }).catch((error) => {
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }, [onProjectAction, pageId, currentStatus, nextStatus])

    const onSubmitAssign = useCallback(() => {
        onProjectAssign({
            variables: {
                id: pageId,
                ownerId: meId,
            }
        }).then(() => {
            enqueueSnackbar(
                strings.notifications.PROJECT_UPDATE_SUCCESS[language],
                {
                    variant: "success",
                }
            );
            setAssignModal(false);
            refetch && refetch();
        }).catch((error) => {
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }, [onProjectAction, pageId, meId, shouldRender])

    return (
        <MainWrapper>
            <ResponsiveTypography
                className={classes.title}
                growthRate={50}
                fontWeight={800}
                smDown={33}
                mdDown={45}
                lgDown={33}
            >
                {`${strings.pages.PROJECT(1).label[language]} #${pageId}`}
            </ResponsiveTypography>
            

            <Card elevation={5} className={classes.root_paper}>
                <Box className={classes.actions}>
                    <Fab
                        color='secondary'
                        disabled
                        size={'medium'}
                        variant={'extended'}
                    >
                        {currentStatus || ''}
                    </Fab>
                    {currentStatus !== 'CANCELLED' && currentStatus !== 'CLOSED' && meId === data?.searchProject.ownerId &&
                        <Fab
                            className={clsx(classes.actionButton, currentStatus === 'STARTED' && classes.acceptButton)}
                            onClick={() => toggleActionModal(currentStatus && getNextStatus(currentStatus) as ProjectStatus)}
                            color='secondary'
                            disabled={loading || loadingAction}
                            size={isDownSm ? 'small' : 'medium'}
                            variant={isDownSm ? 'round' : 'extended'}
                        >
                            {!isDownSm && currentStatus && getNextStatus(currentStatus)}
                            <ArrowForwardIosRounded fontSize='small' className={clsx(!isDownSm && classes.buttonRightIcon)} />
                        </Fab>
                    }
                    {meId !== data?.searchProject.ownerId &&
                        <Fab
                            className={clsx(classes.actionButton, classes.acceptButton)}
                            onClick={toggleAssignModal}
                            color='secondary'
                            disabled={loading || loadingAction}
                            size={isDownSm ? 'small' : 'medium'}
                            variant={isDownSm ? 'round' : 'extended'}
                        >
                            <AssignmentIndRounded fontSize='small' className={clsx(!isDownSm && classes.buttonIcon)} />
                            {!isDownSm && strings.actions.ASSIGN_TO_ME[language]}
                        </Fab>
                    }
                    {currentStatus !== 'CANCELLED' && meId === data?.searchProject.ownerId &&
                        <Tooltip title={strings.actions.CANCEL_EXECUTION[language]}>
                            <Fab
                                onClick={() => toggleActionModal('CANCELLED' as ProjectStatus)}
                                className={clsx(classes.actionButton, classes.deleteButton)}
                                color='secondary'
                                disabled={loading || loadingAction || currentStatus === 'CLOSED'}
                                size={isDownSm ? 'small' : 'medium'}
                                variant={isDownSm ? 'round' : 'extended'}
                            >
                                <CancelRounded className={clsx(!isDownSm && classes.buttonIcon)} />
                                {!isDownSm && strings.actions.CANCEL_EXECUTION[language]}
                            </Fab>
                        </Tooltip>
                    }
                </Box>
                {loading ?
                    <LinearProgress />
                    :
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={3}>
                            <CustomerSection
                                language={language}
                                data={customer}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Grid container direction='column' spacing={2}>
                                <Grid item xs={12}>
                                    <QuoteSection
                                        language={language}
                                        title={strings.pages.QUOTE(1).label[language]}
                                        data={data?.searchProject?.quote}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ProjectSection
                                        language={language}
                                        title={strings.pages.EXECUTION(1).label[language]}
                                        data={data?.searchProject}
                                        

                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Card>

            <Card elevation={5} className={classes.root_paper}>
                <CommentBox
                    id={pageId as number}
                    category='PROJECT'
                    language={language}
                    me={auth.me as AuthUser}
                    refetchTrigger={currentStatus}
                />

            </Card>

            <CustomModal
                language={language}
                title={strings.actions.CONFIRM_ACTION[language]}
                open={actionModal}
                disableExecute={loadingAction}
                onClose={toggleActionModal}
                onExecute={onSubmitAction}
            />

            <CustomModal
                language={language}
                title={strings.actions.CONFIRM_ACTION[language]}
                open={assignModal}
                disableExecute={loadingAssign}
                onClose={toggleAssignModal}
                onExecute={onSubmitAssign}
            />
        </MainWrapper>
    )
}
export default Project;