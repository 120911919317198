import React, {FC, useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from '@material-ui/core/TablePagination';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import styles from './style';
import requests from '../../../requests'
import strings from '../../../strings';
import { useLazyQuery } from "@apollo/client";
import moment from 'moment';

interface HistoryTableProps {
  language: LanguageType;
  data: any[];
}

function Row(props: { data: any, language: LanguageType }) {
  const { data, language } = props;
  const [open, setOpen] = React.useState(false);
  const classes= styles();

  const [priceSearch, { data: priceHistory }] = useLazyQuery(requests.pricehistory.query.SEARCH_PRICEHISTORY,{
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if(priceHistory === undefined){
      priceSearch();
    }
  },[])

  return (
    <React.Fragment>
      <TableRow >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {data.id}
        </TableCell>
        <TableCell className={classes.entries} align="right">{data.status}</TableCell>
        <TableCell className={classes.entries} align="right">{data.category}</TableCell>
        <TableCell className={classes.entries} align="right">{data.manufacturer}</TableCell>
        <TableCell className={classes.entries} align="right">{data.model}</TableCell>
        <TableCell className={classes.entries} align="right">{data.price}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                {strings.priceHistory.HISTORY[language]}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{strings.priceHistory.DATE[language]}</TableCell>
                    <TableCell>{strings.priceHistory.OLD_PRICE[language]}</TableCell>
                    <TableCell>{strings.priceHistory.NEW_PRICE[language]}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {priceHistory?.searchPriceHistory.map((price: any) => (
                    price.productId === data.id && (
                    <TableRow key={price.id}>
                      <TableCell component="th" scope="row">
                        {moment(price.updatedAt).format('DD-MM-YYYY ddd')}
                      </TableCell>
                      <TableCell>{price.oldPrice}</TableCell>
                      <TableCell>{price.newPrice}</TableCell>
                    </TableRow>)
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


export default function CollapsibleTable(props: HistoryTableProps) {
    const classes= styles();
    const [page, setPage]= React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const { language,  data } = props; 
    const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    const handleChangePage = (event: unknown, page: number) => {
        setPage(page);
    };

    const handleChangeRowsPerPage = (event:React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setRowsPerPage(parseInt(value));
        
    };

    useEffect(()=>{
      setShouldRefetch(!shouldRefetch);
    },[])
      
  return (
    <Paper elevation={10} className={classes.paper} style={{marginTop:"1rem"}}>
     <TableContainer  >
        <Table aria-label="collapsible table" className={classes.table}>
            <TableHead>
            <TableRow>
                <TableCell />
                <TableCell className={classes.cell}> {strings.columns.ID[language]}</TableCell>
                <TableCell className={classes.cell} align="right">{strings.columns.STATUS[language]}</TableCell>
                <TableCell className={classes.cell} align="right">{strings.columns.CATEGORY[language]}</TableCell>
                <TableCell className={classes.cell} align="right">{strings.columns.MANUFACTURER[language]}</TableCell>
                <TableCell className={classes.cell} align="right">{strings.columns.MODEL[language]}</TableCell>
                <TableCell className={classes.cell} align="right">{strings.columns.PRICE[language]}</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => (
              <Row key={row.id} data={row} language={language} />
            ))} 
              { emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
            </TableBody>
        </Table>
        <TablePagination
             labelRowsPerPage={strings.general.ROWS_PER_PAGE[language]}
             rowsPerPageOptions={[5, 10, 25]}
             component="div"
             count={data.length}
             rowsPerPage={rowsPerPage}
             page={page}
             onChangePage={handleChangePage}
             onChangeRowsPerPage={handleChangeRowsPerPage}
        />

     </TableContainer>
    </Paper>
    
  );
}
