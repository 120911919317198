import gql from "graphql-tag";

const CREATE_CUSTOMER = gql`
    mutation onCustomerCreate($data: CustomerCreateInput!) {
        createCustomer(data: $data) {
            id
        }
    }
`;

const UPDATE_CUSTOMER = gql`
    mutation onCustomerUpdate($data: CustomerUpdateInput!) {
        updateCustomer(data: $data) {
            id
        }
    }
`;

const SEARCH_CUSTOMERS = gql`
    query onCustomersSearch($searchBy: String, $filters: CustomerWhereInput, $orderBy: CustomerOrderBy, $paginate: Pagination) {
        searchCustomers(searchBy: $searchBy, filters: $filters, orderBy: $orderBy, paginate: $paginate) {
            count
            customers {
                id
                firstName
                lastName
                email
                fullName
                email
                phoneNumbers {
                    id
                    phone
                }
                addresses {
                    region {
                        region
                    }
                }
                createdAt
            }
        }
    }
`;

const SEARCH_CUSTOMER = gql`
    query onCustomerSearch($id: Int!) {
        searchCustomer(id: $id) {
            id
            fullName
            firstName
            lastName
            cnp
            email
            phoneNumbers {
                id
                phone
            }
            addresses {
                id
                addressString
            }
            businesses {
                id
                name
                cif
            }
            proxies {
                id
                firstName
                lastName
                phone
            }
        }
    }
`;

const SEARCH_BUSINESS = gql`
    query onBusinessSearch($id: Int!) {
        searchBusiness(id: $id) {
            id
            name
            cui
        }
    }
`;

const SEARCH_PROXY = gql`
    query onProxySearch($id: Int!) {
        searchProxy(id: $id) {
            id
            fullName
            firstName
            lastName
        }
    }
`;

const SEARCH_ADDRESS = gql`
    query onAddressSearch($id: Int!) {
        searchAddress(id: $id) {
            id
            addressString
        }
    }
`;

const SEARCH_PHONE = gql`
    query onPhoneSearch($id: Int!) {
        searchPhone(id: $id) {
            id
            phone
        }
    }
`;

export const customer = {
    query: {
        SEARCH_CUSTOMER,
        SEARCH_CUSTOMERS,
        SEARCH_BUSINESS,
        SEARCH_PROXY,
        SEARCH_ADDRESS,
        SEARCH_PHONE,
    },
    mutation: {
        CREATE_CUSTOMER,
        UPDATE_CUSTOMER
    },
    subscription: {
    }
}