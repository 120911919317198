import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        borderRadius: 10,
        padding: theme.spacing(2),
        width: '100%',
        border: `1px solid ${theme.palette.divider}`,
        boxShadow: `5px 5px 10px ${theme.palette.common.black}${theme.palette.type === 'light' ? '20' : '2'}`,
    },
    title: {
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    divider: {
        boxShadow: `10px 10px 20px ${theme.palette.common.black}${theme.palette.type === 'light' ? '20' : '2'}`,
    },
    content: {
        paddingTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
    }
}));