import { Document, StyleSheet, Font, View, Page, Text, Image } from '@react-pdf/renderer';
import moment from 'moment';
import { FC, useEffect } from 'react';

import logo from "../../../resources/logo.png";
import RobotoCondensedRegular from "../../../resources/fonts/RobotoCondensed-Regular.ttf";
import RobotoCondensedRegularItalic from "../../../resources/fonts/RobotoCondensed-Italic.ttf";
import RobotoCondensedBold from "../../../resources/fonts/RobotoCondensed-Bold.ttf";
import RobotoCondensedBoldItalic from "../../../resources/fonts/RobotoCondensed-BoldItalic.ttf";
import { QuoteFormValues } from '../../../Views/NewQuote/types';
import { useLazyQuery, useQuery } from '@apollo/client';
import requests from '../../../requests';

const GDPRPDF: FC<{ data: QuoteFormValues }> = ({ data }) => {

    const [searchCustomer, { data: customerData }] = useLazyQuery(requests.customer.query.SEARCH_CUSTOMER, {
        variables: {
            id: data.customerId
        }
    })

    const [searchBusiness, { data: businessData }] = useLazyQuery(requests.customer.query.SEARCH_BUSINESS, {
        variables: {
            id: data.businessId
        }
    })

    const [searchProxy, { data: proxyData }] = useLazyQuery(requests.customer.query.SEARCH_PROXY, {
        variables: {
            id: data.proxyId
        }
    })

    const [searchAddress, { data: addressData }] = useLazyQuery(requests.customer.query.SEARCH_ADDRESS, {
        variables: {
            id: data.billingAddressId
        }
    })

    const [searchPhone, { data: phoneData }] = useLazyQuery(requests.customer.query.SEARCH_PHONE, {
        variables: {
            id: data.customerPhoneId
        }
    })

    useEffect(() => {
        if (data.customerId) searchCustomer();
        if (data.businessId) searchBusiness();
        if (data.proxyId) searchProxy();
        if (data.billingAddressId) searchAddress();
        if (data.customerPhoneId) searchPhone();
    }, [data, searchCustomer, searchBusiness, searchProxy, searchAddress, searchPhone])

    return (
        <Document>
            <Page size='A4' style={styles.body}>
                <Image
                    style={styles.logo}
                    src={logo}
                />
                <View style={styles.header}>
                    <View style={styles.titleGroupRight}>
                        <Text style={styles.titleRight}>
                            SC CORSEM IMPEX SRL – UNGURAȘU COSTEL
                        </Text>
                        <Text style={styles.subtitleRight}>
                            Str. Humorului, nr. 961, Șcheia
                        </Text>
                        <Text style={styles.subtitleRight}>
                            Tel: 0744/ 472358 ; e-mail: corsemimpex@yahoo.com
                        </Text>
                        <Text style={styles.subtitleRight}>
                            Site: www.corsem.ro
                        </Text>
                    </View>
                </View>
                <View style={styles.breakSpace}></View>
                <View style={styles.titleGroup}>
                    <Text style={styles.title}>
                        Declarație Acord privind prelucrarea datelor cu caracter personal
                    </Text>
                </View>

                <View style={styles.field}>
                    <Text style={styles.fieldLabel}>Client/Partener:</Text>
                    <Text style={styles.fieldValue}>{data.customerId}</Text>
                </View>
                <View style={styles.field}>
                    <Text style={styles.fieldLabel}>Nume Prenume/Denumire:</Text>
                    <Text style={styles.fieldValue}>{data.businessId ? businessData?.searchBusiness?.name : customerData?.searchCustomer?.fullName}</Text>
                </View>
                <View style={styles.field}>
                    <Text style={styles.fieldLabel}>CNP/CUI:</Text>
                    <Text style={styles.fieldValue}>{data.businessId ? businessData?.searchBusiness?.cui : customerData?.searchCustomer?.cnp}</Text>
                </View>
                <View style={styles.field}>
                    <Text style={styles.fieldLabel}>Telefon:</Text>
                    <Text style={styles.fieldValue}>{phoneData?.searchPhone?.phone}</Text>
                </View>
                <View style={styles.field}>
                    <Text style={styles.fieldLabel}>Adresă de corespondență:</Text>
                    <Text style={styles.fieldValue}>{addressData?.searchAddress?.addressString}</Text>
                </View>
                <View style={styles.field}>
                    <Text style={styles.fieldLabel}>Prin semnarea prezentei declar pe propria răspundere că:</Text>
                </View>
                <View>
                    <Text style={styles.fieldText}>1. DECLARAȚIE PRIVIND INFORMAREA</Text>
                    <Text style={styles.fieldText}>
                        Am luat la cunoștință de conținutul Notei de informare (verso) și ma oblig s-o transmit tuturor
                        persoanelor ale căror date cu caracter personal le furnizez, în vederea informării corecte a acestora.
                    </Text>
                    <Text style={styles.fieldText}>
                        Nume_Prenume reprezentant Client/Partener {data.proxyId ? proxyData?.searchProxy?.fullName : customerData?.searchCustomer?.fullName} in
                        calitate de {data.proxyId ? 'REPREZENTANT' : 'BENEFICIAR'}
                    </Text>
                </View>
                <View>
                    <Text style={styles.fieldText}>2. ACORD</Text>
                    <Text style={styles.fieldText}>
                        Confirm ca am citit, am înțeles conținutul prezentului acord, iar cele exprimate mai jos reprezinta
                        alegerea mea, în cunoștința de cauza, liber exprimata, transpusa pe acest formular.
                    </Text>
                    <Text style={styles.fieldText}>
                        Pentru prelucrarea datelor cu caracter personal, indispensabile în vederea prestării serviciilor
                        (ofertare, consiliere, proiectare și execuție instalații), cu respectarea legislației specifice în vigoare.
                    </Text>
                    <Text style={styles.fieldText}>
                        Am înțeles ca datele mele cu caracter personal și/sau ale persoanelor pentru care solicit
                        informații/ produse/servicii sunt absolut necesare pentru obținerea acestora și ca refuzul de a le
                        furniza poate bloca procesul de ofertare a unor soluții personalizate, adaptate cerințelor și nevoilor
                        mele și/ sau ale persoanelor pentru care cer prestarea serviciilor.
                    </Text>
                </View>
                <View style={styles.field}>
                    <Text style={styles.fieldLabel}>Semnătura:</Text>
                    <Text style={styles.fieldValue}>.................................</Text>
                </View>
                <View style={styles.field}>
                    <Text style={styles.fieldLabel}>Data:</Text>
                    <Text style={styles.fieldValue}>{moment().format('DD/MM/YYYY')}</Text>
                </View>
            </Page>

            <Page size='A4' style={styles.body}>
                <Image
                    style={styles.logo}
                    src={logo}
                />
                <View style={styles.header}>
                    <View style={styles.titleGroupRight}>
                        <Text style={styles.titleRight}>
                            SC CORSEM IMPEX SRL – UNGURAȘU COSTEL
                        </Text>
                        <Text style={styles.subtitleRight}>
                            Str. Humorului, nr. 961, Șcheia
                        </Text>
                        <Text style={styles.subtitleRight}>
                            Tel: 0744/ 472358 ; e-mail: corsemimpex@yahoo.com
                        </Text>
                        <Text style={styles.subtitleRight}>
                            Site: www.corsem.ro
                        </Text>
                    </View>
                </View>
                <View style={styles.breakSpace}></View>
                <View style={styles.titleGroup}>
                    <Text style={styles.title}>
                        NOTA DE INFORMARE
                    </Text>
                </View>
                <Text>Stimate client/ partener</Text>
                <View style={styles.breakSpace}></View>
                <Text>
                    Prin prezenta va comunicam ca, începând cu data de 25 mai 2018, ne vom alinia la standardele europene privind
                    prelucrarea datelor cu caracter personal și libera lor circulație, stipulate în Regulamentul (UE) 2016/679.
                </Text>
                <Text>
                    În acest sens, ne angajam sa asiguram securitatea datelor d-voastră cu caracter personal, sa le protejam împotriva
                    distrugerii, modificării, dezvăluirii sau accesului neautorizat asupra lor.
                </Text>
                <View style={styles.breakSpace}></View>
                <Text style={styles.paragraphTitle}>Scopul prelucrării datelor personale</Text>
                <Text>
                    Datele cu caracter personal sunt prelucrate de către subscrisa în scop contractual și legal. Temeiul prelucrării îl constituie
                    contractul de prestări servicii și prevederile legale aplicabile. Astfel, în desfășurarea activităților legate de contract sau în
                    vederea îndeplinirii obligațiilor legale, comunicam respectivele date către partenerii noștri contractuali, auditori, autorități
                    și instituții publice.
                </Text>
                <Text>
                    Prelucrarea datelor personale în alte scopuri decât cele contractuale și legale se va realiza ținând cont de
                    opțiunea/alegerile d-voastră.
                </Text>
                <Text>
                    Datele cu caracter personal va sunt prelucrate pe toată durata contractuala, pana la expirarea obligațiilor contractuale și
                    a termenelor de arhivare.
                </Text>
                <View style={styles.breakSpace}></View>
                <Text style={styles.paragraphTitle}>Drepturi</Text>
                <Text>
                    Potrivit Regulamentului (UE) 2016/679, persoanelor cărora li se prelucrează date cu caracter personal, le sunt
                    recunoscute următoarele drepturi:
                </Text>
                <Text>
                    Dreptul de a fi informat- dreptul de a va fi furnizate informații corecte de procesare, fiind asigurata transparenta în ceea
                    ce privește modul de utilizare a datelor cu caracter personal;
                </Text>
                <Text>
                    Dreptul de acces la date- dreptul de a obține o confirmare din partea noastră ca prelucram sau nu datele d-voastră, iar în
                    caz afirmativ, dreptul de a avea acces la respectivele date;
                </Text>
                <Text>
                    Dreptul la rectificare- dreptul de a fi rectificate, fără întârzieri nejustificate, datele personale inexacte;
                </Text>
                <Text>
                    Dreptul la ștergerea datelor‘’dreptul de a fi uitat’’- dreptul de a va fi șterse datele, fără întârzieri nejustificate, dacă
                    acestea au fost prelucrate ilegal, fără consimțământul d-voastră sau dacă nu mai sunt necesare pentru îndeplinirea
                    scopurilor pentru care au fost colectate;
                </Text>
                <Text>
                    Dreptul la restricționarea prelucrării- dreptul de a obține restricționarea prelucrării datelor cu caracter personal;
                </Text>
                <Text>
                    Dreptul la portabilitatea datelor- dreptul de a primi datele personale într-un format structurat, care poate fi citit automat,
                    precum și dreptul ca aceste date sa fie transmise direct altui operator;
                </Text>
                <Text>
                    Dreptul la opoziție- dreptul de a va opune prelucrării datelor cu caracter personal, inclusiv creării de profiluri.
                </Text>

                <View style={styles.breakSpace}></View>
                <Text style={styles.paragraphTitle}>Contact si suport privind drepturile cu caracter personal</Text>
                <Text>Orice cerere privind exercitarea drepturilor menționate mai sus va fi transmisa în scris prin:</Text>
                <Text> - Posta: Scheia, str. Humorului, nr. 961, jud. Suceava;</Text>
                <Text> - Telefon: 0744472358, de luni pana vineri intre orele 8:00-16:00;</Text>
                <Text> - Fax: 0230-514145;</Text>
                <Text> - E-mail: corsemimpex@yahoo.com</Text>
                <Text>
                    În cazul în care considerați ca, legat de acest subiect, un drept al d-voastră v-a fost încălcat, va puteți adresa Autorității
                    Naționale de Supraveghere a Prelucrării Datelor cu Caracter Personal (ANSPDCP).
                </Text>
                <View style={styles.breakSpace}></View>
                <Text>Asigurându-vă de întreaga noastră considerație, va mulțumim</Text>
                <View style={styles.breakSpace}></View>
                <Text>Corsem Impex SRL</Text>
            </Page>
        </Document>
    )
}

export default GDPRPDF;

Font.register({
    family: 'RobotoCondensed',
    fonts: [
        { src: RobotoCondensedRegular, fontWeight: 400, },
        { src: RobotoCondensedRegularItalic, fontWeight: 400, fontStyle: 'italic' },
        { src: RobotoCondensedBold, fontWeight: 700 },
        { src: RobotoCondensedBoldItalic, fontWeight: 700, fontStyle: 'italic' },
    ]
});

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontSize: 10,
        fontFamily: 'RobotoCondensed',
    },
    logo: {
        position: "absolute",
        width: '100',
        top: 35,
        left: 35,
    },
    header: {
        display: "flex",
        flexDirection: 'row',
        width: '100%',
    },
    titleGroup: {
        display: 'flex',
        justifyContent: 'center',
    },
    titleGroupRight: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    title: {
        fontSize: 18,
        textAlign: 'center',
        fontWeight: 700,
    },
    subtitle: {
        fontSize: 14,
        textAlign: 'center',
        fontWeight: 400,
    },
    titleRight: {
        fontSize: 18,
        textAlign: 'right',
        fontWeight: 700,
    },
    subtitleRight: {
        fontSize: 14,
        textAlign: 'right',
        fontWeight: 400,
    },
    breakSpace: {
        marginTop: 15,
    },
    paragraphTitle: {
        fontWeight: 700,
        textDecoration: 'underline',
    },
    field: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'baseline',
        fontSize: 14,
        marginTop: 15,
    },
    fieldLabel: {
        marginRight: 15,
    },
    fieldValue: {
        fontWeight: 700,
    },
    fieldText: {
        fontSize: 14,
        marginTop: 10,
        textIndent: 15,
    },
});