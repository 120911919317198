export const SIGN_IN_SUCCESS = {
    ro: "Autentificare realizată cu succes!",
    en: "Authentication successful!"
}
export const SIGN_UP_SUCCESS = {
    ro: "Înregistrare realizată cu succes!",
    en: "Registration successful!"
}
export const ACCESS_FORBIDDEN = {
    ro: "Permisiune invalidă!",
    en: "Access forbidden!"
}
export const UNABLE_LOGIN = {
    ro: "A intervenit o eroare la autentificare!",
    en: "Unable to login!"
}
export const USER_BLOCKED = {
    ro: "Utilizator blocat!",
    en: "User blocked!"
}
export const EMAIL_EXISTS = {
    ro: "Adresa de email introdusă este folosită de un alt user!",
    en: "Email address already in use by another user!"
}
export const LANGUAGE_CHANGE_SUCCESS = {
    ro: "Limba a fost actualizată!",
    en: "Language updated!"
}
export const USER_UPDATE_SUCCSESS = {
    ro: "Utilizator actualizat cu succes!",
    en: "User updated successfully!",
};
export const SOMETHING_WRONG = {
    ro: "Am întâmpinat o eroare!",
    en: "Something went wrong!",
};
export const ROLE_CREATE_SUCCESS = {
    ro: "Rol creat cu succes!",
    en: "Role created successfully!",
};
export const ROLE_UPDATE_SUCCESS = {
    ro: "Rol actualizat cu succes!",
    en: "Role updated successfully!",
};
export const ROLE_DELETE_SUCCESS = {
    ro: "Rol șters cu succes!",
    en: "Role deleted successfully!",
};
export const CUSTOMER_CREATE_SUCCESS = {
    ro: "Client creat cu succes!",
    en: "Customer created successfully!",
};
export const CUSTOMER_UPDATE_SUCCESS = {
    ro: "Client actualizat cu succes!",
    en: "Customer updated successfully!",
};
export const CUSTOMER_DELETE_SUCCESS = {
    ro: "Client șters cu succes!",
    en: "Customer deleted successfully!",
};
export const QUOTE_CREATE_SUCCESS = {
    ro: "Ofertă creată cu succes!",
    en: "Quote created successfully!",
};
export const QUOTE_TEMPLATE_CREATE_SUCCESS = {
    ro: "Șablon ofertă creat cu succes!",
    en: "Quote template created successfully!",
};
export const QUOTE_TEMPLATE_LOAD_SUCCESS = {
    ro: "Șablon ofertă încărcat cu succes!",
    en: "Quote template loaded successfully!",
};
export const COMMENT_CREATE_SUCCESS = {
    ro: "Comentariu adăugat cu succes!",
    en: "Comment added successfully!",
};
export const QUOTE_UPDATE_SUCCESS = {
    ro: "Ofertă actualizată cu succes!",
    en: "Quote updated successfully!",
};
export const QUOTE_DELETE_SUCCESS = {
    ro: "Ofertă ștearsă cu succes!",
    en: "Quote deleted successfully!",
};
export const PRODUCT_CREATE_SUCCESS = {
    ro: "Produs creat cu succes!",
    en: "Product created successfully!",
};
export const PRODUCT_UPDATE_SUCCESS = {
    ro: "Produs actualizat cu succes!",
    en: "Product updated successfully!",
};
export const PRODUCT_DELETE_SUCCESS = {
    ro: "Produs șters cu succes!",
    en: "Product deleted successfully!",
};
export const SERVICE_CREATE_SUCCESS = {
    ro: "Serviciu creat cu succes!",
    en: "Service created successfully!",
};
export const SERVICE_UPDATE_SUCCESS = {
    ro: "Serviciu actualizat cu succes!",
    en: "Service updated successfully!",
};
export const SERVICE_DELETE_SUCCESS = {
    ro: "Serviciu șters cu succes!",
    en: "Service deleted successfully!",
};
export const CATEGORY_CREATE_SUCCESS = {
    ro: "Categorie creată cu succes!",
    en: "Category created successfully!",
};
export const EXECUTION_SCHEDULED_SUCCESS = {
    ro: "Lucrare programată cu succes!",
    en: "Execution scheduled successfully!",
};
export const EXECUTION_UPDATE_SUCCESS = {
    ro: "Lucrare actualizată cu succes!",
    en: "Execution updated successfully!",
};
export const PROJECT_CREATE_SUCCESS = {
    ro: "Proiect creat cu succes!",
    en: "Project created successfully!",
};
export const PROJECT_UPDATE_SUCCESS = {
    ro: "Proiect actualizat cu succes!",
    en: "Project updated successfully!",
};
export const TICKETS_GENERATED_SUCCESS = {
    ro: "Bilete generate cu succes!",
    en: "Tickets generated successfully!",
};
export const VEHICLE_CREATE_SUCCESS = {
    ro: "Vehicul creat cu succes!",
    en: "Vehicle created successfully!",
};
export const VEHICLE_UPDATE_SUCCESS = {
    ro: "Vehicul actualizat cu succes!",
    en: "Vehicle updated successfully!",
};
