import gql from "graphql-tag";

const ME_QUERY = gql`
{
    me {
        id
        email
        avatar
        active
        fullName
        profile {
            id
            firstName
            lastName
        }
        role {
            name
            value
        }
        permissions
        createdAt
    }
}
`;

const SIGN_IN_QUERY = gql`
    mutation SignInMutation($email: String!, $password: String!, $rememberMe: Boolean! = false) {
        signInUser(email: $email, password: $password, rememberMe: $rememberMe) {
            user {
                id
            }
            token
        }
    }
`;

const SIGN_UP_QUERY = gql`
    mutation SignUpMutation($firstName: String!, $lastName: String!, $email: String!, $password: String!, $roleId: Int!) {
        signUpUser(firstName: $firstName, lastName: $lastName, email: $email, password: $password, roleId: $roleId) {
            id
        }
    }
`;

const UPDATE_SELF_USER = gql`
    mutation onUpdateSelfUser($firstName: String!, $lastName: String!, $email: String!, $avatar: Int!) {
        updateSelfUser(firstName: $firstName, lastName: $lastName, email: $email, avatar: $avatar) {
            id
        }
    }
`;

const UPDATE_USER = gql`
    mutation onUpdateUser($id: Int!, $data: UserUpdateInput!) {
        updateUser(id: $id, data: $data) {
            id
        }
    }
`;

const USER_NAME_UPDATE = gql`
    mutation onUpdateUserName($firstName: String!, $lastName: String!) {
        userNameUpdate(firstName: $firstName, lastName: $lastName) {
            id
        }
    }
`;

const USER_PASSWORD_UPDATE = gql`
	mutation onUpdateUserPassword($oldPassword: String!, $password: String!, $confirm: String!) {
		userPasswordUpdate(oldPassword: $oldPassword, newPassword: $password, confirmPassword: $confirm) {
			id
			password
		}
	}
`;

const USER_CLOSE_ACCOUNT = gql`
	mutation onUserAccountClose {
		userCloseAccount {
			id
		}
	}
`;

const USER_EMAIL_UPDATE = gql`
	mutation onUpdateUserEmail($email: String!) {
		userEmailUpdate(email: $email) {
			id
		}
	}
`;

const NEW_SIGN_UP = gql`
    subscription OnUserSignUp {
        userSignUp {
            id
            email
            createdAt
        }
    }
`;

const SEARCH_USERS = gql`
    query onSearchUsers($searchBy: String, $filters: UserWhereInput, $orderBy: UserOrderBy, $paginate: Pagination) {
        searchUsers(searchBy: $searchBy, filters: $filters, orderBy: $orderBy, paginate: $paginate) {
            users {
                id
                email
                fullName
                active
                validated
                profile {
                    firstName
                    lastName
                }
                createdAt
            }
            count
        }
    }
`;

const SEARCH_USER = gql`
    query onUserSearch($id: Int!) {
        searchUser(id: $id) {
            id
            email
            fullName
            roleId
            avatar
            active
            validated
            profile {
                id
                firstName
                lastName
            }
        }
    }
`;

const VALIDATE_USER = gql`
    mutation onUserValidate($id: Int!) {
        validateUser(id: $id) {
            id
        }
    }
`;

const BLOCK_USER = gql`
    mutation onUserBlock($id: Int!) {
        blockUser(id: $id) {
            id
        }
    }
`;

const UNBLOCK_USER = gql`
    mutation onUserUnblock($id: Int!) {
        unblockUser(id: $id) {
            id
        }
    }
`;

const RESET_USER = gql`
    mutation onUserReset($id: Int!) {
        resetUserPassword(id: $id) {
            id
        }
    }
`;

const SEARCH_ROLES = gql`
    query onRolesSearch {
        roles {
            id
            name
            value
            permissions
            noOfPermissions
        }
    }
`;

const CREATE_ROLE = gql`
    mutation onRoleCreate($name: String, $permissions: [Boolean!]!) {
        createRole(name: $name, permissions: $permissions) {
            id
        }
    }
`;

const UPDATE_ROLE = gql`
    mutation onRoleUpdate($id: Int!,$name: String, $permissions: [Boolean]!) {
        updateRole(id: $id, name: $name, permissions: $permissions) {
            id
        }
    }
`;

const DELETE_ROLE = gql`
    mutation onRoleDelete($id: Int!) {
        deleteRole(id: $id) {
            id
        }
    }
`;

const SEARCH_PLANNERS = gql`
    query onSearchPlanners {
        searchPlanners {
            id
            email
            fullName
            active
            validated
            profile {
                firstName
                lastName
            }
            createdAt
        }
    }
`;

export const user = {
    query: {
        ME_QUERY,
        SEARCH_USERS,
        SEARCH_ROLES,
        SEARCH_PLANNERS,
        SEARCH_USER,
    },
    mutation: {
        SIGN_IN_QUERY,
        SIGN_UP_QUERY,
        UPDATE_SELF_USER,
        UPDATE_USER,
        USER_NAME_UPDATE,
        USER_PASSWORD_UPDATE,
        USER_EMAIL_UPDATE,
        USER_CLOSE_ACCOUNT,
        CREATE_ROLE,
        UPDATE_ROLE,
        DELETE_ROLE,
        VALIDATE_USER,
        RESET_USER,
        BLOCK_USER,
        UNBLOCK_USER,
    },
    subscription: {
        NEW_SIGN_UP,
    }
}