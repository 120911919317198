import { useLazyQuery } from "@apollo/client";
import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ProjectsEnhancedTable, ProjectsTableFilters } from "../../components/Projects";
import { ProjectFilters } from "../../components/Projects/types";

import { ResponsiveTypography } from "../../components/shared";
import MainWrapper from "../../components/shared/MainWrapper";
import { permissionAccess } from "../../functions/permissionAccess";
import requests from "../../requests";
import strings from "../../strings";
import styles from './style';

const Projects: FC<PageBaseProps> = (props) => {
    const { language,auth } = props;
    const history = useHistory();
    const [filters, setFilters] = useState<ProjectFilters>({
        status: [],
        ownerId: [],
    })
    const [openFilters, setOpenFilters] = useState<boolean>(false);
    const classes = styles();

    const [onSearch, { data, loading: loadingSearch }] = useLazyQuery(requests.project.query.SEARCH_PROJECTS, {
        fetchPolicy: "network-only",
    });

    const handleSearch = useCallback((rows: number, page: number, orderBy: ProjectOrderBy, filters?: ProjectFilters) => {
        onSearch({
            variables: {
                filters,
                orderBy,
                paginate: {
                    take: rows,
                    skip: Math.max(page, 0) * rows,
                }
            }
        })
    }, [onSearch]);

    useEffect(() => {
        document.title = `Corsem - ${strings.pages.PROJECTS.label[language]}`;
    }, [language]);

    useEffect(() => {
        if (auth.me)
            if (!permissionAccess(auth.me.data.permissions, 15)) history.replace(strings.pages.FORBIDDEN.url)
    })

    return (
        <MainWrapper>
            <ResponsiveTypography
                className={classes.title}
                growthRate={50}
                fontWeight={800}
                smDown={33}
                mdDown={45} lgDown={33}
            >
                {strings.pages.PROJECTS.label[language]}
            </ResponsiveTypography>

            <ResponsiveTypography
                growthRate={50}
                fontWeight={300}
                smDown={12}
                mdDown={20}
                lgDown={12}
            >
                {strings.pages.PROJECTS.sublabel[language]}
            </ResponsiveTypography>

            <ProjectsEnhancedTable
                rows={data?.searchProjects?.projects || []}
                count={data?.searchProjects?.count || 0}
                loading={loadingSearch}
                language={language}
                handleSearch={handleSearch}
                title={strings.pages.PROJECTS.label[language]}
                openFilters={() => setOpenFilters(true)}
                filters={filters}
                indexes={data?.searchExecutions?.index || []}
            />

            <ProjectsTableFilters
                language={language}
                open={openFilters}
                onClose={() => setOpenFilters(false)}
                filters={filters}
                submitFilters={(filters: ProjectFilters) => setFilters(filters)}
            />
        </MainWrapper>
    )
}
export default Projects;