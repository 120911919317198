import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    divider: {
        margin: theme.spacing(0, 0, 2, 0),
    },
}))