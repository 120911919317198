import { unstable_createMuiStrictModeTheme as createMuiTheme, PaletteType } from "@material-ui/core";

import { paletteDark } from "./palette-dark";
import { paletteLight } from "./palette-light";
import { typography } from './typography';

const generateTheme = (mode: PaletteType) => {
    return createMuiTheme({
        palette: mode === "light" ? paletteLight : paletteDark,
        typography,
        overrides: {
            MuiCssBaseline: {
                "@global": {
                    "*::-webkit-scrollbar": {
                        width: "0.4em",
                    },
                    "*::-webkit-scrollbar-track": {
                        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
                    },
                    "*::-webkit-scrollbar-thumb": {
                        backgroundColor: mode === 'light' ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.3)',
                        borderRadius: 100,
                    },
                },
            },
            MuiPopover: {
                root: {
                    zIndex: 5000,
                }
            },
            MuiOutlinedInput: {
                root: {
                    margin: 0,
                    borderRadius: '10px',
                    fontFamily: 'Montserrat'
                },
                input: {
                    "&:-webkit-autofill": {
                        WebkitBoxShadow: "0 0 0 1000px transparent inset"
                    },
                },
            },
            MuiStepper: {
                root: {
                    backgroundColor: 'transparent',
                }
            },
            MuiStepIcon: {
                root: {
                    '&$completed': {
                        color: '#668bc2',
                    },
                    '&$active': {
                        color: '#668bc2',
                    },
                },
                active: {},
                completed: {}
            },
            MuiTableCell: {
                root: {
                    borderBottom: 'none',
                },
            },
            MuiChip: {
                root: {
                    height: '42px',
                    maxHeight: '42px',
                    borderRadius: '21px',
                },
                avatar: {
                    height: '34px',
                    width: '34px',
                    maxHeight: '34px',
                    maxWidth: '34px',
                },
                avatarColorSecondary: {
                    height: '34px',
                    width: '34px',
                    maxHeight: '34px',
                    maxWidth: '34px',
                }
            },
            MuiAvatar: {
                root: {
                    height: '34px',
                    width: '34px',
                }
            },
        },
        shape: {
            borderRadius: 15,
        },
        zIndex: {
            appBar: 1200,
            drawer: 1100,
            modal: 3000,
            tooltip: 3100,
        },
    });
};

export default generateTheme;
