export const HISTORY ={
    ro: 'Istoric',
    en: 'History',
}

export const DATE = {
    ro: 'Data',
    en: 'Date',
}

export const OLD_PRICE = {
    ro: 'Preț vechi',
    en: 'Old price',
}

export const NEW_PRICE = {
    ro: 'Preț nou',
    en: 'New price',
}

export const SUBTITLE = {
    ro: 'Administrează schimbările de preț',
    en: 'Manage the history prices'
}