import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ExecutionsEnhancedTable, ExecutionsTableFilters } from "../../components/Executions";
import { ExecutionFilters } from "../../components/Executions/types";

import { ResponsiveTypography } from "../../components/shared";
import MainWrapper from "../../components/shared/MainWrapper";
import { permissionAccess } from "../../functions/permissionAccess";
import requests from "../../requests";
import strings from "../../strings";
import styles from './style';

const WorkOrders: FC<PageBaseProps> = (props) => {
    const { language, auth } = props;
    const history = useHistory();
    const [filters, setFilters] = useState<ExecutionFilters>({
        status: [],
        scheduledDate_gte: moment().format().split('T')[0],
        scheduledDate_lte: moment().add(14, 'days').format().split('T')[0]
    })
    const [openFilters, setOpenFilters] = useState<boolean>(false);
    const classes = styles();

    const [onSearch, { data, loading: loadingSearch }] = useLazyQuery(requests.execution.query.SEARCH_EXECUTIONS, {
        fetchPolicy: "network-only",
    });

    const handleSearch = useCallback((rows: number, page: number, orderBy: ExecutionOrderBy, filters?: ExecutionFilters) => {
        onSearch({
            variables: {
                filters,
                orderBy,
                paginate: {
                    take: rows,
                    skip: Math.max(page, 0) * rows,
                }
            }
        })
    }, [onSearch]);

    useEffect(() => {
        document.title = `Corsem - ${strings.pages.EXECUTIONS.label[language]}`;
    }, [language]);

    useEffect(() => {
        if (auth.me)
            if (!permissionAccess(auth.me.data.permissions, 11)) history.replace(strings.pages.FORBIDDEN.url)
    })

    return (
        <MainWrapper>
            <ResponsiveTypography
                className={classes.title}
                growthRate={50}
                fontWeight={800}
                smDown={33}
                mdDown={45}
                lgDown={33}
            >
                {strings.pages.EXECUTIONS.label[language]}
            </ResponsiveTypography>
            <ResponsiveTypography
                growthRate={50}
                fontWeight={300}
                smDown={12}
                mdDown={20}
                lgDown={12}
            >
                {strings.pages.EXECUTIONS.sublabel[language]}
            </ResponsiveTypography>

            <ExecutionsEnhancedTable
                rows={data?.searchExecutions?.executions || []}
                count={data?.searchExecutions?.count || 0}
                loading={loadingSearch}
                language={language}
                handleSearch={handleSearch}
                title={strings.pages.EXECUTIONS.label[language]}
                openFilters={() => setOpenFilters(true)}
                filters={filters}
                indexes={data?.searchExecutions?.index || []}
            />

            <ExecutionsTableFilters
                language={language}
                open={openFilters}
                onClose={() => setOpenFilters(false)}
                filters={filters}
                submitFilters={(filters: ExecutionFilters) => setFilters(filters)}
            />
        </MainWrapper>
    )
}
export default WorkOrders;