import { Box, CircularProgress, useTheme } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import clsx from 'clsx';

import styles from './style';
import { ReactComponent as RaisisCRMLogo } from '../../../../resources/svg/logo.svg';
import { ReactComponent as RaisisCRMLogoDark } from '../../../../resources/svg/logo-white.svg';


type LoadingScreenProps = {
    loading: boolean;
}

const LoadingScreen: FC<LoadingScreenProps> = ({ loading }) => {
    const classes = styles();
    const { palette: { type: theme } } = useTheme();
    const [animateIn, setAnimateIn] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setAnimateIn(true);
        }, 2000);
    }, []);

    return (
        <Box className={clsx(classes.root, loading && !animateIn ? classes.visible : null)}>
            {theme === 'light' ?
                <RaisisCRMLogo className={classes.logo} />
                :
                <RaisisCRMLogoDark className={classes.logo} />
            }
            <CircularProgress
                className={classes.progress}
                color="secondary"
            />
        </Box>
    );
}

export default LoadingScreen;
