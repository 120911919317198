import { Button, FormControl, MenuItem, Select, Switch, useTheme } from "@material-ui/core";
import { Box, Paper } from "@material-ui/core";
import { ArrowDropDownRounded } from "@material-ui/icons";
import { FC } from "react";
import clsx from 'clsx';

import { ResponsiveTypography } from "../../shared";
import styles from './style';
import strings from "../../../strings";
import { useState } from "react";

type SettingsSectionProps = {
    title: string;
    language: LanguageType;
    subtitle?: string;
    buttonText?: string;
    spaceBottom?: boolean;
    hasSwitch?: boolean;
    switchValue?: boolean;
    red?: boolean;
    action?: (value?: any) => void;
    dropDown?: boolean;
    dropDownValue?: any;
}

const SettingsSection: FC<SettingsSectionProps> = (props) => {
    const { title, subtitle, buttonText, spaceBottom, hasSwitch, switchValue, red, action, dropDown, dropDownValue, language } = props;
    const classes = styles();
    const theme = useTheme();
    const [openDropDown, setOpenDropDown] = useState(false);

    const onDropDown = () => setOpenDropDown(true);
    const onDropDownSelect = () => {
        setOpenDropDown(false);
        action && action();
    };

    return (
        <Paper elevation={5} className={clsx(classes.root, spaceBottom && classes.rootMargin, red && classes.rootRed)}>
            <Box className={classes.textWrapper}>
                <ResponsiveTypography
                    className={classes.title}
                    color={red ? theme.palette.error.contrastText : undefined}
                    fontWeight={700}
                    growthRate={50}
                    smDown={17}
                    mdDown={25}
                    lgDown={17}
                >
                    {title}
                </ResponsiveTypography>
                <ResponsiveTypography
                    color={red ? theme.palette.error.contrastText : undefined}
                    className={classes.subtitle}
                    fontWeight={500}
                    growthRate={50}
                    smDown={14}
                    mdDown={20}
                    lgDown={14}
                >
                    {subtitle}
                </ResponsiveTypography>
            </Box>
            {openDropDown ?
                <FormControl variant="outlined">
                    <Select
                        value={dropDownValue}
                        onChange={onDropDownSelect}
                    >
                        <MenuItem value="ro">{strings.general.ROMANIAN[language]}</MenuItem>
                        <MenuItem value="en">{strings.general.ENGLISH[language]}</MenuItem>
                    </Select>
                </FormControl>
                : hasSwitch ?
                    <Switch
                        checked={switchValue}
                        onChange={action}
                    />
                    :
                    <Button
                        className={classes.button}
                        color='inherit'
                        variant='text'
                        onClick={dropDown ? onDropDown : action}
                    >
                        {buttonText}
                        {dropDown && <ArrowDropDownRounded />}
                    </Button>
            }
        </Paper>
    )
}
export default SettingsSection;