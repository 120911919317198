import { FC, useState } from "react";

import strings from "../../../strings";
import styles from './style';
import {useTheme, Grid, Button, Box,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { RoleList, SettingsTitle, SettingsSection } from "..";
import { PermissionWrapper } from "../../shared";
import { renderIfHasPermission } from "../../../functions";
import { useHistory } from "react-router-dom";
import { SignUpView } from "../../../Views";

const GeneralSettings: FC<PageBaseProps> = (props) => {
    const history = useHistory();
    const { language, toggleTheme, toggleLanguage, auth, onClose } = props;
    const classes = styles();
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleOpen = () =>{
        setOpen(true);
    }

    const handleClose = () =>{
        setOpen(false);
    }

   

    return (
        <Box className={classes.root}>
            <SettingsTitle
                title={strings.settings.DISPLAY_SETTINGS[language]}
            />
            <SettingsSection
                language={language}
                title={strings.settings.CHANGE_LANGUAGE.title[language]}
                subtitle={strings.settings.CHANGE_LANGUAGE.subtitle[language]}
                buttonText={strings.actions.CHANGE[language]}
                action={toggleLanguage}
                spaceBottom
                dropDown
                dropDownValue={language}
            />
            <SettingsSection
                language={language}
                title={strings.settings.DARK_THEME.title[language]}
                subtitle={strings.settings.DARK_THEME.subtitle[language]}
                buttonText={strings.actions.CHANGE[language]}
                hasSwitch
                switchValue={theme.palette.type === 'dark'}
                action={toggleTheme}
            />
            <PermissionWrapper hasPermission={renderIfHasPermission(auth.me?.data?.permissions || [], 0)}>
                <SettingsTitle
                    title={strings.settings.PERMISSION_SETTINGS[language]}
                    subtitle={strings.settings.ADMIN_ONLY[language]}
                    topMargin
                />
            </PermissionWrapper>
            <PermissionWrapper hasPermission={renderIfHasPermission(auth.me?.data?.permissions || [], 0)}>
                <RoleList
                    language={language}
                />
            </PermissionWrapper>
            <PermissionWrapper hasPermission={renderIfHasPermission(auth.me?.data?.permissions || [], 0)}>
            <Grid container justify="center" style={{marginTop:"1rem"}} >
                <Button
                    variant="contained"
                    color="primary"
                    size='large'
                    onClick={handleOpen}
                    className={classes.submit}
                >
                    {strings.actions.SIGN_UP[language]}
                </Button>
                <Dialog 
                    open={open}
                    onClose={handleClose}
                     fullWidth
                >
                    <DialogTitle>
                        <Box className={classes.buttonX}>
                            <CloseIcon onClick={handleClose}/>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <SignUpView toggleTheme={toggleTheme} toggleLanguage={toggleLanguage} language={language} onClose={handleClose} />
                        </DialogContentText>
                    </DialogContent>
                </Dialog>                
            </Grid>
            </PermissionWrapper>
        </Box>
    )
}
export default GeneralSettings;