import { FC } from "react";

import styles from './style';
import strings from "../../../strings";
import { CustomWrapper, FieldWithLabel } from "../../shared";
import { Divider } from "@material-ui/core";
import moment from "moment";

type ProjectSectionProps = {
    language: LanguageType;
    title: string;
    data: ProjectProps | undefined;
}
const ProjectSection: FC<ProjectSectionProps> = (props) => {
    const classes = styles();
    const { language, data } = props;
    console.log(data)

    return (
        <CustomWrapper
            title={strings.pages.PROJECT(1).label[language]}
        >
            <FieldWithLabel
                label={strings.columns.SERVICE[language]}
                value={data?.serviceName || '-'}
            />
            <Divider className={classes.divider} />
            <FieldWithLabel
                label={strings.columns.PROJECT_NO[language]}
                value={data?.number || '-'}
            />
            <Divider className={classes.divider} />
            <FieldWithLabel
                label={strings.columns.PLANNER[language]}
                value={data?.ownerName || '-'}
            />
            <Divider className={classes.divider} />
            <FieldWithLabel
                label={strings.columns.CREATED_AT[language]}
                value={moment(data?.createdAt).format('D-MM-YYYY') || '-'}
            />
        </CustomWrapper>
    )
}
export default ProjectSection;