import { makeStyles } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
    checks: {
        margin: theme.spacing(2, 1, 2, 1),
    },
    buttonIcon: {
        marginRight: theme.spacing(1),
    },
    auxTypeBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 10,
        marginBottom: theme.spacing(2),
    },
    auxTypeBoxDeleted: {
        border: `1px solid ${theme.palette.error.dark}`,
    },
    auxTypeControls: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            // alignItems: 'center',
        }
    },
    auxTypeSwitch: {
        margin: theme.spacing(0, 0, 0, 3),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0, 0, 0),
        }
    },
    deleteButton: {
        color: red[500],
    },
    restoreButton: {
        color: green[500],
    },
}))