import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    textField: {
    },
    searchField: {
        flexGrow: 1,
        borderRadius: 10,
        boxShadow: `5px 5px 10px ${theme.palette.common.black}${theme.palette.type === 'light' ? '20' : '2'}`,
    }
}));