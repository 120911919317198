import { FC, MouseEvent, useState } from "react";
import { Chip, Divider, Grid, IconButton, InputAdornment, MenuItem, TextField, Theme, useTheme } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';

import strings from "../../../strings";
import styles from './style';
import CustomModal from "../../shared/CustomModal";
import { ProjectFilters } from "../types";
import moment from "moment";
import { ResponsiveTypography } from "../../shared";
import { ClearRounded } from "@material-ui/icons";
import { useQuery } from "@apollo/client";
import requests from "../../../requests";

enum ProjectStatus {
    OPEN = "OPEN",
    STARTED = "STARTED",
    CLOSED = "CLOSED",
    CANCELLED = "CANCELLED",
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const menuprops = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(index: string, array: string[], theme: Theme) {
    return {
        fontWeight:
            array.indexOf(index) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function getStylesNumber(index: number, array: number[], theme: Theme) {
    return {
        fontWeight:
            array.indexOf(index) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const statusList = [ProjectStatus.OPEN, ProjectStatus.STARTED, ProjectStatus.CLOSED, ProjectStatus.CANCELLED]

type TableFiltersProps = {
    open: boolean;
    language: LanguageType;
    filters: ProjectFilters;
    onClose: () => void;
    submitFilters: (filters: ProjectFilters) => void;
}

const TableFilters: FC<TableFiltersProps> = (props) => {
    const { language, filters, submitFilters, onClose } = props;
    const [localFilters, setLocalFilters] = useState<ProjectFilters>(filters);

    const handleChange = (event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
        console.log(event.target)
        const { value, name } = event.target as HTMLSelectElement;
        setLocalFilters(prev => ({ ...prev, [name]: value }));
    }

    const { data } = useQuery(requests.user.query.SEARCH_PLANNERS, {
        fetchPolicy: 'network-only',
    })

    const handleChangeDate = (name: string, value: any) => setLocalFilters(prev => ({ ...prev, [name]: value }))

    return (
        <CustomModal
            fullWidth
            title={strings.general.FILTERS[language]}
            {...props}
            content={
                <Filters
                    filters={localFilters}
                    handleChange={handleChange}
                    handleChangeDate={handleChangeDate}
                    users={data?.searchPlanners || []}
                    language={language}
                />
            }
            onExecute={() => {
                submitFilters(localFilters);
                onClose();
            }}
        />
    );
}

export default TableFilters;

type FilterProps = {
    filters: ProjectFilters;
    language: LanguageType;
    handleChange: (event: React.ChangeEvent<{ value: unknown, name?: string }>) => void;
    handleChangeDate: (name: string, value: any) => void;
    users: UserProps[];
}

const Filters: FC<FilterProps> = (props) => {
    const classes = styles();
    const theme = useTheme();
    const { filters, handleChange, handleChangeDate, language, users } = props;

    const handleClearDate = (e: MouseEvent<HTMLButtonElement>, name: string) => {
        e.stopPropagation();
        handleChangeDate(name, undefined);
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    select
                    fullWidth
                    name='status'
                    variant='outlined'
                    label={strings.columns.STATUS[language]}
                    value={filters.status}
                    onChange={handleChange}
                    SelectProps={{
                        multiple: true,
                        renderValue: (selected) => (
                            <div className={classes.chips}>
                                {(selected as string[]).map((value) => (
                                    <Chip key={value} label={value} className={classes.chip} />
                                ))}
                            </div>
                        ),
                        MenuProps: menuprops,
                    }}
                >
                    {statusList.map((name) => (
                        <MenuItem key={name} value={name} style={getStyles(name, filters.status, theme)}>
                            {name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    select
                    fullWidth
                    name='ownerId'
                    variant='outlined'
                    label={strings.columns.PLANNER[language]}
                    value={filters.ownerId}
                    onChange={handleChange}
                    SelectProps={{
                        multiple: true,
                        renderValue: (selected) => (
                            <div className={classes.chips}>
                                {(selected as number[]).map((value, index: number) => {
                                    const user = users.find(user => user.id === value);
                                    return <Chip key={index} label={user?.profile.firstName} className={classes.chip} />
                                })}
                            </div>
                        ),
                        MenuProps: menuprops,
                    }}
                >
                    {users.map((user) => (
                        <MenuItem key={user.id} value={user.id} style={getStylesNumber(user.id, filters.ownerId, theme)}>
                            {user.fullName}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider} />
                <ResponsiveTypography growthRate={50}>{strings.columns.CREATED_AT[language]}</ResponsiveTypography>
            </Grid>
            <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        autoOk
                        orientation="portrait"
                        openTo="date"
                        inputVariant="outlined"
                        fullWidth
                        label={strings.general.FROM_DATE[language]}
                        format="D/MM/yyyy"
                        value={filters.createdAt_gte || null}
                        onChange={(date: any) => handleChangeDate('createdAt_gte', moment(date).format().split('T')[0])}
                        InputProps={{
                            endAdornment: filters.createdAt_gte && (
                                <InputAdornment position='end'>
                                    <IconButton onClick={(e) => handleClearDate(e, 'createdAt_gte')}>
                                        <ClearRounded />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        autoOk
                        orientation="portrait"
                        openTo="date"
                        inputVariant="outlined"
                        fullWidth
                        label={strings.general.TO_DATE[language]}
                        format="D/MM/yyyy"
                        value={filters.createdAt_lte || null}
                        onChange={(date: any) => handleChangeDate('createdAt_lte', moment(date).format().split('T')[0])}
                        InputProps={{
                            endAdornment: filters.createdAt_lte && (
                                <InputAdornment position='end'>
                                    <IconButton onClick={(e) => handleClearDate(e, 'createdAt_lte')}>
                                        <ClearRounded />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
        </Grid >
    );
}