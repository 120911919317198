import { Box } from "@material-ui/core";
import React, { FC } from "react";
import clsx from 'clsx';
import styles from './style';

const CustomBooleanChip: FC<{ children: React.ReactNode, value: boolean }> = ({ children, value }) => {
    const classes = styles();
    return (
        <Box className={clsx(classes.root, value && classes.active)}>
            {children}
        </Box>
    )
}

export default CustomBooleanChip;