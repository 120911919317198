import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        borderRadius: 0,
        marginTop: 0,
        padding: theme.spacing(7, 0, 7, 0),
        boxShadow: `inset 5px 5px 10px ${theme.palette.common.black}${theme.palette.type === 'light' ? '20' : '4'}`,
        minHeight: `calc(100vh - 64px - ${theme.spacing(20)}px)`,
        backgroundColor: theme.palette.type === 'light' ? '#fafbfd' : theme.palette.background.paper,
        [theme.breakpoints.down('xs')]: {
            minHeight: `calc(100vh - 64px - ${theme.spacing(16)}px)`,
            padding: theme.spacing(3),
        },
    },
}));