import { makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
    root_paper: {
        overflow: 'hidden',
        marginTop: theme.spacing(4),
    },
    role_item: {
        backgroundColor: theme.palette.type === 'light' ? theme.palette.background.paper : theme.palette.background.default,
    },
    addRoleTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    addRoleIcon: {
        marginRight: theme.spacing(1),
    },
    column: {
        [theme.breakpoints.up('lg')]: {
            flexBasis: '33.33%'
        }
    },
    root: {
        color: theme.palette.text.secondary,
        '&:hover > $content': {
            color: 'var(--tree-view-color)',
            backgroundColor: theme.palette.action.hover,
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: 'transparent',
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent',
        },
    },
    content: {
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 1),
        height: 38,
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
    },
    roleNameField: {
        marginBottom: theme.spacing(1),
    },
    buttonWrapper: {
        position: 'relative',
    },
    progress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 24,
        height: 24,
        marginTop: -12,
        marginLeft: -12,
    },
    deleteButton: {
        color: theme.palette.error.main,
    },
    imageBox: {
        height: 120,
        width: 120,
    },
    imageRoot: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    imageActions: {
        width: 120,
        display: 'flex',
        justifyContent: 'space-around',
        margin: theme.spacing(1, 0),
    },
    imageField: {
        marginTop: theme.spacing(1),
    },
    textField: {
        marginTop: theme.spacing(2)
    }
}))