import { FC } from "react";
import { Grid, TextField } from "@material-ui/core";

import { CustomerFormProps } from "../types";
import styles from './style';
import strings from "../../../strings";
import { getError } from "../../../functions/formFunctions";

const BusinessForm: FC<CustomerFormProps> = (props) => {
    const classes = styles();
    const { language, useForm, handleChangeField } = props;
    const { register, formState: { errors } } = useForm;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    required
                    variant="outlined"
                    className={classes.textField}
                    label={strings.customer.NAME[language]}
                    {...register('business.name', {
                        required: true
                    })}
                    onChange={handleChangeField}
                    error={Boolean(errors.business?.name)}
                    helperText={getError(errors.business?.name, language)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    required
                    variant="outlined"
                    className={classes.textField}
                    label={strings.customer.NRC[language]}
                    {...register('business.nrc', {
                        required: true
                    })}
                    onChange={handleChangeField}
                    error={Boolean(errors.business?.nrc)}
                    helperText={getError(errors.business?.nrc, language)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    required
                    variant="outlined"
                    className={classes.textField}
                    label={strings.customer.CIF[language]}
                    {...register('business.cif', {
                        required: true
                    })}
                    onChange={handleChangeField}
                    error={Boolean(errors.business?.cif)}
                    helperText={getError(errors.business?.cif, language)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.textField}
                    label={strings.customer.ACCOUNT[language]}
                    {...register('business.account')}
                    onChange={handleChangeField}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.textField}
                    label={strings.customer.BANK[language]}
                    {...register('business.bank')}
                    onChange={handleChangeField}
                />
            </Grid>
        </Grid>
    )
}
export default BusinessForm;