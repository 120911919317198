import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        borderTopLeftRadius: '20px',
        paddingTop: theme.spacing(5),
        height: theme.spacing(16),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxShadow: `inset 5px 5px 10px ${theme.palette.common.black}${theme.palette.type === 'light' ? '20' : '2'}`,
        [theme.breakpoints.down('md')]: {
            borderRadius: 0,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(1),
        },
    }
}));