import { FC } from "react";

import styles from './style';
import strings from "../../../strings";
import { CustomWrapper, FieldWithLabel } from "../../shared";
import { Divider } from "@material-ui/core";
import moment from "moment";

type ExecutionSectionProps = {
    language: LanguageType;
    title: string;
    data: ExecutionProps | undefined;
}
const ExecutionSection: FC<ExecutionSectionProps> = (props) => {
    const classes = styles();
    const { language, data } = props;

    return (
        <CustomWrapper
        title={strings.pages.EXECUTION(1).label[language]}
        >
            <FieldWithLabel
                label={strings.columns.SERVICE[language]}
                value={data?.serviceName || '-'}
            />
            <Divider className={classes.divider} />
            <FieldWithLabel
                label={strings.columns.SCHEDULED_AT[language]}
                value={moment(data?.scheduledDate).format('D-MM-YYYY') || '-'}
            />
            <Divider className={classes.divider} />
            <FieldWithLabel
                label={strings.columns.CREATED_AT[language]}
                value={moment(data?.createdAt).format('D-MM-YYYY') || '-'}
            />
        </CustomWrapper>
    )
}
export default ExecutionSection;