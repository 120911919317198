export const ROMANIAN = {
    ro: 'Română',
    en: 'Romanian',
}
export const ENGLISH = {
    ro: 'Engleză',
    en: 'English',
}
export const WELCOME_MESSAGE = (firstName: string) => ({
    ro: `Bine ai revenit, ${firstName}!`,
    en: `Welcome back, ${firstName}!`,
})
export const NEW_NOTIFICAIONS = (count: number) =>
    count
        ? count === 1
            ? {
                  ro: `Ai o notificăre nouă!`,
                  en: `You have one new notification!`,
              }
            : {
                  ro: `Ai ${count} notificări noi!`,
                  en: `You have ${count} new notifications!`,
              }
        : {
              ro: `Nici o noutate!`,
              en: `No news for you!`,
          }
export const ROWS_PER_PAGE = {
    ro: 'Rânduri per pagină',
    en: 'Rows per page',
}
export const NEXT_PAGE = {
    ro: 'Pagina următoare',
    en: 'Next page',
}
export const PREV_PAGE = {
    ro: 'Pagina precedentă',
    en: 'Previous page',
}
export const DISPLAYED_ROWS = (from: number, to: number, count: number) => ({
    ro: `${from}-${to} din ${count}`,
    en: `${from}-${to} of ${count}`,
})
export const SETTINGS_MESSAGE = (firstName: string) => ({
    ro: `Salut ${firstName}! Bine ai venit în centrul tău de control!`,
    en: `Hello ${firstName}! Welcome to your control center!`,
})
export const IS_USER_ACTIVE = (value: boolean) =>
    value
        ? {
              ro: 'Activ',
              en: 'Activ',
          }
        : {
              ro: 'Blocat',
              en: 'Blocked',
          }
export const IS_USER_VALIDATED = (value: boolean) =>
    value
        ? {
              ro: 'Validat',
              en: 'Validated',
          }
        : {
              ro: 'Nevalidat',
              en: 'Not validated',
          }
export const TECH_SUPPORT = {
    ro: 'Suport tehnic',
    en: 'Technical support',
}
export const NOTIFICATIONS = {
    ro: 'Notificări',
    en: 'Notifications',
}
export const NO_NOTIFICATIONS = {
    ro: 'Nu ai notificări noi',
    en: 'Nothing new to see',
}
export const SEE_ALL_NOTIFICATIONS = {
    ro: 'Vezi toate notificările',
    en: 'See all the notifications',
}
export const MARK_ALL_READ = {
    ro: 'Marchează tot ca citit',
    en: 'Mark all as read',
}
export const EMAIL = {
    ro: 'E-mail',
    en: 'E-mail Address',
}
export const Role = {
    ro: 'Rol',
    en: 'Role',
}
export const ID = {
    ro: 'ID',
    en: 'ID',
}
export const FULL_NAME = {
    ro: 'Nume complet',
    en: 'Full name',
}
export const FIRST_NAME = {
    ro: 'Prenume',
    en: 'First Name',
}
export const LAST_NAME = {
    ro: 'Nume',
    en: 'Last Name',
}
export const CNP = {
    ro: 'CNP',
    en: 'SSN',
}
export const PHONE = {
    ro: 'Telefon',
    en: 'Phone',
}
export const PASSWORD = {
    ro: 'Parolă',
    en: 'Password',
}
export const ROLE = {
    ro: 'Rol',
    en: 'Role',
}
export const ROLE_NAME = {
    ro: 'Denumire rol',
    en: 'Role name',
}
export const NO_OF_PERMISSIONS = (value: number) =>
    value === 1
        ? {
              ro: '1 permisiune',
              en: '1 permission',
          }
        : {
              ro: `${value} permisiuni`,
              en: `${value} permissions`,
          }
export const STATUS_OF = (value: string) =>
    value === 'ACTIVE'
        ? {
              ro: 'Activ',
              en: 'Active',
          }
        : {
              ro: 'Inactiv',
              en: 'Inactive',
          }
export const TRUE_OR_FALSE = (value: boolean) =>
    value === true
        ? {
              ro: 'Da',
              en: 'Yes',
          }
        : {
              ro: 'Nu',
              en: 'No',
          }
export const COMMENTS = {
    ro: 'Comentarii',
    en: 'Comments',
}
export const NO_COMMENTS = {
    ro: 'Nu există comentarii',
    en: 'There are no comments',
}
export const PIECES = {
    ro: 'BUC',
    en: 'PCS',
}
export const SIZE = {
    ro: 'Dimensiune',
    en: 'Size',
}
export const FILE_SIZE_ERROR = {
    ro: 'Limită fișier 5MB',
    en: 'File exceeds 5MB',
}
export const NO_RESULTS = {
    ro: 'Niciun rezultat',
    en: 'No rezultat',
}
export const ADD_AUX_TYPE = {
    ro: 'Adaugă tip subserviciu',
    en: 'Add subservice type ',
}
export const AUX_TYPE = {
    ro: 'Subserviciu',
    en: 'Subservice',
}
export const QUOTE_CUSTOMER_STEP = {
    ro: 'Detalii client',
    en: 'Customer details',
}
export const QUOTE = {
    ro: 'Ofertă',
    en: 'Quote',
}
export const QUOTE_CONTENT_STEP = {
    ro: 'Detalii servicii',
    en: 'Services details',
}
export const QUOTE_SUMMARY_STEP = {
    ro: 'Finalizare',
    en: 'Summary',
}
export const QUOTE_STEPS = (language: LanguageType) => [
    QUOTE_CUSTOMER_STEP[language],
    QUOTE_CONTENT_STEP[language],
    QUOTE_SUMMARY_STEP[language],
]
export const CUSTOMER_DETAILS = {
    ro: 'Detalii client',
    en: 'Customer details',
}
export const BILLING_DETAILS = {
    ro: 'Detalii facturare',
    en: 'Billing details',
}
export const IF_EXISTS = {
    ro: '*dacă există',
    en: '*if it exists',
}
export const FILTERS = {
    ro: 'Filtrare',
    en: 'Filters',
}
export const FROM_DATE = {
    ro: 'De la',
    en: 'From',
}
export const TO_DATE = {
    ro: 'Până la',
    en: 'To',
}
export const TEMPLATE = {
    ro: 'Șablon',
    en: 'Template',
}
export const TEMPLATE_NAME = {
    ro: 'Denumire șablon',
    en: 'Template name',
}
