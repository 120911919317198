import gql from 'graphql-tag'

const SEARCH_COMMENTS = gql`
    query onSearchComments($id: Int, $category: CommentType) {
        searchComments(id: $id, category: $category) {
            id
            author {
                id
                fullName
                profile {
                    firstName
                    lastName
                }
                avatar
            }
            category
            message
            sys
            createdAt
            fileAddress
        }
    }
`

const CREATE_COMMENT = gql`
    mutation onCommentCreate(
        $id: Int!
        $message: String
        $category: CommentType
        $fileAddress: String
    ) {
        createComment(
            id: $id
            message: $message
            category: $category
            fileAddress: $fileAddress
        ) {
            id
        }
    }
`

export const comment = {
    query: {
        SEARCH_COMMENTS,
    },
    mutation: {
        CREATE_COMMENT,
    },
    subscription: {},
}
