import gql from "graphql-tag";

const GET_NOTIFICATIONS = gql`
    query getNotifications( $read: Boolean! ) {
        notifications(read: $read) {
            id
            type
            relatedId
            read
            softRead
            createdAt
        }
    }
`;

const COUNT_NOTIFICAITONS = gql`
    query onCountNotifications( $read: Boolean! ) {
        countNotifications(read: $read)
    }
`;

const NEW_NOTIFICATION = gql`
    subscription onNewNotification {
        newNotification {
            id
            type
            relatedId
            read
            softRead
        }
    }
`;

const SOFT_READ_NOTIFICATIONS = gql`
    mutation onSoftReadNotifications {
        softReadNotifications
    }
`;

const READ_NOTIFICATION = gql`
    mutation onReadNotification($id: Int!) {
        readNotification(id: $id) {
            id
        }
    }
`;

export const notification = {
    query: {
        GET_NOTIFICATIONS,
        COUNT_NOTIFICAITONS,
    },
    mutation: {
        SOFT_READ_NOTIFICATIONS,
        READ_NOTIFICATION,
    },
    subscription: {
        NEW_NOTIFICATION,
    }
}