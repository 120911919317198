export const ID = {
    ro: "ID",
    en: "ID"
};
export const FULL_NAME = {
    ro: "Nume",
    en: "Full Name"
};
export const FIRST_NAME = {
    ro: "Prenume",
    en: "First Name"
};
export const LAST_NAME = {
    ro: "Nume",
    en: "Last Name"
};
export const EMAIL = {
    ro: "Email",
    en: "Email"
};
export const ACTIVE = {
    ro: "Status",
    en: "Status"
};
export const VALIDATED = {
    ro: "Validare",
    en: "Validation"
};
export const CREATED_AT = {
    ro: "Dată creare",
    en: "Created At"
};
export const SCHEDULED_AT = {
    ro: "Dată programare",
    en: "Scheduled At"
};
export const MANUFACTURER = {
    ro: "Producător",
    en: "Manufacturer"
};
export const MODEL = {
    ro: "Model",
    en: "Model"
};
export const CATEGORY = {
    ro: "Categorie",
    en: "Category"
};
export const SERVICE = {
    ro: "Serviciu",
    en: "Service"
};
export const SERVICES = {
    ro: "Servicii",
    en: "Services"
};
export const SUBSERVICES = {
    ro: "Subservicii",
    en: "Subservices"
};
export const PRICE = {
    ro: "Preț",
    en: "Price"
};
export const OBS = {
    ro: "Observații",
    en: "Observations"
};
export const AMOUNT = {
    ro: "Nr. bucăți",
    en: "Amount"
};
export const DISCOUNT = {
    ro: "Discount",
    en: "Discount"
};
export const STATUS = {
    ro: "Status",
    en: "Status"
};
export const CUSTOMER = {
    ro: "Client",
    en: "Customer"
};
export const PHONE = {
    ro: "Telefon",
    en: "Phone"
};
export const ADDRESS = {
    ro: "Adresă",
    en: "Address"
};
export const ACCEPTED = {
    ro: "Acceptată",
    en: "Accepted"
};
export const NAME = (field?: string) => field ? ({
    ro: `Denumire ${field.toLowerCase()}`,
    en: `${field[0].toUpperCase() + field.slice(1).toLowerCase()} name`
}) : ({
    ro: "Denumire",
    en: "Name"
});
export const ACCEPTS_PRICE = {
    ro: "Acceptă preț",
    en: "Accepts price"
};
export const ACCEPTS_DISCOUNT = {
    ro: "Acceptă discount",
    en: "Accepts discount"
};
export const ACCEPTS_PRODUCTS = {
    ro: "Acceptă produse",
    en: "Accepts products"
};
export const SCHEDULABLE = {
    ro: "Execuție programată",
    en: "Schedulable"
};
export const IS_PLAN = {
    ro: "Tip proiect",
    en: "Plannable"
};
export const PROJECT_NO = {
    ro: "Număr proiect",
    en: "Project no."
}
export const PLANNER = {
    ro: "Proiectant",
    en: "Planner"
}
export const VEHICLE_PLATE = {
    ro: "Numere",
    en: "Plate"
}
export const RCA = {
    ro: "RCA",
    en: "RCA"
}
export const AMR_RCA = {
    ro: "AMR RCA",
    en: "RCA (Days)"
}
export const ITP = {
    ro: "ITP",
    en: "ITP"
}
export const AMR_ITP = {
    ro: "AMR ITP",
    en: "ITP (Days)"
}
export const ROV = {
    ro: "ROV",
    en: "ROV"
}
export const AMR_ROV = {
    ro: "AMR ROV",
    en: "ROV (Days)"
}