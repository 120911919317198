import gql from "graphql-tag";

const CREATE_QUOTE = gql`
    mutation onQuoteCreate($data: QuoteCreateInput!) {
        createQuote(data: $data) {
            id
        }
    }
`;

const CREATE_QUOTE_TEMPLATE = gql`
    mutation onQuoteTemplateCreate($data: QuoteTemplateCreateInput!) {
        createQuoteTemplate(data: $data) {
            id
        }
    }
`;

const UPDATE_QUOTE = gql`
    mutation onQuoteCreate($id: Int!, $data: QuoteCreateInput!) {
        updateQuote(id: $id, data: $data) {
            id
        }
    }
`;

const ACCEPT_QUOTE = gql`
    mutation onQuoteAccept($id: Int!) {
        acceptQuote(id: $id) {
            id
        }
    }
`;

const SEARCH_QUOTES = gql`
    query onSearchQuotes($searchBy: String, $filters: QuoteWhereInput, $orderBy: QuoteOrderBy, $paginate: Pagination) {
        searchQuotes(searchBy: $searchBy, filters: $filters, orderBy: $orderBy, paginate: $paginate) {
            quotes {
                id
                noOfServices
                phone
                fullName
                address
                accepted
                createdAt
            }
            count
        }
    }
`;

const SEARCH_QUOTE_TEMPLATES = gql`
    query onSearchQuoteTemplates {
        searchQuoteTemplates {
            id
            title
            discount
            services {
                typeId
                price
                discount
                obs
                auxs {
                    typeId
                    price
                    discount
                    obs
                }
                products {
                    productId
                    amount
                }
            }
        }
    }
`;

const SEARCH_QUOTE = gql`
    query onSearchQuote($id: Int!) {
        searchQuote(id: $id) {
            id
            discount
            accepted
            customerId
            customerPhoneId
            businessId
            proxyId
            homeAddressId
            billingAddressId
            workAddressId
            services {
                id
                typeId
                price
                discount
                obs
                auxs {
                    id
                    typeId
                    price
                    discount
                    obs
                }
                products {
                    id
                    productId
                    amount
                }
            }
        }
    }
`;

const SEARCH_QUOTE_DETAIL = gql`
    query onSearchQuote($id: Int!) {
        searchQuote(id: $id) {
            id
            discount
            accepted
            phone
            createdAt
            customer {
                id
                firstName
                lastName
                fullName
                cnp
                email
            }
            customerPhone {
                id
                phone
            }
            business {
                id
                name
                cif
                nrc
                account
                bank
            }
            proxy {
                id
                firstName
                lastName
                phone
            }
            homeAddress {
                id
                addressString
            }
            billingAddress {
                id
                addressString
            }
            workAddress {
                id
                addressString
                region {
                    region
                }
                city {
                    city
                }
                streetName
                streetNo
                building
                entrance
                apartment
                floor
                zip
            }
            services {
                id
                typeId
                type {
                    price
                    discount
                    products
                    schedulable
                    description {
                        description
                    }
                }
                price
                discount
                obs
                auxs {
                    id
                    typeId
                    type {
                        price
                        discount
                        project
                        description {
                            description
                        }
                    }
                    price
                    discount
                    obs
                }
                products {
                    id
                    productId
                    amount
                    product {
                        id
                        category
                        image
                        manufacturer
                        model
                        price
                    }
                }
                execution {
                    id
                }
                project {
                    id
                }
            }
        }
    }
`;

export const quote = {
    query: {
        SEARCH_QUOTES,
        SEARCH_QUOTE,
        SEARCH_QUOTE_DETAIL,
        SEARCH_QUOTE_TEMPLATES,
    },
    mutation: {
        CREATE_QUOTE,
        CREATE_QUOTE_TEMPLATE,
        UPDATE_QUOTE,
        ACCEPT_QUOTE,
    },
    subscription: {
    }
}