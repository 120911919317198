import React, { FC, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
// import DeleteIcon from '@material-ui/icons/Delete';
// import FilterListIcon from '@material-ui/icons/FilterList';

import { CustomBooleanChip } from '../../shared';
import { TableActions } from '../';
import styles from './style';
import { ResponsiveTypography } from '../../shared'
import { ArrowDropDownRounded, MoreHorizRounded } from '@material-ui/icons';
import strings from '../../../strings';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
// import { numberWithCommas } from '../../../functions/numberFunctions';

interface Data {
    id: number;
    manufacturer: string;
    model: string;
    plate: string;
    RCA: Date;
    ITP: Date;
    ROV: Date;
    updatedAt: Date;
    createdAt: Date;
}
interface FleetTableProps extends CustomTableProps {
    rows: VehicleProps[];
    handleSearch: (rows: number, page: number, orderBy: VehicleOrderBy) => void;
}

type Order = 'asc' | 'desc';

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
    sort?: boolean;
}

const headCells = (language: LanguageType) => ([
    { id: 'id', sort: true, numeric: false, disablePadding: true, label: strings.columns.ID[language] },
    { id: 'manufacturer', sort: true, numeric: false, disablePadding: false, label: strings.columns.MANUFACTURER[language] },
    { id: 'model', sort: true, numeric: false, disablePadding: false, label: strings.columns.MODEL[language] },
    { id: 'plate1', sort: true, numeric: false, disablePadding: false, label: strings.columns.VEHICLE_PLATE[language] },
    { id: 'RCA', sort: true, numeric: false, disablePadding: false, label: strings.columns.RCA[language] },
    { id: 'AMR_RCA', numeric: false, disablePadding: false, label: strings.columns.AMR_RCA[language] },
    { id: 'ITP', sort: true, numeric: false, disablePadding: false, label: strings.columns.ITP[language] },
    { id: 'AMR_ITP', numeric: false, disablePadding: false, label: strings.columns.AMR_ITP[language] },
    { id: 'ROV', sort: true, numeric: false, disablePadding: false, label: strings.columns.ROV[language] },
    { id: 'AMR_ROV', numeric: false, disablePadding: false, label: strings.columns.AMR_ROV[language] },
    { id: 'createdAt', sort: true, numeric: true, disablePadding: false, label: strings.columns.CREATED_AT[language] },
] as HeadCell[]);

interface EnhancedTableProps {
    classes: ReturnType<typeof styles>;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    language: LanguageType
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, language, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                </TableCell>
                {headCells(language).map((headCell) => (
                    headCell.sort ?
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                IconComponent={ArrowDropDownRounded}
                            >
                                <ResponsiveTypography fontWeight={700}
                                    smDown={15}
                                    mdDown={20}
                                    lgDown={15}>
                                    {headCell.label}
                                </ResponsiveTypography>
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                        :
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <ResponsiveTypography fontWeight={700}
                                smDown={15}
                                mdDown={20}
                                lgDown={15}>
                                {headCell.label}
                            </ResponsiveTypography>
                        </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
    language: LanguageType;
    title: String;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = styles();
    const {
        title
    } = props;

    return (
        <Toolbar
            className={classes.toolbar}
        >
            <ResponsiveTypography
                className={classes.title}
                growthRate={50}
                fontWeight={800}
                smDown={24}
                mdDown={33}
                lgDown={24}
            >
                {title}
            </ResponsiveTypography>
        </Toolbar>
    );
};

const EnhancedTable: FC<FleetTableProps> = (props) => {
    const classes = styles();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>('id');
    const [selected, setSelected] = useState<number[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [isRendered, setIsRendered] = useState(false);
    const { rows, count, language, handleSearch, loading, title } = props;

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, id: number) => {
        setAnchorEl(event.currentTarget);
        setSelectedId(id);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setPage(0);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((item: VehicleProps) => item.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event: unknown, page: number) => {
        setPage(page);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setRowsPerPage(parseInt(value));
    };


    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    

    useEffect(() => {
        setIsRendered(!isRendered);
    },[])

    useEffect(() => {
        if(isRendered){
            handleSearch(rowsPerPage, page, `${orderBy}_${order}` as VehicleOrderBy);
            setIsRendered(true);
        }
        
    }, [handleSearch, rowsPerPage, page, order, orderBy,isRendered]);

    return (
        <div className={classes.root}>
            <TableActions
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                open={anchorEl !== null}
                selectedId={selectedId}
                language={language}
            />
            <Paper elevation={10} className={classes.paper}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    language={language}
                    title={title}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            language={language}
                        />
                        <TableBody>
                            {rows
                                .map((row, index: number) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                            hover
                                        >
                                            <TableCell padding="checkbox">
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                <ResponsiveTypography
                                                    growthRate={50}
                                                    fontWeight={500}
                                                    smDown={10}
                                                    mdDown={12}
                                                    lgDown={10}
                                                >
                                                    {row.id}
                                                </ResponsiveTypography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <ResponsiveTypography
                                                    growthRate={50}
                                                    fontWeight={500}
                                                    smDown={10}
                                                    mdDown={12}
                                                    lgDown={10}
                                                >
                                                    {row.manufacturer}
                                                </ResponsiveTypography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <ResponsiveTypography
                                                    growthRate={50}
                                                    fontWeight={500}
                                                    smDown={10}
                                                    mdDown={12}
                                                    lgDown={10}
                                                >
                                                    {row.model}
                                                </ResponsiveTypography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <ResponsiveTypography
                                                    growthRate={50}
                                                    fontWeight={500}
                                                    smDown={10}
                                                    mdDown={12}
                                                    lgDown={10}
                                                >
                                                    {row.plate}
                                                </ResponsiveTypography>
                                            </TableCell>
                                            <Tooltip enterDelay={1000} placement='top-start' title={moment(row.RCA).format("dddd, MMMM Do YYYY")}>
                                                <TableCell align="left">
                                                    <ResponsiveTypography
                                                        growthRate={50}
                                                        fontWeight={500}
                                                        smDown={10}
                                                        mdDown={12}
                                                        lgDown={10}
                                                    >
                                                        {row.RCA ? moment(row.RCA).format("D MMM. YYYY") : '-'}
                                                    </ResponsiveTypography>
                                                </TableCell>
                                            </Tooltip>
                                            <TableCell align="left">
                                                <CustomBooleanChip value={moment(row.RCA).diff(moment(), 'days') > 3}>
                                                    {moment(row.RCA).diff(moment(), 'days') < 0 || !row.RCA ? 'EXPIRAT' : moment(row.RCA).diff(moment(), 'days')}
                                                </CustomBooleanChip>
                                            </TableCell>
                                            <Tooltip enterDelay={1000} placement='top-start' title={moment(row.ITP).format("dddd, MMMM Do YYYY")}>
                                                <TableCell align="left">
                                                    <ResponsiveTypography
                                                        growthRate={50}
                                                        fontWeight={500}
                                                        smDown={10}
                                                        mdDown={12}
                                                        lgDown={10}
                                                    >
                                                        {row.ITP ? moment(row.ITP).format("D MMM. YYYY") : '-'}
                                                    </ResponsiveTypography>
                                                </TableCell>
                                            </Tooltip>
                                            <TableCell align="left">
                                                <CustomBooleanChip value={moment(row.ITP).diff(moment(), 'days') > 3}>
                                                    {moment(row.ITP).diff(moment(), 'days') < 0 || !row.ITP ? 'EXPIRAT' : moment(row.ITP).diff(moment(), 'days')}
                                                </CustomBooleanChip>
                                            </TableCell>
                                            <Tooltip enterDelay={1000} placement='top-start' title={moment(row.ROV).format("dddd, MMMM Do YYYY")}>
                                                <TableCell align="left">
                                                    <ResponsiveTypography
                                                        growthRate={50}
                                                        fontWeight={500}
                                                        smDown={10}
                                                        mdDown={12}
                                                        lgDown={10}
                                                    >
                                                        {row.ROV ? moment(row.ROV).format("D MMM. YYYY") : '-'}
                                                    </ResponsiveTypography>
                                                </TableCell>
                                            </Tooltip>
                                            <TableCell align="left">
                                                <CustomBooleanChip value={moment(row.ROV).diff(moment(), 'days') > 3}>
                                                    {moment(row.ROV).diff(moment(), 'days') < 0 || !row.ROV ? 'EXPIRAT' : moment(row.ROV).diff(moment(), 'days')}
                                                </CustomBooleanChip>
                                            </TableCell>
                                            <Tooltip enterDelay={1000} placement='top-start' title={moment(row.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss A")}>
                                                <TableCell align="right">
                                                    <ResponsiveTypography
                                                        growthRate={50}
                                                        fontWeight={500}
                                                        smDown={10}
                                                        mdDown={12}
                                                        lgDown={10}
                                                    >
                                                        {moment(row.createdAt).format("D MMM. YYYY")}
                                                    </ResponsiveTypography>
                                                </TableCell>
                                            </Tooltip>
                                            <TableCell align="right">
                                                <IconButton onClick={e => handlePopoverOpen(e, row.id)} size='small'>
                                                    <MoreHorizRounded fontSize='small' />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {rowsPerPage - rows.length > 0 && (
                                <TableRow
                                    style={{
                                        height:
                                            66 *
                                            (rowsPerPage -
                                                rows.length),
                                    }}
                                >
                                    <TableCell colSpan={8} />
                                </TableRow>
                            )}
                            {loading && getTableSkeleton(8, 5)}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage={strings.general.ROWS_PER_PAGE[language]}
                    labelDisplayedRows={({ from, to, count }) => strings.general.DISPLAYED_ROWS(from, to, count)[language]}
                    nextIconButtonText={strings.general.NEXT_PAGE[language]}
                    backIconButtonText={strings.general.PREV_PAGE[language]}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

export default EnhancedTable;

const getTableSkeleton = (columns: any, rows: any) => {
    let rowsContent = [];
    for (let i = 0; i < rows; i++) {
        rowsContent.push(
            <TableRowSkeleton key={i} columns={columns} />
        );
    }
    return rowsContent;
};

const TableRowSkeleton: FC<{ columns: number }> = ({ columns }) => {
    return (
        <TableRow style={{ height: 66 }}>
            <TableCell colSpan={columns} style={{ width: '100%' }}>
                <Skeleton style={{ width: '100%' }} />
            </TableCell>
        </TableRow>
    )
};