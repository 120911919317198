import { ApolloProvider, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Box, Button, Fab, MenuItem, MobileStepper, Step, TextField, Tooltip, FormControl,
    FormGroup,
    FormControlLabel,
    FormHelperText,
    Checkbox } from "@material-ui/core";
import { Paper, StepLabel, Stepper } from "@material-ui/core";
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded, ReplayRounded, SaveRounded, AccountCircleRounded } from "@material-ui/icons";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CustomerDetails, ServiceDetails, Summary } from "../../components/NewQuote";
import GDPRPDF from "../../components/PDFs/GDRPPDF";

import { ResponsiveTypography } from "../../components/shared";
import CustomModal from "../../components/shared/CustomModal";
import MainWrapper from "../../components/shared/MainWrapper";
import apolloClient from "../../config/apolloClient";
import { useRouteQuery } from "../../functions";
import { permissionAccess } from "../../functions/permissionAccess";
import requests from "../../requests";
import strings from "../../strings";
import styles from './style';
import { QuoteFormErrors, QuoteFormValues } from "./types";

const NewQuote: FC<PageBaseProps> = (props) => {
    const classes = styles();
    const { language, auth } = props;
    const history = useHistory();
    const query = useRouteQuery()
    const { enqueueSnackbar } = useSnackbar();
    const [activeStep, setActiveStep] = useState(0);
    const [quoteId, setQuoteId] = useState<number | undefined>();
    const [formData, setFormData] = useState<QuoteFormValues>();
    const [formErrors, setFormErrors] = useState<QuoteFormErrors>();
    const [saveTemplateModal, setSaveTemplateModal] = useState<boolean>(false);
    const [loadTemplateModal, setLoadTemplateModal] = useState<boolean>(false);
    const [templateName, setTemplateName] = useState<string>('');
    const [templateId, setTemplateId] = useState<number>();
    const [gdpr, setGdpr] = useState<boolean>(false);
    const [gdprChecked, setGdprChecked] = useState<boolean>(false);

    const [onQuoteSearch, { data }] = useLazyQuery(requests.quote.query.SEARCH_QUOTE, {
        fetchPolicy: "cache-first",
    });

    const { data: quoteTemplates, refetch: refetchTemplates } = useQuery(requests.quote.query.SEARCH_QUOTE_TEMPLATES, {
        fetchPolicy: "cache-and-network",
    });

    const [createQuoteTemplate] = useMutation(requests.quote.mutation.CREATE_QUOTE_TEMPLATE);

    useEffect(() => {
        const quote = Number(query.get("edit"));
        setQuoteId(quote)
    }, [query]);

    useEffect(() => {
        if (data?.searchQuote) {
            setFormData(data?.searchQuote)
        }
    }, [data]);

    useEffect(() => {
        if (quoteId && typeof quoteId === 'number') {
            onQuoteSearch({
                variables: { id: quoteId }
            })
        }
    }, [quoteId, onQuoteSearch]);

    useEffect(() => {
        document.title = `Corsem - ${strings.pages.NEW_QUOTE.label[language]}`;
    }, [language]);

    useEffect(() => {
        if (auth.me)
            if (!permissionAccess(auth.me.data.permissions, 6)) history.replace(strings.pages.FORBIDDEN.url)
    })

    const steps = strings.general.QUOTE_STEPS(language);

    const handleNextStep = () => setActiveStep(prevStep => prevStep + 1);
    const handlePrevStep = () => setActiveStep(prevStep => prevStep - 1);
    const toggleSaveTemplateModal = () => {
        setSaveTemplateModal(prev => !prev);
        setTemplateName('');
    }
    const toggleLoadTemplateModal = () => {
        setLoadTemplateModal(prev => !prev);
        setTemplateId(undefined);
    }
    const toggleGdpr = () => setGdpr(prev => !prev)

    const onSubmit = () => {
        createQuote({
            variables: {
                data: formData,
            }
        }).then((response) => {
            history.push(strings.pages.QUOTE(response.data.createQuote.id).url)
            enqueueSnackbar(
                strings.notifications.QUOTE_CREATE_SUCCESS[language],
                {
                    variant: "success",
                }
            );
        }).catch((error) => {
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }

    const onSubmitUpdate = () => {
        updateQuote({
            variables: {
                id: quoteId,
                data: { ...formData },
            }
        }).then((response) => {
            history.push(strings.pages.QUOTE(response.data.updateQuote.id).url)
            enqueueSnackbar(
                strings.notifications.QUOTE_UPDATE_SUCCESS[language],
                {
                    variant: "success",
                }
            );
        }).catch((error) => {
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }

    const onSubmitSaveTemplate = () => {
        createQuoteTemplate({
            variables: {
                data: {
                    title: templateName,
                    discount: formData?.discount,
                    services: formData?.services,
                },
            }
        }).then((response) => {
            enqueueSnackbar(
                strings.notifications.QUOTE_TEMPLATE_CREATE_SUCCESS[language],
                {
                    variant: "success",
                }
            );
            refetchTemplates && refetchTemplates();
            setSaveTemplateModal(false);
        }).catch((error) => {
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }

    const onSubmitLoadTemplate = () => {
        const template = quoteTemplates?.searchQuoteTemplates.find((item: any) => item.id === templateId);
        console.log(template)
        setFormData({
            ...formData,
            discount: template.discount,
            services: template.services,
        } as QuoteFormValues)

        setLoadTemplateModal(false);
        enqueueSnackbar(
            strings.notifications.QUOTE_TEMPLATE_LOAD_SUCCESS[language],
            {
                variant: "success",
            }
        );
    }

    const [
        createQuote,
        { loading: loadingCreateQuote, called: calledCreateQuote }
    ] = useMutation(requests.quote.mutation.CREATE_QUOTE);

    const [
        updateQuote,
        { loading: loadingUpdateQuote, called: calledUpdateQuote }
    ] = useMutation(requests.quote.mutation.UPDATE_QUOTE);

    const onSubmitNextStep = () => {
        if (activeStep === 0) {
            let errors: QuoteFormErrors = {};
            let hasError = false;
            if (!formData?.customerPhoneId) { errors.customerPhoneId = { type: 'required' }; hasError = true; }
            if (!formData?.homeAddressId) { errors.homeAddressId = { type: 'required' }; hasError = true; }
            if (!formData?.workAddressId) { errors.workAddressId = { type: 'required' }; hasError = true; }
            if (!formData?.billingAddressId) { errors.billingAddressId = { type: 'required' }; hasError = true; }

            if (hasError) setFormErrors(errors)
            else {
                setFormErrors(undefined);
                handleNextStep();
            }
        }

        if (activeStep === 1) {
            handleNextStep();
        }

        if (activeStep === 2) {
            if (quoteId) onSubmitUpdate()
            else onSubmit();
        }
    }

    return (
        <MainWrapper>
            <ResponsiveTypography className={classes.title} growthRate={50} fontWeight={800} smDown={33} mdDown={45} lgDown={33}>{strings.pages.NEW_QUOTE.label[language]}</ResponsiveTypography>
            <ResponsiveTypography growthRate={50} fontWeight={300} smDown={12} mdDown={20} lgDown={12}>{strings.pages.NEW_QUOTE.sublabel[language]}</ResponsiveTypography>

            <Paper elevation={5} className={classes.root_paper}>
                <Stepper
                    activeStep={activeStep}
                >
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>

                {activeStep === 0 && formData?.customerId &&
                    <Box className={classes.templateActions}>
                        <PDFDownloadLink document={<ApolloProvider client={apolloClient}><GDPRPDF data={formData} /></ApolloProvider>} fileName="gdpr.pdf">
                            {({ loading, error }) => error ? error :
                                <Tooltip title={strings.actions.GENERATE_GDPR[language]}>
                                    <span>
                                        <Fab
                                            onClick={toggleGdpr}
                                            disabled={loading}
                                            variant='extended'
                                            size='medium'
                                            color='secondary'
                                            className={classes.actionButton}
                                        >
                                            <AccountCircleRounded className={classes.buttonIcon} /> GDPR
                                        </Fab>
                                    </span>
                                </Tooltip>
                            }
                        </PDFDownloadLink>
                        {/* gdpr start */}
                        <FormControl>
                            <FormGroup className={classes.checkbox}>
                                <FormControlLabel
                                    control={<Checkbox checked={gdprChecked} onChange={() => setGdprChecked(!gdprChecked)} />}
                                    label={strings.actions.GDPR_CONFIRM.title[language]}
                                />
                            </FormGroup>
                            <FormHelperText>{strings.actions.GDPR_CONFIRM.subtitle[language]}</FormHelperText>
                        </FormControl>
                        {/* gdpr end */}
                    </Box>
                }

                {activeStep === 0 &&
                    <CustomerDetails
                        formData={formData}
                        formErrors={formErrors}
                        setFormData={setFormData}
                        language={language}
                    />
                }
                {activeStep === 1 &&
                    <Box className={classes.templateActions}>
                        <Tooltip title={strings.actions.SAVE_AS_TEMPLATE[language]}>
                            <Fab
                                onClick={toggleSaveTemplateModal}
                                variant='round'
                                size='medium'
                                color='secondary'
                                className={classes.actionButton}
                            >
                                <SaveRounded />
                            </Fab>
                        </Tooltip>
                        <Tooltip title={strings.actions.LOAD_TEMPLATE[language]}>
                            <Fab
                                onClick={toggleLoadTemplateModal}
                                variant='round'
                                size='medium'
                                color='primary'
                                className={classes.actionButton}
                            >
                                <ReplayRounded />
                            </Fab>
                        </Tooltip>
                    </Box>
                }
                {activeStep === 1 &&
                    <ServiceDetails
                        formData={formData}
                        setFormData={setFormData}
                        formErrors={formErrors}
                        setFormErrors={setFormErrors}
                        language={language}
                    />
                }
                {activeStep === 2 &&
                    <Summary
                        formData={formData}
                        formErrors={formErrors}
                        setFormData={setFormData}
                        language={language}
                    />
                }

                <MobileStepper
                    color='secondary'
                    variant="progress"
                    steps={4}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button size="small" onClick={onSubmitNextStep} disabled={activeStep === 3 || loadingCreateQuote || calledCreateQuote || loadingUpdateQuote || calledUpdateQuote || !gdprChecked}>
                            {activeStep === 2 ? strings.actions.DONE[language] : strings.actions.NEXT[language]}
                            <KeyboardArrowRightRounded />
                        </Button>
                    }
                    backButton={activeStep > 0 &&
                        <Button size="small" onClick={handlePrevStep} disabled={activeStep === 0 || loadingCreateQuote || loadingUpdateQuote}>
                            <KeyboardArrowLeftRounded />
                            {strings.actions.BACK[language]}
                        </Button>
                    }
                />

                <CustomModal
                    fullWidth
                    language={language}
                    title={strings.actions.SAVE_AS_TEMPLATE[language]}
                    open={saveTemplateModal}
                    disableExecute={templateName === ''}
                    content={
                        <TextField
                            fullWidth
                            required
                            variant='outlined'
                            value={templateName}
                            label={strings.general.TEMPLATE_NAME[language]}
                            onChange={(e) => setTemplateName(e.target.value)}
                        />
                    }
                    onClose={toggleSaveTemplateModal}
                    onExecute={onSubmitSaveTemplate}
                />
                <CustomModal
                    fullWidth
                    language={language}
                    title={strings.actions.LOAD_TEMPLATE[language]}
                    open={loadTemplateModal}
                    disableExecute={typeof templateId !== 'number'}
                    content={
                        <TextField
                            fullWidth
                            select
                            required
                            variant='outlined'
                            value={templateId}
                            label={strings.general.TEMPLATE[language]}
                            onChange={(e) => setTemplateId(Number(e.target.value))}
                        >
                            <MenuItem value={undefined}>
                                <em>-</em>
                            </MenuItem>
                            {quoteTemplates?.searchQuoteTemplates && quoteTemplates.searchQuoteTemplates.map((template: any) => (
                                <MenuItem key={template.id} value={template.id}>
                                    {`#${template.id} - ${template.title}`}
                                </MenuItem>
                            ))}
                        </TextField>
                    }
                    onClose={toggleLoadTemplateModal}
                    onExecute={onSubmitLoadTemplate}
                />
            </Paper>
        </MainWrapper >
    )
}
export default NewQuote;