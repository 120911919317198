export const MAIN_SECTION = {
    ro: 'Meniu',
    en: 'Menu',
}
export const ADMIN_SECTION = {
    ro: 'Administrare',
    en: 'Admin',
}
export const ACCOUNT_SECTION = {
    ro: 'Cont',
    en: 'Account',
}

export const FORBIDDEN = {
    url: '/forbidden',
}
export const GENERAL_SETTINGS = {
    url: '/settings?tab=0',
    label: {
        ro: 'Setări generale',
        en: 'General settings',
    },
}
export const PROFILE_SETTINGS = {
    url: '/settings?tab=1',
    label: {
        ro: 'Setări profil',
        en: 'Profile settings',
    },
}
export const NOTIFICATION_SETTINGS = {
    url: '/settings?tab=2',
    label: {
        ro: 'Gestionează notificările',
        en: 'Notification settings',
    },
}
export const SETTINGS = {
    url: '/settings',
    label: {
        ro: 'Setări',
        en: 'Settings',
    },
}
export const PROFILE = {
    url: '/settings?tab=1',
    label: {
        ro: 'Profil',
        en: 'Profile',
    },
}
export const SIGN_IN = {
    url: '/sign-in',
    label: {
        ro: 'Autentificare',
        en: 'Sign In',
    },
}
export const SIGN_UP = {
    url: '/sign-up',
    label: {
        ro: 'Înregistrare',
        en: 'Sign Up',
    },
}
export const DASHBOARD = {
    url: '/dashboard',
    label: {
        ro: 'Panou',
        en: 'Dashboard',
    },
    sublabel: {
        ro: 'Fluidizează-ți activitățile și administrează-ți proiectele mai ușor.',
        en: 'An easier way of managing your projects',
    },
}
export const CUSTOMERS = {
    url: '/customers',
    label: {
        ro: 'Clienți',
        en: 'Customers',
    },
    sublabel: {
        ro: 'Administrează-ți clienții',
        en: 'Manage your customers',
    },
}
export const CUSTOMER = (id: number) => ({
    url: `/customer?id=${id}`,
    label: {
        ro: 'Client',
        en: 'Customer',
    },
})
export const LEADS = {
    url: '/leads',
    label: {
        ro: 'Potențiali clienți',
        en: 'Leads',
    },
    sublabel: {
        ro: 'Administrează-ți clienții',
        en: 'Manage your customers',
    },
}
export const LEAD = (id: number) => ({
    url: `/lead?id=${id}`,
    label: {
        ro: 'Potențial client',
        en: 'Lead',
    },
})
export const QUOTES = {
    url: '/quotes',
    label: {
        ro: 'Oferte',
        en: 'Quotes',
    },
    sublabel: {
        ro: 'Fluidizează-ți activitățile și administrează-ți proiectele mai ușor.',
        en: 'An easier way of managing your projects',
    },
}
export const NEW_QUOTE = {
    url: '/new_quote',
    label: {
        ro: 'Ofertare',
        en: 'Quotation',
    },
    sublabel: {
        ro: 'Fluidizează-ți activitățile și administrează-ți proiectele mai ușor.',
        en: 'An easier way of managing your projects',
    },
}
export const PROJECTS = {
    url: '/projects',
    label: {
        ro: 'Proiecte',
        en: 'Projects',
    },
    sublabel: {
        ro: 'Fluidizează-ți activitățile și administrează-ți proiectele mai ușor.',
        en: 'An easier way of managing your projects',
    },
}
export const PROJECT = (id: number) => ({
    url: `/project?id=${id}`,
    label: {
        ro: 'Proiect',
        en: 'Project',
    },
})

export const EXECUTIONS = {
    url: '/executions',
    label: {
        ro: 'Execuții',
        en: 'Executions',
    },
    sublabel: {
        ro: 'Fluidizează-ți activitățile și administrează-ți proiectele mai ușor.',
        en: 'An easier way of managing your projects',
    },
}
export const EXECUTION = (id: number) => ({
    url: `/execution?id=${id}`,
    label: {
        ro: 'Execuție',
        en: 'Execution',
    },
})
export const FLEET = {
    url: '/fleet',
    label: {
        ro: 'Flotă auto',
        en: 'Car fleet',
    },
    sublabel: {
        ro: 'Adminstrează-ți autovehiculele',
        en: 'Manage your vehicles',
    },
}
export const CAR = (id: number) => ({
    url: `/vehicle?id=${id}`,
    label: {
        ro: 'Autovehicul',
        en: 'Car',
    },
})
export const USERS = {
    url: '/users',
    label: {
        ro: 'Utilizatori',
        en: 'Users',
    },
    sublabel: {
        ro: 'Administrează-ți utilizatorii',
        en: 'Manage your users',
    },
}
export const SERVICES = {
    url: '/services',
    label: {
        ro: 'Servicii',
        en: 'Services',
    },
    sublabel: {
        ro: 'Administrează-ți serviciile',
        en: 'Manage your services',
    },
}
export const SERVICE = (id: number) => ({
    url: `/service?id=${id}`,
    label: {
        ro: 'Serviciu',
        en: 'Service',
    },
})
export const VEHICLE = (id: number) => ({
    url: `/vehicle?id=${id}`,
    label: {
        ro: 'Vehicul',
        en: 'Vehicle',
    },
})
export const PRODUCTS = {
    url: '/products',
    label: {
        ro: 'Produse',
        en: 'Products',
    },
    sublabel: {
        ro: 'Administrează-ți produsele',
        en: 'Manage your products',
    },
}
export const PRODUCT = (id: number) => ({
    url: `/product?id=${id}`,
    label: {
        ro: 'Produs',
        en: 'Product',
    },
})

export const HISTORY = {
    url: '/productshistory',
    label: {
        ro:'Istoric prețuri',
        en: 'History prices'
    },
}

export const USER = (id: number) => ({
    url: `/user?id=${id}`,
    label: {
        ro: 'Utilizator',
        en: 'User',
    },
})
export const QUOTE = (id: number) => ({
    url: `/quote?id=${id}`,
    label: {
        ro: 'Ofertă',
        en: 'Quote',
    },
})
export const VERSIONS = {
    url: '/versions',
    label: {
        ro: 'Versiuni',
        en: 'Versions',
    },
    sublabel: {
        ro: 'Actualizări făcute și versiuni',
        en: 'Updates and past versions',
    },
}
