import { useLocation } from "react-router-dom";

export const getPageByNotificationType = (type: NotificationType) => {
    switch (type) {
        case 'NEW_USER': return 'USER';
        default: break;
    }
}

export const renderIfHasPermission = (permissions: boolean[], permissionNeeded: number) => permissions[permissionNeeded];

export const useRouteQuery = () => new URLSearchParams(useLocation().search);

export const openNewTab = (link: string) => window.open(link, '_blank');