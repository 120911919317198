import { Button, TextField, Theme, useMediaQuery } from "@material-ui/core";
import { Box, Divider, MenuItem } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { FC, useEffect, ChangeEvent } from "react";
import clsx from 'clsx';
import { getError } from "../../../functions/formFunctions";
import strings from "../../../strings";
import { FieldWithLabel } from "../../shared";
import { CustomerPhoneProps, CustomerPreviewProps } from "../types";

import styles from './style';

const CustomerPreview: FC<CustomerPreviewProps> = (props) => {
    const classes = styles();
    const { language, data, handleChangeField, formData, formErrors, toggleAddPhoneModal } = props;

    const isDownSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    useEffect(() => {
        if(data?.phoneNumbers && data?.phoneNumbers.length > 0) {
            handleChangeField({ target: { name: "customerPhoneId", value: data?.phoneNumbers[0].id } } as any)
        }
    }, [data]);

    return (
        <Box className={classes.root}>
            <FieldWithLabel
                label={strings.general.ID[language]}
                value={data?.id.toString() || '-'}
            />
            <Divider className={classes.divider} />
            <FieldWithLabel
                label={strings.general.FIRST_NAME[language]}
                value={data?.firstName || '-'}
            />
            <Divider className={classes.divider} />
            <FieldWithLabel
                label={strings.general.LAST_NAME[language]}
                value={data?.lastName || '-'}
            />
            <Divider className={classes.divider} />
            <FieldWithLabel
                label={strings.general.EMAIL[language]}
                value={data?.email || '-'}
            />
            <Divider className={classes.divider} />
            <FieldWithLabel
                label={strings.general.CNP[language]}
                value={data?.cnp || '-'}
            />
            <Divider className={classes.divider} />
            <TextField
                select
                fullWidth
                required
                className={classes.textField}
                variant="outlined"
                label={strings.general.PHONE[language]}
                value={data?.phoneNumbers ? (formData?.customerPhoneId ? formData.customerPhoneId : '') : ''}
                name='customerPhoneId'
                onChange={handleChangeField}
                error={Boolean(formErrors?.customerPhoneId)}
                helperText={getError(formErrors?.customerPhoneId, language)}
            >
                {data?.phoneNumbers ? data.phoneNumbers.map((item: CustomerPhoneProps, index: number) =>
                    <MenuItem
                        key={index}
                        value={item.id}
                    >
                        {item.phone}
                    </MenuItem>
                ) :
                    <MenuItem value=''>
                        <em>NONE</em>
                    </MenuItem>
                }
            </TextField>

            <Button
                onClick={toggleAddPhoneModal}
                className={classes.actionButton}
                color='secondary'
                size={isDownSm ? 'small' : 'medium'}
                variant={isDownSm ? 'contained' : 'outlined'}
            >
                <Add className={clsx(!isDownSm && classes.buttonIcon)} />
                {!isDownSm && strings.actions.ADD_PHONE[language]}
            </Button>
        </Box>
    )
}
export default CustomerPreview;