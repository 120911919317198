import { gql } from "@apollo/client";

const CREATE_EXECUTION = gql`
mutation onExecutionCreate($data: ExecutionCreateInput!) {
    createExecution(data: $data) {
        id
    }
}
`;

const SEARCH_EXECUTIONS = gql`
    query onExecutionsSearch($searchBy: String, $filters: ExecutionWhereInput, $orderBy: ExecutionOrderBy, $paginate: Pagination) {
        searchExecutions(searchBy: $searchBy, filters: $filters, orderBy: $orderBy, paginate: $paginate) {
            count
            index
            executions {
                id
                serviceName
                status
                scheduledDate
                quote {
                    id
                    fullName
                    phone
                    address
                    business {
                        name
                    }
                }
                createdAt
            }
        }
    }
`;

const SEARCH_EXECUTION_DETAIL = gql`
    query onSearchExecution($id: Int!) {
        searchExecution(id: $id) {
            id
            status
            serviceName
            scheduledDate
            actions {
                id
                author {
                    id
                    fullName
                }
                from
                to
                createdAt
            }
            quote {
                id
                customer {
                    id
                    firstName
                    lastName
                    fullName
                    cnp
                    email
                }
                customerPhone {
                    id
                    phone
                }
                business {
                    id
                    name
                    cif
                    nrc
                    account
                    bank
                }
            }
        }
    }
`;

const GENERATE_TICKETS = gql`
    mutation onGenerateTickets($executionIds: [Int], $scheduledDate_gte: Date, $scheduledDate_lte: Date) {
        generateExecutionSpreadSheet(executionIds: $executionIds, scheduledDate_gte: $scheduledDate_gte, scheduledDate_lte: $scheduledDate_lte)
    }
`;

const EXECUTION_ACTION = gql`
    mutation onExecutionAction($data: ExecutionActionCreateInput!) {
        executionAction(data: $data) {
            id
        }
    }
`;

export const execution = {
    query: {
        SEARCH_EXECUTIONS,
        SEARCH_EXECUTION_DETAIL,
    },
    mutation: {
        CREATE_EXECUTION,
        GENERATE_TICKETS,
        EXECUTION_ACTION,
    },
    subscription: {
    }
}