import { green, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    title: {
        margin: theme.spacing(0, 0, 2, 0),
    },
    root_paper: {
        overflow: 'hidden',
        marginTop: theme.spacing(4),
        padding: theme.spacing(2, 2, 0, 2),
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.background.paper
    },
    divider: {
        margin: theme.spacing(2, 0, 0, 0)
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    deleteButton: {
        color: red[500]
    },
    restoreButton: {
        color: green[500]
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonStd: {
        margin: theme.spacing(1),
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
    buttonError: {
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700],
        },
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}));