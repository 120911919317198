import { Box } from "@material-ui/core";
import { FC } from "react";
import clsx from 'clsx';

import { ResponsiveTypography } from "../../shared";
import styles from './style';

type SettingsTitleProps = {
    title: string;
    subtitle?: string;
    topMargin?: boolean;
}

const SettingsTitle: FC<SettingsTitleProps> = (props) => {
    const { title, subtitle, topMargin } = props;
    const classes = styles();

    return (
        <Box className={clsx(classes.root, topMargin && classes.rootMargin)}>
            <ResponsiveTypography
                fontWeight={700}
                growthRate={50}
                smDown={17}
                mdDown={28}
                lgDown={17}
            >
                {title}
            </ResponsiveTypography>
            <ResponsiveTypography
                fontWeight={400}
                growthRate={50}
                smDown={10}
                mdDown={20}
                lgDown={12}
            >
                {subtitle}
            </ResponsiveTypography>
        </Box>
    )
}
export default SettingsTitle;