import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey['300'] : theme.palette.common.black,
        transition: 'transform .3s ease-out',
        pointerEvents: 'none',
        transform: 'translateY(-105%)',
        zIndex: 3000,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        transitionDelay: '600ms',
        '& svg': {
            marginBottom: '50px',
        },
    },
    visible: {
        transform: 'translateY(0)',
        pointerEvents: 'all',
    },
    logo: {
        width: '424px',
        height: '111.5px',
        opacity: 1,
        transition: 'opacity .3s ease, transform .3s ease',
    },
    progress: {
        opacity: 1,
        transition: 'opacity .3s ease, transform .3s ease',
    },
    animated: {
        opacity: 0,
        transform: 'translateY(-50px)',
    },
}));