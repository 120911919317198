import { IconButton } from "@material-ui/core";
import { FC } from "react";
import clsx from "clsx";

type CustomIconButtonProps = {
    children?: React.ReactNode;
    color?: 'default' | 'inherit' | 'primary' | 'secondary';
    onClick: ((x?: any) => void) | undefined;
    className?: any;
};

const CustomIconButton: FC<CustomIconButtonProps> = (props) => {
    const {
        children,
        color = 'default',
        className,
        onClick,
    } = props;

    return (
        <IconButton
            onClick={onClick}
            color={color}
            className={
                clsx({
                    [className]: !!className
                })
            }>
            {children || ''}
        </IconButton>
    );
};

export default CustomIconButton;
