import React, { FC, useEffect} from 'react';
import styles from './style';
import { permissionAccess } from "../../functions/permissionAccess";
import { useHistory } from "react-router-dom";
import requests from "../../requests";
import strings from "../../strings";
import { MainWrapper, ResponsiveTypography } from "../../components/shared";
import { EnhancedTable } from '../../components/History';
import { useLazyQuery } from "@apollo/client";


type PriceHistoryAllType = {
    oldPrice: PriceHistoryProps;
    newPrice: PriceHistoryProps;
    createdAt: PriceHistoryProps;
    productId: PriceHistoryProps;
}

const ProductsHistory : FC<PageBaseProps> = (props) => {
    const classes = styles();
    const {language, auth} = props;
    const history = useHistory();

    useEffect(() => {
        document.title = `Corsem - ${strings.pages.HISTORY.label[language]}`;
    }, [language]);

    useEffect(() => {
        if (auth.me) 
            if (!permissionAccess(auth.me.data.permissions, 15)) history.replace(strings.pages.FORBIDDEN.url)
    })

    const [ onSearchProducts, {data, loading: loadingSearchProducts}] = useLazyQuery(requests.product.query.SEARCH_PRODUCTS, {
        fetchPolicy: "network-only",
    });

    useEffect(() =>{
        onSearchProducts();
    }, [])


    return(
        <MainWrapper>
              <ResponsiveTypography
                className={classes.title}
                growthRate={50}
                fontWeight={800}
                smDown={33}
                mdDown={45}
                lgDown={33}
            >
                {strings.pages.HISTORY.label[language]}
            </ResponsiveTypography>
            <ResponsiveTypography
                growthRate={50}
                fontWeight={300}
                smDown={12}
                mdDown={20}
                lgDown={12}
            >
                {strings.priceHistory.SUBTITLE[language]}
            </ResponsiveTypography>
            <EnhancedTable 
                data={data?.searchProducts?.products ?? []} 
                language={language} 
            />
           
        </MainWrapper>
    )

}

export default ProductsHistory;