import { FC, useEffect } from 'react'
import { Box, Typography, Grid, Divider } from '@material-ui/core'
import { ResponsiveTypography } from '../../components/shared'
import MainWrapper from '../../components/shared/MainWrapper'
import strings from '../../strings'
import jsonData from './changelog.json'

type features = string[]
type bugfixes = string[]

interface version {
    title: string
    description: string
    version: string
    features: features
    bugfixes: bugfixes
}

const Versions: FC<PageBaseProps> = (props) => {
    const { language } = props

    useEffect(() => {
        document.title = `Corsem - ${strings.pages.VERSIONS.label[language]}`
    }, [language])

    return (
        <MainWrapper>
            <Typography 
                variant='h1'
                style={{
                    fontWeight: 800,
                    marginBottom: '1rem'
                }}

            >
                {strings.pages.VERSIONS.label[language]}
            </Typography>
            <Typography>
                {strings.pages.VERSIONS.sublabel[language]}
            </Typography>
            <Box
                style={{
                    borderRadius: '25px',
                    padding: '3rem',
                }}
            >
                {jsonData.map((version: version) => (
                    <>
                        <Grid container justify='flex-start' spacing={10}>
                            <Grid item xs={12}>
                                <Typography
                                    variant='h1'
                                    style={{
                                        marginBottom: '1rem',
                                    }}
                                >
                                    {version.title} {version.version}
                                </Typography>
                                <Typography
                                    variant='h5'
                                    style={{ width: '90%' }}
                                >
                                    {version.description}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography
                                    variant='h3'
                                    style={{
                                        marginBottom: '1rem',
                                    }}
                                >
                                    Features
                                </Typography>
                                {version.features.map((feature) => (
                                    <Grid
                                        item
                                        spacing={5}
                                        style={{
                                            marginBottom: '0.5rem',
                                        }}
                                    >
                                        <Typography variant='h6'>
                                            {feature}
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid item xs>
                                <Typography
                                    variant='h3'
                                    style={{
                                        marginBottom: '1rem',
                                    }}
                                >
                                    Bugfixes
                                </Typography>
                                {version.bugfixes.map((bugfix) => (
                                    <Grid
                                        item
                                        spacing={5}
                                        style={{
                                            marginBottom: '0.5rem',
                                        }}
                                    >
                                        <Typography variant='h6'>
                                            {bugfix}
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Divider
                            style={{
                                margin: '2rem 0',
                            }}
                        />
                    </>
                ))}
            </Box>
        </MainWrapper>
    )
}
export default Versions
