import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    divider: {
        margin: theme.spacing(1, 0),
    },
    aux_root: {
        margin: theme.spacing(0, 0, 2, 0),
        boxShadow: `inset 5px 5px 10px ${theme.palette.common.black}${theme.palette.type === 'light' ? '20' : '2'}`,
    },
    actions: {
        margin: theme.spacing(0, 0, 2, 0),
    },
    buttonIcon: {
        marginRight: theme.spacing(1),
    },
    root_scheduled: {
        border: `1px solid ${theme.palette.secondary.light}`,
    },
}));