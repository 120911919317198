import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    avatar: {
        height: 90,
        width: 90,
        transition: "0.3s",
        cursor: "pointer",
        "&:hover": {
            opacity: 0.7,
            transition: "0.3s",
        },
    },
    selected: {
        transition: "0.3s",
        border: `2px solid ${theme.palette.secondary.main}`,
        cursor: "pointer",
    },
    wrapper: {
        display: "flex",
        justifyContent: "center",
    },
}));