import { user } from './user';
import { notification } from './notification';
import { product } from './product';
import { service } from './service';
import { customer } from './customer';
import { quote } from './quote';
import { general } from './general';
import { comment } from './comment';
import { execution } from './execution';
import { project } from './project';
import { vehicle } from './vehicle';
import { pricehistory } from './priceHistory';


const requests = {
    user,
    notification,
    product,
    service,
    customer,
    quote,
    general,
    comment,
    execution,
    project,
    vehicle,
    pricehistory
};

export default requests;