import { Box, Slide } from "@material-ui/core";
import React, { FC } from "react";
import styles from './style';

const MainWrapper: FC<{ children: React.ReactNode }> = ({ children }) => {
    const classes = styles();
    return (
        <Slide in={true} direction='up'>
            <Box className={classes.root}>
                {children}
            </Box>
        </Slide>
    )
}
export default MainWrapper;