import { ChangeEvent, FC, useCallback } from "react";
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Divider, Paper, SvgIconProps, TextField, Typography } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import clsx from 'clsx';

import styles from './style';
import strings from "../../../strings";
import requests from "../../../requests";
import { useState } from "react";
import { useEffect } from "react";
import {
    AddBoxRounded,
    DeleteRounded,
    ExpandMoreRounded,
    ArrowDropDownRounded,
    ArrowRightRounded,
    CheckBox as CheckBoxOn,
    CheckBoxOutlineBlankRounded as CheckBoxOff,
} from "@material-ui/icons";
import { ResponsiveTypography } from "../../shared";
import { Hidden } from "@material-ui/core";
import { TreeItem, TreeItemProps, TreeView } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import CustomModal from "../../shared/CustomModal";

type RoleListProps = {
    language: LanguageType;
}

type RoleProps = {
    id: number;
    name: string;
    value: number;
    permissions: boolean[];
    noOfPermissions: number;
}

type RoleItemProps = {
    language: LanguageType;
    role: RoleProps;
    expanded: number | false;
    roleData: RoleDataProps;
    togglePermission: (index: number) => void;
    closeAcordion: () => void;
    handleChange: (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
    handleRoleNameChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    loadingUpdateRole: boolean;
    handleUpdateRole: () => void;
    loadingDeleteRole: boolean;
    handleDeleteRole: () => void;
}

type AddRoleProps = {
    language: LanguageType;
    expanded: number | false;
    roleData: RoleDataProps;
    togglePermission: (index: number) => void;
    closeAcordion: () => void;
    handleChange: (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
    handleRoleNameChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    loadingCreateRole: boolean;
    handleCreateRole: () => void;
}

type RoleDataProps = {
    name: string;
    permissions: boolean[];
}

const RoleList: FC<RoleListProps> = (props) => {
    const { language } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [roleList, setRoleList] = useState<RoleProps[]>([]);
    const [expanded, setExpanded] = useState<number | false>(false);
    const [deleteRoleModal, setDeleteRoleModal] = useState<boolean>(false);
    const [roleData, setRoleData] = useState<RoleDataProps>({
        name: '',
        permissions: new Array(100).fill(false),
    })

    const closeAcordion = () => setExpanded(false);

    const { data: rolesData, refetch: refetchRoles } = useQuery(requests.user.query.SEARCH_ROLES, {
        fetchPolicy: "no-cache",
    });

    const [
        updateRole,
        { loading: loadingUpdateRole }
    ] = useMutation(requests.user.mutation.UPDATE_ROLE);

    const [
        deleteRole,
        { loading: loadingDeleteRole }
    ] = useMutation(requests.user.mutation.DELETE_ROLE);

    const [
        createRole,
        { loading: loadingCreateRole }
    ] = useMutation(requests.user.mutation.CREATE_ROLE);

    const handleCreateRole = useCallback(() => {
        createRole({
            variables: {
                name: roleData.name,
                permissions: roleData.permissions
            }
        }).then(() => {
            enqueueSnackbar(
                strings.notifications.ROLE_CREATE_SUCCESS[language],
                {
                    variant: "success",
                }
            );
            refetchRoles();
            closeAcordion();
        }).catch((error) => {
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }, [roleData, createRole, enqueueSnackbar, language, refetchRoles]);

    const handleUpdateRole = useCallback(() => {
        updateRole({
            variables: {
                id: expanded,
                name: roleData.name,
                permissions: roleData.permissions
            }
        }).then(() => {
            enqueueSnackbar(
                strings.notifications.ROLE_UPDATE_SUCCESS[language],
                {
                    variant: "success",
                }
            );
            refetchRoles();
            closeAcordion();
        }).catch((error) => {
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }, [roleData, enqueueSnackbar, expanded, language, refetchRoles, updateRole]);

    const handleDeleteRole = useCallback(() => {
        deleteRole({
            variables: {
                id: expanded,
            }
        }).then(() => {
            enqueueSnackbar(
                strings.notifications.ROLE_DELETE_SUCCESS[language],
                {
                    variant: "success",
                }
            );
            refetchRoles();
            closeAcordion();
            setDeleteRoleModal(false);
        }).catch((error) => {
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }, [deleteRole, enqueueSnackbar, expanded, language, refetchRoles]);


    const togglePermission = useCallback((index: number) => {
        let newPermissions = roleData.permissions;
        newPermissions[index] = !newPermissions[index];
        setRoleData({ ...roleData, permissions: newPermissions })
    }, [roleData]);

    const handleChange = (panel: number) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleRoleNameChange = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { value } = event.target;
        setRoleData({ ...roleData, name: value })
    }, [roleData]);


    useEffect(() => {
        if (rolesData?.roles && typeof expanded === 'number')
            if (expanded === 0)
                setRoleData({
                    name: '',
                    permissions: new Array(100).fill(false),
                });
            else {
                const role = roleList.find(role => role.id === expanded)
                setRoleData({
                    name: role?.name || '',
                    permissions: role?.permissions || new Array(100).fill(false),
                });
            }
        if (expanded === false)
            setRoleData({
                name: '',
                permissions: new Array(100).fill(false),
            });
    }, [expanded, roleList, rolesData?.roles])

    useEffect(() => {
        if (rolesData?.roles)
            setRoleList(rolesData.roles)
    }, [rolesData])

    return (
        <Paper elevation={5} style={{ overflow: 'hidden' }}>
            <CustomModal
                language={language}
                open={deleteRoleModal}
                onClose={() => setDeleteRoleModal(false)}
                title={strings.actions.DELETE_ROLE.title[language]}
                content={strings.actions.DELETE_ROLE.subtitle[language]}
                onExecute={handleDeleteRole}
            />
            <AddRoleItem
                language={language}
                expanded={expanded}
                roleData={roleData}
                togglePermission={togglePermission}
                handleChange={handleChange}
                closeAcordion={() => setExpanded(false)}
                handleRoleNameChange={handleRoleNameChange}
                loadingCreateRole={loadingCreateRole}
                handleCreateRole={handleCreateRole}
            />
            {roleList.filter(role => !['ADMIN', 'DEFAULT'].includes(role.name)).map((role: RoleProps, index: number) =>
                <RoleItem
                    key={index}
                    language={language}
                    role={role}
                    expanded={expanded}
                    roleData={roleData}
                    togglePermission={togglePermission}
                    handleChange={handleChange}
                    closeAcordion={() => setExpanded(false)}
                    handleRoleNameChange={handleRoleNameChange}
                    loadingUpdateRole={loadingUpdateRole}
                    handleUpdateRole={handleUpdateRole}
                    loadingDeleteRole={loadingDeleteRole}
                    handleDeleteRole={() => setDeleteRoleModal(true)}
                />
            )}
        </Paper>
    )
}
export default RoleList;

const RoleItem: FC<RoleItemProps> = (props) => {
    const { language, expanded, role, handleChange, roleData, closeAcordion, togglePermission, handleRoleNameChange, loadingDeleteRole, loadingUpdateRole, handleDeleteRole, handleUpdateRole } = props;
    const classes = styles();

    return (
        <Accordion className={classes.role_item} expanded={expanded === role.id} onChange={handleChange(role.id)}>
            <AccordionSummary
                expandIcon={<ExpandMoreRounded />}
                aria-controls={`${role.id}-${role.name}-content`}
                id={`${role.id}-${role.name}-header`}
            >
                <Box className={classes.addRoleTitle}>
                    {expanded === role.id ?
                        <TextField
                            label={strings.general.ROLE_NAME[language]}
                            variant='outlined'
                            value={roleData.name}
                            onClick={(e) => e.stopPropagation()}
                            onChange={handleRoleNameChange}
                            className={clsx(classes.column)}
                        />
                        :
                        <ResponsiveTypography
                            className={clsx(classes.addRoleTitle, classes.column)}
                        >
                            {role.name}
                        </ResponsiveTypography>

                    }
                    {expanded !== role.id &&
                        <Hidden mdDown>
                            <ResponsiveTypography
                                className={classes.column}
                                lgDown={12}
                            >
                                {strings.general.NO_OF_PERMISSIONS(role.noOfPermissions)[language]}
                            </ResponsiveTypography>
                        </Hidden>
                    }
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <PermissionTree
                    language={language}
                    roleData={roleData}
                    togglePermission={togglePermission}
                />
            </AccordionDetails>
            <Divider />
            <AccordionActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box className={classes.buttonWrapper}>
                    <Button
                        onClick={handleDeleteRole}
                        size="small"
                        disabled={loadingUpdateRole || loadingDeleteRole}
                        className={classes.deleteButton}
                    >
                        <DeleteRounded fontSize='small' />
                        {strings.actions.DELETE[language]}
                    </Button>
                    {loadingDeleteRole && <CircularProgress size={24} className={classes.progress} />}
                </Box>
                <Box style={{ flexBasis: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        size="small"
                        onClick={closeAcordion}
                        disabled={loadingUpdateRole || loadingDeleteRole}
                    >
                        {strings.actions.CANCEL[language]}
                    </Button>
                    <Box className={classes.buttonWrapper}>
                        <Button
                            onClick={handleUpdateRole}
                            size="small"
                            disabled={loadingUpdateRole || loadingDeleteRole}
                            color="secondary"
                        >
                            {strings.actions.SAVE[language]}
                        </Button>
                        {loadingUpdateRole && <CircularProgress size={24} className={classes.progress} />}
                    </Box>
                </Box>
            </AccordionActions>
        </Accordion>
    )
}

const AddRoleItem: FC<AddRoleProps> = (props) => {
    const { language, expanded, handleChange, roleData, togglePermission, closeAcordion, handleRoleNameChange, handleCreateRole, loadingCreateRole } = props;
    const name = '_ADD_ROLE';
    const classes = styles();


    return (
        <Accordion className={classes.role_item} expanded={expanded === 0} onChange={handleChange(0)}>
            <AccordionSummary
                expandIcon={<ExpandMoreRounded />}
                aria-controls={`${name}-content`}
                id={`${name}-header`}
            >
                <Box className={classes.addRoleTitle}>
                    <ResponsiveTypography className={clsx(classes.addRoleTitle, classes.column)}>
                        <AddBoxRounded className={classes.addRoleIcon} />
                        {strings.actions.ADD_NEW_ROLE.title[language]}
                    </ResponsiveTypography>
                    <Hidden mdDown>
                        <ResponsiveTypography
                            className={classes.column}
                            lgDown={12}
                        >
                            {strings.actions.ADD_NEW_ROLE.subtitle[language]}
                        </ResponsiveTypography>
                    </Hidden>
                </Box>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                    label={strings.general.ROLE_NAME[language]}
                    variant='outlined'
                    value={roleData.name}
                    onChange={handleRoleNameChange}
                    className={classes.roleNameField}
                />
                <PermissionTree
                    language={language}
                    roleData={roleData}
                    togglePermission={togglePermission}
                />
            </AccordionDetails>
            <Divider />
            <AccordionActions>
                <Button
                    size="small"
                    onClick={closeAcordion}
                    disabled={loadingCreateRole}
                >
                    {strings.actions.CANCEL[language]}
                </Button>
                <Box className={classes.buttonWrapper}>
                    <Button
                        onClick={handleCreateRole}
                        size="small"
                        disabled={loadingCreateRole}
                        color="secondary"
                    >
                        {strings.actions.SAVE[language]}
                    </Button>
                    {loadingCreateRole && <CircularProgress size={24} className={classes.progress} />}
                </Box>
            </AccordionActions>
        </Accordion>
    )
}


type StyledTreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    labelIcon: React.ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText: string;
    index?: number;
    roleData: RoleDataProps;
    togglePermission?: () => void;
};


function StyledTreeItem(props: StyledTreeItemProps) {
    const classes = styles();
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, roleData, index, togglePermission, ...other } = props;

    return (
        <TreeItem
            onClick={togglePermission}
            label={
                <div className={classes.labelRoot}>
                    <LabelIcon color="inherit" className={classes.labelIcon} />
                    <Typography variant="body2" className={classes.labelText}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                    {typeof index === 'number' && (roleData.permissions[index]
                        ? <CheckBoxOn color='action' fontSize='small' />
                        : <CheckBoxOff color='action' fontSize='small' />)
                    }
                </div>
            }
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}

type PermissionTreeProps = {
    language: LanguageType;
    roleData: RoleDataProps;
    togglePermission: (index: number) => void;
}

const PermissionTree: FC<PermissionTreeProps> = ({ language, roleData, togglePermission }) => {


    return (
        <TreeView
            style={{ width: '100%' }}
            defaultExpanded={['0']}
            defaultCollapseIcon={<ArrowDropDownRounded />}
            defaultExpandIcon={<ArrowRightRounded />}
            defaultEndIcon={<div style={{ width: 24 }} />}
        >
            {strings.permissions.permissions(language).map((item: any, index: number) =>
                <StyledTreeItem
                    key={index}
                    nodeId={index.toString()}
                    labelText={item.labelText}
                    labelIcon={item.labelIcon}
                    labelInfo={item.permissions
                        ? `${roleData.permissions.filter((value: boolean, index: number) =>
                            item.permissions.map((it: any) => it.index).includes(index) && value
                        ).length}/${item.permissions?.length}`
                        : undefined
                    }
                    roleData={roleData}
                    index={typeof item.index === 'number' ? item.index : undefined}
                    togglePermission={typeof item.index === 'number' ? () => togglePermission(item.index) : undefined}
                >
                    {item.permissions && item.permissions.map((element: any, indexEl: number) =>
                        <StyledTreeItem
                            key={indexEl}
                            nodeId={index.toString() + indexEl.toString()}
                            labelText={element.labelText}
                            labelIcon={element.labelIcon}
                            color={element.color}
                            bgColor={element.bgColor}
                            roleData={roleData}
                            index={element.index}
                            togglePermission={() => togglePermission(element.index)}
                        />
                    )}
                </StyledTreeItem>
            )}
        </TreeView>
    )
}