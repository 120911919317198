import { FC } from "react";

import styles from './style';
import { CustomWrapper, FieldWithLabel } from "../../shared";
import { IconButton } from "@material-ui/core";
import { OpenInNewRounded } from "@material-ui/icons";
import strings from "../../../strings";
import { useHistory } from "react-router";

type QuoteSectionProps = {
    language: LanguageType;
    data: QuoteProps | undefined;
    title: string;
}
const QuoteSection: FC<QuoteSectionProps> = (props) => {
    const classes = styles();
    const history = useHistory();
    const { data, title } = props;


    return (
        <CustomWrapper
            className={classes.root}
            title={title}
            link={
                <IconButton
                    size='small'
                    color='secondary'
                    onClick={() => data && history.push(strings.pages.QUOTE(data?.id).url)}
                >
                    <OpenInNewRounded fontSize='small' />
                </IconButton>
            } >
        </CustomWrapper>
    )
}
export default QuoteSection;