import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    divider: {
        margin: theme.spacing(1, 0),
        width: '100%',
    },
    textField: {
        margin: theme.spacing(1, 0),
    },
    actionButton: {
        margin: theme.spacing(1, 0),
    },
    buttonIcon: {
        marginRight: theme.spacing(1),
    },
}));