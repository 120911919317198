import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        minHeight: "50vh",
        padding: theme.spacing(2),
        // background: "linear-gradient(0deg, rgba(190,190,190,1) 0%, rgba(235,235,235,0.5830707282913166) 28%, rgba(255,255,255,0) 54%",
    },
    paper: {
        width: '424px',
        padding: theme.spacing(2),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 0, 0),
        borderRadius: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
    },
    link: {
        margin: theme.spacing(2, 0, 2, 0),
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        }
    },
    buttonWrapper: {
        position: 'relative',
    },
    progress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    textField: {
        borderRadius: theme.spacing(1),
    },
    divider: {
        margin: theme.spacing(2, 0, 0, 0),
    },
    logo: {
        width: '200px',
        height: '60px',
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
    }
}));