import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    content: {
        textAlign: "center",
    },
    image: {
        marginTop: 50,
        display: "inline-block",
        maxWidth: "100%",
        width: 560,
    },
}));