import { makeStyles, useTheme, Typography } from "@material-ui/core";
import { FC, useCallback } from "react";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    normalWeight: {
        fontWeight: 300,
    },
    boldWeight: {
        fontWeight: 700,
    },
    semiboldWeight: {
        fontWeight: 600,
    },
    fontFamilyMontserrat: {
        fontFamily: "Montserrat",
    },
    fontFamilyRoboto: {
        fontFamily: "Roboto",
    },
}));

const createCustomTypographyClass = (
    sm = 14,
    md = 16,
    lg = 16,
    growthRate = 100
) => {
    const getCalcSize = (size: number, rate: number, viewPort: number) => {
        const px = size - (size * rate) / 100;
        const vw = ((size - px) / viewPort) * 100;

        return `calc(${px}px + ${vw}vw)`;
    };

    const mdSize = getCalcSize(md, growthRate, 1850);
    const smSize = getCalcSize(lg, growthRate, 1920);

    const customStyle = makeStyles((theme: any) => ({
        root: {
            fontSize: smSize,
            [theme.breakpoints.down("md")]: {
                fontSize: mdSize,
            },
            [theme.breakpoints.down("xs")]: {
                fontSize: sm,
            },
        },
    }))();

    return customStyle.root;
};

const createCustomColorClass = (color: string, opacity = 100) => {
    const customStyle = makeStyles(() => ({
        root: {
            color:
                opacity < 100 && opacity > 0
                    ? `${color}${opacity < 10 ? "0" + opacity : opacity}`
                    : color,
        },
    }))();
    return customStyle.root;
};

const createCustomFontWeight = (weight: number) => {
    const style = makeStyles(() => ({
        root: {
            fontWeight: weight,
        },
    }))();

    return style.root;
};

type ResponsiveTypographyProps = {
    smDown?: number;
    mdDown?: number;
    lgDown?: number;
    color?: "primary" | string;
    className?: any;
    fontFamily?: "Montserrat" | "Roboto";
    fontWeight?:
    | "normal"
    | "semibold"
    | "bold"
    | 300
    | 400
    | 500
    | 600
    | 700
    | 800;
    children?: React.ReactNode;
    growthRate?: number;
    opacity?: number;
    noWrap?: boolean;
    fixedFontSize?: number
};

const ResponsiveTypography: FC<ResponsiveTypographyProps> = (props) => {
    const {
        smDown,
        mdDown,
        lgDown,
        color,
        className,
        fontFamily,
        fontWeight,
        growthRate,
        opacity,
        noWrap,
        fixedFontSize,
        children,
    } = props;
    const classes = useStyles();
    const theme = useTheme();

    const getCustomTypographyClass = useCallback(() =>
        fixedFontSize || createCustomTypographyClass(smDown, mdDown, lgDown, growthRate),
        [lgDown, mdDown, smDown, growthRate, fixedFontSize]
    );

    const getFontWeight = useCallback(() => {
        if (fontWeight === "bold") {
            return classes.boldWeight;
        } else if (fontWeight === "semibold") {
            return classes.semiboldWeight;
        } else if (typeof fontWeight === "number") {
            return createCustomFontWeight(fontWeight);
        } else {
            return classes.normalWeight;
        }
    }, [fontWeight, classes]);

    const getFontFamily = useCallback(() => {
        if (fontFamily === "Roboto") {
            return classes.fontFamilyRoboto;
        } else {
            return classes.fontFamilyMontserrat;
        }
    }, [fontFamily, classes]);

    const getCustomColor = useCallback(() => {
        let customColor;

        if (color === "primary") {
            customColor = theme.palette.text.primary;
        } else {
            customColor = color;
        }

        return createCustomColorClass(customColor || theme.palette.text.primary, opacity);
    }, [color, opacity, theme]);

    return (
        <Typography
            noWrap={noWrap ? noWrap : false}
            className={clsx({
                [getFontWeight()]: true,
                [getCustomTypographyClass()]: true,
                [getFontFamily()]: true,
                [getCustomColor()]: true,
                [className]: !!className
            }
            )}
        >
            {children || ''}
        </Typography>
    );
};

export default ResponsiveTypography;
