import { ChangeEvent, FC } from "react";
import { Grid, TextField } from "@material-ui/core";

import strings from "../../../strings";
import { getError } from "../../../functions/formFunctions";
import { UseFormReturn } from "react-hook-form";
import styles from './style';

type EmailModalProps = {
    language: LanguageType;
    useForm: UseFormReturn<EmailModalFieldProps>;
    onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const EmailModal: FC<EmailModalProps> = (props) => {
    const classes = styles();
    const { language, useForm, onChange } = props;
    const { register, watch, formState: { errors } } = useForm;

    return (
        <Grid container>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={strings.settings.NEW_EMAIL[language]}
                    className={classes.textField}
                    type="email"
                    variant="outlined"
                    autoComplete="email"
                    {...register("email", {
                        required: true,
                        pattern: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/i
                    })}
                    onChange={onChange}
                    error={!!errors?.email}
                    helperText={getError(errors.email, language, 8, 16)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={strings.settings.CONFIRM_EMAIL[language]}
                    className={classes.textField}
                    type="email"
                    variant="outlined"
                    autoComplete="email"
                    {...register("confirm", {
                        required: true,
                        pattern: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/i,
                        validate: value => value === watch('email'),
                    })}
                    onChange={onChange}
                    error={!!errors?.confirm}
                    helperText={getError(errors.confirm, language, 8, 16)}
                />
            </Grid>
        </Grid>
    );
};

export default EmailModal;
