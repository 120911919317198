import { ChangeEvent, FC } from "react";
import { Grid, TextField } from "@material-ui/core";

import strings from "../../../strings";
import { getError } from "../../../functions/formFunctions";
import { UseFormReturn } from "react-hook-form";
import styles from './style';

type NameModalProps = {
    language: LanguageType;
    useForm: UseFormReturn<NameModalFieldProps>;
    onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const NameModal: FC<NameModalProps> = (props) => {
    const classes = styles();
    const { language, useForm, onChange } = props;
    const { register, formState: { errors } } = useForm;

    return (
        <Grid container>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={strings.columns.FIRST_NAME[language]}
                    className={classes.textField}
                    variant="outlined"
                    autoComplete="firstName"
                    {...register("firstName", {
                        required: true,
                        minLength: 1,
                        maxLength: 20,
                    })}
                    onChange={onChange}
                    error={!!errors?.firstName}
                    helperText={getError(errors.firstName, language, 1, 20)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={strings.columns.LAST_NAME[language]}
                    className={classes.textField}
                    variant="outlined"
                    autoComplete="lastName"
                    {...register("lastName", {
                        required: true,
                        minLength: 1,
                        maxLength: 20,
                    })}
                    onChange={onChange}
                    error={!!errors?.lastName}
                    helperText={getError(errors.lastName, language, 1, 20)}
                />
            </Grid>
        </Grid>
    );
};

export default NameModal;
