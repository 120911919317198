import { ChangeEvent, FC, useCallback } from "react";
import { useMutation } from "@apollo/client";
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Divider,
    Hidden,
    Paper,
    TextField
} from "@material-ui/core";
import { AddBoxRounded, ExpandMoreRounded } from "@material-ui/icons";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import clsx from 'clsx';

import { ResponsiveTypography } from "../../shared";
import styles from './style';
import strings from "../../../strings";
import requests from "../../../requests";
import { getError } from "../../../functions/formFunctions";

type AddTypeSectionProps = {
    language: LanguageType;
    expanded: boolean;
    toggleAction: () => void;
}

type ProductTypeFormValues = {
    description: string;
};

const AddTypeSection: FC<AddTypeSectionProps> = (props) => {
    const classes = styles();
    const { enqueueSnackbar } = useSnackbar();
    const { language, expanded, toggleAction } = props;
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm<ProductTypeFormValues>();

    const handleToggle = () => {
        toggleAction();
        reset();
    }

    const onSubmit: SubmitHandler<ProductTypeFormValues> = (data, event) => {
        event?.preventDefault();
        createProductType({
            variables: {
                ...data,
            }
        }).then(() => {
            enqueueSnackbar(
                strings.notifications.CATEGORY_CREATE_SUCCESS[language],
                {
                    variant: "success",
                }
            );
            toggleAction();
            reset();
            setValue('description', "");
        }).catch((error) => {
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }

    const handleChangeField = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { name, value } = event.target;
        setValue(name as any, value);
    }, [setValue])

    const [
        createProductType,
        { loading: loadingCreateProductType }
    ] = useMutation(requests.product.mutation.CREATE_PRODUCT_TYPE);

    return (
        <Paper elevation={5} className={classes.root_paper}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Accordion className={classes.role_item} expanded={expanded} onChange={handleToggle}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreRounded />}
                        aria-controls={'content'}
                        id={'header'}
                    >
                        <Box className={classes.addRoleTitle}>
                            <ResponsiveTypography
                                className={clsx(classes.addRoleTitle, classes.column)}
                                growthRate={50}
                                mdDown={20}
                            >
                                <AddBoxRounded className={classes.addRoleIcon} />
                                {strings.actions.ADD_NEW_CATEGORY.title[language]}
                            </ResponsiveTypography>
                            <Hidden mdDown>
                                <ResponsiveTypography
                                    className={classes.column}
                                    growthRate={50}
                                    lgDown={12}
                                >
                                    {strings.actions.ADD_NEW_CATEGORY.subtitle[language]}
                                </ResponsiveTypography>
                            </Hidden>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                        {expanded &&
                            <TextField
                                variant='outlined'
                                disabled={loadingCreateProductType}
                                label={strings.columns.CATEGORY[language]}
                                {...register('description', {
                                    required: true,
                                })}
                                onChange={handleChangeField}
                                className={classes.textField}
                                error={Boolean(errors?.description)}
                                helperText={getError(errors.description, language)}
                            />
                        }
                    </AccordionDetails>
                    <Divider />
                    <AccordionActions>
                        <Button
                            size="small"
                            onClick={handleToggle}
                            disabled={loadingCreateProductType}
                        >
                            {strings.actions.CANCEL[language]}
                        </Button>
                        <Box className={classes.buttonWrapper}>
                            <Button
                                size="small"
                                disabled={loadingCreateProductType}
                                color="secondary"
                                type="submit"
                            >
                                {strings.actions.SAVE[language]}
                            </Button>
                            {loadingCreateProductType && <CircularProgress size={24} className={classes.progress} />}
                        </Box>
                    </AccordionActions>
                </Accordion>
            </form>
        </Paper>
    );
}

export default AddTypeSection;