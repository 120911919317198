import { FC } from "react";
import { Grid, TextField } from "@material-ui/core";

import { CustomerFormProps } from "../types";
import styles from './style';
import strings from "../../../strings";
import { getError } from "../../../functions/formFunctions";

const PhoneForm: FC<CustomerFormProps> = (props) => {
    const classes = styles();
    const { language, useForm, handleChangeField } = props;
    const { register, formState: { errors } } = useForm;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    required
                    variant="outlined"
                    className={classes.textField}
                    label={strings.general.PHONE[language]}
                    {...register('phone', {
                        required: true,
                        pattern: /^(\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|-)?([0-9]{3}(\s|\.|-|)){2}$/i,
                    })}
                    onChange={handleChangeField}
                    error={Boolean(errors.phone)}
                    helperText={getError(errors.phone, language)}
                />
            </Grid>
        </Grid>
    )
}
export default PhoneForm;