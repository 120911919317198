import { FC, useCallback, useEffect, useState } from "react";
import { AuxPreviewProps, AuxTypeProps } from "../types";
import styles from './style';
import strings from "../../../strings";
import { Box, Divider, Grid, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { ResponsiveTypography } from "../../shared";
import { Delete } from "@material-ui/icons";
import { useLazyQuery } from "@apollo/client";
import requests from "../../../requests";

const AuxPreview: FC<AuxPreviewProps> = (props) => {
    const classes = styles();
    const { language, index, auxData, handleDeleteAux, handleChangeField } = props;
    const [auxTypeData, setAuxTypeData] = useState<AuxTypeProps>();

    const [onSearchAuxType, { data }] = useLazyQuery(requests.service.query.SEARCH_AUX_TYPE, {
        fetchPolicy: "cache-first",
    });

    const handleSearcAuxType = useCallback(() => {
        onSearchAuxType({
            variables: {
                id: auxData.typeId,
            }
        })
    }, [onSearchAuxType, auxData.typeId]);

    useEffect(() => {
        handleSearcAuxType();
    }, [handleSearcAuxType]);

    useEffect(() => {
        if (data?.searchAuxType) {
            setAuxTypeData(data?.searchAuxType);
        }
    }, [data])

    return (
        <Box className={classes.root}>
            <ResponsiveTypography
                className={classes.title}
                fontWeight={600}
                growthRate={50}
                lgDown={15}
                mdDown={20}
                smDown={15}
            >
                {auxTypeData && `#${index + 1} ${auxTypeData.category.toUpperCase()}`}
                <IconButton className={classes.deleteButton} onClick={handleDeleteAux} size='small'>
                    <Delete fontSize='small' />
                </IconButton>
            </ResponsiveTypography>
            <Divider className={classes.dividerTitle} />
            <Grid container className={classes.content} direction='row' spacing={2}>
                {auxTypeData?.price &&
                    <Grid item xs={12} lg={4}>
                        <TextField
                            required
                            fullWidth
                            type='number'
                            variant='outlined'
                            label={strings.columns.PRICE[language]}
                            name='price'
                            value={auxData.price || ''}
                            onChange={(e) => handleChangeField(e, index)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">RON</InputAdornment>,
                            }}
                        />
                    </Grid>
                }
                {auxTypeData?.discount &&
                    <Grid item xs={12} lg={4}>
                        <TextField
                            required
                            fullWidth
                            type='number'
                            variant='outlined'
                            label={strings.columns.DISCOUNT[language]}
                            name='discount'
                            value={auxData.discount || ''}
                            onChange={(e) => handleChangeField(e, index)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">RON</InputAdornment>,
                            }}
                        />
                    </Grid>
                }
                <Grid item xs={12} lg={4}>
                    <TextField
                        fullWidth
                        multiline
                        variant='outlined'
                        label={strings.columns.OBS[language]}
                        name='obs'
                        value={auxData.obs || ''}
                        onChange={(e) => handleChangeField(e, index)}
                    />
                </Grid>
            </Grid>
        </Box >
    )
}
export default AuxPreview;