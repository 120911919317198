import { colors } from "@material-ui/core";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";

const white = "#ffffff";
const black = "#242424";
const blackContrast = "rgba(0, 0, 0, 0.87)";

const customColors = {
    light: '#84a2ce',
    main: '#668bc2',
    dark: '#476187',
    "50": '#e5ebf4',
    "100": '#becce5',
    "200": '#93abd3',
    "300": '#668bc2',
    "400": '#3e72b6',
    "500": '#005aae',
    "600": '#0052a4',
    "700": '#004899',
    "800": '#003e8e',
    "900": '#002e7a',
}

export const paletteLight: PaletteOptions = {
    common: {
        black,
        white,
    },
    type: "light",
    primary: {
        light: colors.grey[50],
        main: colors.grey[300],
        dark: colors.grey[600],
        contrastText: black,
    },
    secondary: {
        ...customColors,
        "900": '#27554b',
        contrastText: white,
    },
    error: {
        light: colors.red[300],
        main: colors.red[500],
        dark: colors.red[700],
        contrastText: white,
    },
    warning: {
        light: colors.orange[300],
        main: colors.orange[500],
        dark: colors.orange[700],
        contrastText: blackContrast,
    },
    info: {
        light: colors.blue[300],
        main: colors.blue[500],
        dark: colors.blue[700],
        contrastText: white,
    },
    success: {
        light: colors.green[300],
        main: colors.green[500],
        dark: colors.green[700],
        contrastText: blackContrast,
    },
    text: {
        primary: "rgba(0,0,0,0.62)",
        secondary: "rgba(0,0,0,0.54)",
        disabled: "rgba(0,0,0,0.38)",
        hint: "rgba(0,0,0,0.38)",
    },
    divider: "rgba(0,0,0,0.12)",
    background: {
        paper: white,
        default: white,
    },
    action: {
        active: "rgba(0,0,0,0.54)",
        hover: "rgba(0,0,0,0.1)",
        hoverOpacity: 0.04,
        selected: "rgba(0,0,0,0.2)",
        selectedOpacity: 0.08,
        disabled: "rgba(0,0,0,0.26)",
        disabledBackground: "rgba(0,0,0,0.12)",
        disabledOpacity: 0.38,
        focus: "rgba(0,0,0,0.12)",
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
    },
};
