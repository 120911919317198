export const CUSTOMER = {
    ro: "Client",
    en: "Customer"
};
export const ADDRESS = {
    ro: "Adresă",
    en: "Address"
};
export const HOME_ADDRESS = {
    ro: "Adresă domiciliu",
    en: "Residence"
};
export const WORK_ADDRESS = {
    ro: "Adresă lucrare",
    en: "Work address"
};
export const BILLING_ADDRESS = {
    ro: "Adresă facturare",
    en: "Billing address"
};
export const BILLING_INFO = {
    ro: "Facturare persoană juridică",
    en: "Business billing"
};
export const PROXY = {
    ro: "Persoană împuternicită",
    en: "Proxy"
};
export const COUNTRY = {
    ro: "Țară",
    en: "Country",
};
export const REGION = {
    ro: "Județ",
    en: "Region",
};
export const CITY = {
    ro: "Oraș",
    en: "City",
};
export const STREET_NAME = {
    ro: "Strada",
    en: "Street",
};
export const STREET_NO = {
    ro: "Nr.",
    en: "No.",
};
export const BUILDING = {
    ro: "Bloc",
    en: "Building",
};
export const ENTRANCE = {
    ro: "Scară",
    en: "Entrance",
};
export const APARTMENT = {
    ro: "Apartament",
    en: "Apartment",
};
export const FLOOR = {
    ro: "Etaj",
    en: "Floor",
};
export const ZIPCODE = {
    ro: "Cod poștal",
    en: "Zip code",
};
export const NAME = {
    ro: "Denumire",
    en: "Name",
};
export const CIF = {
	ro: "CIF",
	en: "FIC",
};
export const NRC = {
	ro: "Nr. Reg. Com.",
	en: "NRC",
};
export const ACCOUNT = {
	ro: "Cont",
	en: "Account",
};
export const BANK = {
	ro: "Bancă",
	en: "Bank",
};