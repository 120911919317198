import { FC, useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import SwipeableViews from 'react-swipeable-views';

import { HeaderWrapper, ResponsiveTypography, SettingsWrapper } from "../../components/shared";
import strings from "../../strings";
import styles from './style';
import { useRouteQuery } from "../../functions";
import { TabContext, TabPanel } from "@material-ui/lab";
import ProfileSettings from "../../components/Settings/ProfileSettings";
import GeneralSettings from "../../components/Settings/GeneralSettings";

const Settings: FC<PageBaseProps> = (props) => {
    const { language, auth } = props;
    const classes = styles();
    const query = useRouteQuery()
    const history = useHistory()
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (_: React.ChangeEvent<{}>, newTab: number) => history.push(`${strings.pages.SETTINGS.url}?tab=${newTab}`);

    useEffect(() => {
        const tabIndex = Number(query.get("tab"));
        tabIndex < 2 && setActiveTab(tabIndex)
    }, [query]);

    return (
        <TabContext value={activeTab.toString()}>
            <HeaderWrapper>
                <ResponsiveTypography className={classes.title} growthRate={50} fontWeight={800} smDown={20} mdDown={33} lgDown={33}>
                    {strings.general.SETTINGS_MESSAGE(auth?.me?.data?.profile?.firstName)[language]}
                </ResponsiveTypography>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab style={{ textTransform: 'none' }} label={strings.pages.GENERAL_SETTINGS.label[language]} />
                    <Tab style={{ textTransform: 'none' }} label={strings.pages.PROFILE_SETTINGS.label[language]} />
                    <Tab style={{ textTransform: 'none' }} label={strings.pages.NOTIFICATION_SETTINGS.label[language]} />
                </Tabs>
            </HeaderWrapper>
            <SettingsWrapper>
                <SwipeableViews
                    axis='x'
                    index={activeTab}
                    onChangeIndex={(index: number) => history.push(`${strings.pages.SETTINGS.url}?tab=${index}`)}
                >
                    <TabPanel value="0"><GeneralSettings {...props} /></TabPanel>
                    <TabPanel value="1"><ProfileSettings {...props} /></TabPanel>
                    <TabPanel value="2"></TabPanel>
                </SwipeableViews>
            </SettingsWrapper>
        </TabContext>
    )
}
export default Settings;