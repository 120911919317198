import { ChangeEvent, FC, useCallback } from "react";
import { useMutation } from "@apollo/client";
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Divider,
    Hidden,
    Paper,
} from "@material-ui/core";
import { AddBoxRounded, ExpandMoreRounded } from "@material-ui/icons";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import clsx from 'clsx';

import { ResponsiveTypography } from "../../shared";
import styles from './style';
import strings from "../../../strings";
import requests from "../../../requests";
import { AddTypeSectionProps, ServiceTypeFormValues } from "../types";
import { ServiceForm } from "..";


const AddTypeSection: FC<AddTypeSectionProps> = (props) => {
    const classes = styles();
    const { enqueueSnackbar } = useSnackbar();
    const { language, expanded, toggleAction, refetch } = props;

    const ServiceTypeForm = useForm<ServiceTypeFormValues>({
        defaultValues: {
            description: "",
            products: false,
            price: false,
            discount: false,
            schedulable: false,
            auxTypes: [],
        }
    });
    const { handleSubmit, unregister, setValue, reset, getValues } = ServiceTypeForm;

    const handleToggle = () => {
        toggleAction();
        reset();
    }

    const onSubmit: SubmitHandler<ServiceTypeFormValues> = (data, event) => {
        event?.preventDefault();
        createServiceType({
            variables: {
                ...data,
            }
        }).then(() => {
            enqueueSnackbar(
                strings.notifications.SERVICE_CREATE_SUCCESS[language],
                {
                    variant: "success",
                }
            );
            refetch && refetch();
            toggleAction();
            reset();
            setValue('description', "");
        }).catch((error) => {
            enqueueSnackbar(
                error.message in strings.notifications
                    ? strings.notifications[error.message as GlobalNotification][language]
                    : error.message,
                {
                    variant: "error",
                }
            );
        });
    }

    const onChangeAuxTypesNumber = useCallback((index?: number) => {
        let currentAuxTypes = getValues('auxTypes');
        if (typeof index === 'number') {
            const lastItemIndex = currentAuxTypes.length - 1;
            for (let i = index; i < currentAuxTypes.length - 1; i++) {
                const nextValue = getValues(`auxTypes.${i + 1}` as any);
                setValue(`auxTypes.${i}` as any, nextValue);
            }
            unregister(`auxTypes.${lastItemIndex}` as any);
        }
        else {
            const newItemIndex = currentAuxTypes?.length || 0;
            setValue(`auxTypes.${newItemIndex}.description` as any, "")
            setValue(`auxTypes.${newItemIndex}.discount` as any, false)
            setValue(`auxTypes.${newItemIndex}.project` as any, false)
            setValue(`auxTypes.${newItemIndex}.price` as any, false)
        }
    }, [setValue, getValues, unregister]);

    const handleChangeField = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, checked?: boolean) => {
        const { name, value } = event.target;
        setValue(name as any, typeof checked === 'boolean' ? checked : value);
    }, [setValue])

    const [
        createServiceType,
        { loading: loadingCreateServiceType }
    ] = useMutation(requests.service.mutation.CREATE_SERVICE_TYPE);

    return (
        <Paper elevation={5} className={classes.root_paper}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Accordion className={classes.role_item} expanded={expanded} onChange={handleToggle}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreRounded />}
                        aria-controls={'content'}
                        id={'header'}
                    >
                        <Box className={classes.addRoleTitle}>
                            <ResponsiveTypography
                                className={clsx(classes.addRoleTitle, classes.column)}
                                growthRate={50}
                                mdDown={20}
                            >
                                <AddBoxRounded className={classes.addRoleIcon} />
                                {strings.actions.ADD_NEW_SERVICE_TYPE.title[language]}
                            </ResponsiveTypography>
                            <Hidden mdDown>
                                <ResponsiveTypography
                                    className={classes.column}
                                    growthRate={50}
                                    lgDown={12}
                                >
                                    {strings.actions.ADD_NEW_SERVICE_TYPE.subtitle[language]}
                                </ResponsiveTypography>
                            </Hidden>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                        {expanded &&
                            <ServiceForm
                                language={language}
                                loading={loadingCreateServiceType}
                                handleChangeField={handleChangeField}
                                useForm={ServiceTypeForm}
                                onChangeAuxTypesNumber={onChangeAuxTypesNumber}
                                type='CREATE'
                            />
                        }
                    </AccordionDetails>
                    <Divider />
                    <AccordionActions>
                        <Button
                            size="small"
                            onClick={handleToggle}
                            disabled={loadingCreateServiceType}
                        >
                            {strings.actions.CANCEL[language]}
                        </Button>
                        <Box className={classes.buttonWrapper}>
                            <Button
                                size="small"
                                disabled={loadingCreateServiceType}
                                color="secondary"
                                type="submit"
                            >
                                {strings.actions.SAVE[language]}
                            </Button>
                            {loadingCreateServiceType && <CircularProgress size={24} className={classes.progress} />}
                        </Box>
                    </AccordionActions>
                </Accordion>
            </form>
        </Paper>
    );
}

export default AddTypeSection;