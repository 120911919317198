import { makeStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
    title: {
        margin: theme.spacing(0, 0, 2, 0),
    },
    root_paper: {
        overflow: 'hidden',
        marginTop: theme.spacing(4),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.background.paper
    },
    actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            justifyContent: "flex-end",
        }
    },
    actionButton: {
        marginLeft: theme.spacing(2),
    },
    deleteButton: {
        backgroundColor: theme.palette.error.main,
        marginLeft: 'auto',
        "&:hover": {
            backgroundColor: theme.palette.error.dark,
        }
    },
    buttonIcon: {
        marginRight: theme.spacing(1),
    },
    buttonRightIcon: {
        marginLeft: theme.spacing(1),
    },
    acceptButton: {
        backgroundColor: theme.palette.success.main,
        "&:hover": {
            backgroundColor: theme.palette.success.dark,
        },
    },
    refuseButton: {
        backgroundColor: theme.palette.error.main,
        "&:hover": {
            backgroundColor: theme.palette.error.dark,
        }
    },
    divider: {
        margin: theme.spacing(2, 0, 0, 0)
    },
   
    restoreButton: {
        color: green[500]
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonStd: {
        margin: theme.spacing(1),
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
    buttonError: {
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700],
        },
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    buttonWrapper: {
        position: 'relative',
    },
    textField: {
        marginTop: theme.spacing(2)
    }
}));