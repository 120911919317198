import { FC, useEffect } from "react";
import { Switch, Redirect, useLocation } from "react-router-dom";
import { Route } from "react-router-dom";
import Layout from "../components/Layout";

import { useLazyQuery } from "@apollo/client";
import requests from "../requests";
import { NotFoundView, DashboardView, VersionsView, SignInView, SignUpView, UsersView, CustomersView, QuotesView, ExecutionsView, UserView, SettingsView, ProductsView, ServicesView, ServiceView, NewQuoteView, ProjectsView, QuoteView, ProjectView, ExecutionView, LeadsView, FleetView, CustomerView, VehicleView, ForbiddenView, ProductView, ProductsHistoryView } from "../Views";
import { CustomRouteProps, RoutesProps } from './types';
import strings from "../strings";

const Routes: FC<RoutesProps> = (props) => {
    return (
        <Switch>
            <Redirect exact from="/" to="/dashboard" />
            {/* <Redirect exact from="/settings" to="/settings?tab=0" /> */}
            <RouteWithLayout
                component={SettingsView}
                layout={Layout}
                path={strings.pages.SETTINGS.url}
                {...props}
            />
            <RouteWithLayout
                component={VersionsView}
                exact
                layout={Layout}
                path={strings.pages.VERSIONS.url}
                {...props}
            />
            <RouteWithLayout
                component={DashboardView}
                exact
                layout={Layout}
                path={strings.pages.DASHBOARD.url}
                {...props}
            />
            <RouteWithLayout
                component={ProjectsView}
                exact
                layout={Layout}
                path={strings.pages.PROJECTS.url}
                {...props}
            />
            <RouteWithLayout
                component={ProjectView}
                exact
                layout={Layout}
                path={strings.pages.PROJECT(1).url.split('?')[0]}
                {...props}
            />
            <RouteWithLayout
                component={NewQuoteView}
                exact
                layout={Layout}
                path={strings.pages.NEW_QUOTE.url}
                {...props}
            />
            <RouteWithLayout
                component={UsersView}
                exact
                layout={Layout}
                path={strings.pages.USERS.url}
                {...props}
            />
            <RouteWithLayout
                component={ServicesView}
                exact
                layout={Layout}
                path={strings.pages.SERVICES.url}
                {...props}
            />
            <RouteWithLayout
                component={ServiceView}
                exact
                layout={Layout}
                path={strings.pages.SERVICE(1).url.split('?')[0]}
                {...props}
            />
            <RouteWithLayout
                component={ProductsView}
                exact
                layout={Layout}
                path={strings.pages.PRODUCTS.url}
                {...props}
            />
            <RouteWithLayout
            component={ProductView}
            exact
            layout={Layout}
            path={strings.pages.PRODUCT(1).url.split('?')[0]}
            {...props}
            />
            <RouteWithLayout
            component={ProductsHistoryView}
            exact
            layout={Layout}
            path={strings.pages.HISTORY.url.split('?')[0]}
            {...props}

            >

            </RouteWithLayout>
            
            <RouteWithLayout
                component={UserView}
                exact={false}
                layout={Layout}
                path={strings.pages.USER(1).url.split('?')[0]}
                {...props}
            />
            <RouteWithLayout
                component={CustomersView}
                exact
                layout={Layout}
                path={strings.pages.CUSTOMERS.url}
                {...props}
            />
            <RouteWithLayout
                component={CustomerView}
                exact
                layout={Layout}
                path={strings.pages.CUSTOMER(1).url.split('?')[0]}
                {...props}
            />
            <RouteWithLayout
                component={LeadsView}
                exact
                layout={Layout}
                path={strings.pages.LEADS.url}
                {...props}
            />
            <RouteWithLayout
                component={FleetView}
                exact
                layout={Layout}
                path={strings.pages.FLEET.url}
                {...props}
            />
            <RouteWithLayout
                component={VehicleView}
                exact
                layout={Layout}
                path={strings.pages.VEHICLE(1).url.split('?')[0]}
                {...props}
            />
            <RouteWithLayout
                component={QuotesView}
                exact
                layout={Layout}
                path={strings.pages.QUOTES.url}
                {...props}
            />
            <RouteWithLayout
                component={QuoteView}
                exact={false}
                layout={Layout}
                path={strings.pages.QUOTE(1).url.split('?')[0]}
                {...props}
            />
            <RouteWithLayout
                component={ExecutionsView}
                exact
                layout={Layout}
                path={strings.pages.EXECUTIONS.url}
                {...props}
            />
            <RouteWithLayout
                component={ExecutionView}
                exact
                layout={Layout}
                path={strings.pages.EXECUTION(1).url.split('?')[0]}
                {...props}
            />
            <RouteWithoutLayout
                component={SignInView}
                exact
                path={strings.pages.SIGN_IN.url}
                {...props}
            />
            <RouteWithoutLayout
                component={SignUpView}
                exact
                path={strings.pages.SIGN_UP.url}
                {...props}
            />
            <Route
                component={ForbiddenView}
                exact
                path={strings.pages.FORBIDDEN.url}
            />
            <Route component={NotFoundView} exact path="*" />
        </Switch>
    );
};

export default Routes;

const RouteWithLayout: FC<CustomRouteProps> = (props) => {
    const { layout: Layout, component: Component, ...rest } = props;
    const { auth } = props;
    const location = useLocation();

    const [me, { data, loading, error, refetch }] = useLazyQuery(requests.user.query.ME_QUERY, {
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (location.pathname !== "/sign-in" && !data && auth.isAuthenticated) me();
    }, [auth, me, location, data]);

    useEffect(() => {
        if (error) auth.logout();
    }, [data, error, auth]);

    return (
        <Route
            {...rest}
            render={({ location }) => auth.isAuthenticated ?
                <Layout {...rest}
                    me={{ data: { ...data?.me }, loading }}
                    auth={{
                        ...auth,
                        me: {
                            me,
                            data: { ...data?.me },
                            loading,
                            error,
                            refetch,
                        }
                    }}
                >
                    <Component {...rest}
                        me={{ data: { ...data?.me }, loading }}
                        auth={{
                            ...auth,
                            me: {
                                me,
                                data: { ...data?.me },
                                loading,
                                error,
                                refetch,
                            }
                        }}
                    />
                </Layout >
                :
                <Redirect
                    to={{
                        pathname: '/sign-in',
                        state: { from: location }
                    }}
                />
            }
        />
    );
};


const RouteWithoutLayout: FC<CustomRouteProps> = (props) => {
    const { component: Component, ...rest } = props;
    const { auth } = props;

    const authRoutes = [
        strings.pages.SIGN_IN.url,
        strings.pages.SIGN_UP.url,
    ];

    return (
        <Route
            {...rest}
            render={({ location }) => auth.isAuthenticated && authRoutes.includes(location.pathname) ?
                <Redirect
                    to={{
                        pathname: '/dashboard',
                        state: { from: location }
                    }}
                />
                :
                <Component {...rest} />
            }
        />
    );
};
