import React, { FC } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Typography,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import clsx from 'clsx';
import styles from './style';
import strings from "../../../strings";
import { UseFormHandleSubmit } from "react-hook-form";

type CustomModalProps = {
    open: boolean;
    language: LanguageType;
    onClose: () => void;
    onExecute?: (data?: any) => void;
    backButtonLabel?: string | false;
    doneButtonLabel?: string | false;
    title?: string;
    content?: React.ReactNode;
    nonDismissible?: boolean;
    disableExecute?: boolean;
    customBackground?: boolean;
    fullWidth?: boolean;
    fullScreen?: boolean;
    form?: boolean;
    handleSubmit?: UseFormHandleSubmit<any>;
}

const CustomModal: FC<CustomModalProps> = (props) => {
    const classes = styles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const {
        customBackground,
        nonDismissible,
        title,
        content,
        onExecute,
        handleSubmit,
        language,
        form,
        disableExecute,
        backButtonLabel,
        doneButtonLabel,
        children,
        ...rest
    } = props;

    rest.fullScreen = rest.fullScreen || fullScreen;

    const onSubmit = handleSubmit !== undefined && onExecute !== undefined
        ? handleSubmit(data => onExecute(data))
        : undefined;

    return (
        <Dialog
            {...rest}
            disableBackdropClick={nonDismissible}
            disableEscapeKeyDown={nonDismissible}
            TransitionComponent={Slide}
            closeAfterTransition={true}
            style={{ zIndex: 3000 }}
        > <div
            className={clsx(classes.form, {
                [classes.customBackground]: customBackground,
            })}
            style={{ height: rest.fullScreen ? "100%" : "auto" }}
        >
                {typeof title === "string" && (
                    <DialogTitle>{title}</DialogTitle>
                )}

                <DialogContent
                    style={{
                        maxHeight:
                            !rest.fullScreen && !fullScreen ? "70vh" : "auto",
                    }}
                >
                    {typeof content === "string" ? (
                        <DialogContentText>{content}</DialogContentText>
                    ) : (
                        content
                    )}
                    {children}
                </DialogContent>

                <DialogActions className={classes.button}>
                    <>
                        {backButtonLabel !== false && <Button onClick={rest.onClose}>
                            <Typography color="error">
                                {typeof backButtonLabel === 'string' ? backButtonLabel : strings.actions.BACK[language]}
                            </Typography>
                        </Button>}
                        {!!onExecute && doneButtonLabel !== false &&
                            (form && handleSubmit ? (
                                <Button onClick={onSubmit} disabled={disableExecute}>
                                    <Typography>
                                        {typeof doneButtonLabel === 'string' ? doneButtonLabel : strings.actions.DONE[language]}
                                    </Typography>
                                </Button>
                            ) : (
                                <Button onClick={onExecute} disabled={disableExecute}>
                                    <Typography>
                                        {typeof doneButtonLabel === 'string' ? doneButtonLabel : strings.actions.DONE[language]}
                                    </Typography>
                                </Button>
                            ))
                        }
                    </>
                </DialogActions>
            </div>

        </Dialog>
    )
}
export default CustomModal;