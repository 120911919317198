import { ChangeEvent, FC } from "react";
import {
    Box,
    Grid,
    TextField,
} from "@material-ui/core";
import { UseFormReturn } from "react-hook-form";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';

import strings from "../../../strings";
import { getError } from "../../../functions/formFunctions";
import { VehicleFormValues } from "../types";
import moment from "moment";

type SerivceTypeFormProps = {
    language: LanguageType;
    loading: boolean;
    useForm: UseFormReturn<VehicleFormValues>;
    handleChangeField: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, checked?: boolean) => void;
}

const SerivceTypeForm: FC<SerivceTypeFormProps> = (props) => {
    const { language, loading, handleChangeField, useForm } = props;
    const { register, watch, setValue, formState: { errors } } = useForm;

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                    <TextField
                        fullWidth
                        variant='outlined'
                        disabled={loading}
                        value={watch('manufacturer')}
                        placeholder='Mercedes'
                        label={strings.columns.MANUFACTURER[language]}
                        {...register('manufacturer', {
                            required: true,
                        })}
                        onChange={handleChangeField}
                        error={Boolean(errors?.manufacturer)}
                        helperText={getError(errors.manufacturer, language)}
                    />
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                    <TextField
                        fullWidth
                        variant='outlined'
                        disabled={loading}
                        value={watch('model')}
                        placeholder='Benz'
                        label={strings.columns.MODEL[language]}
                        {...register('model')}
                        onChange={handleChangeField}
                    />
                </Grid>
                <Grid item lg={2} md={2} sm={4} xs={4}>
                    <TextField
                        fullWidth
                        variant='outlined'
                        disabled={loading}
                        value={watch('plate1')}
                        placeholder='SV'
                        label={strings.columns.VEHICLE_PLATE[language]}
                        {...register('plate1', {
                            required: true,
                        })}
                        onChange={handleChangeField}
                        error={Boolean(errors?.plate1)}
                        helperText={getError(errors.plate1, language)}
                    />
                </Grid>
                <Grid item lg={2} md={2} sm={4} xs={4}>
                    <TextField
                        fullWidth
                        variant='outlined'
                        disabled={loading}
                        value={watch('plate2')}
                        placeholder='01'
                        label={strings.columns.VEHICLE_PLATE[language]}
                        {...register('plate2', {
                            required: true,
                        })}
                        onChange={handleChangeField}
                        error={Boolean(errors?.plate2)}
                        helperText={getError(errors.plate2, language)}
                    />
                </Grid>
                <Grid item lg={2} md={2} sm={4} xs={4}>
                    <TextField
                        fullWidth
                        variant='outlined'
                        disabled={loading}
                        value={watch('plate3')}
                        placeholder='XYZ'
                        label={strings.columns.VEHICLE_PLATE[language]}
                        {...register('plate3', {
                            required: true,
                        })}
                        onChange={handleChangeField}
                        error={Boolean(errors?.plate3)}
                        helperText={getError(errors.plate3, language)}
                    />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            autoOk
                            orientation="portrait"
                            openTo="date"
                            inputVariant="outlined"
                            fullWidth
                            label={strings.columns.RCA[language]}
                            format="D/MM/yyyy"
                            value={watch('RCA') || null}
                            onChange={(date: any) => setValue('RCA', moment(date).toDate())}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            autoOk
                            orientation="portrait"
                            openTo="date"
                            inputVariant="outlined"
                            fullWidth
                            label={strings.columns.ITP[language]}
                            format="D/MM/yyyy"
                            value={watch('ITP') || null}
                            onChange={(date: any) => setValue('ITP', moment(date).toDate())}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            autoOk
                            orientation="portrait"
                            openTo="date"
                            inputVariant="outlined"
                            fullWidth
                            label={strings.columns.ROV[language]}
                            format="D/MM/yyyy"
                            value={watch('ROV') || null}
                            onChange={(date: any) => setValue('ROV', moment(date).toDate())}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
        </Box>
    );
}

export default SerivceTypeForm;