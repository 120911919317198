import { FC } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";

import { CityProps, CustomerFormProps, RegionProps } from "../types";
import styles from './style';
import strings from "../../../strings";
import { getError } from "../../../functions/formFunctions";
import { VirtualizedSelect } from "../../shared";
import { useLazyQuery, useQuery } from "@apollo/client";
import requests from "../../../requests";
import { useEffect } from "react";

const AddressForm: FC<CustomerFormProps> = (props) => {
    const classes = styles();
    const { language, useForm, handleChangeField, onHandleChangeRegion, onHandleChangeCity } = props;
    const { register, watch, formState: { errors } } = useForm;

    const { data: regionList } = useQuery(requests.general.query.SEARCH_REGIONS, {
        fetchPolicy: "cache-first",
        variables: {
            read: false,
        }
    });

    const [searchCities, {
        data: cityList,
    }] = useLazyQuery(requests.general.query.SEARCH_CITIES, {
        fetchPolicy: "cache-and-network",
        variables: {
            // regionId
        }
    });

    let regionId = watch('address.regionId');

    useEffect(() => {
        if (watch('address.regionId'))
            searchCities({
                variables: {
                    regionId: watch('address.regionId')
                }
            })
    }, [searchCities, watch, regionId])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <VirtualizedSelect
                    language={language}
                    options={regionList?.searchRegions || []}
                    onChange={onHandleChangeRegion}
                    getOptionSelected={(option: RegionProps, value: RegionProps) => option.id === value.id}
                    getOptionLabel={(option: RegionProps) => option.region}
                    renderOption={(option: RegionProps) => <Typography noWrap>{option.region}</Typography>}
                    textFieldProps={{
                        required: true,
                        fullWidth: true,
                        label: strings.customer.REGION[language],
                        ...register('address.regionId', {
                            required: true,
                        }),
                        error: Boolean(errors.address?.regionId),
                        helperText: getError(errors.address?.regionId, language),
                    }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <VirtualizedSelect
                    language={language}
                    options={cityList?.searchCities || []}
                    onChange={onHandleChangeCity}
                    disabled={watch('address.regionId') === undefined || watch('address.regionId') === 0}
                    getOptionSelected={(option: CityProps, value: CityProps) => option.id === value.id}
                    getOptionLabel={(option: CityProps) => option.city}
                    renderOption={(option: CityProps) => <Typography noWrap>{option.city}</Typography>}
                    textFieldProps={{
                        required: true,
                        fullWidth: true,
                        label: strings.customer.CITY[language],
                        ...register('address.cityId', {
                            required: true,
                        }),
                        error: Boolean(errors.address?.cityId),
                        helperText: getError(errors.address?.cityId, language),
                    }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.textField}
                    label={strings.customer.STREET_NAME[language]}
                    {...register('address.streetName')}
                    onChange={handleChangeField}
                />
            </Grid>
            <Grid item xs={6} md={3}>
                <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.textField}
                    label={strings.customer.STREET_NO[language]}
                    {...register('address.streetNo')}
                    onChange={handleChangeField}
                />
            </Grid>
            <Grid item xs={6} md={3}>
                <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.textField}
                    label={strings.customer.BUILDING[language]}
                    {...register('address.building')}
                    onChange={handleChangeField}
                />
            </Grid>
            <Grid item xs={6} md={3}>
                <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.textField}
                    label={strings.customer.ENTRANCE[language]}
                    {...register('address.entrance')}
                    onChange={handleChangeField}
                />
            </Grid>
            <Grid item xs={6} md={3}>
                <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.textField}
                    label={strings.customer.FLOOR[language]}
                    {...register('address.floor')}
                    onChange={handleChangeField}
                />
            </Grid>
            <Grid item xs={6} md={3}>
                <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.textField}
                    label={strings.customer.APARTMENT[language]}
                    {...register('address.apartment')}
                    onChange={handleChangeField}
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.textField}
                    label={strings.customer.ZIPCODE[language]}
                    {...register('address.zip')}
                    onChange={handleChangeField}
                />
            </Grid>
        </Grid>
    )
}
export default AddressForm;