import { FC } from "react";
import clsx from 'clsx';

import styles from './style';
import strings from "../../../strings";
import { CustomWrapper, FieldWithLabel } from "../../shared";
import { Button, ButtonGroup, Divider, Grid, Theme, Tooltip, useMediaQuery } from "@material-ui/core";
import { GetAppRounded, OpenInNewRounded, ScheduleRounded, SquareFootRounded } from "@material-ui/icons";
import { useHistory } from "react-router";

type ServiceSectionProps = {
    language: LanguageType;
    data: ServiceProps;
    toggleScheduleModal: () => void;
    toggleProjectModal: () => void;
    quoteAccepted?: boolean;
}
const ServiceSection: FC<ServiceSectionProps> = (props) => {
    const classes = styles();
    const history = useHistory();
    const { data, language, toggleProjectModal, toggleScheduleModal, quoteAccepted } = props;

    const isDownSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    return (
        <CustomWrapper className={clsx(Boolean(data.execution) && classes.root_scheduled)} title={data.type.description.description}>
            {quoteAccepted && <ButtonGroup
                color='secondary'
                variant='contained'
                fullWidth
                className={classes.actions}
            >
                {data.type.schedulable && (
                    Boolean(data.execution) ?
                        <Tooltip title={strings.actions.GO_TO_PAGE(strings.pages.EXECUTION(1).label[language])[language]}>
                            <Button
                                onClick={() => data.execution?.id && history.push(strings.pages.EXECUTION(data.execution?.id).url)}
                            >
                                <OpenInNewRounded className={classes.buttonIcon} />
                                {!isDownSm && data.products.length > 0 && strings.actions.GO_TO_PAGE(strings.pages.EXECUTION(1).label[language])[language]}
                            </Button>
                        </Tooltip>
                        :
                        <Tooltip title={strings.actions.SCHEDULE[language]}>
                            <Button
                                onClick={toggleScheduleModal}
                            >
                                <ScheduleRounded className={classes.buttonIcon} />
                                {!isDownSm && data.products.length > 0 && strings.actions.SCHEDULE[language]}
                            </Button>
                        </Tooltip>
                )}
                {data.auxs.some(aux => aux.type.project) && (
                    Boolean(data.project) ?
                        <Tooltip title={strings.actions.SCHEDULE[language]}>
                            <Button
                                onClick={() => data.project?.id && history.push(strings.pages.PROJECT(data.project?.id).url)}
                            >
                                <OpenInNewRounded className={classes.buttonIcon} />
                                {!isDownSm && data.products.length > 0 && strings.actions.GO_TO_PAGE(strings.pages.PROJECT(1).label[language])[language]}
                            </Button>
                        </Tooltip>
                        :
                        <Tooltip title={strings.actions.CREATE_PROJECT[language]}>
                            <Button
                                onClick={toggleProjectModal}
                            >
                                <SquareFootRounded className={classes.buttonIcon} />
                                {!isDownSm && data.products.length > 0 && strings.actions.CREATE_PROJECT[language]}
                            </Button>
                        </Tooltip>
                )
                }
                <Tooltip title={strings.actions.DOWNLOAD_CONTRACT[language]}>
                    <Button
                        color='default'
                        disabled
                    >
                        <GetAppRounded className={classes.buttonIcon} />
                        {!isDownSm && data.products.length > 0 && strings.actions.DOWNLOAD_CONTRACT[language]}
                    </Button>
                </Tooltip>
            </ButtonGroup>}
            <Grid container spacing={2}>
                <Grid item xs={12} md={data.products?.length > 0 ? 6 : 12}>
                    {data.auxs.map((aux: AuxProps, index: number) =>
                        <AuxSection
                            key={index}
                            language={language}
                            data={aux}
                        />
                    )}
                </Grid>
                {data.products?.length > 0 &&
                    <Grid item xs={12} md={6}>
                        {data.products.map((prod: ServiceProductProps, index: number) =>
                            <ProductSection
                                key={index}
                                language={language}
                                data={prod}
                            />
                        )}
                    </Grid>
                }
            </Grid>
            {
                data.type.price &&
                <>
                    <FieldWithLabel
                        label={strings.columns.PRICE[language]}
                        value={`${data?.price?.toFixed(2) === undefined ? `0` : data.price.toFixed(2)} RON`}
                    />
                    <Divider className={classes.divider} />
                </>
            }
            {
                data.type.discount && data.discount &&
                <>
                    <FieldWithLabel
                        label={strings.columns.DISCOUNT[language]}
                        value={`${data?.discount?.toFixed(2)} RON` || '-'}
                    />
                    <Divider className={classes.divider} />
                </>
            }
            {
                data.obs &&
                <FieldWithLabel
                    label={strings.columns.OBS[language]}
                    value={data?.obs || '-'}
                />
            }
        </CustomWrapper >
    )
}
export default ServiceSection;

type AuxSectionProps = {
    language: LanguageType;
    data: AuxProps;
}
const AuxSection: FC<AuxSectionProps> = (props) => {
    const classes = styles();
    const { data, language } = props;


    return (
        <CustomWrapper className={classes.aux_root} title={data.type.description.description}>
            {data.type.price &&
                <>
                    <FieldWithLabel
                        label={strings.columns.PRICE[language]}
                        value={`${data?.price?.toFixed(2)} RON` || '-'}
                    />
                    <Divider className={classes.divider} />
                </>
            }
            {data.type.discount && data.discount &&
                <>
                    <FieldWithLabel
                        label={strings.columns.DISCOUNT[language]}
                        value={`${data?.discount?.toFixed(2)} RON` || '-'}
                    />
                    <Divider className={classes.divider} />
                </>
            }
            {data.obs &&
                <FieldWithLabel
                    label={strings.columns.OBS[language]}
                    value={data?.obs || '-'}
                />
            }
        </CustomWrapper >
    )
}


type ProductSectionProps = {
    language: LanguageType;
    data: ServiceProductProps;
}
const ProductSection: FC<ProductSectionProps> = (props) => {
    const classes = styles();
    const { data, language } = props;


    return (
        <CustomWrapper className={classes.aux_root} title={`${data.product.category} - ${data.product.manufacturer} ${data.product.model}`}>
            <FieldWithLabel
                label={strings.columns.AMOUNT[language]}
                value={`${data.amount}` || '-'}
            />
            <Divider className={classes.divider} />
            <FieldWithLabel
                label={strings.columns.PRICE[language]}
                value={`${data.product.price.toFixed(2)} RON` || '-'}
            />
        </CustomWrapper >
    )
}