import { FC, useCallback, useEffect, useRef, useState } from "react";
import { ClickAwayListener, AppBar, Toolbar, Hidden, IconButton, Theme, Box, Slide, Chip, Avatar, Badge, Collapse, Paper, MenuItem, Divider, createStyles, withStyles } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {
    ExpandMoreRounded,
    MeetingRoomRounded,
    AccountCircleRounded,
    Notifications as NotificationIcon,
    SettingsRounded as SettingsIcon,
} from "@material-ui/icons";
import styles from './style';
import clsx from 'clsx';
import { ReactComponent as RaisisCRMLogo } from '../../../../resources/svg/logo.svg';
import { ReactComponent as RaisisCRMLogoDark } from '../../../../resources/svg/logo-white.svg';
import { useHistory } from "react-router";
import strings from "../../../../strings";
import { ResponsiveTypography } from "../../../shared";
import { useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import requests from "../../../../requests";
import Notifications from '../Notifications';
import { getPageByNotificationType } from "../../../../functions";

type TopbarProps = {
    className?: string;
    onSidebarOpen: () => void;
    toggleTheme: () => void;
    theme: Theme;
    auth: AuthProps;
    language: LanguageType;
    sideBarOpen: boolean;
    openLogoutModal: () => void;
}

type DropDownMenuProps = {
    open: boolean;
    language: LanguageType;
    openLogoutModal: () => void;
}

const SmallAvatar = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 34,
            height: 34,
        },
    }),
)(Avatar);

const Topbar: FC<TopbarProps> = (props) => {
    const { onSidebarOpen, theme, auth, language, sideBarOpen, openLogoutModal } = props;
    const [notifications, setNotifications] = useState<NotificationProps[]>([]);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [notificationMenuOpen, setNotificationMenu] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const classes = styles();
    const history = useHistory();

    const {
        data: newNotification,
    } = useSubscription(
        requests.notification.subscription.NEW_NOTIFICATION
    );

    const [getNotifications, {
        data: dbNotifications,
        refetch: refetchNotifications
    }] = useLazyQuery(requests.notification.query.GET_NOTIFICATIONS, {
        fetchPolicy: "no-cache",
        variables: {
            read: false,
        }
    });

    const [
        softReadNotifications,
        { loading: loadingSoftReadNotifications }
    ] = useMutation(requests.notification.mutation.SOFT_READ_NOTIFICATIONS);

    const [
        readNotification,
        { loading: loadingReadNotification }
    ] = useMutation(requests.notification.mutation.READ_NOTIFICATION);

    const onNotificationMenuClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setNotificationMenu(false);
    };

    const onMenuToggle = useCallback(() => setMenuOpen(!menuOpen), [menuOpen])

    const onNotificationRead = useCallback((id: number, e: any) => {
        e?.stopPropagation();
        readNotification({
            variables: { id }
        })
            .catch((error) => {
                console.log(error);
            });
    }, [readNotification])

    const onNotificationClick = useCallback((type: NotificationType, relatedId: number, id: number) => {
        onNotificationRead(id, null);
        const page = getPageByNotificationType(type);
        if (page) history.push(strings.pages[page](relatedId).url);
    }, [history, onNotificationRead])

    const onNotificationToggle = useCallback(() => {
        setNotificationMenu(!notificationMenuOpen);
    }, [notificationMenuOpen])

    const onSoftReadNotifications = useCallback(() => {
        softReadNotifications().catch(err => console.log(err));
    }, [softReadNotifications])

    useEffect(() => {
        if (refetchNotifications) refetchNotifications();
    }, [newNotification, loadingSoftReadNotifications, loadingReadNotification, refetchNotifications]);

    useEffect(() => {
        if (dbNotifications)
            setNotifications(dbNotifications.notifications);
    }, [dbNotifications]);

    useEffect(() => {
        getNotifications();
    }, [getNotifications]);

    return (
        <AppBar
            color='transparent'
            position='absolute'
            classes={{ root: classes.root }}
        >
            <Toolbar
                variant='regular'
                classes={{
                    regular: classes.topbar_regular
                }}
            >
                <Notifications
                    anchorEl={anchorRef.current}
                    open={notificationMenuOpen}
                    onClose={onNotificationMenuClose}
                    notifications={notifications}
                    onNotificationRead={onNotificationRead}
                    onSoftReadNotifications={onSoftReadNotifications}
                    onNotificationClick={onNotificationClick}
                    language={language}
                />
                <Hidden lgUp>
                    <Slide in={!sideBarOpen}>
                        <Box className={classes.logo}>
                            {theme.palette.type === 'light' ? <RaisisCRMLogo className={classes.logoSvg} /> : <RaisisCRMLogoDark className={classes.logoSvg} />}
                        </Box>
                    </Slide>
                </Hidden>
                <div className={classes.flexGrow} />
                {/* <Hidden mdDown>
                    <IconButton color="inherit" onClick={toggleTheme}>
                        {theme.palette.type === "light" ? <DarkIcon /> : <LightIcon />}
                    </IconButton>
                </Hidden> */}
                <IconButton color="inherit" onClick={() => history.push('/settings?tab=0')}>
                    <SettingsIcon />
                </IconButton>
                <IconButton ref={anchorRef} color="inherit" onClick={onNotificationToggle}>
                    <Badge badgeContent={notifications ? notifications.filter(item => !item.softRead).length : 0} color="secondary" variant="standard">
                        <NotificationIcon />
                    </Badge>
                </IconButton>
                <Hidden mdDown>
                    <Box className={classes.menuWrapper}>
                        <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                            <Box className={classes.chipWrapper}>
                                <Chip
                                    clickable
                                    onClick={onMenuToggle}
                                    variant="outlined"
                                    color="secondary"
                                    className={classes.profile_chip}
                                    label={
                                        <ResponsiveTypography className={classes.profile_text} fontWeight='normal' lgDown={15} color={theme.palette.primary.contrastText}>
                                            {auth?.me?.data?.fullName}
                                            <ExpandMoreRounded className={clsx({ [classes.arrow]: true, [classes.arrowActive]: menuOpen })} />
                                        </ResponsiveTypography>
                                    }
                                    avatar={
                                        <SmallAvatar
                                            src={
                                                auth?.me?.data?.avatar
                                                    ? `/svg/avatars/avatar-0${auth?.me?.data?.avatar}.svg`
                                                    : ""
                                            }
                                        />
                                    }
                                />
                            </Box>
                        </ClickAwayListener>
                        <DropDownMenu
                            open={menuOpen}
                            language={language}
                            openLogoutModal={openLogoutModal}
                        />
                    </Box>
                </Hidden>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onSidebarOpen} >
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

export default Topbar;

const DropDownMenu: FC<DropDownMenuProps> = ({ open, language, openLogoutModal }) => {
    const classes = styles();
    const history = useHistory();
    const profileString = strings.actions.GO_TO_PAGE(strings.pages.PROFILE.label[language])[language];
    return (
        <Paper elevation={open ? 10 : 0} className={classes.menuRoot}>
            <Collapse in={open}>
                <MenuItem onClick={() => history.push(strings.pages.PROFILE_SETTINGS.url)}>
                    <AccountCircleRounded className={classes.icon} />
                    {profileString.charAt(0).toUpperCase() + profileString.slice(1).toLowerCase()}
                </MenuItem>

                <Divider />

                <MenuItem
                    className={classes.logout}
                    onClick={openLogoutModal}
                >
                    <MeetingRoomRounded className={classes.icon} />
                    {strings.actions.LOG_OUT[language]}
                </MenuItem>
            </Collapse>
        </Paper>
    )
}